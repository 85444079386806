import angular from 'angular';

export default angular
  .module('swoa.ensure-expression', [])
  .directive('swoaEnsureExpression', swoaEnsureExpression)
  .name;

/** @ngInject */
function swoaEnsureExpression($parse) {
  return {
    restrict: 'A',
    require: '^form',
    link: linkEnsureExpression,
  };

  function linkEnsureExpression(scope, element, attrs, ngFormController) {
    function watcherFunction() {
      const valid = $parse(attrs.swoaEnsureExpression)(scope);
      ngFormController[attrs.name].$setValidity('expression', valid);
    }

    if (attrs.expressionWatcher.indexOf(',') >= 0) {
      const watcherArr = $parse(attrs.expressionWatcher)(scope);
      scope.$watchGroup(watcherArr, watcherFunction);
    } else {
      scope.$watch(attrs.expressionWatcher, watcherFunction);
    }
  }
}
