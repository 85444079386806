import angular from 'angular';
import 'restangular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaRoleService from './role.service';
import htmlTemplate from './role-edit.html';

export default angular
  .module('swoa.admin.role.role-edit', [
    'restangular',
    swoaConstants,
    swoaNotification,
    swoaRoleService
  ])
  .component('swoaRoleEdit', {
    template: htmlTemplate,
    bindings: {},
    controller: RoleEditController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function RoleEditController(
  $rootScope,
  $q,
  $location,
  $state,
  $stateParams,
  lodash,
  $translate,
  Restangular,
  notificationService,
  Constants,
  roleService
) {
  const vm = this,
    errorHandler = notificationService.errorHandler(vm);

  vm.roleId = $stateParams.roleId;
  vm.role = null;
  vm.allRoles = null;
  vm.copyFromRoleId = null;
  vm.permissions = null;
  vm.roleTypes = Constants.roleTypes;
  vm.permissionCategories = Constants.permissionCategories;
  vm.cardYearStates = Constants.sportYears.states;
  vm.nwfStates = Constants.nwf.states;
  vm.permissionsByCategory = null;
  vm.notificationKey = $stateParams.notificationKey;
  vm.isSuccess = $stateParams.isSuccess;
  vm.submitted = false;
  vm.isPersonRole = false;

  vm.copyFrom = copyFrom;
  vm.hasPermissions = hasPermissions;
  vm.save = save;
  vm.cancel = cancel;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    const promises = [];
    $rootScope.showSpinner = true;
    $rootScope.$broadcast('swoa:updateBreadcrumbPlaceholder', 'roleName', '');

    if (vm.roleId === 'new') {
      vm.role = {
        id: null,
        permissions: []
      };
      promises.push($q.when());
    } else {
      promises.push(
        roleService.getRole(vm.roleId).then(role => (vm.role = role))
      );
    }
    promises.push(
      roleService.getAllRoles().then(roles => (vm.allRoles = roles))
    );
    promises.push(
      roleService
        .getAllPermissions()
        .then(permissions => (vm.permissions = permissions))
    );

    $q.all(promises)
      .then(() => {
        vm.permissions = lodash.map(vm.permissions, permission => {
          permission.isPersonData =
            permission.name && permission.name.indexOf('DATA_') === 0;
          permission.isPersonDataOwn =
            permission.isPersonData && permission.name.indexOf('_OWN') >= 0;
          permission.isPersonFoto =
            permission.name && permission.name.indexOf('FOTOS') >= 0;
          permission.isPersonFotoOwn =
            permission.isPersonFoto && permission.name.indexOf('_OWN') >= 0;
          permission.isProfileAccess =
            permission.name && permission.name.indexOf('PROFILE_ACCESS') === 0;
          permission.isProfileAccessNoCard =
            permission.name && permission.name === 'PROFILE_ACCESS_NO_CARD';
          permission.isProfileAccessNwfTrainer =
            permission.name && permission.name === 'PROFILE_ACCESS_NWF_TRAINER';

          return Restangular.stripRestangular(permission);
        });

        selectPermissions(vm.role.permissions);

        // add default permissions for new roles
        if (vm.roleId === 'new') {
          lodash.forEach(vm.permissions, permission => {
            if (permission.defaultPermission) {
              permission.selected = true;
            }
          });
          $rootScope.$broadcast(
            'swoa:updateBreadcrumbPlaceholder',
            'roleName',
            $translate.instant('role.new')
          );
        } else {
          $rootScope.$broadcast(
            'swoa:updateBreadcrumbPlaceholder',
            'roleName',
            vm.role.name
          );
          vm.isPersonRole = vm.role.roleType === 'PERSON';
        }
      })
      .catch(errorHandler)
      .finally(() => ($rootScope.showSpinner = false));
  }

  function copyFrom() {
    if (vm.copyFromRoleId !== null) {
      roleService.getRole(vm.copyFromRoleId).then(role => {
        selectPermissions(role.permissions);
      });
    }
  }

  function selectPermissions(permissions) {
    lodash.forEach(vm.permissions, permission => {
      permission.selected = false;
    });
    lodash.forEach(permissions, permission => {
      const listPermission = lodash.find(vm.permissions, { id: permission.id });
      if (listPermission) {
        listPermission.selected = true;
      }
    });
    vm.permissionsByCategory = lodash.groupBy(
      vm.permissions,
      'permissionCategory'
    );
  }

  function hasPermissions(category, state) {
    if (!vm.permissionsByCategory) {
      return false;
    }
    return lodash.some(vm.permissionsByCategory[category], [
      'permissionEntityStatus',
      state
    ]);
  }

  function save(valid) {
    vm.submitted = true;
    if (!valid) {
      return;
    }

    vm.role.permissions = [];
    lodash.forEach(vm.permissions, permission => {
      if (permission.selected) {
        vm.role.permissions.push({
          id: permission.id,
          version: permission.version
        });
      }
    });

    roleService.saveRole(vm.role).then(newRole => {
      $location
        .path(`/roles/${newRole.id}`)
        .search({ notificationKey: 'role.updateSuccessful', isSuccess: true })
        .replace();
    }, errorHandler);
  }

  function cancel() {
    $state.go('roleList');
  }
}
