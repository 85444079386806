import angular from 'angular';

export default angular
  .module('swoa.escape', [])
  .filter('swoaEscape', swoaEscapeFilter)
  .name;

const entityMap = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  '\'': '&#39;',
  // Forward slash escaping is recommended by the owasp
  // See: https://www.owasp.org/index.php/XSS_(Cross_Site_Scripting)_Prevention_Cheat_Sheet
  '/': '&#x2F;',
  // Backticks should be escaped for IE8 and earlier.
  // See: http://html5sec.org/#102
  '`': '&#x60;',
  // Equal sign should be escaped in case of using attributes without quotes.
  // Example: <div class={{somevalue}}>
  '=': '&#x3D;',
};

function replaceFromEntityMap(str) {
  return entityMap[str];
}

function escape(str) {
  if (!str) {
    return undefined;
  }

  return String(str).replace(/[&<>"'`=/]/g, replaceFromEntityMap);
}

/** @ngInject */
function swoaEscapeFilter() {
  return escape;
}

