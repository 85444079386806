import angular from 'angular';
import swoaSportService from './sport.service';
import swoaSportRoutes from './sport.routes';
import swoaSportManager from './sport-manager.component';

export default angular.module('swoa.sport', [
  swoaSportRoutes,
  swoaSportService,
  swoaSportManager,
]).name;
