import angular from 'angular';
import swoaConstants, { APP_CONSTANTS } from '../../app.constants';
import swoaDateService from '../../components/date/date.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaNwfBaseAmountPeriodService from './nwf-base-amount-period.service';
import swoaNwfContributionService from '../nwf-contribution.service';
import htmlTemplate from './nwf-base-amount-period-contribution.html';

export default angular
  .module(
    'swoa.nwf-contribution.base-amount.nwf-base-amount-period-contribution',
    [
      swoaConstants,
      swoaDateService,
      swoaNotification,
      swoaNwfContributionService,
      swoaNwfBaseAmountPeriodService
    ]
  )
  .component('swoaNwfBaseAmountPeriodContribution', {
    template: htmlTemplate,
    bindings: {
      period: '<'
    },
    controller: NwfBaseAmountPeriodContribution,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfBaseAmountPeriodContribution(
  $stateParams,
  $sce,
  $q,
  $scope,
  $translate,
  numberFilter,
  lodash,
  moment,
  notificationService,
  dateService,
  nwfContributionService,
  nwfBaseAmountPeriodService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.years = [];
  vm.baseAmount = null;
  vm.calendarYears = [];
  vm.calendarYearsTotal = null;

  vm.getPayment1 = getPayment1;
  vm.getIntroText = getIntroText;

  // for testing
  vm._initCalendarYearsAndTotal = initCalendarYearsAndTotal;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    $scope.$watch('vm.period', onPeriodChanged);
  }

  function onPeriodChanged() {
    if (vm.period) {
      const promises = [];
      promises.push(nwfContributionService.currentBaseAmountCategories());
      promises.push(
        nwfBaseAmountPeriodService.getCalendarYearPayments(vm.period.id)
      );

      $q.all(promises).then(result => {
        const categories = result[0];
        vm.calendarYears = result[1];

        vm.baseAmount = vm.period.category
          ? lodash.find(categories, ['id', vm.period.category]).amount
          : undefined;

        for (
          let i = 0;
          i <
          dateService.periodLengthInYears(
            vm.period.validFrom,
            vm.period.validUntil
          );
          i++
        ) {
          vm.years.push(
            moment(vm.period.validFrom)
              .add(i, 'years')
              .year()
          );
        }

        initCalendarYearsAndTotal();
      }, onError);
    }
  }

  function getCalendarYear(year) {
    return lodash.find(vm.calendarYears, ['year', year]);
  }

  function getPayment1(year) {
    const calendarYear = getCalendarYear(year);
    if (!calendarYear) {
      return '-';
    }
    return parsePayment(calendarYear.payment1BaseAmount);
  }

  function parsePayment(payment) {
    if (payment !== null && payment !== undefined) {
      return payment;
    } else if (vm.period.status === APP_CONSTANTS.nwf.PAYMENT_FAILED) {
      return 'X';
    }
    return '-';
  }

  function initCalendarYearsAndTotal() {
    lodash.remove(
      vm.calendarYears,
      calendarYear => !vm.years.includes(calendarYear.year)
    );
    if (vm.calendarYears.length === vm.years.length) {
      const totals = lodash.map(vm.calendarYears, year =>
        parsePayment(year.payment1BaseAmount)
      );
      vm.calendarYearsTotal = lodash.sum(
        lodash.filter(totals, lodash.isNumber)
      );
    }
  }

  function getIntroText() {
    if (vm.period) {
      if (vm.period.category) {
        return $sce.trustAsHtml(
          $translate.instant('nwfBaseAmountPeriod.calculation.text', {
            category: vm.period.category,
            amount: numberFilter(vm.baseAmount, 0)
          })
        );
      }
      return $translate.instant('nwfContributions.baseAmount.missingCategory');
    }
    return null;
  }
}
