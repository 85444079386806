import angular from 'angular';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaNotification from '../../components/notification/notification.module';
import swoaFormHelper from '../../components/form-helper/form-helper.service';
import swoaNwfControllingService from './nwf-controlling.service';
import swoaDialog from '../../components/dialog/dialog.module';

export default angular
  .module('swoa.nwf-contribution.controlling.controlling', [
    swoaNotification,
    swoaTranslatable,
    swoaFormHelper,
    swoaDialog,
    swoaNwfControllingService
  ])
  .component('swoaNwfControlling', {
    bindings: {},
    controller: NwfControlling,
    template: ` <form  name="controllingForm"
                       ng-submit="vm.save(controllingForm.$valid)"
                       ng-class="{submitted: vm.submitted}"
                       novalidate>
                       
                  <swoa-notification ng-if="vm.notificationKey"
                     notification-key="vm.notificationKey"
                     notification-values="vm.notificationValues"
                     is-success="vm.notificationSuccess">
                  </swoa-notification>
                  
                  <md-content class="swoa-offset" flex="90" ng-if="vm.calendarYear">
                  
                    <div layout="row" layout-align="start end">
                        <h2 flex>
                            {{'nwfContributions.controlling' | translate }} <strong>{{vm.organisation}}</strong> {{vm.calendarYear.year}}
                        </h2>
                        <swoa-toolbar flex="none"
                                      ng-if="vm.calendarYear.controllingStatus !== 'RELEASED'"
                                      ua-permission="nwf_contribution_controlling_w"
                                      class="toolbar-compact toolbar-grey"
                                      save="vm.editing" save-submit="true"
                                      cancel="vm.editing" cancel-action="vm.cancelEdit()"
                                      edit="!vm.editing" edit-action="vm.startEdit()">
                        </swoa-toolbar>
                    </div>
                    
                    <swoa-nwf-controlling-edit ng-if="vm.calendarYear.controllingStatus !== 'RELEASED'" 
                                                calendar-year="vm.calendarYear"
                                                on-send="vm.sendList"
                                                submitted="vm.submitted"
                                                editing="vm.editing">
                    </swoa-nwf-controlling-edit>
                    <swoa-nwf-controlling-show ng-if="vm.calendarYear.controllingStatus === 'RELEASED'"
                                               calendar-year="vm.calendarYear"
                                               on-edit="vm.editList">
                    </swoa-nwf-controlling-show>
                    
                    <div class="margin-top-10 margin-bottom-20 is-caption">
                        <small>* {{'nwfContributions.excludingVat' | translate}}</small>
                    </div>
  
                  </md-content>
                </form>`,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfControlling(
  $rootScope,
  $stateParams,
  $translate,
  lodash,
  formHelper,
  swoaTranslatableFilter,
  dialogService,
  notificationService,
  nwfControllingService
) {
  const vm = this,
    helper = formHelper.createFormHelper(vm),
    onError = notificationService.errorHandler(vm);

  vm.calendarYearId = $stateParams.calendarYearId;
  vm.calendarYear = undefined;
  vm.organisation = '';

  vm.save = save;
  vm.startEdit = startEdit;
  vm.cancelEdit = cancelEdit;
  vm.sendList = sendList;
  vm.editList = editList;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    nwfControllingService
      .loadCalendarYear(vm.calendarYearId)
      .then(calendarYear => {
        vm.calendarYear = calendarYear;
        vm.organisation = `${swoaTranslatableFilter(
          vm.calendarYear.organisation.name,
          'de'
        )}`;
      }, onError);
  }

  function save(valid) {
    helper.resetNotifications();
    vm.submitted = true;

    if (!valid) {
      vm.notificationKey = 'form.invalid';
      return;
    }

    nwfControllingService
      .updateControllingEntries(
        vm.calendarYearId,
        vm.calendarYear.controllingEntries
      )
      .then(calendarYear => {
        vm.calendarYear = calendarYear;
        vm.calendarYear.controllingEntries = nwfControllingService.sortControllingEntries(
          vm.calendarYear.controllingEntries
        );
        helper.showSuccess('nwfContributions.controlling.save.success');
      }, onError);
  }

  function sendList() {
    helper.resetNotifications();
    dialogService
      .showConfirmationDialog('nwfContributions.controlling.sendDialog')
      .then(() => {
        nwfControllingService
          .sendControllingEntries(vm.calendarYear.id)
          .then(calendarYear => (vm.calendarYear = calendarYear), onError);
      });
  }

  function editList() {
    helper.resetNotifications();
    nwfControllingService
      .editControllingEntries(vm.calendarYear.id)
      .then(calendarYear => (vm.calendarYear = calendarYear), onError);
  }

  function startEdit() {
    vm.originalCalendarYear = angular.copy(vm.calendarYear);
    helper.startEdit();
  }

  function cancelEdit() {
    vm.calendarYear = vm.originalCalendarYear;
    vm.originalCalendarYear = null;
    helper.cancelEdit();
  }
}
