import angular from 'angular';
import swoaNotificationComponent from './notification.component';
import swoaNotificationService from './notification.service';

export default angular
  .module('swoa.notification', [
    swoaNotificationComponent,
    swoaNotificationService,
  ])
  .name;

