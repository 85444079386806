import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaAsyncService from '../../components/async/async-helper.service';
import swoaDate from '../../components/date/date.filter';
import swoaExportService from './export.service';
import swoaReportHelper from './report-helper.service';
import swoaNwfContributionService from '../../nwf-contribution/nwf-contribution.service';

export default angular
  .module('swoa.admin.report.exports.nwfBaseAmount', [
    angularTranslate,
    swoaConstants,
    swoaNotification,
    swoaAsyncService,
    swoaDate,
    swoaExportService,
    swoaReportHelper,
    swoaNwfContributionService
  ])
  .component('swoaExportsNwfBaseAmount', {
    template: `
<swoa-notification ng-if="vm.notificationKey"
                   notification-key="vm.notificationKey"
                   notification-values="vm.notificationValues"
                   is-success="vm.notificationSuccess">
</swoa-notification>
<md-content class="swoa-offset">
  <form name="exportForm" ng-submit="vm.generate()" flex="70">
    <swoa-nwf-export-base-amount-filter 
      flex
      ng-if="vm.maxYear"
      organisations="vm.organisations"
      organisation-ids="vm.exportParams.organisationsIds"
      export-from="vm.exportParams.exportFrom"
      export-until="vm.exportParams.exportUntil"
      max-year="vm.maxYear">
    </swoa-nwf-export-base-amount-filter>
    <swoa-exports-attributes-selector attributes="vm.attributes"></swoa-exports-attributes-selector>
    <swoa-export-buttons on-reset-export-params="vm.resetExportParams()"></swoa-export-buttons>
  </form>
</md-content>
`,
    bindings: {},
    controller: ExportsNwfBaseAmountController,
    controllerAs: 'vm'
  }).name;

export const NWF_START_YEAR = 2018;

/** @ngInject */
function ExportsNwfBaseAmountController(
  $scope,
  $q,
  lodash,
  $translate,
  notificationService,
  asyncHelperService,
  Constants,
  exportService,
  userService,
  reportHelperService,
  nwfContributionService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    reportHelper = reportHelperService.createReportHelper(
      vm,
      $scope,
      'exportNwfBaseAmountFavorites',
      { getInitialExportParamState }
    ),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      reportHelper.downloadReport
    );

  vm.organisations = [];
  vm.attributes = { selected: [], all: [], saved: [] };
  vm.exportParams = {};
  vm.uiConfig = {};

  vm.resetExportParams = reportHelper.resetExportParams;
  vm.generate = generate;

  vm.$onInit = () => {
    activate();
  };

  // //////

  function activate() {
    initializeDataFromServices().then(reportHelper.resetExportParams);
    asyncHelper.getAsyncJobStatus();
    vm.uiConfig = userService.getCurrentUser().uiConfig;
  }

  function initializeDataFromServices() {
    const promises = [];
    promises.push(
      exportService
        .loadOrganisations()
        .then(organisations => (vm.organisations = organisations))
    );
    promises.push(
      exportService
        .loadExportableColumnsNwfBaseAmount()
        .then(reportHelper.translateAttributes)
    );
    promises.push(
      nwfContributionService
        .getMaxYear()
        .then(maxYear => (vm.maxYear = maxYear))
    );
    return $q.all(promises).catch(onError);
  }

  function getInitialExportParamState() {
    const exportParams = {
      organisationsIds: []
    };
    if (vm.organisations && vm.organisations.length === 1) {
      exportParams.organisationsIds.push(vm.organisations[0].id);
    }
    reportHelper.setExportDateRange(exportParams);
    return exportParams;
  }

  function generate() {
    const parameters = reportHelper.prepareToSerializeAsFavorite();
    asyncHelper.showSpinner();
    exportService
      .loadExport(
        `nwf-base-amount/${$translate.use()}`,
        buildFileName(),
        parameters
      )
      .then(asyncHelper.getAsyncJobStatus)
      .catch(asyncHelper.errorHandler);
  }

  function buildFileName() {
    const language = $translate.use();

    const fileNameElements = ['sockelbeitraege', language.toUpperCase()];

    return `${fileNameElements.filter(e => !!e).join('_')}.xlsx`;
  }
}
