import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaDate from '../../components/date/date.filter';
import swoaNotification from '../../components/notification/notification.module';
import swoaPersonSync from '../sync/person-sync.module';
import htmlTemplate from './person-mutation-sync.html';

export default angular
  .module('swoa.person.mutation.person-mutation-sync', [
    swoaDate,
    swoaNotification,
    swoaPersonSync,
    angularTranslate
  ])
  .component('swoaPersonMutationSync', {
    template: htmlTemplate,
    bindings: {},
    controller: PersonMutationSyncController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonMutationSyncController(
  $stateParams,
  $state,
  personMutationService,
  notificationService,
  personSyncService
) {
  const vm = this;

  vm.personMutationId = null;
  vm.personMutation = null;
  vm.syncData = null;

  // error handling
  vm.notificationKey = null;

  vm.saveSync = saveSync;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.personMutationId = $stateParams.personMutationId;

    if (vm.personMutationId) {
      personMutationService
        .loadPersonMutation(vm.personMutationId)
        .then(personMutation => {
          vm.personMutation = personMutation;
          personMutation.configKey = 'personKey';
          vm.syncData = personSyncService.extendSyncModel({
            cardImport: personMutation,
            person: personMutation.originalPerson
          });
        });
    }
  }

  function saveSync() {
    vm.syncData.syncResult.originalPerson = vm.personMutation.originalPerson;
    vm.syncData.syncResult.version = vm.personMutation.version;
    vm.syncData.syncResult.id = vm.personMutation.id;
    personMutationService
      .saveSync(vm.personMutation.id, vm.syncData.syncResult)
      .then(() => {
        $state.go('personMutations', $stateParams);
      }, notificationService.errorHandler(vm));
  }
}
