import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.sport.sport-manager', [])
  .component('swoaSportManager', {
    template:
      '<md-content class="swoa-offset">' +
      '<p>{{ "sport.sportmanager.info" | translate }}</p>' +
      '<p><a href="/sportmanager" target="_blank">{{ "sport.sportmanager.open" | translate }}</a></p>' +
      '</md-content>',
    controller: SportManagerController,
  }).name;

/** @ngInject */
function SportManagerController($window) {
  $window.open('/sportmanager', '_blank');
}
