import angular from 'angular';
import swoaUserRoutes from './user.routes';
import swoaUserService from './user.service';
import swoaUserChangePassword from './change-password.component';
import swoaUserResetPassword from './reset-password.component';
import swoaUserUserList from './user-list.component';
import swoaUserUserDetail from './user-detail.component';
import swoaUserSelectPerson from './user-select-person.component';
import swoaUserUserCreatePerson from './user-create-person.component';

export default angular
  .module('swoa.user', [
    swoaUserRoutes,
    swoaUserService,
    swoaUserChangePassword,
    swoaUserResetPassword,
    swoaUserUserList,
    swoaUserUserDetail,
    swoaUserSelectPerson,
    swoaUserUserCreatePerson,
  ])
  .name;

