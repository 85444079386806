import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaStringUtil from '../../components/string-util/string-util.service';
import swoaObjectProperty from '../../components/object-property/object-property.filter';
import swoaPersonSyncService from './person-sync.service';
import htmlTemplate from './person-sync.html';

export default angular
  .module('swoa.person.sync.person-sync', [
    uiRouter,
    swoaStringUtil,
    swoaObjectProperty,
    swoaPersonSyncService
  ])
  .component('swoaPersonSync', {
    template: htmlTemplate,
    bindings: {
      syncData: '=',
      translationPrefix: '@',
      cancelState: '@',
      saveSync: '&'
    },
    controller: PersonSyncController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonSyncController(
  $scope,
  $state,
  $stateParams,
  lodash,
  personSyncService
) {
  const vm = this;

  vm.showSelectRevert = showSelectRevert;
  vm.choosePerson = choosePerson;
  vm.chooseAllPerson = chooseAllPerson;
  vm.chooseImport = chooseImport;
  vm.chooseAllImport = chooseAllImport;
  vm.revertSelection = revertSelection;
  vm.syncComplete = syncComplete;
  vm.cancel = cancel;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.syncData', updateRequiredProperties);
  }

  function updateRequiredProperties() {
    if (vm.syncData && vm.syncData.cardImport.sportYear) {
      const requiredProperties =
        personSyncService[
          `ADDITIONAL_${vm.syncData.cardImport.sportYear.category}_REQUIRED`
        ];
      lodash.forEach(requiredProperties, property => {
        const requiredProperty = lodash.find(vm.syncData.config, {
          importKey: property.key
        });
        if (requiredProperty) {
          requiredProperty.required = property.requiredValue === true;
        }
      });
    }
  }

  function showSelectRevert(config, obj) {
    const objKey = config[obj.configKey];
    const value = lodash.get(obj, objKey);
    const syncValue = lodash.get(vm.syncData.syncResult, config.personKey);

    return (
      !config.infoOnly &&
      config.syncNeeded &&
      (!config.syncDone || value === syncValue)
    );
  }

  function choosePerson(config) {
    config.syncDone = true;
    lodash.set(
      vm.syncData.syncResult,
      config[vm.syncData.syncResult.configKey],
      lodash.get(vm.syncData.person, config[vm.syncData.person.configKey])
    );
  }

  function chooseAllPerson() {
    chooseAll(choosePerson);
  }

  function chooseImport(config) {
    config.syncDone = true;
    lodash.set(
      vm.syncData.syncResult,
      config[vm.syncData.syncResult.configKey],
      lodash.get(
        vm.syncData.cardImport,
        config[vm.syncData.cardImport.configKey]
      )
    );
  }

  function chooseAllImport() {
    chooseAll(chooseImport);
  }

  function revertSelection(config) {
    config.syncDone = false;
    lodash.set(
      vm.syncData.syncResult,
      config.personKey,
      personSyncService.PROPERTY_NOT_SET_VALUE
    );
  }

  function chooseAll(chooseFunc) {
    lodash.forEach(vm.syncData.config, config => {
      if (config.syncNeeded && !config.infoOnly) {
        chooseFunc(config);
      }
    });
  }

  function syncComplete() {
    if (vm.syncData === null) {
      return false;
    }
    return !personSyncService.hasUnsetProperty(vm.syncData.syncResult);
  }

  function cancel() {
    $state.go(vm.cancelState, $stateParams);
  }
}
