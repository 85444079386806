import angular from 'angular';
import htmlTemplate from './address.html';

export default angular.module('swoa.address', []).component('swoaAddress', {
  template: htmlTemplate,
  bindings: {
    address: '=',
    editing: '<',
    submitted: '<',
    isReadOnly: '<',
    suppressTitle: '<'
  },
  controller: AddressController,
  controllerAs: 'vm'
}).name;

/** @ngInject */
function AddressController($scope, $timeout) {
  const vm = this;

  vm.$onInit = () => {
    activate();
  };

  function activate() {
    $scope.$watch('vm.address.country', handleCountryChange);
    $scope.$watch('vm.submitted', handleSubmitted);
  }

  function handleCountryChange(oldValue, newValue) {
    if (oldValue && newValue && oldValue !== newValue) {
      $timeout(() => {
        ['zip', 'city', 'state'].forEach(prop => {
          $scope.addressForm[prop].$setTouched(true);
          vm.address[prop] = undefined;
        });
        vm.searchCity = undefined;
        vm.searchZip = undefined;
      });
    }
  }

  function handleSubmitted() {
    if (vm.submitted) {
      // we need to use ng-form instead of form for the address to avoid nested forms (browsers just ignore the nested one
      // sometimes). but the ng-form doesn't handle the ng-invalid-required the same way as the normal form does.
      // setting all the required fields to touched solves the problem.
      ['streetAndNumber', 'city', 'zip'].forEach(prop => {
        if ($scope.addressForm[prop]) {
          $scope.addressForm[prop].$setTouched(true);
        }
      });
    }
  }
}
