import angular from 'angular';
import htmlTemplate from './notification.html';

export default angular
  .module('swoa.notification.component', [])
  .component('swoaNotification', {
    template: htmlTemplate,
    bindings: {
      notificationKeyList: '<',
      notificationKey: '=',
      notificationValues: '=',
      isSuccess: '<',
      isInfo: '<',
    },
    controller: NotificationController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function NotificationController() {
  const vm = this;

  vm.closePanel = closePanel;
  vm.showNotification = showNotification;

  // /////////

  function closePanel() {
    vm.notificationKeyList = undefined;
    vm.notificationKey = undefined;
  }

  function showNotification() {
    if (vm.notificationKeyList) {
      return vm.notificationKeyList.length > 0;
    }
    return vm.notificationKey !== undefined;
  }
}
