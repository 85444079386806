import angular from 'angular';
import ngMaterial from 'angular-material';
import htmlTemplate from './dialog.html';

export default angular
  .module('swoa.dialog.dialog', [
    ngMaterial,
  ])
  .component('swoaDialog', {
    template: htmlTemplate,
    replace: true,
    transclude: true,
    bindings: {
      closeable: '<',
      dialogTitle: '<',
    },
    controller: DialogController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function DialogController($mdDialog) {
  const vm = this;

  vm.hide = $mdDialog.hide;
  vm.cancel = $mdDialog.cancel;
}
