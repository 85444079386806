import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaDataTable from '../components/data-table/data-table.module';
import htmlTemplate from './cockpit-new-person-list.html';

export default angular
  .module('swoa.cockpit.cockpit-new-person-list', [
    angularTranslate,
    swoaDataTable
  ])
  .component('swoaCockpitNewPersonList', {
    template: htmlTemplate,
    bindings: {
      limit: '<'
    },
    controller: CockpitNewPersonListController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CockpitNewPersonListController(personService) {
  const vm = this;

  vm.personCreationDetails = null;
  vm.running = true;

  vm.$onInit = () => {
    activate();
  };

  // /////////

  function activate() {
    personService.loadNewPersons(0, vm.limit).then(personCreationDetails => {
      vm.personCreationDetails = personCreationDetails;
      vm.running = false;
    });
  }
}
