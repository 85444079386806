import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import LocalStorageModule from 'angular-local-storage';
import swoaConstants from './app.constants';

export default angular
  .module('swoa.run', [
    uiRouter,
    angularTranslate,
    LocalStorageModule,
    swoaConstants
  ])
  .run(runBlock).name;

/** @ngInject */
function runBlock(
  $window,
  $rootScope,
  $state,
  $transitions,
  moment,
  $translate,
  lodash,
  localStorageService,
  ngUserAuthInfoService,
  ConfigConstants
) {
  // put the module state name to the root scope so we can use it to place and translate the title
  // in the header since it's outside of the ui-view.

  $transitions.onStart({}, transition => {
    const newStateName = transition.to().name.match(/^[^.]+/)[0];
    if (newStateName !== $rootScope.moduleStateName) {
      $rootScope.moduleStateName = newStateName;
    }
  });

  $rootScope.$on('$translateChangeSuccess', () => {
    const language = $translate.use();
    $rootScope.currentLanguage = language;
    moment.locale(language);
    const oldLanguage = localStorageService.get(
      ConfigConstants.translation.localStorageKeyName
    );
    localStorageService.set(
      ConfigConstants.translation.localStorageKeyName,
      language
    );
    if (oldLanguage && oldLanguage !== language) {
      $window.location.reload();
    }
  });

  $rootScope.$on('showBackdrop', () => ($rootScope.showBackdrop = true));
  $rootScope.$on('hideBackdrop', () => ($rootScope.showBackdrop = false));
  $rootScope.$on('showSpinner', () => ($rootScope.showSpinner = true));
  $rootScope.$on('hideSpinner', () => ($rootScope.showSpinner = false));

  // expose the userAuthInfoService to the root scope for easy access
  $rootScope.authInfo = ngUserAuthInfoService;
  $rootScope.getUserRoles = () => (ngUserAuthInfoService.getUser() || {}).roles;
  $rootScope.getUniqueUserRoleTypes = () =>
    lodash.uniq(lodash.map($rootScope.getUserRoles(), role => role.roleType));
  $rootScope.userHasRole = roleType =>
    lodash.some($rootScope.getUserRoles(), role => role.roleType === roleType);
  $rootScope.userHasOnlyRole = roleType =>
    lodash.isEqual($rootScope.getUniqueUserRoleTypes(), [roleType]);
  $rootScope.isSwoaUser = () => $rootScope.userHasRole('SWOA');
  $rootScope.isAssociationUser = () => $rootScope.userHasRole('ASSOCIATION');
  $rootScope.isCantonUser = () => $rootScope.userHasRole('CANTON');
  $rootScope.isPersonUser = () => $rootScope.userHasRole('PERSON');
  $rootScope.uaPermission = ngUserAuthInfoService.userHasPermission;
  $rootScope.uaAnyPermission = ngUserAuthInfoService.userHasAnyPermission;
  $rootScope.uaLacksPermission = ngUserAuthInfoService.userLacksPermission;
  $rootScope.goto = $state.go;

  $rootScope.personPermission = (person, part, action) => {
    const isOwnProfile =
      (ngUserAuthInfoService.getUser() || { person: {} }).person.id ===
      person.id;
    const permissionPart = isOwnProfile ? `${part}_own` : part;
    const anyCardPermission = $rootScope.uaPermission(
      `person_${permissionPart}_${action}`
    );
    return (
      anyCardPermission ||
      (person.isTalentAthlete &&
        $rootScope.uaPermission(`person_${permissionPart}_${action}_talent`)) ||
      (person.isEliteAthlete &&
        $rootScope.uaPermission(`person_${permissionPart}_${action}_elite`)) ||
      (person.isTrainer &&
        $rootScope.uaPermission(
          `person_${permissionPart}_${action}_trainer`
        )) ||
      (person.isTechnician &&
        $rootScope.uaPermission(
          `person_${permissionPart}_${action}_technician`
        ))
    );
  };

  const storedLanguage = localStorageService.get(
    ConfigConstants.translation.localStorageKeyName
  );
  $rootScope.currentLanguage = storedLanguage;
  if (storedLanguage && storedLanguage !== $translate.use()) {
    $translate.use(storedLanguage);
  }
}
