import angular from 'angular';
import swoaPersonProfileTimeline from './person-profile-timeline.component';
import swoaPersonTimelineEntry from './person-timeline-entry.component';
import swoaPersonTimelineEntryCard from './person-timeline-entry-card.component';
import swoaPersonTimelineEntryResult from './person-timeline-entry-result.component';
import swoaPersonTimelineEntryFile from './person-timeline-entry-file.component';

export default angular
  .module('swoa.person.timeline', [
    swoaPersonProfileTimeline,
    swoaPersonTimelineEntry,
    swoaPersonTimelineEntryCard,
    swoaPersonTimelineEntryResult,
    swoaPersonTimelineEntryFile,
  ])
  .name;

