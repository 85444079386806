import angular from 'angular';
import moment from 'moment';
import * as lodash from 'lodash';
import angularTranslate from 'angular-translate';

export const CARDTYPE = {
  GOLD: 'GOLD',
  SILVER: 'SILVER',
  BRONZE: 'BRONZE',
  ELITE: 'ELITE',
  NATIONAL: 'NATIONAL',
  REGIONAL: 'REGIONAL',
  LOCAL: 'LOCAL',
  TRAINER: 'TRAINER',
  TECHNICIAN: 'TECHNICIAN',
  FUNCTIONARY: 'FUNCTIONARY',
};

export const FTEMTYPE = {
  MASTERY: 'MASTERY',
  ELITE_1: 'ELITE_1',
  ELITE_2: 'ELITE_2',
  TALENT_1: 'TALENT_1',
  TALENT_2: 'TALENT_2',
  TALENT_3: 'TALENT_3',
  TALENT_4: 'TALENT_4',
};

export const CATEGORY = {
  ELITE: 'ELITE',
  TALENT: 'TALENT',
  TRAINER: 'TRAINER',
  TECHNICIAN: 'TECHNICIAN',
  FUNCTIONARY: 'FUNCTIONARY',
};

export const APP_CONSTANTS = {
  cards: {
    category: {
      ELITE: {
        types: [
          CARDTYPE.GOLD,
          CARDTYPE.SILVER,
          CARDTYPE.BRONZE,
          CARDTYPE.ELITE,
        ],
      },
      TALENT: {
        types: [CARDTYPE.NATIONAL, CARDTYPE.REGIONAL, CARDTYPE.LOCAL],
      },
      TRAINER: {
        types: [CARDTYPE.TRAINER],
      },
      TECHNICIAN: {
        types: [CARDTYPE.TECHNICIAN],
        responsibilities: [
          'HEAD_CHIEF',
          'CHIEF_LEISTUNGSSPORT',
          'CHIEF_NACHWUCHS',
          'CHIEF_AUSBILDUNG',
        ],
      },
      FUNCTIONARY: {
        types: [CARDTYPE.FUNCTIONARY],
      },
    },
    categories: [
      CATEGORY.ELITE,
      CATEGORY.TALENT,
      CATEGORY.TRAINER,
      CATEGORY.TECHNICIAN,
      CATEGORY.FUNCTIONARY,
    ],
    cardTypes: [
      CARDTYPE.GOLD,
      CARDTYPE.SILVER,
      CARDTYPE.BRONZE,
      CARDTYPE.ELITE,
      CARDTYPE.NATIONAL,
      CARDTYPE.REGIONAL,
      CARDTYPE.LOCAL,
      CARDTYPE.TRAINER,
      CARDTYPE.TECHNICIAN,
      CARDTYPE.FUNCTIONARY,
    ],
    ftemTypes: [
      FTEMTYPE.MASTERY,
      FTEMTYPE.ELITE_2,
      FTEMTYPE.ELITE_1,
      FTEMTYPE.TALENT_4,
      FTEMTYPE.TALENT_3,
      FTEMTYPE.TALENT_2,
      FTEMTYPE.TALENT_1,
    ],
  },
  sportYears: {
    states: [
      'NEW',
      'FOR_CONSIDERATION',
      'REVISION_NEEDED',
      'PROVISIONALLY_RELEASED',
      'RELEASED',
      'CLOSED',
    ],
    staffStates: ['NEW', 'FOR_CONSIDERATION', 'RELEASED', 'CLOSED'],
  },
  sport: {
    classifications: [
      'LEVEL_1',
      'LEVEL_2',
      'LEVEL_3',
      'LEVEL_4',
      'LEVEL_5',
      'TO_BE_DEFINED',
      'NONE',
    ],
    olympic: ['OLYMPIC', 'NON_OLYMPIC', 'PARALYMPIC'],
    types: ['INDIVIDUAL', 'SMALL_TEAM', 'LARGE_TEAM'],
    seasons: ['SUMMER', 'WINTER'],
  },
  months: [
    { id: 1, month: 'JANUARY' },
    { id: 2, month: 'FEBRUARY' },
    { id: 3, month: 'MARCH' },
    { id: 4, month: 'APRIL' },
    { id: 5, month: 'MAY' },
    { id: 6, month: 'JUNE' },
    { id: 7, month: 'JULY' },
    { id: 8, month: 'AUGUST' },
    { id: 9, month: 'SEPTEMBER' },
    { id: 10, month: 'OCTOBER' },
    { id: 11, month: 'NOVEMBER' },
    { id: 12, month: 'DECEMBER' },
  ],
  genders: ['MALE', 'FEMALE'],
  nationalities: ['SWISS', 'OTHER'],
  roleTypes: ['SWOA', 'ASSOCIATION', 'CANTON', 'PERSON'],
  institutionLevels: ['NATIONAL', 'OTHER'],
  permissionCategories: [
    'CARDLIST',
    'PERSON_MANAGEMENT',
    'ORGANISATION_MANAGEMENT',
    'SPORT_MANAGEMENT',
    'HOME',
    'ADMINISTRATION',
    'NWF_CONTRIBUTIONS',
  ],
  organisationTypes: [
    {
      type: 'ASSOCIATION',
      categories: ['NATIONAL_ASSOCIATION', 'PARTNER_ORGANISATION'],
    },
    { type: 'CANTON', categories: ['SPORT_DEPARTMENT'] },
  ],
  // Regex source: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
  validationExpressions: {
    email: `^[a-zA-Z0-9.!#$%&'*+/=?^_\`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$`,
  },
  reports: {
    selectionTypes: ['CURRENTLY_RELEASED_CARDS', 'TIME_RANGE', 'CARD_CHANGES'],
  },
  nwf: {
    states: [
      'NEW',
      'FOR_CONSIDERATION',
      'REVISION_NEEDED',
      'RELEASED',
      'PAYMENT_FAILED',
    ],
    PAYMENT_FAILED: 'PAYMENT_FAILED',
  },
  recognition: {
    startYear: 1960,
  },
  nullValue: '__EXPLICIT_NULL_VALUE__',
};

export default angular
  .module('swoa.constants', [angularTranslate])
  .constant('moment', moment)
  .constant('lodash', lodash)
  .constant('Constants', APP_CONSTANTS)
  .provider('ConfigConstants', function ConfigProvider() {
    let urlHostPort = '';

    this.setUrlHostPort = (hostPort) => {
      urlHostPort = hostPort;
    };

    this.$get = () => ({
      api: {
        url: `${urlHostPort}/adm-domain/api`,
      },
      upload: {
        url: {
          TALENT: `${urlHostPort}/adm-domain/api/import/excel/talent-cards`,
          ELITE: `${urlHostPort}/adm-domain/api/import/excel/elite-cards`,
          TRAINER: `${urlHostPort}/adm-domain/api/import/excel/trainer-cards`,
          TECHNICIAN: `${urlHostPort}/adm-domain/api/import/excel/technician-cards`,
          FUNCTIONARY: `${urlHostPort}/adm-domain/api/import/excel/functionary-cards`,
          TALENT_CARDS: `${urlHostPort}/adm-domain/api/import/talent/cards`,
        },
      },
      translation: {
        prefix: `${urlHostPort}/adm-domain/api/translations/`,
        suffix: '',
        localStorageKeyName: 'language',
        preferredLanguage: 'de',
        availableLanguages: ['de', 'fr', 'it'],
        availableLanguagesEnum: ['GERMAN', 'FRENCH', 'ITALIAN'],
      },
    });
  }).name;
