import angular from 'angular';

export default angular
  .module('swoa.nwf-contribution.controlling.entry', [])
  .component('swoaNwfControllingEntry', {
    template: ` <ng-form name="entryForm"
                    ng-submit="vm.save(entryForm.$valid)"
                    ng-class="{submitted: vm.submitted}"
                    novalidate>
                    
                    <div flex layout="row" layout-align="start start">
                        <swoa-input-select ng-model="vm.entry.sport"
                                     name="sport"
                                     label-key="organisation.sport"
                                     id-value-list="vm.sports"
                                     value-property="displayName"
                                     option-as-value="true"
                                     is-read-only="!vm.editing"
                                     is-required="true"
                                     messages="entryForm.sport.$error"
                                     messages-if="vm.submitted || entryForm.sport.$touched"
                                     flex="25">
                        </swoa-input-select>
                        <md-autocomplete flex="25"
                                          ng-required="true"
                                          md-input-name="institution"
                                          md-floating-label="{{'nwfInstitution' | translate}} {{vm.editing ? '*' : ''}}"
                                          md-selected-item="vm.entry.institution" 
                                          md-search-text="vm.searchText"
                                          md-items="i in vm.filteredInstitutions | filter: vm.searchText" 
                                          md-item-text="i.name"
                                          md-require-match="true"
                                          md-min-length="0"
                                          ng-disabled="!vm.editing"
                                          md-no-asterisk="true"
                                          md-no-cache="true">
                          <md-item-template>
                              <span class="{{i.strong ? 'strong' : ''}}">{{ i.name }}</span>
                          </md-item-template>
                          <div class="select-validation-messages"   
                                ng-messages="entryForm.institution.$error"
                                role="alert"
                                ng-if="vm.submitted || entryForm.institution.$touched">
                              <swoa-validator-messages></swoa-validator-messages>
                          </div>
                        </md-autocomplete>
                        <swoa-input-number ng-model="vm.entry.amount"
                                   name="amount"
                                   label-key="amount.CHF"
                                   is-read-only="!vm.editing"
                                   is-required="true"
                                   messages="entryForm.amount.$error"
                                   messages-if="vm.submitted || entryForm.amount.$touched"
                                   flex="25">
                        </swoa-input-number>
                        <md-button class="md-accent md-hue-1 has-icon"
                                   ng-show="vm.editing"
                                   ng-click="vm.onDelete()">
                          <swoa-icon name="delete" class="is-small"></swoa-icon>
                          <span translate="app.deleteRow"></span>
                        </md-button>
                    </div>
                </ng-form>`,
    bindings: {
      entry: '=',
      onDelete: '&',
      submitted: '<',
      editing: '<',
      sports: '<',
      institutions: '<'
    },
    controller: NwfControllingEntry,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfControllingEntry($scope, lodash) {
  const vm = this;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    $scope.$watch('vm.submitted', handleSubmitted);
    $scope.$watch('vm.entry.sport', updateInstitutions);

    if (vm.entry.sport) {
      vm.entry.sport = lodash.find(vm.sports, s => s.id === vm.entry.sport.id);
    }

    vm.filteredInstitutions = vm.institutions;
  }

  function updateInstitutions() {
    if (vm.entry.sport) {
      vm.filteredInstitutions = lodash.filter(
        vm.institutions,
        i => lodash.findIndex(i.sports, ['id', vm.entry.sport.id]) >= 0
      );
      vm.entry.institution = lodash.find(
        vm.filteredInstitutions,
        i => i.id === vm.entry.institution.id
      );
    }
  }

  function handleSubmitted() {
    if (vm.submitted) {
      // we need to use ng-form instead of form for the address to avoid nested forms (browsers just ignore the nested one
      // sometimes). but the ng-form doesn't handle the ng-invalid-required the same way as the normal form does.
      // setting all the required fields to touched solves the problem.
      ['sport', 'institution', 'amount'].forEach(prop => {
        if ($scope.entryForm[prop]) {
          $scope.entryForm[prop].$setTouched(true);
        }
      });
    }
  }
}
