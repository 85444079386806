import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.nwf-contribution.variable-amount.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('nwfVariableAmountPeriod', {
      url: '/nwf-periods/:organisationId/variable/:periodId',
      template:
        '<swoa-nwf-variable-amount-period current-tab="selectedTab"></swoa-nwf-variable-amount-period>',
      controller($scope, $state, $transitions) {
        if ($state.current.name === 'nwfVariableAmountPeriod') {
          $state.go('nwfVariableAmountPeriod.foundation');
          $scope.selectedTab = 'foundation';
        } else {
          $scope.selectedTab = $state.current.data.selectedTab;
        }

        $transitions.onSuccess(
          { to: 'nwfVariableAmountPeriod.**' },
          transition => {
            if (transition.to().name === 'nwfVariableAmountPeriod') {
              $state.go('nwfVariableAmountPeriod.foundation');
              $scope.selectedTab = 'foundation';
            } else {
              $scope.selectedTab = $state.current.data.selectedTab;
            }
          }
        );
      },
      controllerAs: 'vm',
      data: {
        breadcrumb: {
          parent: 'nwfPeriods',
          title: {
            stateParam: 'periodId',
            service: 'nwfVariableAmountPeriodService'
          }
        },
        hasPermission: 'nwf_contribution_r'
      }
    })
    .state('nwfVariableAmountPeriod.foundation', {
      url: '/foundation',
      views: {
        foundation: {
          template:
            '<swoa-nwf-variable-amount-period-foundation period="vm.period"></swoa-nwf-variable-amount-period-foundation>'
        }
      },
      data: {
        selectedTab: 'foundation',
        breadcrumb: {
          parent: 'nwfVariableAmountPeriod',
          ignore: true
        },
        hasPermission: 'nwf_contribution_r'
      }
    })
    .state('nwfVariableAmountPeriod.foundation.detail', {
      url: '/:trainerId?state',
      views: {
        'detail@nwfVariableAmountPeriod': {
          template: `<swoa-nwf-variable-amount-period-foundation-detail period="vm.period">
                     </swoa-nwf-variable-amount-period-foundation-detail>`
        }
      },
      data: {
        hasPermission: 'nwf_contribution_r'
      }
    })
    .state('variableAmountAddTrainer', {
      url: '/nwf-periods/:organisationId/variable/:periodId/addTrainer',
      template:
        '<swoa-nwf-variable-amount-period-add-trainer></swoa-nwf-variable-amount-period-add-trainer>',
      data: {
        breadcrumb: {
          parent: 'nwfVariableAmountPeriod.foundation'
        },
        hasAnyPermission: [
          'nwf_contribution_variable_amount_trainer_w_new',
          'nwf_contribution_variable_amount_trainer_w_for_consideration',
          'nwf_contribution_variable_amount_trainer_w_revision_needed',
          'nwf_contribution_variable_amount_trainer_w_released'
        ]
      }
    })
    .state('nwfContributionVariableAmountCreatePerson', {
      url: '/nwf-periods/:organisationId/variable/:periodId/createPerson',
      template:
        '<swoa-nwf-variable-amount-period-create-person></swoa-nwf-variable-amount-period-create-person>',
      data: {
        breadcrumb: {
          parent: 'nwfVariableAmountPeriod.foundation'
        },
        hasPermission: 'person_create_w'
      }
    })
    .state('nwfVariableAmountPeriod.contribution', {
      url: '/contribution',
      views: {
        contribution: {
          template: `<swoa-nwf-variable-amount-period-contribution period="vm.period" sum-percent="vm.sumPercent">
                     </swoa-nwf-variable-amount-period-contribution>`
        }
      },
      data: {
        selectedTab: 'contribution',
        breadcrumb: {
          parent: 'nwfVariableAmountPeriod',
          ignore: true
        },
        hasPermission: 'nwf_contribution_r'
      }
    });
}
