import angular from 'angular';
import swoaPersonCreationRoutes from './person-creation.routes';
import swoaPersonCreationPersonCreationAssign from './person-creation-assign.component';
import swoaPersonCreationPersonCreationDetail from './person-creation-detail.component';
import swoaPersonCreationPersonCreationSync from './person-creation-sync.component';
import swoaPersonCreationPersonCreationSimilarPersonsTable from './person-creation-similar-persons-table.component';

export default angular
  .module('swoa.person.creation', [
    swoaPersonCreationRoutes,
    swoaPersonCreationPersonCreationAssign,
    swoaPersonCreationPersonCreationDetail,
    swoaPersonCreationPersonCreationSync,
    swoaPersonCreationPersonCreationSimilarPersonsTable,
  ])
  .name;

