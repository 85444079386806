import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.jobs.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('jobsList', {
    url: '/admin/jobs',
    template: '<swoa-jobs-list></swoa-jobs-list>',
    data: {
      breadcrumb: true,
      hasPermission: 'admin_jobs_r'
    }
  });
}
