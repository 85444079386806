import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaNotification from '../../components/notification/notification.module';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import htmlTemplate from './news-list.html';
import urlHelper from '../../components/url-helper/url-helper';

export default angular
  .module('swoa.admin.news.news-list', [
    uiRouter,
    swoaNotification,
    swoaTranslatable,
  ])
  .component('swoaNewsList', {
    template: htmlTemplate,
    bindings: {},
    controller: NewsListController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function NewsListController($scope, $window, $translate, $stateParams) {
  const vm = this;

  let newsUrl = new URL($window.location);
  newsUrl.pathname = '/admin/news';
  vm.$onInit = () => {
    vm.language = $translate.use() || $translate.proposedLanguage();

    if ($stateParams && $stateParams.id) {
      newsUrl.pathname += `/${$stateParams.id}`;
    }

    newsUrl.searchParams.append('lang', vm.language);

    vm.newsPath = newsUrl.pathname + newsUrl.search;
  };

  vm.$onDestroy = () => {
    $window.removeEventListener('message', handleIncomingMessage);
  };

  $window.addEventListener('message', handleIncomingMessage);

  function handleIncomingMessage({ data }) {
    switch (data.type) {
      case 'url-changed': {
        if (!data.payload) return;
        if (data.payload.pathname.startsWith('/token-signin')) return;

        let url = new URL($window.location);
        url.pathname = data.payload.pathname;
        url = urlHelper.sanitize(url, data);

        window.history.pushState({}, '', url.toString());
        break;
      }
    }
  }
}
