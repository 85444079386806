import angular from 'angular';
import htmlTemplate from './data-table.html';

export default angular
  .module('swoa.data-table.data-table', [])
  .component('swoaDataTable', {
    restrict: 'E',
    replace: true,
    bindings: {
      withPagination: '<',
      total: '<',
      limit: '=',
      page: '=',
      onPaginate: '&',
      prePaginate: '&',
      showNoDataFound: '<',
      noDataFoundTextKey: '@',
      showSpinner: '<'
    },
    transclude: true,
    template: htmlTemplate,
    controller: SwoaDataTableController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function SwoaDataTableController($translate) {
  const vm = this;

  vm.$onInit = () => {
    if (vm.withPagination) {
      vm.limitOptions = [10, 20, 50, 100];
      vm.limit = vm.limit || 10;
      vm.page = vm.page || 1;
    }
    $translate([
      'pagination.page',
      'pagination.rowsPerPage',
      'pagination.of'
    ]).then(translations => {
      vm.label = {
        page: translations['pagination.page'],
        rowsPerPage: translations['pagination.rowsPerPage'],
        of: translations['pagination.of']
      };
    });
  };
}
