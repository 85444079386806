import angular from 'angular';
import ngMaterial from 'angular-material';
import angularTranslate from 'angular-translate';
import uiRouter from '@uirouter/angularjs';
import swoaConstants from '../app.constants';
import swoaCardCardYearService from '../card/card-year/card-year.service';
import swoaUserService from '../user/user.service';
import swoaTranslatable from '../components/translatable/translatable.filter';
import swoaSportService from '../sport/sport.service';
import swoaDialogService from '../components/dialog/dialog.service';
import swoaCockpitCockpitConfigService from './cockpit-config.service';
import htmlTemplate from './cockpit-sport-summary.html';

export default angular
  .module('swoa.cockpit.cockpit-sport-summary', [
    ngMaterial,
    angularTranslate,
    uiRouter,
    swoaConstants,
    swoaCardCardYearService,
    swoaUserService,
    swoaTranslatable,
    swoaSportService,
    swoaDialogService,
    swoaCockpitCockpitConfigService,
  ])
  .component('swoaCockpitSportSummary', {
    template: htmlTemplate,
    bindings: {},
    controller: CockpitSportSummaryController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function CockpitSportSummaryController(
  $q,
  $mdDialog,
  $translate,
  $state,
  Constants,
  cardYearService,
  userService,
  sportService,
  dialogService,
  cockpitConfigService
) {
  const vm = this;
  const YEARS_BACK = 10;

  // Filter choices
  vm.categories = ['ELITE', 'TALENT', 'TRAINER', 'TECHNICIAN', 'FUNCTIONARY'];
  vm.currentYear = new Date().getFullYear();
  vm.currentValidYearHack = vm.currentYear + 10000;
  vm.years = [];
  vm.configuredSportIds = null;
  vm.configuredSports = null;
  vm.sportYearStates = [];

  // Filter selections
  vm.selectedCategory = '';
  vm.selectedYear = vm.currentValidYearHack;
  vm.selectedSport = '';
  vm.selectedSportYearStatus = '';

  vm.sportSummaries = null;
  vm.sportSummaryTotals = null;
  vm.order = '-dateFrom';

  vm.updateSportSummaries = updateSportSummaries;
  vm.showSportsConfigDialog = showSportsConfigDialog;
  vm.showSportsConfigDialogAndUpdate = showSportsConfigDialogAndUpdate;
  vm.visitSportYear = visitSportYear;
  vm.hasFilter = hasFilter;
  vm.statusOrder = statusOrder;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    getUiConfigSports().then((sportIds) => {
      updateConfiguredSports(sportIds);
      updateCockpitFilter();
      updateSportSummaries();
    });

    for (let i = 0; i <= YEARS_BACK; i++) {
      vm.years.push(vm.currentYear - i);
    }
  }

  function updateConfiguredSports(sportIds) {
    vm.configuredSportIds = sportIds;

    if (
      sportIds &&
      (sportIds.length === 1 || vm.selectedSport !== '') &&
      sportIds.indexOf(vm.selectedSport) === -1
    ) {
      vm.selectedSport = sportIds[0];
    }

    sportService.loadSportsFiltered({ sports: sportIds }).then((sports) => {
      vm.configuredSports = sports;
    });
  }

  function updateSportSummaries() {
    vm.sportSummaries = null;
    initShowFilters();
    initShowColumns();
    initSportYearStatesFilter();

    fetchSportSummaries().then((sportSummaries) => {
      vm.sportSummaries = sportSummaries;
      saveFilterConfiguration();
    });
  }

  function saveFilterConfiguration() {
    const cockpitConfig = {
      category: vm.selectedCategory,
      year: vm.selectedYear,
      sport: vm.selectedSport,
      status: vm.selectedSportYearStatus,
    };
    cockpitConfigService.updateConfig(cockpitConfig);
  }

  function initShowFilters() {
    if (
      vm.selectedCategory === 'FUNCTIONARY' ||
      vm.selectedCategory === 'TECHNICIAN'
    ) {
      vm.showFilters = ['cardCategory', 'year', 'status'];
    } else {
      vm.showFilters = ['cardCategory', 'year', 'sport', 'status'];
    }
  }

  function initShowColumns() {
    if (vm.selectedCategory === 'FUNCTIONARY') {
      vm.showColumns = [
        'validFrom',
        'validUntil',
        'status',
        'cardCounts',
        'cardTotal',
      ];
    } else if (vm.selectedCategory === 'TECHNICIAN') {
      vm.showColumns = [
        'validFrom',
        'validUntil',
        'organisation',
        'status',
        'cardCounts',
        'cardTotal',
      ];
    } else {
      vm.showColumns = [
        'validFrom',
        'validUntil',
        'sport',
        'status',
        'cardCounts',
        'cardTotal',
      ];
    }
  }

  function initSportYearStatesFilter() {
    if (
      vm.selectedCategory === 'FUNCTIONARY' ||
      vm.selectedCategory === 'TECHNICIAN'
    ) {
      vm.sportYearStates = Constants.sportYears.staffStates;
    } else {
      vm.sportYearStates = Constants.sportYears.states;
    }
  }

  function getSportIds() {
    const uiConfig = userService.getCurrentUser().uiConfig;
    return uiConfig && uiConfig.sports;
  }

  function fetchSportSummaries() {
    return sportService.loadCockpitSportSummaries({
      category: vm.selectedCategory,
      startYear: vm.selectedYear,
      sports: vm.selectedSport || vm.configuredSportIds,
      status: vm.selectedSportYearStatus,
    });
  }

  function getUiConfigSports() {
    const sports = getSportIds();

    if (!sports) {
      return showSportsConfigDialog();
    }

    return $q.when(sports);
  }

  function updateCockpitFilter() {
    const cockpitConfig = cockpitConfigService.getConfig();
    vm.selectedCategory = getCockpitCategory(cockpitConfig);

    if (cockpitConfig) {
      if (
        vm.configuredSportIds &&
        !vm.configuredSportIds.includes(cockpitConfig.sport)
      ) {
        cockpitConfig.sport = '';
      }
      vm.selectedYear = cockpitConfig.year;
      vm.selectedSport = cockpitConfig.sport;
      vm.selectedSportYearStatus = cockpitConfig.status;
    }
  }

  function getCockpitCategory(cockpitConfig) {
    if (cockpitConfig) {
      return cockpitConfig.category ? cockpitConfig.category : vm.categories[0];
    }
    return vm.categories[0];
  }

  function showSportsConfigDialog() {
    const deferred = $q.defer();
    const template = `<swoa-my-sports on-save="closeAndResolve($sports)"></swoa-my-sports>`;
    const scope = {
      allowCancel: true,
      closeAndResolve(sports) {
        $mdDialog.cancel();
        deferred.resolve(sports || getSportIds());
      },
    };
    const title = $translate.instant('mySports.title');
    dialogService.showDialog(template, false, title, scope);
    return deferred.promise;
  }

  function showSportsConfigDialogAndUpdate() {
    return showSportsConfigDialog().then((sportIds) => {
      updateConfiguredSports(sportIds);
      updateCockpitFilter();
      updateSportSummaries();
      return sportIds;
    });
  }

  function visitSportYear(cardYear) {
    return $state.go('sportYearDetail', {
      type: cardYear.type,
      typedId: cardYearService.getTypedId(cardYear),
      yearId: cardYear.id,
    });
  }

  function hasFilter(filterName) {
    const filters = vm.showFilters || [];
    return filters.indexOf(filterName) !== -1;
  }

  function statusOrder(status) {
    switch (status) {
      case 'NEW':
        return 0;
      case 'FOR_CONSIDERATION':
        return 1;
      case 'REVISION_NEEDED':
        return 2;
      case 'PROVISIONALLY_RELEASED':
        return 3;
      case 'RELEASED':
        return 4;
      case 'CLOSED':
        return 5;
      default:
        return null;
    }
  }
}
