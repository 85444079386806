import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import 'restangular';
import swoaConstants from '../app.constants';
import swoaChangeLogService from '../components/change-log/change-log.service';
import swoaDialog from '../components/dialog/dialog.module';
import swoaUserService from './user.service';
import swoaOrganisationService from '../organisation/organisation.service';
import swoaRoleService from '../admin/role/role.service';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './user-detail.html';
import swoaTranslatable from '../components/translatable/translatable.filter';

export default angular
  .module('swoa.user.user-detail', [
    'restangular',
    swoaConstants,
    uiRouter,
    swoaDialog,
    swoaUserService,
    swoaChangeLogService,
    swoaOrganisationService,
    swoaRoleService,
    swoaNotification,
    swoaTranslatable
  ])
  .component('swoaUserDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: UserDetailController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function UserDetailController(
  $scope,
  $rootScope,
  $q,
  $stateParams,
  $state,
  $translate,
  swoaTranslatableFilter,
  Restangular,
  lodash,
  Constants,
  dialogService,
  personService,
  userService,
  changeLogService,
  organisationService,
  roleService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);
  let originalUser = null;

  vm.closeDetail = closeDetail;
  vm.startEdit = startEdit;
  vm.cancelEdit = cancelEdit;
  vm.save = save;
  vm.removeUser = removeUser;
  vm.showSportList = showSportList;
  vm.getOrganisationDisplayName = getOrganisationDisplayName;
  vm.informUser = informUser;
  vm.formatNumberOfRoles = formatNumberOfRoles;
  vm.isOrganisationRequired = isOrganisationRequired;
  vm.showChangeLogDialog = showChangeLogDialog;

  // only for testing
  vm._loadOrganisationSports = loadOrganisationSports;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    setInitialState();

    const promises = [];

    $scope.$watch(
      'vm.organisationTab.selectedCategory',
      loadOrganisationsForAssociation
    );
    $scope.$watch(
      'vm.organisationTab.selectedOrganisation',
      loadOrganisationSports
    );
    $scope.$watch('vm.cantonTab.selectedCategory', loadOrganisationsForCanton);

    if (vm.isNewUser) {
      vm.user = {
        roles: [],
        organisations: [],
        sports: []
      };
      vm.editing = true;
      if ($stateParams.personId) {
        promises.push(
          personService
            .loadPerson($stateParams.personId)
            .then(selectedPerson => {
              vm.user.person = selectedPerson;
              setDefaultLogin();
            }, onError)
        );
      }
    } else {
      promises.push(
        userService
          .get($stateParams.userId)
          .then(user => (vm.user = user), onError)
      );
    }

    promises.push(
      organisationService
        .getList()
        .then(organisations => (vm.allOrganisations = organisations), onError)
    );
    promises.push(
      roleService.getAllRoles().then(roles => (vm.allRoles = roles), onError)
    );

    $q.all(promises).then(() => {
      vm.organisationTab.roles = filterRoles(vm.allRoles, ['ASSOCIATION']);
      vm.swoaTab.roles = filterRoles(vm.allRoles, ['SWOA']);
      vm.cantonTab.roles = filterRoles(vm.allRoles, ['CANTON']);
      vm.personTab.roles = filterRoles(vm.allRoles, ['PERSON']);

      if (vm.isNewUser) {
        vm.user.roles = vm.user.roles.concat(vm.personTab.roles);
      }
      initializeOrganisations();
    });
  }

  function setInitialState() {
    vm.isNewUser =
      $stateParams.userId === null || $stateParams.userId === undefined;

    vm.users = null;
    vm.user = null;
    vm.editing = false;
    vm.submitted = false;

    vm.allRoles = [];
    vm.allOrganisations = [];

    vm.organisationTab = {
      roles: [],
      categories: [],
      organisations: [],
      sports: [],
      selectedCategory: 'NATIONAL_ASSOCIATION'
    };
    Constants.organisationTypes
      .filter(elem => elem.type === 'ASSOCIATION')
      .forEach(orgType =>
        vm.organisationTab.categories.push(...orgType.categories)
      );

    vm.swoaTab = {
      roles: []
    };

    vm.cantonTab = {
      roles: [],
      categories: [],
      organisations: [],
      selectedCategory: 'SPORT_DEPARTMENT'
    };
    Constants.organisationTypes
      .filter(elem => elem.type === 'CANTON')
      .forEach(orgType => vm.cantonTab.categories.push(...orgType.categories));

    vm.personTab = {
      roles: []
    };

    vm.availableSports = [];

    vm.newUserCredentials = {
      oldPassword: null,
      password: null
    };
    vm.notificationKey = null;
  }

  function initializeOrganisations() {
    const organisations = vm.user.organisations;
    const organisationTab = vm.organisationTab;
    if (organisations.ASSOCIATION) {
      organisationTab.selectedOrganisation = organisations.ASSOCIATION.id;
      organisationTab.selectedCategory =
        organisations.ASSOCIATION.categories[0];
    } else {
      organisationTab.selectedOrganisation = null;
    }
    vm.cantonTab.selectedOrganisation = organisations.CANTON
      ? organisations.CANTON.id
      : null;
    loadOrganisationsForAssociation();
    loadOrganisationsForCanton();
  }

  function setDefaultLogin() {
    if (!vm.user.login) {
      vm.user.login = `SODB-${vm.user.person.swissOlympicId}`;
    }
  }

  function closeDetail() {
    $state.go('userList');
  }

  function startEdit() {
    vm.editing = true;
    originalUser = Restangular.copy(vm.user);
  }

  function cancelEdit() {
    if (vm.editing && vm.isNewUser) {
      vm.closeDetail();
    } else if (vm.editing) {
      vm.editing = false;
      vm.user = originalUser;
      initializeOrganisations();
      originalUser = null;
    }
  }

  function informUser() {
    if (!vm.user.person.email || vm.user.person.email === '') {
      return dialogService.showErrorToast('user.information.not.possible', {
        userName: vm.user.login
      });
    }

    dialogService.showSuccessToast('user.information.done', {
      userName: vm.user.login
    });
    return userService.informUser(vm.user);
  }

  function save(valid) {
    vm.notificationKey = null;
    vm.submitted = true;
    const person = vm.user.person;

    if (!valid) {
      vm.notificationKey = 'form.invalid';
      return;
    }

    if (isRoleMissing()) {
      vm.notificationKey = 'user.role.missing';
      return;
    }

    if (isSportMissing()) {
      vm.notificationKey = 'user.sport.missing';
      return;
    }

    if (!person.id) {
      person.foreignIds = personService.unhashifyForeignIds(person.foreignIds);
    }

    updateUser();
    userService
      .persist(vm.user, vm.newUserCredentials)
      .then(onSaveSuccess, onError);
  }

  function updateUser() {
    vm.user.organisations = {};
    if (vm.organisationTab.selectedOrganisation) {
      vm.user.organisations.ASSOCIATION = getSelectedOrganisation(
        vm.organisationTab.selectedOrganisation
      );
    }
    if (vm.cantonTab.selectedOrganisation) {
      vm.user.organisations.CANTON = getSelectedOrganisation(
        vm.cantonTab.selectedOrganisation
      );
    }
  }

  function removeUser() {
    dialogService
      .showConfirmationDialog('user.delete.confirmation')
      .then(() => {
        vm.user.remove().then(onDeleteSuccess, onError);
      });
  }

  function onSaveSuccess(user) {
    vm.editing = false;
    vm.user = user;
    originalUser = null;

    dialogService.showSuccessToast('user.save.done', {
      userName: vm.user.login
    });

    if (vm.isNewUser) {
      $rootScope.$broadcast('swoa:userAdded', vm.user);
      // reload detail page to have id in url
      $state.go('userList.detail', { userId: vm.user.id });
    } else {
      $rootScope.$broadcast('swoa:userUpdated', vm.user);
    }
  }

  function onDeleteSuccess() {
    dialogService.showSuccessToast('user.delete.done', {
      userName: vm.user.login
    });
    $rootScope.$broadcast('swoa:userDeleted', vm.user);
    closeDetail();
  }

  function getOrganisationDisplayName(organisation) {
    return organisationService.getOrganisationDisplayName(organisation);
  }

  function filterRoles(roles, types) {
    return lodash.filter(roles, role => lodash.includes(types, role.roleType));
  }

  function filterSports(userSports, availableSports) {
    return lodash.filter(userSports, userSport =>
      lodash.some(
        availableSports,
        availableSport => availableSport.id === userSport.id
      )
    );
  }

  function showSportList() {
    return (
      vm.organisationTab.selectedOrganisation !== null &&
      vm.availableSports.length > 0
    );
  }

  function loadOrganisationSports() {
    if (vm.user) {
      const selected = vm.organisationTab.selectedOrganisation;
      if (selected) {
        vm.availableSports = getSelectedOrganisation(selected)
          ? getSelectedOrganisation(selected).sports
          : [];
        vm.availableSports = lodash.forEach(
          vm.availableSports,
          sport =>
            (sport.displayName = swoaTranslatableFilter(
              sport.translatedName,
              $translate.use()
            ))
        );
        vm.user.sports = filterSports(vm.user.sports, vm.availableSports);
      } else {
        vm.availableSports = [];
        vm.user.sports = [];
      }
    }
  }

  function getSelectedOrganisation(id, organisations = vm.allOrganisations) {
    return lodash.find(organisations, { id });
  }

  function loadOrganisationsForAssociation() {
    const tab = vm.organisationTab;
    const selectedCategory = tab.selectedCategory;
    if (selectedCategory) {
      tab.organisations = lodash.filter(vm.allOrganisations, org =>
        lodash.includes(org.categories, selectedCategory)
      );
      tab.organisations = getSortedValueListOrganisations(tab.organisations);
      if (
        lodash.findIndex(tab.organisations, { id: tab.selectedOrganisation }) <
        0
      ) {
        tab.selectedOrganisation = null;
      }
    }
  }

  function loadOrganisationsForCanton() {
    const selected = vm.cantonTab.selectedCategory;
    if (selected) {
      vm.cantonTab.organisations = lodash.filter(vm.allOrganisations, org =>
        lodash.includes(org.categories, selected)
      );
      vm.cantonTab.organisations = getSortedValueListOrganisations(
        vm.cantonTab.organisations
      );
    }
  }

  function showChangeLogDialog() {
    userService
      .getChangeLog(vm.user.id)
      .then(
        userChangeLog => changeLogService.showDialog(userChangeLog, 'user'),
        onError
      );
  }

  function getSortedValueListOrganisations(organisations) {
    const sortedOrganisations = lodash.map(organisations, organisation => ({
      id: organisation.id,
      displayName: organisationService.getOrganisationDisplayName(organisation)
    }));
    return lodash.sortBy(sortedOrganisations, 'displayName');
  }

  function formatNumberOfRoles(types) {
    return vm.user && filterRoles(vm.user.roles, types).length > 0
      ? `(${filterRoles(vm.user.roles, types).length})`
      : '';
  }

  function isOrganisationRequired(type) {
    return vm.user && filterRoles(vm.user.roles, [type]).length > 0;
  }

  function isRoleMissing() {
    return (
      (vm.organisationTab.selectedOrganisation &&
        filterRoles(vm.user.roles, ['ASSOCIATION']).length === 0) ||
      (vm.cantonTab.selectedOrganisation &&
        filterRoles(vm.user.roles, ['CANTON']).length === 0)
    );
  }

  function isSportMissing() {
    return (
      vm.organisationTab.selectedOrganisation &&
      vm.availableSports.length > 0 &&
      vm.user.sports.length === 0
    );
  }
}
