import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaConstants from '../../app.constants';
import swoaSportYearSettingService from './sport-year-setting.service';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaNotification from '../../components/notification/notification.module';
import swoaDate from '../../components/date/date.filter';
import htmlTemplate from './card-parameter.html';

export default angular
  .module('swoa.admin.card-parameter.component', [
    uiRouter,
    swoaConstants,
    swoaSportYearSettingService,
    swoaTranslatable,
    swoaNotification,
    swoaDate
  ])
  .component('swoaCardParameter', {
    template: htmlTemplate,
    bindings: {},
    controller: CardParameterController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardParameterController(
  $scope,
  $rootScope,
  $q,
  $translate,
  lodash,
  sportYearService,
  sportService,
  notificationService,
  swoaDateFilter,
  swoaTranslatableFilter,
  sportYearSettingService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.order = 'displayName';
  vm.latestSportYears = [];
  vm.latestOrganisationYears = [];
  vm.sportYearSettings = [];

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    getLatestSportYears();
    $scope.$on('swoa:sportYearSettingUpdated', getLatestSportYears);
    $scope.$on('swoa:cardYearGenerationSuccess', getLatestSportYears);
  }

  function getLatestSportYears() {
    $rootScope.showSpinner = true;
    const currentSportYearsPromise = sportYearService.loadLatestSportYears();
    const sportsPromise = sportService.loadSports();
    const sportYearSettingsPromise = sportYearSettingService.loadSportYearSettings();
    return $q
      .all([currentSportYearsPromise, sportsPromise, sportYearSettingsPromise])
      .then(results => {
        const sportYears = results[0];
        const sports = results[1];
        vm.sportYearSettings = results[2];

        sports.forEach(
          sport =>
            (sport.displayName = swoaTranslatableFilter(
              sport.translatedName,
              'de'
            ))
        );
        vm.latestSportYears = toLatestSportYears(sports, sportYears);
        vm.latestOrganisationYears = toOrganisationSportYears(sportYears);
        $rootScope.showSpinner = false;
      }, onError);
  }

  function toLatestSportYears(sports, sportYears) {
    return lodash.map(sports, sport => {
      const sportYearsOfSport = sportYearsForSport(sportYears, sport);

      if (lodash.isEmpty(sportYearsOfSport)) {
        return emptySportYear(sport);
      }
      return transformedSportYear(sport, sportYearsOfSport);
    });
  }

  function toOrganisationSportYears(sportYears) {
    const sportYearsWithoutSport = lodash.filter(
      sportYears,
      sportYear => !sportYear.sport
    );
    const lastTechnicianSportYear = lodash.maxBy(
      lodash.filter(
        sportYearsWithoutSport,
        sportYear => sportYear.category === 'TECHNICIAN'
      ),
      sportYear => sportYear.id
    );
    const lastFunctionarySportYear = lodash.filter(
      sportYearsWithoutSport,
      sportYear => sportYear.category === 'FUNCTIONARY'
    )[0];

    lastTechnicianSportYear.formattedValidityDateString = `${swoaDateFilter(
      lastTechnicianSportYear.validFrom
    )} - ${swoaDateFilter(lastTechnicianSportYear.validUntil)}`;
    lastTechnicianSportYear.displayName = $translate.instant(
      `cardParameter.type.${lastTechnicianSportYear.category}`
    );
    lastFunctionarySportYear.formattedValidityDateString = `${swoaDateFilter(
      lastFunctionarySportYear.validFrom
    )} - ${swoaDateFilter(lastFunctionarySportYear.validUntil)}`;
    lastFunctionarySportYear.displayName = $translate.instant(
      `cardParameter.type.${lastFunctionarySportYear.category}`
    );
    return [lastFunctionarySportYear, lastTechnicianSportYear];
  }

  function sportYearsForSport(sportYears, sport) {
    return lodash.filter(
      sportYears,
      sportYear => sportYear.sport && sportYear.sport.id === sport.id
    );
  }

  function emptySportYear(sport) {
    return {
      displayName: sport.displayName,
      sportId: sport.id,
      ELITE: '-',
      TRAINER: '-',
      TALENT: '-',
      contingent: { NATIONAL: '-', REGIONAL: '-', LOCAL: '-' }
    };
  }

  function transformedSportYear(sport, sportYears) {
    const transformed = emptySportYear(sport);
    const settingsForSport = lodash.find(
      vm.sportYearSettings,
      setting => setting.sport && setting.sport.id === sport.id
    );

    sportYears.forEach(sportYear => {
      const settingsForCategory = lodash.find(
        settingsForSport.cardYearCategorySettings,
        setting => setting.sportYearCategory === sportYear.category
      );

      if (
        settingsForCategory.seasonStartMonth !== null &&
        settingsForCategory.seasonDuration !== null
      ) {
        transformed[sportYear.category] = `${swoaDateFilter(
          sportYear.validFrom
        )} - ${swoaDateFilter(sportYear.validUntil)}`;
        if (
          sportYear.category === 'TALENT' &&
          !lodash.isEmpty(sportYear.cardTypeSettings)
        ) {
          sportYear.cardTypeSettings.forEach(cardTypeSetting => {
            if (cardTypeSetting.contingent) {
              transformed.contingent[cardTypeSetting.cardType] =
                cardTypeSetting.contingent;
            }
          });
        }
      }
    });
    return transformed;
  }
}
