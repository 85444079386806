import angular from 'angular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.date.service', [
    swoaConstants,
  ])
  .factory('dateService', dateService)
  .name;

/** @ngInject */
function dateService(moment) {
  return {
    periodLengthInYears,
  };

  // //////////

  function periodLengthInYears(validFrom, validUntil) {
    const from = moment(validFrom, moment.ISO_8601);
    const to = moment(validUntil, moment.ISO_8601).add(1, 'days');
    return to.diff(from, 'years');
  }
}
