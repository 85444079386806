import angular from 'angular';
import swoaRoleRoutes from './role.routes';
import swoaRoleService from './role.service';
import swoaRoleList from './role-list.component';
import swoaRoleEdit from './role-edit.component';
import swoaPermissionRoleGroup from './permission-role-group.component';
import swoaPermissionCheckboxes from './permission-checkboxes';

export default angular
  .module('swoa.admin.role', [
    swoaRoleRoutes,
    swoaRoleService,
    swoaRoleList,
    swoaRoleEdit,
    swoaPermissionRoleGroup,
    swoaPermissionCheckboxes,
  ])
  .name;

