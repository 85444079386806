import angular from 'angular';
import swoaEscape from '../escape/escape.filter';
import swoaTranslatable from './translatable.filter';
import swoaTranslatableElementService from '../../translation/translatable-element.service';
import htmlTemplate from './translatable-input-select.html';

export default angular
  .module('swoa.translatable-input-select', [
    swoaEscape,
    swoaTranslatable,
    swoaTranslatableElementService
  ])
  .component('swoaTranslatableInputSelect', {
    template: htmlTemplate,
    bindings: {
      containerClass: '@',
      isReadOnly: '=',
      labelKey: '@',
      name: '@',
      ngModel: '=',
      translatableType: '@',
      valueProperty: '@',
      messages: '=',
      messagesIf: '=',
      isRequired: '=',
      multiple: '<',
      allowEmpty: '<',
      emptyLabel: '@',
      allowExplicitNull: '<',
      explicitNullLabel: '@'
    },
    controller: TranslatableInputSelectController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function TranslatableInputSelectController(
  $scope,
  translatableElementService,
  swoaTranslatableFilter,
  lodash,
  $translate
) {
  const vm = this;

  vm.translatableElements = [];

  vm.selectAll = selectAll;
  vm.allSelected = allSelected;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    translatableElementService
      .loadTranslatableElementsFromType(vm.translatableType)
      .then(translatableElements => {
        lodash.forEach(
          translatableElements,
          element =>
            (element.displayName = swoaTranslatableFilter(
              element,
              $translate.use()
            ))
        );
        const customSort = lodash.uniq(
          lodash.map(translatableElements, element => element.customSort)
        )[0];
        const translatableElementsSorted = lodash.sortBy(
          translatableElements,
          element =>
            customSort
              ? element.sort
              : (element.displayName || '').toLowerCase()
        );
        vm.translatableElements = vm.translatableElements.concat(
          translatableElementsSorted
        );
      });
    $scope.$watch('vm.ngModel', removeUndefined, true);
  }

  function removeUndefined() {
    if (vm.multiple) {
      vm.ngModel = lodash.compact(vm.ngModel);
    }
  }

  function selectAll() {
    if (vm.multiple) {
      toggleAll(vm.translatableElements);
    }
  }

  function toggleAll(arr, prop) {
    if (vm.ngModel.length === arr.length) {
      vm.ngModel = [];
    } else {
      arr.forEach(elem => vm.ngModel.push(prop ? elem[prop] : elem));
    }
  }

  function allSelected() {
    return (
      vm.ngModel &&
      vm.translatableElements &&
      vm.ngModel.length === lodash.compact(vm.translatableElements).length
    );
  }
}
