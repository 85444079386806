import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.person.mutation.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('personMutations', {
      url: '/persons/mutations',
      template: '<swoa-person-mutations></swoa-person-mutations>',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_personmutation_r'
      }
    })
    .state('personMutations.mutationDetail', {
      url: '/persons/mutations/:personMutationId',
      views: {
        detailMutation: {
          template:
            '<swoa-person-mutation-detail></swoa-person-mutation-detail>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'personMutations',
          ignore: true
        },
        hasPermission: 'admin_personmutation_r'
      }
    })
    .state('personMutations.creationDetail', {
      url: '/persons/creations/:personId',
      views: {
        detailCreation: {
          template:
            '<swoa-person-creation-detail></swoa-person-creation-detail>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'personMutations',
          ignore: true
        },
        hasPermission: 'admin_personmutation_r'
      }
    })
    .state('personMutationEdit', {
      url: '/persons/mutations/:personMutationId/edit',
      template: '<swoa-person-mutation-sync></swoa-person-mutation-sync>',
      data: {
        breadcrumb: {
          parent: 'personMutations'
        },
        hasPermission: 'admin_personmutation_w'
      }
    });
}
