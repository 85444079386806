import angular from 'angular';
import swoaOrganisationSearchRoutes from './organisation-search.routes';
import swoaOrganisationSearchComponent from './organisation-search.component';
import swoaOrganisationSearchExtendedComponent from './organisation-extended-search.component';
import swoaOrganisationSearchPageComponent from './organisation-search-page.component';
import swoaOrganisationSearchDetailsTableComponent from './organisation-search-details-table.component';

export default angular
  .module('swoa.organisation.search', [
    swoaOrganisationSearchRoutes,
    swoaOrganisationSearchComponent,
    swoaOrganisationSearchExtendedComponent,
    swoaOrganisationSearchPageComponent,
    swoaOrganisationSearchDetailsTableComponent,
  ])
  .name;
