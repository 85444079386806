import angular from 'angular';
import swoaCockpitCockpitSportSummary from './cockpit-sport-summary.component';
import swoaCockpitCockpitPersonMutationList from './cockpit-person-mutation-list.component';
import swoaCockpitCockpitNewPersonList from './cockpit-new-person-list.component';
import htmlTemplate from './cockpit-dashboard.html';

export default angular
  .module('swoa.cockpit.cockpit-dashboard', [
    swoaCockpitCockpitSportSummary,
    swoaCockpitCockpitPersonMutationList,
    swoaCockpitCockpitNewPersonList
  ])
  .component('swoaCockpitDashboard', {
    template: htmlTemplate,
    bindings: {},
    controller: CockpitDashboardController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CockpitDashboardController(
  $rootScope,
  $state,
  translatableElementService,
  userService,
  cityService
) {
  const vm = this;

  vm.showCockpit = false;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    // this is the landing page for every user after the login. so we can do some pre-loading here.
    translatableElementService.fillCache();
    cityService.fillCache();

    // redirect person users to the profile page
    if ($rootScope.userHasOnlyRole('PERSON')) {
      $state.go('personProfile', {
        soId: userService.getCurrentUser().person.swissOlympicId
      });
    } else {
      vm.showCockpit = true;
    }
  }
}
