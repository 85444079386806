import angular from 'angular';
import swoaNwfInstitution from './nwf-institution/nwf-institution.module';
import swoaOrganisationProfile from './organisation-profile.component';
import swoaOrganisationProfileInfos from './organisation-profile-infos.component';
import swoaOrganisationProfileSport from './organisation-profile-sport.component';
import swoaOrganisationProfileSportEntry from './organisation-profile-sport-entry.component';
import swoaOrganisationProfileHelper from './organisation-profile-helper.service';

export default angular
  .module('swoa.organisation.profile', [
    swoaNwfInstitution,
    swoaOrganisationProfile,
    swoaOrganisationProfileInfos,
    swoaOrganisationProfileHelper,
    swoaOrganisationProfileSport,
    swoaOrganisationProfileSportEntry,
  ])
  .name;
