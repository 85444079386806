import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.sport.service', ['restangular'])
  .factory('sportService', sportService).name;

/** @ngInject */
function sportService(Restangular) {
  const SPORTS_ROUTE = 'sports';
  const SPORTCONTAINERS_ROUTE = 'sportcontainers';

  const baseSports = Restangular.all(SPORTS_ROUTE);
  const baseSportWithSupportPersons = Restangular.all(SPORTCONTAINERS_ROUTE);

  // expose public service API
  return {
    loadSportsWithSupportPerson,
    persistSportCostCenter,
    loadSports,
    loadSportsFiltered,
    loadSportContacts,
    loadCockpitSportSummaries,
    getBreadcrumbTitle,
  };

  // //////////

  function persistSportCostCenter(sportId, costCenter) {
    return Restangular.one('sports', sportId).customPUT(
      costCenter,
      'cost-center'
    );
  }

  function loadSports(params) {
    return baseSports.getList(params);
  }

  function loadSportsWithSupportPerson(params) {
    return baseSportWithSupportPersons.getList(params);
  }

  /** Load all sports allowed for the actual user and return as a list */
  function loadSportsFiltered(params) {
    return baseSports.customGET('filtered', params);
  }

  function loadSportContacts(sportId) {
    return Restangular.one('sports', sportId).customGET('contacts');
  }

  function loadCockpitSportSummaries(options) {
    const params = {};

    if (options.limit) {
      params.limit = options.limit;
    }

    if (options.category) {
      params.category = options.category;
    }

    if (options.startYear) {
      params.startYear = options.startYear;
    }

    if (options.sports) {
      params.sports = options.sports;
    }

    if (options.status) {
      params.status = options.status;
    }

    return baseSports.customGET('cockpit', params);
  }

  function getBreadcrumbTitle(id) {
    return Restangular.one('sports', id)
      .get()
      .then((sport) => sport.translatedName);
  }
}
