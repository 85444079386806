import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaConstants from '../../app.constants';
import htmlTemplate from './toolbar.html';

export default angular
  .module('swoa.toolbar', [uiRouter, swoaConstants])
  .component('swoaToolbar', {
    template: htmlTemplate,
    transclude: true,
    bindings: {
      toolbarClass: '@',
      close: '<',
      closeAction: '&',
      save: '<',
      saveAction: '&',
      saveSubmit: '<',
      saveDisabled: '<',
      cancel: '<',
      cancelAction: '&',
      edit: '<',
      editAction: '&',
      delete: '<',
      deleteAction: '&',
      deactivate: '<',
      deactivateAction: '&',
      activate: '<',
      activateAction: '&',
      changelog: '<',
      changelogAction: '&',
      add: '<',
      addDisabled: '<',
      addAction: '&',
      addLabel: '@'
    },
    controller: ToolbarController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function ToolbarController($rootScope, $scope, $element, $state, $transitions) {
  const vm = this;

  vm.showBackdrop = showBackdrop;
  vm.hideBackdrop = hideBackdrop;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    onStateChangeSuccess();
    const unregisterFn = $transitions.onSuccess({}, onStateChangeSuccess);
    $scope.$on('$destroy', unregisterFn);
    $scope.$watch('vm.save', newVal => {
      if (newVal === false) {
        vm.hideBackdrop();
      }
    });
  }

  function onStateChangeSuccess() {
    vm.smallLayout = $state.current.data && $state.current.data.smallToolbar;
  }

  function showBackdrop() {
    if (!$element.hasClass('toolbar-compact')) {
      $rootScope.$emit('showBackdrop');
    }
  }

  function hideBackdrop() {
    $rootScope.$emit('hideBackdrop');
  }
}
