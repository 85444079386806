import angular from 'angular';
import swoaConstants from '../../../app.constants';
import swoaTranslatableElementService from '../../../translation/translatable-element.service';
import swoaNotification from '../../../components/notification/notification.module';
import htmlTemplate from './translations-dropowns.html';

export default angular
  .module('swoa.admin.translations-dropdown', [
    swoaConstants,
    swoaTranslatableElementService,
    swoaNotification
  ])
  .component('swoaTranslationsDropdown', {
    template: htmlTemplate,
    bindings: {},
    controller: TranslationsDropdownController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function TranslationsDropdownController(
  $rootScope,
  $scope,
  $translate,
  lodash,
  translatableElementService,
  notificationService,
  swoaTranslatableFilter
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);
  vm.translatableElements = [];
  vm.selectedType = undefined;
  vm.types = [];
  vm.notificationKey = null;
  vm.notificationValues = null;
  vm.order = ['translationType', 'sort'];

  vm.toggleCustomSort = toggleCustomSort;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    getTranslatableElements();
    $scope.$watch('vm.selectedType', selectedTypeChanged, true);
  }

  function getTranslatableElements() {
    $rootScope.showSpinner = true;
    translatableElementService
      .loadTranslatableElementsFiltered(
        element => element.technicalName === null
      )
      .then(elements => {
        vm.translatableElements = lodash.sortBy(
          elements,
          element => element.translationType
        );
        vm.translatableElements.forEach(element => addDisplayName(element));
        vm.types = lodash.uniq(
          lodash.map(vm.translatableElements, element => element.type)
        );
        $rootScope.showSpinner = false;
      }, onError);
  }

  function addDisplayName(element) {
    element.displayName = swoaTranslatableFilter(element, 'de');
  }

  function selectedTypeChanged() {
    const translatableElementsByType = lodash.filter(
      vm.translatableElements,
      element => element.type === vm.selectedType
    );
    vm.customSort = lodash.uniq(
      lodash.map(translatableElementsByType, element => element.customSort)
    )[0];
    setSortOrder();
  }

  function toggleCustomSort() {
    translatableElementService
      .updateCustomSortForType(vm.selectedType, vm.customSort)
      .then(() => {
        getTranslatableElements();
        vm.notificationKey = 'translations.dropdown.customSort.saved';
        vm.notificationValues = {
          type: $translate.instant(`applicationParameter.${vm.selectedType}`)
        };
        vm.isSuccess = true;
        setSortOrder();
      }, onError);
  }

  function setSortOrder() {
    if (vm.selectedType && !vm.customSort) {
      vm.order = ['translationType', 'displayName'];
    } else if (vm.customSort) {
      vm.order = ['translationType', 'sort'];
    }
  }
}
