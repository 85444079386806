import angular from 'angular';

export default angular
  .module('swoa.organisation.profile.organisation-profile-helper.service', [])
  .factory('organisationProfileHelper', organisationProfileHelper)
  .name;

/** @ngInject */
function organisationProfileHelper() {
  return {
    createFormHelper,
  };

  // //////////

  function createFormHelper(vm) {
    return new FormHelper(vm);
  }

  function FormHelper(vm) {
    this.resetNotifications = resetNotifications;

    function resetNotifications() {
      vm.errors = [];
      vm.notificationKey = null;
      vm.notificationSuccess = null;
      vm.notificationKeyList = [];
    }

    this.startEdit = () => {
      vm.editing = true;
      vm.submitted = false;
      resetNotifications(vm);
    };

    this.cancelEdit = () => {
      vm.editing = false;
      vm.submitted = false;
      resetNotifications(vm);
      vm.initialized = false;
      vm.initViewData();
    };

    /** checks if the view data is not initialized and the needed data is available. */
    this.needsInitialization = () => {
      if (!vm.organisation) {
        return false;
      }
      return !vm.initialized && (vm.organisation !== null);
    };

    this.processSaved = (organisation) => {
      vm.organisation = organisation;
      vm.editing = false;

      // show save success info
      vm.notificationSuccess = true;
      vm.notificationKey = 'organisationProfileInfos.save.success.message';

      // update view
      vm.initialized = false;
      vm.initViewData();
    };
  }
}
