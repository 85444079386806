import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaValidatorMessages from '../validator-messages/validator-messages.component';
import htmlTemplate from './input-date.html';

export default angular
  .module('swoa.input-date', [swoaConstants, swoaValidatorMessages])
  .component('swoaInputDate', {
    template: htmlTemplate,
    bindings: {
      isReadOnly: '<',
      labelKey: '@',
      name: '@',
      dateString: '=ngModel',
      maxDate: '<',
      isRequired: '<',
      messages: '<',
      messagesIf: '<',
      customMessageKeys: '<',
      showTime: '<'
    },
    controller: InputDateController,
    controllerAs: 'vm'
  }).name;

export const DATE_FORMAT_JSON = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT_JSON = 'YYYY-MM-DDTHH:mm:ss';

/** @ngInject */
function InputDateController($scope, $timeout, $element, moment) {
  const vm = this;

  vm.date = null;
  vm.minDate = moment('1900-01-01', DATE_FORMAT_JSON).toDate();

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.dateString', updateDate, true);
    $scope.$watch('vm.date', updateString, true);
    $scope.$watch('vm.isReadOnly', updateInputReadonly, true);

    $timeout(() => {
      // disable tab index for calendar arrow icon, as requested by SWOA
      $element
        .find('button.md-datepicker-triangle-button')
        .attr('tabindex', '-1');
    });
  }

  function updateDate() {
    vm.date = vm.dateString
      ? moment(
          vm.dateString,
          vm.showTime ? DATE_TIME_FORMAT_JSON : DATE_FORMAT_JSON
        ).toDate()
      : null;
  }

  function updateString() {
    vm.dateString = vm.date
      ? moment(vm.date).format(
          vm.showTime ? DATE_TIME_FORMAT_JSON : DATE_FORMAT_JSON
        )
      : null;
  }

  function updateInputReadonly() {
    if (vm.isReadOnly) {
      $element.find('input.md-datepicker-input').attr('readonly', 'readonly');
    } else {
      $element.find('input.md-datepicker-input').removeAttr('readonly');
    }
  }
}
