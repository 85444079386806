import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaDialogService from '../../components/dialog/dialog.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaChangeLogService from '../../components/change-log/change-log.service';
import swoaNwfVariableAmountPeriodService from './nwf-variable-amount-period.service';
import htmlTemplate from './nwf-variable-amount-period.html';

export default angular
  .module('swoa.nwf-contribution.variable-amount.nwf-variable-amount-period', [
    uiRouter,
    swoaDialogService,
    swoaNotification,
    swoaChangeLogService,
    swoaNwfVariableAmountPeriodService
  ])
  .component('swoaNwfVariableAmountPeriod', {
    template: htmlTemplate,
    bindings: {
      currentTab: '<'
    },
    controller: NwfVariableAmountPeriod,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfVariableAmountPeriod(
  $scope,
  $rootScope,
  $stateParams,
  $q,
  lodash,
  dialogService,
  notificationService,
  changeLogService,
  nwfVariableAmountPeriodService,
  nwfTrainerService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.periodId = $stateParams.periodId;
  vm.sumPercent = 0;
  vm.loaded = false;

  vm.statusChange = statusChange;
  vm.showChangeLogDialog = showChangeLogDialog;
  vm.importFromPreviousPeriod = importFromPreviousPeriod;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    loadTrainers();
    $scope.$on('swoa:nwfTrainerUpdated', loadTrainers);
    $scope.$on('swoa:nwfTrainersUpdated', onTrainersUpdated);
    $scope.$on('swoa:nwfTrainerDeleted', onTrainerDeleted);
    $scope.$on('swoa:nwfTrainerImported', loadTrainers);
  }

  function loadTrainers() {
    nwfVariableAmountPeriodService
      .loadPeriodWithTrainers(vm.periodId)
      .then(period => {
        vm.period = period;
        vm.sumPercent = getTotalPercentage(vm.period.trainers);
        vm.loaded = true;
      }, onError);
  }

  function getTotalPercentage(trainers) {
    return lodash
      .chain(trainers)
      .map(nwfTrainerService.calculateLevelOfEmployment)
      .sum()
      .value();
  }

  function onTrainersUpdated(event, trainers) {
    vm.period.trainers = trainers;
    vm.sumPercent = getTotalPercentage(trainers);
  }

  function onTrainerDeleted(event, deletedTrainer) {
    onTrainersUpdated(
      event,
      lodash.filter(vm.trainers, trainer => deletedTrainer !== trainer)
    );
  }

  function statusChange(status, confirmationMessageKey) {
    let confirmPromise = $q.when();
    if (confirmationMessageKey) {
      confirmPromise = dialogService.showConfirmationDialog(
        confirmationMessageKey
      );
    }

    return confirmPromise.then(() => {
      notificationService.resetErrors(vm);
      return nwfVariableAmountPeriodService
        .goToStatus(vm.periodId, status)
        .then(activate);
    }, onError);
  }

  function showChangeLogDialog() {
    // TODO: implement function getChangeLog and backend and configuration in change-log component
    nwfVariableAmountPeriodService
      .getChangeLog(vm.periodId)
      .then(
        sportYearChangeLog =>
          changeLogService.showDialog(sportYearChangeLog, 'variableAmount'),
        onError
      );
  }

  function importFromPreviousPeriod() {
    nwfVariableAmountPeriodService
      .importFromPreviousPeriod(vm.periodId)
      .then(
        imported => $scope.$broadcast('swoa:nwfTrainerImported', imported),
        onError
      );
  }
}
