import angular from 'angular';
import swoaNwfInstitution from './nwf-institution.component';
import swoaNwfInstitutionDetail from './nwf-institution-detail.component';

export default angular
  .module('swoa.organisation.profile.nwf-institution', [
    swoaNwfInstitution,
    swoaNwfInstitutionDetail,
  ])
  .name;
