import angular from 'angular';
import swoaConstants from './app.constants';

export default angular
  .module('swoa.config', ['matchmedia-ng', swoaConstants])
  .config(config).name;

/** @ngInject */

/* jshint -W072 */
function config(
  $provide,
  $logProvider,
  toastrConfig,
  moment,
  RestangularProvider,
  ConfigConstantsProvider,
  $translateProvider,
  $windowProvider,
  $mdDateLocaleProvider,
  $mdThemingProvider,
  $httpProvider,
  localStorageServiceProvider,
  $locationProvider,
  $urlRouterProvider,
  ngUserAuthServiceProvider,
  matchmediaProvider,
  $animateProvider,
  $mdAriaProvider
) {
  // Allow selectively disabling of class animations with the class
  // 'ng-animate-disabled'
  $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);

  // Enable log
  $logProvider.debugEnabled(true);

  // HTML 5 mode on (needs undertow-handlers.conf for Wildfly to rewrite everything to index.ejs)
  $locationProvider.html5Mode({ enabled: true, requireBase: false });

  // Default URL if no other matches, handled by the authentication provider
  $urlRouterProvider.otherwise(
    ngUserAuthServiceProvider.getOtherwiseRouteHandler('/cockpit')
  );

  const $window = $windowProvider.$get();
  const hostname = $window.location.hostname;
  const localEnv =
    hostname.indexOf('localhost') === 0 || hostname.indexOf('10.') === 0;

  // Set options third-party lib
  angular.extend(toastrConfig, {
    timeOut: localEnv ? 1000 : 3000,
    positionClass: 'toast-top-right',
    preventOpenDuplicates: true,
    progressBar: true
  });

  // Disable animations in IE11, this is done via an regexp which tries
  // to not match 'x' immediately following an 'x', this way, the regexp
  // never matches anything, disabling animations in _all_ elements.
  if (isIE11()) {
    $animateProvider.classNameFilter(/(?!x)x/);
  }

  // local storage
  localStorageServiceProvider.setPrefix('swoa');
  localStorageServiceProvider.setStorageType('localStorage');
  // in case a browser does not support localStorage, the library will fall back to cookies
  localStorageServiceProvider.setStorageCookie(0, '/');

  const Config = ConfigConstantsProvider.$get();

  // configure security interceptor
  ngUserAuthServiceProvider.setApiEndpoint(
    `${Config.api.url}/authentication${localEnv ? '?force=true' : ''}`
  );
  ngUserAuthServiceProvider.setUnauthorizedUrl('/login');
  ngUserAuthServiceProvider.setAbortRequestsUrlPrefix(Config.api.url);
  ngUserAuthServiceProvider.setDefaultLoggedInPermissionName('token_r');
  ngUserAuthServiceProvider.addLogoutAction($injector => {
    $injector.get('$mdDialog').hide();
  });
  ngUserAuthServiceProvider.setIgnoreCaseInRoleNames(true);
  ngUserAuthServiceProvider.setSessionCheckSettings({
    enabled: true,
    checkUrl: `${Config.api.url}/authentication/sessioncheck`,
    interval: localEnv ? 120000 : 30000,
    onSessionInvalid() {
      $window.location.reload();
      throw new Error(
        'Session is invalid! Refreshing page so we will be redirected to the login page.'
      );
    }
  });

  // configure Restangular
  RestangularProvider.setBaseUrl(Config.api.url);
  RestangularProvider.setDefaultHeaders({ 'Content-Type': 'application/json' });
  RestangularProvider.addRequestInterceptor(function(elem, operation, what, url) {
    if (operation === "remove") {
      return null;
    }
    return elem;
  });

  // configure translation service
  $translateProvider.preferredLanguage(Config.translation.preferredLanguage);
  $translateProvider.useSanitizeValueStrategy('escapeParameters');
  $translateProvider.useStaticFilesLoader({
    prefix: Config.translation.prefix,
    suffix: Config.translation.suffix,
    $http: {
      noCancelOnRouteChange: true
    }
  });

  // Globally disables all ARIA warnings.
  $mdAriaProvider.disableWarnings();

  // configure material design components
  $mdDateLocaleProvider.formatDate = date => {
    if (date) {
      return moment(date).format('DD.MM.YYYY');
    }
    return null;
  };
  $mdDateLocaleProvider.parseDate = dateString => {
    if (dateString) {
      return moment(dateString, ['DD.MM.YYYY', 'D.M.YYYY'], true).toDate();
    }
    return null;
  };
  $mdDateLocaleProvider.firstDayOfWeek = 1;

  // Fix material design bug: "Password label not floating with Chrome password-manager auto-fill"
  // https://github.com/angular/material/issues/1376
  $provide.decorator('mdInputContainerDirective', ($delegate, $interval) => {
    const directive = $delegate[0];

    directive.compile = () => ({
      post($scope, element, attr, ctrl) {
        let interval;
        let count = 0;

        if (ctrl.input && ctrl.input[0].type === 'password') {
          interval = $interval(() => {
            if (count > 10) {
              $interval.cancel(interval);
            }

            let autofill = null;
            try {
              autofill = ctrl.input
                .parent()[0]
                .querySelector('input:-webkit-autofill');
            } catch (e) {
              // ignore firefox error because of invalid css selector
            }
            if (autofill) {
              ctrl.element.addClass('md-input-has-value');
              $interval.cancel(interval);
            }

            count += 1;
          }, 25);
        }
      }
    });

    return $delegate;
  });

  // overwrite default orderBy filter to ignore accents/umlauts
  $provide.decorator('orderByFilter', ($delegate, $injector) => {
    const stringUtilService = $injector.get('stringUtilService');

    return (array, sortPredicate, reverseOrder) =>
      $delegate(
        array,
        sortPredicate,
        reverseOrder,
        stringUtilService.sortComparator
      );
  });

  // SWOA Color Theme
  $mdThemingProvider.definePalette('swoaColorPrimary', {
    50: 'f2f2f2',
    100: 'e6e6e6',
    200: 'cccccc',
    300: '999999',
    400: '007BBE',
    500: '333333',
    600: '222222',
    700: '111111',
    800: '000000',
    900: '000000',
    A100: 'f2f2f2',
    A200: 'e6e6e6',
    A400: '5C5C5C',
    A700: '333333',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50', '100', '200', '300', '400', 'A100', 'A200'],
    contrastLightColors: undefined
  });

  $mdThemingProvider.definePalette('swoaColorAccent', {
    50: 'E2001A',
    100: 'E2001A',
    200: 'E2001A',
    300: 'E2001A',
    400: 'E2001A',
    500: 'E2001A',
    600: 'E2001A',
    700: 'E2001A',
    800: 'E2001A',
    900: 'E2001A',
    A100: '007BBE',
    A200: '009036',
    A400: 'F9B200',
    A700: '006AB3',
    contrastDefaultColor: 'light',
    contrastDarkColors: ['50'],
    contrastLightColors: undefined
  });

  $mdThemingProvider
    .theme('default')
    .primaryPalette('swoaColorPrimary', {
      default: '500',
      'hue-1': 'A100',
      'hue-2': 'A200',
      'hue-3': 'A400'
    })
    .accentPalette('swoaColorAccent', {
      default: '500',
      'hue-1': 'A100',
      'hue-2': 'A200',
      'hue-3': 'A400'
    });

  const originalMatchmediaServiceConstructor = matchmediaProvider.$get.slice(
    -1
  )[0];
  matchmediaProvider.$get.splice(
    -1,
    1,
    function swoaMatchMediaServiceConstructor(...args) {
      const matchmediaService = originalMatchmediaServiceConstructor.apply(
        this,
        args
      );
      const mediaQueries = [
        { name: 'xs', media: '(max-width: 600px)' },
        { name: 'gtXs', media: '(min-width: 600px)' },
        { name: 'sm', media: '(min-width: 600px) and (max-width: 959px)' },
        { name: 'gtSm', media: '(min-width: 960px)' },
        { name: 'md', media: '(min-width: 960px) and (max-width: 1279px)' },
        { name: 'gtMd', media: '(min-width: 1280px)' },
        { name: 'lg', media: '(min-width: 1280px) and (max-width: 1919px)' },
        { name: 'gtLg', media: '(min-width: 1920px)' },
        { name: 'xl', media: '(min-width: 1920px)' }
      ];

      mediaQueries.forEach(mediaQuery => {
        matchmediaProvider.rules[mediaQuery.name] = mediaQuery.media;

        const methodNameSuffix = upperCaseFirst(mediaQuery.name);
        const isMethodName = `is${methodNameSuffix}`;
        const onMethodName = `on${methodNameSuffix}`;

        matchmediaService[isMethodName] = () =>
          matchmediaService.is(matchmediaProvider.rules[mediaQuery.name]);

        matchmediaService[onMethodName] = (listener, $scope) => {
          matchmediaService.on(
            matchmediaProvider.rules[mediaQuery.name],
            listener,
            $scope
          );
        };
      });

      return matchmediaService;
    }
  );

  function upperCaseFirst(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  // http://stackoverflow.com/a/21825207/4737452
  function isIE11() {
    return !$window.ActiveXObject && 'ActiveXObject' in window;
  }
}
