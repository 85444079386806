import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './organisation-profile-infos.html';
import swoaOrganisationService from './../organisation.service';
import swoaNotification from './../../components/notification/notification.module';
import swoaOrganisationProfileHelper from './organisation-profile-helper.service';

export default angular
  .module('swoa.organisation.profile.person-organisation-infos', [
    swoaConstants,
    swoaOrganisationService,
    swoaNotification,
    swoaOrganisationProfileHelper
  ])
  .component('swoaOrganisationProfileInfos', {
    template: htmlTemplate,
    bindings: {
      organisation: '=',
      organisationHeadChiefs: '<'
    },
    controller: OrganisationProfileInfosController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function OrganisationProfileInfosController(
  $scope,
  $element,
  Constants,
  organisationProfileHelper,
  organisationService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    helper = organisationProfileHelper.createFormHelper(vm);

  // view data
  vm.editing = false;
  vm.organisationConfig = null;
  vm.contactConfig = null;
  vm.costCenterConfig = null;
  vm.editOrganisation = null;
  vm.submitted = false;
  vm.initialized = false;
  vm.hasRegisterNumber = true;

  // functions
  vm.initViewData = initViewData;
  vm.save = save;
  vm.startEdit = helper.startEdit;
  vm.cancelEdit = cancelEdit;

  // error handling
  vm.errors = [];
  vm.notificationKey = null;
  vm.notificationSuccess = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.organisation', initViewData, true);
  }

  function initViewData() {
    if (helper.needsInitialization()) {
      vm.originalOrganisation = angular.copy(vm.organisation);
      vm.initialized = true;
      organisationService.updateIsEmailRequiredForOrganisation(
        vm.organisation,
        vm.contactConfig
      );
      vm.organisationConfig = [
        {
          organisationKey: 'name',
          key: 'name',
          columnName: 'organisation.name',
          dataType: 'translatable-name',
          requiredLanguages: ['GERMAN', 'FRENCH', 'ITALIAN']
        },
        {
          organisationKey: 'translatedAcronym',
          key: 'translatedAcronym',
          columnName: 'organisation.translatedAcronym',
          dataType: 'translatable-name',
          requiredLanguages: []
        }
      ];

      vm.contactConfig = [
        {
          organisationKey: 'email',
          key: 'email',
          dataType: 'email',
          pattern: Constants.validationExpressions.email
        }
      ];

      vm.costCenterConfig = [
        {
          organisationKey: 'costCenter',
          key: 'costCenter',
          dataType: 'text',
          pattern: '[0-9]*',
          customMessageKeys: { pattern: 'form.input.validation.number' }
        }
      ];
    }
    if (vm.organisation) {
      vm.hasRegisterNumber = vm.organisation.registerNumber;
    }
  }

  function save(formValid) {
    vm.submitted = true;
    helper.resetNotifications();
    if (formValid) {
      organisationService.persist(vm.organisation).then(onSaveSuccess, onError);
    } else {
      vm.notificationKey = 'form.invalid';
      vm.notificationSuccess = false;
      $element.find('form')[0].scrollIntoView();
    }
  }

  function onSaveSuccess(organisation) {
    helper.processSaved(organisation);
    vm.submitted = false;
  }

  function cancelEdit() {
    vm.organisation = vm.originalOrganisation;
    helper.cancelEdit();
  }
}
