import angular from 'angular';
import 'restangular';
import swoaConstants from '../../app.constants';
import swoaOrganisationService from '../../organisation/organisation.service';

export default angular
  .module('swoa.admin.report.export.service', [
    'restangular',
    swoaConstants,
    swoaOrganisationService,
  ])
  .factory('exportService', exportService)
  .name;

/** @ngInject */
function exportService(Restangular, organisationService, lodash) {
  const EXPORTS_ROUTE = 'exports';

  const baseExports = Restangular.all(EXPORTS_ROUTE);

  // expose public service API
  return {
    loadEarliestYear,
    loadExportableColumnsCards,
    loadExportableColumnsCardChanges,
    loadExportableColumnsSports,
    loadExportableColumnsUsers,
    loadExportableColumnsNwfBaseAmount,
    loadExportableColumnsFor,
    loadExport,
    loadCardChangeExport,
    loadOrganisations,
  };

  // //////////

  function loadEarliestYear() {
    return baseExports.customGET('earliest-year');
  }

  function loadExportableColumnsCards() {
    return baseExports.customGET('exportable-columns-cards');
  }

  function loadExportableColumnsCardChanges() {
    return baseExports.customGET('exportable-columns-card-changes');
  }

  function loadExportableColumnsSports() {
    return baseExports.customGET('exportable-columns-sports');
  }

  function loadExportableColumnsUsers() {
    return baseExports.customGET('exportable-columns-users');
  }

  function loadExportableColumnsNwfBaseAmount() {
    return baseExports.customGET('exportable-columns-nwf-base-amount');
  }

  function loadExportableColumnsFor(endpoint) {
    return baseExports.customGET(endpoint);
  }

  function loadExport(resource, fileName, parameters) {
    return baseExports.customPOST(parameters, resource, { fileName }, {});
  }

  function loadCardChangeExport(resource, fileName, parameters) {
    return baseExports.all('cards').all('change').customPOST(parameters, resource, { fileName }, {});
  }

  function loadOrganisations() {
    return organisationService.getAllowedOrganisations().then((organisations) => {
      const allowedOrganisations = lodash.map(organisations, organisation => ({
        id: organisation.id,
        displayName: organisationService.getOrganisationDisplayName(organisation),
        sports: organisation.sports,
      }));
      return lodash.sortBy(allowedOrganisations, org => org.displayName.toLowerCase());
    });
  }
}
