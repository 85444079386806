import angular from 'angular';
import swoaDialog from '../components/dialog/dialog.module';
import swoaUserService from './user.service';
import htmlTemplate from './reset-password.html';

export default angular
  .module('swoa.user.reset-password', [swoaDialog, swoaUserService])
  .component('swoaResetPassword', {
    template: htmlTemplate,
    bindings: {
      user: '=',
      isRequired: '<',
      runByEditing: '&',
      cancelEditing: '<'
    },
    controller: ResetPasswordController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function ResetPasswordController($scope, dialogService, userService) {
  const vm = this;

  vm.isSuccess = true;

  vm.credentials = null;
  vm.savePassword = false;
  vm.editPassword = true;
  vm.cancelPassword = false;
  vm.editingPassword = false;
  vm.passwordStrength = null;

  vm.startEditPassword = startEditPassword;
  vm.cancelEditPassword = cancelEditPassword;
  vm.resetPassword = resetPassword;
  vm.savePasswordDisabled = savePasswordDisabled;
  vm.ensurePassword = ensurePassword;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.user', updateUser, true);
    $scope.$watch('vm.cancelEditing', updateEditingStatus, true);
    initCredentials();
    vm.submitted = false;
  }

  function updateUser(newUser) {
    if (!vm.user) {
      vm.user = newUser;
    }
  }

  function updateEditingStatus() {
    if (vm.editingPassword && vm.cancelEditing) {
      cancelEditPassword();
    }
  }

  function startEditPassword() {
    vm.editingPassword = true;
    vm.cancelPassword = true;
    vm.savePassword = true;
    vm.editPassword = false;
    vm.runByEditing();
  }

  function cancelEditPassword() {
    vm.editingPassword = false;
    vm.cancelPassword = false;
    vm.savePassword = false;
    vm.editPassword = true;
    initCredentials();
  }

  function resetPassword() {
    vm.errors = [];
    vm.notificationKey = null;
    vm.isSuccess = true;
    return userService
      .resetPassword(vm.user, vm.credentials)
      .then(onResetPasswordSuccess, onError);
  }

  function initCredentials() {
    vm.credentials = {
      password: null
    };
  }

  function savePasswordDisabled() {
    return (
      !vm.credentials.password ||
      !vm.credentials.password.length ||
      vm.passwordStrength < 4
    );
  }

  function ensurePassword() {
    return (
      !vm.editingPassword || (vm.editingPassword && vm.passwordStrength >= 4)
    );
  }

  function onResetPasswordSuccess(mergedUser) {
    vm.editingPassword = false;
    vm.cancelPassword = false;
    vm.savePassword = false;
    vm.editPassword = true;
    vm.user = mergedUser;
    initCredentials();
    dialogService.showSuccessToast('user.password.save.done', {
      userName: vm.user.login
    });
  }

  function onError(reason) {
    if (reason.data && reason.data.errors) {
      vm.errors = reason.data.errors;
    }
  }
}
