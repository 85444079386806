import angular from 'angular';
import swoaAhvAhvNumberValidator from './ahv-number-validator.service';

export default angular
  .module('swoa.ahv.ahv-number', [swoaAhvAhvNumberValidator])
  .directive('swoaAhvNumber', swoaAhvNumber).name;

/** @ngInject */
function swoaAhvNumber() {
  return {
    restrict: 'A',
    scope: {
      swoaAhvNumber: '='
    },
    require: 'ngModel',
    controller: AhvNumberController,
    controllerAs: 'vm',
    bindToController: true
  };
}

/** @ngInject */
function AhvNumberController($element, ahvNumberValidator) {
  const vm = this,
    ngModel = $element.controller('ngModel');

  if (!ngModel) {
    return;
  }

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    ngModel.$parsers.unshift(ahvNumberParser);
    ngModel.$formatters.unshift(ahvNumberFormatter);
  }

  function ahvNumberParser(viewValue) {
    if (!vm.swoaAhvNumber || !viewValue) {
      return viewValue;
    }

    const valid = ahvNumberValidator.isValidAHVNumber(viewValue);
    ngModel.$setValidity('ahvNumber', valid);

    let modelValue;
    if (valid) {
      modelValue = ahvNumberValidator.getUnformattedAHVNumber(viewValue);
      ngModel.$setViewValue(
        ahvNumberValidator.getFormattedAHVNumber(modelValue)
      );
      ngModel.$render();
    }

    return modelValue;
  }

  function ahvNumberFormatter(modelValue) {
    if (!vm.swoaAhvNumber || !modelValue) {
      return modelValue;
    }

    const valid = ahvNumberValidator.isValidAHVNumber(modelValue);
    ngModel.$setValidity('ahvNumber', valid);

    let viewValue;
    if (valid) {
      viewValue = ahvNumberValidator.getFormattedAHVNumber(
        ahvNumberValidator.getUnformattedAHVNumber(modelValue)
      );
    }

    return viewValue;
  }
}
