import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './permission-checkboxes.html';

export default angular
  .module('swoa.admin.role.permission-checkboxes', [
    swoaConstants,
  ])
  .component('swoaPermissionCheckboxes', {
    template: htmlTemplate,
    bindings: {
      read: '<',
      write: '<',
      isReadOnly: '<',
    },
    controller: PermissionCheckboxesController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function PermissionCheckboxesController() {
  const vm = this;

  vm.readChanged = readChanged;
  vm.writeChanged = writeChanged;

  // ///////////////

  function readChanged() {
    if (vm.write) {
      if (!vm.read.selected) {
        vm.write.selected = false;
      }
    }
  }

  function writeChanged() {
    if (vm.read) {
      if (vm.write.selected && !vm.read.selected) {
        vm.read.selected = true;
      }
    }
  }
}
