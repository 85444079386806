import angular from 'angular';
import 'restangular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.async.service', [
    'restangular',
    swoaConstants,
  ])
  .factory('asyncHelperService', asyncHelper)
  .name;

/** @ngInject */
function asyncHelper($interval, $rootScope, $timeout, $sce, Restangular, ConfigConstants) {
  return {
    createAsyncHelper,
  };

  // //////////

  function createAsyncHelper(vm, $scope, errorHandler, resultCallback, options) {
    return new AsyncHelper(vm, $scope, errorHandler, resultCallback, options || {});
  }

  function AsyncHelper(vm, $scope, errorHandler, resultCallback, options) {
    const self = this;
    self.intervalPromise = null;
    self.callInProgress = false;

    $scope.$on('$destroy', () => {
      self.stopRefresh();
    });

    self.getAsyncJobStatus = () => {
      // if a status response takes longer than 2 seconds to finish, we don't want multiple requests going on at the
      // same time. otherwise we might end up fetching the result twice which will result in an error.
      if (!self.callInProgress) {
        self.callInProgress = true;
        return Restangular.one('async-job').customGET('status', options.httpOptions)
          .then(self.updateResult)
          .catch(self.errorHandler);
      }
      return null;
    };

    self.startRefresh = () => {
      self.intervalPromise = $interval(self.getAsyncJobStatus, 2000);
    };

    self.updateResult = (result) => {
      self.callInProgress = false;

      // exit without doing anything if the job does not belong to this instance
      if (options.resultHint && result.resultHint !== options.resultHint) {
        return null;
      }

      vm.running = result.running;
      $rootScope.spinnerProgress = result.progress;

      // parse log
      if (result.log && result.log.length > 0) {
        const list = result.log.replace(/^\[(\w+)\](.*?)$/gmi, '<li class="$1">$1$2</li>');
        vm.log = $sce.trustAsHtml(`<ul>${list}</ul>`);
      }

      // if job is still running, query it in an interval
      if (result.running && !self.intervalPromise) {
        self.startRefresh();
      }

      // job has finished, stop refresh
      if (!result.existent || !result.running) {
        self.stopRefresh();
      }

      if (result.existent && !result.running && angular.isFunction(resultCallback)) {
        if (options.fetchResult) {
          Restangular.one('async-job').customGET('result', options.httpOptions).then(resultCallback)
            .catch(self.errorHandler)
            .finally(self.hideSpinner);
        } else {
          resultCallback(`${ConfigConstants.api.url}/async-job/result`, result, options.httpOptions)
            .catch(self.errorHandler)
            .finally(self.hideSpinner);
        }
      }

      $timeout(() => {
        const elem = angular.element('#asyncStatusLog')[0];
        if (elem) {
          elem.scrollTop = elem.scrollHeight;
        }
      });
      return result;
    };

    self.stopRefresh = () => {
      $interval.cancel(self.intervalPromise);
      self.intervalPromise = null;
    };

    self.errorHandler = (error) => {
      self.hideSpinner();
      vm.log = null;
      errorHandler(error);
    };

    self.getSpinnerContent = () => $sce.trustAsHtml('<div class="spinner-inline"></div>');
    self.showSpinner = () => ($rootScope.showSpinner = true);
    self.hideSpinner = () => ($rootScope.showSpinner = false);
  }
}
