import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaPositiveNegative from '../../components/positive-negative/positive-negative.filter';
import swoaCardSportYearService from './sport-year.service';
import htmlTemplate from './sport-year-summary.html';

export default angular
  .module('swoa.card.sport-year.sport-year-summary', [
    uiRouter,
    swoaPositiveNegative,
    swoaCardSportYearService
  ])
  .component('swoaSportYearSummary', {
    template: htmlTemplate,
    bindings: {},
    controller: SportYearSummaryController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function SportYearSummaryController($stateParams, $state, sportYearService) {
  const vm = this;

  vm.organisationId = parseInt($stateParams.organisationId, 10);
  vm.sportId = parseInt($stateParams.sportId, 10);

  vm.sportYearSummary = null;
  vm.order = {};

  // functions
  vm.goToCardImport = goToCardImport;
  vm.visitSportYear = visitSportYear;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    if (vm.organisationId) {
      sportYearService
        .loadOrganisationCardYearSummary(vm.organisationId)
        .then(sportYearSummary => {
          vm.sportYearSummary = sportYearSummary;
        });
    } else if (vm.sportId) {
      sportYearService
        .loadSportYearSummary(vm.sportId)
        .then(sportYearSummary => {
          vm.sportYearSummary = sportYearSummary;
        });
    } else {
      vm.organisationId = 0;
      sportYearService
        .loadFunctionaryCardYearSummary()
        .then(functionaryYearSummary => {
          vm.sportYearSummary = functionaryYearSummary;
        });
    }
  }

  function visitSportYear(year) {
    return $state.go('sportYearDetail', {
      type: year.type,
      typedId: getTypedId(),
      yearId: year.id
    });
  }

  function goToCardImport(year) {
    return $state.go('cardImport.overview', {
      type: year.type,
      typedId: getTypedId(),
      yearId: year.id
    });
  }

  function getTypedId() {
    let typedId;
    if (vm.organisationId) {
      typedId = vm.organisationId;
    } else if (vm.sportId) {
      typedId = vm.sportId;
    } else {
      typedId = vm.organisationId;
    }
    return typedId;
  }
}
