import angular from 'angular';
import htmlTemplate from './card.html';
import swoaConstants from '../app.constants';

export default angular
  .module('swoa.card.card', [swoaConstants])
  .component('swoaCard', {
    template: htmlTemplate,
    bindings: {
      type: '<',
      ftem: '<',
      clickable: '<',
      isDisabled: '<'
    },
    controllerAs: 'vm',
    controller: CardController
  }).name;

/** @ngInject */
function CardController(Constants) {
  const vm = this;

  vm.showFtemType = showFtemType;

  function showFtemType() {
    return (
      vm.ftem !== undefined &&
      vm.ftem !== null &&
      !vm.isDisabled &&
      isTalentOrEliteCard()
    );
  }

  function isTalentOrEliteCard() {
    return (
      Constants.cards.category.ELITE.types.includes(vm.type) ||
      Constants.cards.category.TALENT.types.includes(vm.type)
    );
  }
}
