import angular from 'angular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.date', [
    swoaConstants,
  ])
  .filter('swoaDate', swoaDateFilter)
  .name;

export const DATE_FORMAT_DATE_ONLY = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

/** @ngInject */
function swoaDateFilter(moment) {
  return (date, outputFormat, inputFormat) => {
    if (!date) {
      return undefined;
    }

    const isDateLike = moment.isDate(date) || moment.isMoment(date);
    const momentDate = isDateLike ?
      moment(date) :
      moment(date, inputFormat || moment.ISO_8601);

    return momentDate.format(outputFormat || DATE_FORMAT_DATE_ONLY);
  };
}

