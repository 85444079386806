import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaFormHelper from '../../components/form-helper/form-helper.service';
import swoaNwfBaseAmountPeriodService from './nwf-base-amount-period.service';
import htmlTemplate from './nwf-base-amount-period-foundation.html';

export default angular
  .module(
    'swoa.nwf-contribution.base-amount.nwf-base-amount-period-foundation',
    [
      swoaConstants,
      swoaNotification,
      swoaFormHelper,
      swoaNwfBaseAmountPeriodService
    ]
  )
  .component('swoaNwfBaseAmountPeriodFoundation', {
    template: htmlTemplate,
    bindings: {
      period: '<'
    },
    controller: NwfBaseAmountPeriodFoundation,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfBaseAmountPeriodFoundation(
  $stateParams,
  $rootScope,
  $scope,
  notificationService,
  formHelper,
  Restangular,
  nwfBaseAmountPeriodService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    helper = formHelper.createFormHelper(vm);

  vm.category = null;

  vm.editing = false;

  vm.startEdit = helper.startEdit;
  vm.cancelEdit = helper.cancelEdit;

  vm.save = save;
  vm.initViewData = initViewData;

  // for testing
  vm._save = save;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    $scope.$watch('vm.period', initViewData);
  }

  function save(valid) {
    helper.resetNotifications();
    vm.submitted = true;

    if (!valid) {
      vm.notificationKey = 'form.invalid';
      return;
    }

    vm.period.category = vm.category;
    nwfBaseAmountPeriodService.save(vm.period).then(onSaveSuccess, onError);
  }

  function onSaveSuccess(period) {
    $rootScope.$broadcast('swoa:nwfCategoryUpdated', period);
    helper.showSuccess('nwfContributions.category.save.success');
  }

  function initViewData() {
    if (vm.period) {
      vm.category = vm.period.category;
    }
  }
}
