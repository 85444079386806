import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.card.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('cardSportList', {
    url: '/cards',
    template: '<swoa-sport-list sport-year-mode="true"></swoa-sport-list>',
    data: {
      breadcrumb: true,
      hasPermission: 'sport_r'
    }
  });
}
