import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.user.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('userSelectPerson', {
      url: '/users/add',
      template: '<swoa-user-select-person></swoa-user-select-person>',
      data: {
        breadcrumb: {
          parent: 'userList',
          hasPermission: 'admin_user_r',
        },
      },
    })
    .state('fillPersonForNewUser', {
      url: '/users/create-person',
      template: '<swoa-user-create-person></swoa-user-create-person>',
      data: {
        breadcrumb: {
          parent: 'userSelectPerson',
          hasPermission: 'admin_user_w',
        },
      },
    })
    .state('userList', {
      url: '/users',
      template: '<swoa-user-list></swoa-user-list>',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_user_r',
      },
    })
    .state('userDetail', {
      url: '/users/:userId',
      template: '<swoa-user-list></swoa-user-list>',
      data: {
        breadcrumb: {
          parent: 'userList',
          ignore: true,
        },
        hasPermission: 'admin_user_r',
      },
    })
    .state('createUserForPerson', {
      url: '/users/add/:personId',
      template: '<swoa-user-list></swoa-user-list>',
      data: {
        breadcrumb: {
          parent: 'userSelectPerson',
          hasPermission: 'admin_user_w',
        },
      },
    });
}
