import angular from 'angular';
import swoaTemplateRoutes from './templates.routes';
import swoaTemplateTemplates from './templates.component';
import swoaTemplateService from './templates.service';

export default angular
  .module('swoa.admin.template', [
    swoaTemplateRoutes,
    swoaTemplateTemplates,
    swoaTemplateService,
  ])
  .name;

