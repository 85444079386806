import angular from 'angular';

export default angular
  .module('swoa.nwf-contribution.controlling.link', [])
  .component('swoaNwfControllingLink', {
    template: `<div ng-if="vm.showControllingLink()"
                    ua-permission="nwf_contribution_controlling_r">
                  <a ui-sref="nwfControlling(::{organisationId: vm.calendarYear.organisation.id, calendarYearId: vm.calendarYear.id})"
                     ng-click="$event.stopPropagation()">
                        {{'nwfContributions.column.controlling' | translate}}
                  </a>
                  <swoa-icon name="{{vm.calendarYear.controllingStatus === 'RELEASED' ? 'check' : 'close'}}"
                             class="is-medium">
                  </swoa-icon>
                </div>
                <div ng-if="vm.noControllingRequested()">
                  {{'nwfContributions.controlling.not.requested' | translate}}
                </div>
                `,
    bindings: {
      calendarYear: '<',
      variableAmountTotal: '<'
    },
    controller: NwfControllingLink,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfControllingLink() {
  const vm = this;

  vm.showControllingLink = () =>
    vm.calendarYear.payment2 !== null && vm.variableAmountTotal > 0;

  vm.noControllingRequested = () =>
    vm.calendarYear.payment2 !== null && vm.variableAmountTotal === 0;
}
