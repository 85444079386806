import angular from 'angular';
import 'restangular';
import swoaDialogService from '../../components/dialog/dialog.service';

export default angular
  .module('swoa.card.card-year.service', [
    'restangular',
    swoaDialogService,
  ])
  .factory('cardYearService', cardYearService)
  .name;

/** @ngInject */
function cardYearService(Restangular, dialogService, $rootScope) {
  return {
    getTypedId,
    isTypeOrganisation,
    isTypeSport,
    generateCardYear,
  };

  // //////////

  function getTypedId(cardYear) {
    if (isTypeOrganisation(cardYear)) {
      return cardYear.organisation.id;
    }
    if (isTypeSport(cardYear)) {
      return cardYear.sport.id;
    }
    return null;
  }

  function isTypeOrganisation(cardYear) {
    return cardYear.type === 'ORGANISATION';
  }

  function isTypeSport(cardYear) {
    return cardYear.type === 'SPORT';
  }

  function generateCardYear(category, year, sport, organisation) {
    if (category === 'FUNCTIONARY') {
      Restangular.one('functionary', null)
        .customPOST({}, 'generate-cardyear', { category, overrideYear: year })
        .then(generationSuccess);
    } else if (category === 'TECHNICIAN') {
      Restangular.one('organisations', organisation)
        .customPOST({}, 'generate-cardyear', { category, overrideYear: year })
        .then(generationSuccess);
    } else {
      Restangular.one('sports', sport)
        .customPOST({}, 'generate-cardyear', { category, overrideYear: year })
        .then(generationSuccess);
    }
  }

  function generationSuccess(result) {
    $rootScope.$broadcast('swoa:cardYearGenerationSuccess', result);
    dialogService.showSuccessToast('cardParameter.generationSuccess', { createdLists: result.length });
  }
}
