import angular from 'angular';
import htmlTemplate from './person-timeline-entry.html';

export default angular
  .module('swoa.person.timeline.person-timeline-entry', [])
  .component('swoaPersonTimelineEntry', {
    template: htmlTemplate,
    bindings: {
      person: '<',
      timelineEntry: '<',
      deleteAction: '<',
    },
    controller: PersonTimelineEntryController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function PersonTimelineEntryController($rootScope) {
  const vm = this;

  vm.hasCommentAccess = hasCommentAccess;

  // ////////////////

  function hasCommentAccess(type, action, authorId) {
    const ownComment = $rootScope.authInfo.getUser().person.id === authorId;
    return $rootScope.uaPermission(`comment_${type}_all_${action}`) ||
      (ownComment && $rootScope.uaPermission(`comment_${type}_own_${action}`));
  }
}
