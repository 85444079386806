import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaDate from '../../components/date/date.filter';
import swoaPersonService from '../person.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './person-creation-assign.html';

export default angular
  .module('swoa.person.creation.person-creation-assign', [
    uiRouter,
    swoaDate,
    swoaPersonService,
    swoaNotification
  ])
  .component('swoaPersonCreationAssign', {
    template: htmlTemplate,
    bindings: {},
    controller: PersonCreationAssignController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonCreationAssignController(
  $state,
  $stateParams,
  personService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  // functions
  vm.assignAction = assignAction;
  vm.selectPerson = selectPerson;
  vm.cancelAction = cancelAction;
  vm.confirmAction = confirmAction;

  // state params
  vm.personCreationId = $stateParams.personCreationId;
  vm.searchMode = $stateParams.search;

  // loaded data
  vm.person = null;
  vm.foundPersons = null;

  // selections
  vm.selectedPerson = null;
  vm.searchText = null;

  // error handling
  vm.notificationKey = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    personService.loadPerson(vm.personCreationId).then(person => {
      vm.person = person;
    }, onError);

    if (vm.searchMode === 'similar') {
      personService
        .loadSimilarPersons(vm.personCreationId, 0, 999)
        .then(data => {
          vm.foundPersons = data.result;
        }, onError);
    }
  }

  function selectPerson(personId) {
    $stateParams.personId = personId;
    $state.go('personCreationSync', $stateParams);
  }

  function assignAction() {
    selectPerson(vm.selectedPerson);
  }

  function cancelAction() {
    $state.go('personMutations', $stateParams);
  }

  function confirmAction() {
    return personService.confirm(vm.person.id).then(cancelAction, onError);
  }
}
