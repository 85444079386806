import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './permission-role-group.html';

export default angular
  .module('swoa.admin.role.permission-role-group', [
    swoaConstants,
  ])
  .component('swoaPermissionRoleGroup', {
    template: htmlTemplate,
    bindings: {
      permissions: '<',
      categoryNumber: '<',
      permissionEntityStatus: '<',
      cardYearStatusNumber: '<',
      isReadOnly: '<',
      isPersonRole: '<',
    },
    controller: PermissionRoleGroupController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function PermissionRoleGroupController($translate, lodash, Constants) {
  const vm = this;

  vm.readPermissions = [];
  vm.writePermissions = [];
  vm.allRead = false;
  vm.allWrite = false;
  vm.groupPermissions = null;
  vm.entities = null;

  vm.getEntities = getEntities;
  vm.getUnique = getUnique;
  vm.getRead = getRead;
  vm.getWrite = getWrite;
  vm.allSelected = allSelected;
  vm.someSelected = someSelected;
  vm.hasAny = hasAny;
  vm.getAllFromSameCategory = getAllFromSameCategory;
  vm.setAll = setAll;
  vm.getPermissionId = getPermissionId;
  vm.getCategoryTranslation = getCategoryTranslation;

  // ///////////////

  function getEntities() {
    let permissions = vm.permissions;
    if (vm.permissionEntityStatus !== undefined) {
      permissions = lodash.filter(permissions, ['permissionEntityStatus', vm.permissionEntityStatus]);
    }
    permissions = lodash.map(permissions, perm => customizeEntity(perm));
    permissions = lodash.orderBy(permissions, ['customizedEntity', p => p.sportYearCategory !== null, 'description']);

    vm.readPermissions = lodash.filter(permissions, perm => perm.action === 'READ');
    vm.writePermissions = lodash.filter(permissions, perm => perm.action === 'WRITE');
    vm.permissionsByEntity = lodash.groupBy(permissions, 'customizedEntity');
    vm.groupPermissions = permissions;
    return lodash.uniq(lodash.map(permissions, perm => perm.customizedEntity));
  }

  function customizeEntity(permission) {
    // customize/hack some custom entity groupings because they want it like that...
    if (permission.entity === 'NWF_CONTRIBUTION' && lodash.includes(permission.name, '_AMOUNT_')) {
      permission.customizedEntity = lodash.includes(permission.name, 'VARIABLE_AMOUNT_') ? 'NWF_VARIABLE_AMOUNT' : 'NWF_BASE_AMOUNT';
    } else if (permission.entity === 'NWF_CONTRIBUTION' && lodash.includes(permission.name, 'RECOGNITION')) {
      permission.customizedEntity = 'NWF_VARIABLE_AMOUNT';
    } else if (permission.entity === 'NWF_CONTRIBUTION' && lodash.includes(permission.name, 'CONTROLLING')) {
      permission.customizedEntity = 'NWF_CONTROLLING';
    } else {
      permission.customizedEntity = permission.entity;
    }
    return permission;
  }

  function getUnique(entity) {
    const unique = lodash.uniqWith(vm.permissionsByEntity[entity], permissionsEqualWithoutActionAndCategory);
    return lodash.orderBy(unique, ['description']);
  }

  function permissionsEqualWithoutActionAndCategory(a, b) {
    let aCategoryNull = (a.sportYearCategory === null);
    let bCategoryNull = (b.sportYearCategory === null);
    let aName = a.name;
    let bName = b.name;
    // special case for person data, treat _all and _own as category
    if (a.isPersonData || a.isProfileAccess) {
      aName = aName.replace('_OWN', '').replace('_NO_CARD', '').replace('_NWF_TRAINER', '');
      aCategoryNull = false;
    }
    if (b.isPersonData || b.isProfileAccess) {
      bName = bName.replace('_OWN', '').replace('_NO_CARD', '').replace('_NWF_TRAINER', '');
      bCategoryNull = false;
    }
    return a.customizedEntity === b.customizedEntity && aName === bName && a.permissionEntityStatus === b.permissionEntityStatus &&
      aCategoryNull === bCategoryNull;
  }

  function permissionsEqualWithoutAction(a, b) {
    return permissionsEqualWithoutActionAndCategory(a, b) &&
      a.sportYearCategory === b.sportYearCategory &&
      a.isPersonData === b.isPersonData &&
      a.isPersonDataOwn === b.isPersonDataOwn &&
      a.isProfileAccessNwfTrainer === b.isProfileAccessNwfTrainer;
  }

  function getRead(permission) {
    return lodash.find(vm.readPermissions, elem => permissionsEqualWithoutAction(permission, elem));
  }

  function getWrite(permission) {
    return lodash.find(vm.writePermissions, elem => permissionsEqualWithoutAction(permission, elem));
  }

  function allSelected(permissions, action) {
    return permissions.length > 0 && lodash.every(permissions, perm => perm.action === action && perm.selected);
  }

  function someSelected(permissions, action) {
    return permissions.length > 0 && lodash.some(permissions, perm => perm.action === action && perm.selected);
  }

  function hasAny(permissions, action) {
    return permissions.length > 0 && lodash.filter(permissions, perm => perm.action === action).length > 0;
  }

  function getAllFromSameCategory(permission, action) {
    let filtered = lodash.filter(vm.groupPermissions, elem => permissionsEqualWithoutActionAndCategory(permission, elem));
    if (action !== 'BOTH') {
      if (!action) {
        filtered = lodash.uniqWith(filtered, permissionsEqualWithoutAction);
      } else {
        filtered = lodash.filter(filtered, elem => elem.action === action);
      }
    }
    if (vm.isPersonRole) {
      filtered = lodash.filter(filtered, elem => !elem.isPersonData || elem.isPersonDataOwn);
    } else {
      filtered = lodash.filter(filtered, elem => !elem.isPersonData || !elem.isPersonDataOwn);
    }
    return lodash.orderBy(filtered, [p => Constants.cards.categories.indexOf(p.sportYearCategory)]);
  }

  function setAll(where, action, valueToSet) {
    const permissions = lodash.filter(where, perm => perm.action === action);
    permissions.forEach(perm => (perm.selected = valueToSet));

    if (action === 'WRITE' && valueToSet) {
      setAll(where, 'READ', valueToSet);
    } else if (action === 'READ' && !valueToSet) {
      setAll(where, 'WRITE', valueToSet);
    }
  }

  function getPermissionId(unique) {
    const name = unique.name ? `${unique.name}_` : '';
    const read = vm.getRead(unique) ? 'R' : '';
    const write = vm.getWrite(unique) ? 'W' : '';
    const divider1 = (unique.permissionEntityStatus || unique.sportYearCategory) ? '_' : '';
    const divider2 = (unique.permissionEntityStatus && unique.sportYearCategory) ? '_' : '';
    const status = unique.permissionEntityStatus || '';
    const category = unique.sportYearCategory || '';
    return `${unique.entity}_${name}${read}${write}${divider1}${status}${divider2}${category}`;
  }

  function getCategoryTranslation(permission) {
    const categoryTranslation = $translate.instant(`cardParameter.type.${permission.sportYearCategory}`);
    if (permission.isPersonData) {
      if (permission.sportYearCategory) {
        if (permission.isPersonDataOwn) {
          return `${categoryTranslation} eigenes Profil`;
        }
        return `${categoryTranslation} fremdes Profil`;
      }
      if (permission.isPersonDataOwn) {
        return 'Cardunabhängig eigenes Profil (mit oder ohne Card kann Attribut auf eigenem Profil gelesen/bearbeitet werden, ' +
          'übersteuert cardspezifische Häkchen in diesem Abschnitt)';
      }
      return 'Cardunabhängig fremdes Profil (mit oder ohne Card kann Attribut auf fremdem Profil gelesen/bearbeitet werden, ' +
        'übersteuert cardspezifische Häkchen in diesem Abschnitt)';
    } else if (permission.isProfileAccessNoCard) {
      return 'Keine Card (Profile und Auszüge von Personen, welche keine Card besitzen dürfen gelesen/bearbeitet werden)';
    } else if (permission.isProfileAccessNwfTrainer) {
      return 'NWF-Trainer (Profile von Personen, welche als NWF-Trainer tätig sind)';
    }
    return categoryTranslation;
  }
}
