import angular from 'angular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.notification.service', [
    swoaConstants,
  ])
  .factory('notificationService', notificationService)
  .name;

/** @ngInject */
function notificationService($rootScope, $window, $q, lodash) {
  return {
    errorHandler,
    resetErrors,
  };

  // //////////

  function errorHandler(vm, disableScrolling) {
    return error => handleBackendError(error, vm, disableScrolling);
  }

  function handleBackendError(error, vm, disableScrolling) {
    if (!disableScrolling) {
      $window.scrollTo(0, 0);
    }
    vm.showNotification = false;
    vm.isSuccess = false;
    vm.notificationSuccess = false;
    vm.isInfo = false;
    vm.notificationInfo = false;
    vm.notificationKeyList = [];
    $rootScope.showSpinner = false;

    // don't show error message if there is no error object or the timeout promise has been resolved to cancel the request
    if (!error || (lodash.get(error, 'config.timeout.$$state.status') === 1)) {
      return null;
    }

    let data = error.data;

    if (error.config && error.config.responseType === 'arraybuffer') {
      data = JSON.parse(uint8ArrayToString(new Uint8Array(error.data)));
    }

    if (data && data.errors && data.errors.length > 0) {
      // if there are multiple messages, push them to
      data.errors.forEach((err) => {
        vm.notificationKeyList.push(saveNotification(err, {}));
      });

      // set also key for other directives
      saveNotification(data.errors[0], vm);
    } else if (data && data.indexOf('request was larger') >= 0) {
      vm.notificationKey = 'ch.swissolympic.errors.fileTooLarge';
    } else if (data && data.indexOf('Gateway Time-out') >= 0) {
      vm.notificationKey = 'ch.swissolympic.errors.timeout';
    } else {
      vm.notificationKey = 'ch.swissolympic.errors.internalServerError';
    }

    return $q.reject(error);
  }

  function resetErrors(vm) {
    vm.notificationKeyList = [];
    vm.notificationKey = null;
    vm.notificationValues = {};
  }

  function saveNotification(error, notificationObj) {
    notificationObj.notificationKey = error.messageKey;
    notificationObj.correlationId = error.correlationId;
    notificationObj.notificationValues = { correlationId: error.correlationId };
    lodash.forEach(error.messageArguments, (value, index) => {
      notificationObj.notificationValues[`arg${index}`] = value;
    });
    return notificationObj;
  }

  function uint8ArrayToString(uint8Array) {
    const encodedString = String.fromCharCode.apply(null, uint8Array);
    return decodeURIComponent(escape(encodedString));
  }
}
