import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaCardSportYearService from './sport-year.service';
import htmlTemplate from './card-contingent.html';

export default angular
  .module('swoa.card.sport-year.card-contingent', [
    swoaConstants,
    swoaCardSportYearService
  ])
  .component('swoaCardContingent', {
    template: htmlTemplate,
    bindings: {
      cardType: '<',
      cardSummaries: '<'
    },
    controller: CardContingentController,
    controllerAs: 'vm',
    bindToController: true
  }).name;

/** @ngInject */
function CardContingentController($scope, lodash, sportYearService) {
  const vm = this;

  vm.cardSummary = null;
  vm.exceeded = false;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.cardType', updateCardContingent);
    $scope.$watch('vm.cardSummaries', updateCardContingent, true);
  }

  // Private helpers

  function updateCardContingent() {
    if (!vm.cardType || !vm.cardSummaries) {
      vm.cardSummary = null;
      vm.exceeded = false;
    } else {
      vm.cardSummary = lodash.find(vm.cardSummaries, { type: vm.cardType });

      const remainder = sportYearService.getCardContingentsRemainder(
        vm.cardType,
        vm.cardSummaries
      );
      vm.exceeded = angular.isDefined(remainder) && remainder < 0;
    }
  }
}
