import angular from 'angular';
import swoaComment from './comment.component';
import swoaCommentCommentsTimeline from './comments-timeline.component';
import swoaCommentCommentForm from './comment-form.component';

export default angular
  .module('swoa.comment', [
    swoaComment,
    swoaCommentCommentsTimeline,
    swoaCommentCommentForm,
  ])
  .name;

