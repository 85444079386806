import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.file-storage.service', [
    'restangular',
  ])
  .factory('fileStorageService', fileStorageService)
  .name;

/** @ngInject */
function fileStorageService(Restangular) {
  const service = {
    deleteFileStorage,
  };
  return service;

  // //////////

  function deleteFileStorage(fileStorageId) {
    return Restangular.one('file-storages', fileStorageId).remove();
  }
}
