import angular from 'angular';
import 'restangular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaPersonService from '../../person/person.service';
import swoaPersonSyncService from '../../person/sync/person-sync.service';

export default angular
  .module('swoa.card.sport-year.service', [
    'restangular',
    angularTranslate,
    swoaConstants,
    swoaPersonService,
    swoaPersonSyncService
  ])
  .factory('sportYearService', sportYearService).name;

/** @ngInject */
function sportYearService(
  lodash,
  Restangular,
  Constants,
  personService,
  personSyncService
) {
  const service = {
    addComment,
    addPerson,
    changeAllSelectedCardStatus,
    deleteAllSelectedCards,
    copyCardsFromLastSportYear,
    deleteComment,
    getFileCount,
    getFiles,
    goToStatus,
    loadImportedCards,
    loadSportYear,
    loadSportYearDetails,
    loadSportYearDetailsWithAddressFlag,
    loadCardYearBasicInfo,
    loadSportYearContacts,
    loadSportYearSummary,
    loadSportYearCardWarnings,
    loadOrganisationCardYearSummary,
    loadFunctionaryCardYearSummary,
    saveImportedCards,
    startMatch,
    matchAgain,
    cancelImport,
    getCardContingentsRemainder,
    areCardContingentsExceeded,
    getAdditionalRequiredProperties,
    getChangeLog,
    getCardListDetailRouteParameter,
    updateSportYearDates,
    purgeSportYear,
    loadLatestSportYears,
    createDigitalCards,

    // for tests only!
    _getAdditionalProps: getAdditionalProps
  };

  activate();

  return service;

  // //////////

  function changeAllSelectedCardStatus(sportYearId, cardStatus, cardIds) {
    const container = { cardsId: cardIds, cardStatus };
    return Restangular.one('sportyears', sportYearId).customPUT(
      container,
      'cards-update'
    );
  }

  function deleteAllSelectedCards(sportYearId, cardIds) {
    return Restangular.one('sportyears', sportYearId).customPOST(
      cardIds,
      'cards-purge'
    );
  }

  function activate() {
    // extend the sportyears model and calculate some summaries
    Restangular.extendModel('sportyears', extendSportYearsModel);

    // extend the imported cards and flatten the foreign IDs
    Restangular.extendModel('importedcards', extendImportedCardsModel);
  }

  function typeIndex(category, type) {
    return lodash.indexOf(Constants.cards.category[category].types, type);
  }

  function getAdditionalRequiredProperties(sportYearId) {
    return service
      .loadSportYearDetails(sportYearId)
      .then(sportYear => getAdditionalProps(sportYear));
  }

  function getAdditionalProps(sportYear) {
    // some properties are only required if it is a Elite, Trainer or Technician card
    return personSyncService[`ADDITIONAL_${sportYear.category}_REQUIRED`];
  }

  function loadSportYear(sportYearId) {
    return Restangular.one('sportyears', sportYearId).get();
  }

  function loadSportYearDetails(sportYearId) {
    return loadCardYearDetails(sportYearId, false);
  }

  function loadSportYearDetailsWithAddressFlag(sportYearId) {
    return loadCardYearDetails(sportYearId, true);
  }

  function loadCardYearDetails(sportYearId, loadAddressFlag) {
    return Restangular.one('sportyears', sportYearId).customGET('details', {
      loadAddressFlag
    });
  }

  function loadCardYearBasicInfo(sportYearId) {
    return Restangular.one('sportyears', sportYearId).customGET('basicinfo');
  }

  function loadSportYearContacts(sportYearId) {
    return Restangular.one('sportyears', sportYearId).customGET('contacts');
  }

  function loadSportYearSummary(selectedSport) {
    return Restangular.one('sports', selectedSport).getList('summary');
  }

  function loadOrganisationCardYearSummary(selectedOrganisation) {
    return Restangular.one('organisations', selectedOrganisation).getList(
      'summary'
    );
  }

  function loadFunctionaryCardYearSummary() {
    return Restangular.one('functionary').getList('summary');
  }

  function copyCardsFromLastSportYear(sportYearId) {
    return Restangular.one('sportyears', sportYearId).customPOST(
      {},
      'copycards'
    );
  }

  function saveImportedCards(cards, sportYearId) {
    return Restangular.one('sportyears', sportYearId).customPOST(
      cards,
      'importcards'
    );
  }

  function loadImportedCards(sportYearId) {
    return Restangular.one('sportyears', sportYearId).getList('importedcards');
  }

  function startMatch(sportYearId) {
    return Restangular.one('sportyears', sportYearId).customPOST(
      {},
      'processimport'
    );
  }

  function matchAgain(sportYearId) {
    return Restangular.one('sportyears', sportYearId).customPOST(
      {},
      'processimport',
      { reset: true }
    );
  }

  function cancelImport(sportYearId) {
    return Restangular.one('sportyears', sportYearId).customPOST(
      {},
      'cancelimport'
    );
  }

  function loadSportYearCardWarnings(sportYearId, cardIds) {
    return Restangular.one('sportyears', sportYearId).customGET(
      'cardwarnings',
      cardIds
    );
  }

  function getCardContingentsRemainder(cardType, cardSummaries) {
    const summaryIndex = lodash.findIndex(cardSummaries, { type: cardType });

    if (
      cardSummaries[summaryIndex].contingent === null ||
      cardSummaries[summaryIndex].contingent === undefined
    ) {
      return null;
    }

    return cardSummaries.reduce((remaining, summary, index) => {
      if (index <= summaryIndex) {
        return Math.max(0, remaining) + (summary.contingent - summary.count);
      }
      return remaining;
    }, 0);
  }

  function areCardContingentsExceeded(cardSummaries) {
    return cardSummaries
      .map(summary =>
        service.getCardContingentsRemainder(summary.type, cardSummaries)
      )
      .some(remainder => angular.isDefined(remainder) && remainder < 0);
  }

  function addComment(sportYearId, comment) {
    return Restangular.one('sportyears', sportYearId).customPOST(
      comment,
      'comments'
    );
  }

  function deleteComment(sportYearId, commentId) {
    return Restangular.one('sportyears', sportYearId)
      .one('comments', commentId)
      .remove();
  }

  function goToStatus(sportYearId, status) {
    return Restangular.one('sportyears', sportYearId).customPUT(
      status,
      'status'
    );
  }

  function getFileCount(sportYearId) {
    return Restangular.one('sportyears', sportYearId)
      .one('files/count')
      .get();
  }

  function getFiles(sportYearId) {
    return Restangular.one('sportyears', sportYearId).getList('files');
  }

  // returns the created card
  function addPerson(sportYearId, person) {
    if (!person.id) {
      person.foreignIds = personService.unhashifyForeignIds(person.foreignIds);
    }
    return Restangular.one('sportyears', sportYearId).customPOST(
      person,
      'add-person'
    );
  }

  function getChangeLog(sportYearId) {
    return Restangular.one('sportyears', sportYearId).getList('changelog');
  }

  function loadLatestSportYears() {
    return Restangular.one('sportyears').customGET('latest');
  }

  function getCardListDetailRouteParameter(sportYear) {
    let typedId = '';
    if (sportYear.type === 'FUNCTIONARY') {
      typedId = 0;
    } else if (sportYear.type === 'ORGANISATION') {
      typedId = sportYear.organisation.id;
    } else {
      typedId = sportYear.sport.id;
    }
    return {
      type: sportYear.type,
      typedId,
      yearId: sportYear.id
    };
  }

  function extendSportYearsModel(sportYear) {
    sportYear.importPending = sportYear.pendingCardsFromImport > 0;

    // cardTypeSummaries should be set from the server
    if (sportYear.cards && sportYear.cardTypeSummaries) {
      // cards without type and inactive cards should not be counted against totals and contingents
      lodash.forEach(sportYear.cards, card => {
        if (card.type) {
          if (card.active) {
            card[card.type] = true;
          } else {
            sportYear.cardTypeSummaries[
              typeIndex(sportYear.category, card.type)
            ].count -= 1;
          }
        }
      });
    }
    return sportYear;
  }

  function extendImportedCardsModel(cardImport) {
    if (cardImport.foreignIds) {
      lodash.forEach(cardImport.foreignIds, foreignId => {
        cardImport[foreignId.idType.toLowerCase()] = foreignId;
      });
    }
    return cardImport;
  }

  function updateSportYearDates(sportYearId, validFrom, validUntil) {
    return Restangular.one('sportyears', sportYearId).customPUT(
      { validFrom, validUntil },
      'dates-update'
    );
  }

  function purgeSportYear(sportYearId) {
    return Restangular.one('sportyears', sportYearId).remove();
  }

  function createDigitalCards(sportYearId, cardIds) {
    return Restangular.one('sportyears', sportYearId).customPOST(
      cardIds,
      'digital-card'
    );
  }
}
