import angular from 'angular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.nwf-contribution.variable-amount.nwf-trainer.service', [
    swoaConstants,
  ])
  .factory('nwfTrainerService', nwfTrainerService).name;

/** @ngInject */
function nwfTrainerService(lodash) {
  const LEVEL_OF_EMPLOYMENT_THRESHOLD_TOTAL = 30;
  const LEVEL_OF_EMPLOYMENT_THRESHOLD_REGIONAL = 10;

  return {
    hasRecognition,
    checkLevelOfEmployment,
    calculateLevelOfEmployment,
  };

  /**
   * Check if a trainer has a valid recognition, that is something other than 'NO_DTA_BTA'
   * @param trainer
   * @returns {boolean}
   */
  function hasRecognition(trainer) {
    const recognitionData = trainer.athlete.person.recognitionData;
    return recognitionData
      ? recognitionData.recognition.technicalName !== 'NO_DTA_BTA'
      : false;
  }

  /**
   * checks the level of employment for a given nwf-trainer according to these rules:
   * - a level of employment is valid and counts towards the final payment if:
   * - the trainer is exclusively active in regional ("other") institutions and the level of employment
   *   for all sports in an organisation is equal or greater than LEVEL_OF_EMPLOYMENT_THRESHOLD_REGIONAL.
   * - the trainer is exclusively active in national institutions and the level of employment
   *   for all sports in an organisation is equal or greater than LEVEL_OF_EMPLOYMENT_THRESHOLD_TOTAL.
   * - the trainer is active in national and regional institutions and the level of employment is
   *   equal or greater than LEVEL_OF_EMPLOYMENT_THRESHOLD_TOTAL.
   * @param trainer
   * @returns {boolean}
   */
  function checkLevelOfEmployment(trainer) {
    if (onlyRegional(trainer)) {
      return (
        trainer.levelOfEmploymentOther >= LEVEL_OF_EMPLOYMENT_THRESHOLD_REGIONAL
      );
    }
    if (onlyNational(trainer)) {
      return (
        trainer.levelOfEmploymentNational >= LEVEL_OF_EMPLOYMENT_THRESHOLD_TOTAL
      );
    }
    return (
      trainer.levelOfEmploymentOther + trainer.levelOfEmploymentNational >=
      LEVEL_OF_EMPLOYMENT_THRESHOLD_TOTAL
    );
  }

  /**
   * Calculates the LevelOfEmployment for a given trainer in its variable amount period, according to the rules of the 30%- and the 10%-Rule.
   * Caution: the 30% and 10%-Rule applies to all sports/ periods in an organisation - but this method calculates based on the trainer-data
   * for the current period (a trainer is a period + athlete...)
   *
   *
   * If nationalPercentage.active === true all rules are overwritten and everything counts towards the final payment...
   * @param trainer
   * @returns {number}
   */
  function calculateLevelOfEmployment(trainer) {
    if (!hasRecognition(trainer)) {
      return 0;
    }
    const filterFn = getTrainerDataFilter(trainer);

    return lodash
      .chain(trainer.trainerData)
      .filter((trainerData) => trainerData.institution.active === true)
      .filter(filterFn)
      .map('levelOfEmployment')
      .sum()
      .value();
  }

  function getTrainerDataFilter(trainer) {
    const total =
      trainer.levelOfEmploymentNational + trainer.levelOfEmploymentOther;

    if (
      trainer.nationalPercentageActive === true ||
      total >= LEVEL_OF_EMPLOYMENT_THRESHOLD_TOTAL
    ) {
      return () => true;
    }

    if (
      trainer.levelOfEmploymentOther >= LEVEL_OF_EMPLOYMENT_THRESHOLD_REGIONAL
    ) {
      return (trainerData) => trainerData.institution.level === 'OTHER';
    }

    return () => false;
  }

  function onlyRegional(trainer) {
    return (
      trainer.levelOfEmploymentOther > 0 &&
      trainer.levelOfEmploymentNational === 0
    );
  }

  function onlyNational(trainer) {
    return (
      trainer.levelOfEmploymentNational > 0 &&
      trainer.levelOfEmploymentOther === 0
    );
  }
}
