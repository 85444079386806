import angular from 'angular';
import 'restangular';
import swoaConstants from '../app.constants';
import swoaStringUtil from '../components/string-util/string-util.service';
import swoaTranslatable from '../components/translatable/translatable.filter';

export default angular
  .module('swoa.organisation.service', [
    'restangular',
    swoaConstants,
    swoaStringUtil,
    swoaTranslatable,
  ])
  .config(organisationServiceConfig)
  .factory('organisationService', organisationService).name;

const ORGANISATIONS_ROUTE = 'organisations';

/** @ngInject */
function organisationServiceConfig(RestangularProvider) {
  RestangularProvider.addRequestInterceptor((element, operation, route) => {
    if (
      route === ORGANISATIONS_ROUTE &&
      (operation === 'post' || operation === 'put')
    ) {
      // Don't include sports when saving organisations
      delete element.sports;
    }

    return element;
  });
}

/** @ngInject */
function organisationService(
  $q,
  lodash,
  Restangular,
  Constants,
  stringUtilService,
  swoaTranslatableFilter
) {
  const service = Restangular.all(ORGANISATIONS_ROUTE);

  let abortPromise = null;

  service.getBreadcrumbTitle = getBreadcrumbTitle;
  service.loadOrganisation = loadOrganisation;
  service.getAllowedOrganisations = getAllowedOrganisations;
  service.persist = persist;
  service.typeaheadMatch = typeaheadMatch;
  service.getOrganisationDisplayName = getOrganisationDisplayName;
  service.deactivate = deactivate;
  service.activate = activate;
  service.getUsers = getUsers;
  service.queryOrganisations = queryOrganisations;
  service.updateIsEmailRequiredForOrganisation =
    updateIsEmailRequiredForOrganisation;
  service.getOrganisationTypes = getOrganisationTypes;
  service.getChangeLog = getChangeLog;
  service.persistInstitution = persistInstitution;
  service.removeInstitution = removeInstitution;
  service.getWithSports = getWithSports;

  return service;

  // //////////

  function persist(organisation) {
    if (!organisation.restangularized) {
      Restangular.restangularizeElement(
        null,
        organisation,
        ORGANISATIONS_ROUTE
      );
    }

    return organisation.save();
  }

  /**
   * Fetches Organisations from organisations/filtered;
   * the resource only returns organisations with type NATIONAL_ASSOCIATION - for superusers.
   * association users will just see associations they're part of.
   * @param params
   * @returns {list of Organisations}
   */
  function getAllowedOrganisations(params) {
    return service.customGET('filtered', params);
  }

  function getUsers(organisation) {
    return Restangular.one(ORGANISATIONS_ROUTE, organisation.id).customGET(
      'users'
    );
  }

  function getBreadcrumbTitle(id) {
    return Restangular.one(ORGANISATIONS_ROUTE, id)
      .get()
      .then((organisation) => organisation.name);
  }

  function loadOrganisation(organisationId) {
    return Restangular.one(ORGANISATIONS_ROUTE, organisationId).get();
  }

  function typeaheadMatch(organisation, inputString) {
    return stringUtilService.typeaheadMatch(
      [getOrganisationDisplayName(organisation)],
      inputString
    );
  }

  function getOrganisationDisplayName(organisation) {
    const translatedName = swoaTranslatableFilter(organisation.name, 'de');
    if (lodash.includes(organisation.categories, 'SPORT_DEPARTMENT')) {
      return `${translatedName} ${swoaTranslatableFilter(
        organisation.address.state,
        'de'
      )}`;
    }
    return translatedName;
  }

  function deactivate(organisation) {
    return Restangular.one(ORGANISATIONS_ROUTE, organisation.id).customPUT(
      organisation.deactivationReason,
      'deactivate'
    );
  }

  function activate(organisation) {
    return Restangular.one(ORGANISATIONS_ROUTE, organisation.id).customPUT(
      '',
      'activate'
    );
  }

  /** Full-text person search. */
  function queryOrganisations(query, offset, limit) {
    const options = {
      query,
      offset,
      limit,
    };
    if (offset === undefined) {
      options.offset = 0;
    }
    if (limit === undefined) {
      options.limit = 10;
    }

    if (abortPromise !== null) {
      abortPromise.resolve();
    }
    abortPromise = $q.defer();

    return Restangular.all(ORGANISATIONS_ROUTE)
      .withHttpConfig({ timeout: abortPromise.promise })
      .customGET('search', options);
  }

  function updateIsEmailRequiredForOrganisation(organisation, configuration) {
    if (isOrganisationFromType(organisation, 'CANTON')) {
      updateConfigWithRequired(configuration, 'email', false);
    } else {
      updateConfigWithRequired(configuration, 'email', true);
    }
  }

  function updateConfigWithRequired(configuration, key, required) {
    const configProperty = lodash.find(configuration, { key });
    if (configProperty) {
      configProperty.required = required;
    }
  }

  function getOrganisationTypes(categories) {
    const types = [];
    if (categories) {
      categories.forEach((category) => {
        const type = Constants.organisationTypes.find((t) =>
          lodash.includes(t.categories, category)
        );
        types.push(type.type);
      });
    }
    return types;
  }

  function isOrganisationFromType(organisation, type) {
    if (organisation.type === type) {
      return true;
    }
    return lodash.includes(getOrganisationTypes(organisation.categories), type);
  }

  function getChangeLog(organisationId) {
    return Restangular.one(ORGANISATIONS_ROUTE, organisationId).customGET(
      'changelog'
    );
  }

  function persistInstitution(organisation, institution) {
    if (lodash.isNil(institution.id)) {
      return Restangular.one(ORGANISATIONS_ROUTE, organisation.id).customPOST(
        institution,
        'nwf-institutions'
      );
    }
    return Restangular.one(ORGANISATIONS_ROUTE, organisation.id)
      .one('nwf-institutions', institution.id)
      .customPUT(institution);
  }

  function removeInstitution(organisation, institution) {
    return Restangular.one(ORGANISATIONS_ROUTE, organisation.id)
      .one('nwf-institutions', institution.id)
      .remove();
  }

  function getWithSports() {
    return service.customGET('with-sports');
  }
}
