import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import 'restangular';
import swoaSportService from '../sport/sport.service';
import swoaNotification from '../components/notification/notification.module';
import swoaTranslatable from '../components/translatable/translatable.filter';
import htmlTemplate from './sport-list.html';

export default angular
  .module('swoa.sport.sport-list', [
    uiRouter,
    'restangular',
    swoaSportService,
    swoaNotification,
    swoaTranslatable,
  ])
  .component('swoaSportList', {
    template: htmlTemplate,
    bindings: {
      sportYearMode: '<',
      filter: '<',
    },
    controller: SportListController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function SportListController(
  $state,
  $scope,
  $rootScope,
  lodash,
  Restangular,
  sportService,
  notificationService,
  swoaTranslatableFilter
) {
  const vm = this;
  const onError = notificationService.errorHandler(vm);

  vm.sports = [];
  vm.order = vm.sportYearMode ? 'displayName' : 'organisation.displayName';
  vm.notificationKey = null;

  vm.getSportLink = getSportLink;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    loadSports().then(() => {
      updateDisplayName();
    });

    $scope.$on('swoa:sportAdded', onSportAdded);
    $scope.$on('swoa:sportUpdated', onSportUpdated);
  }

  function getSportLink(sport) {
    if (vm.sportYearMode) {
      return `sportYearSummary({sportId: ${sport.id}})`;
    }
    return `sportList.detail({id: ${sport.id}})`;
  }

  function loadSports() {
    if (!vm.sportYearMode) {
      $rootScope.showSpinner = true;
    }
    const loadFunc = vm.sportYearMode
      ? sportService.loadSportsFiltered
      : sportService.loadSportsWithSupportPerson;
    return loadFunc({ withInactive: true }).then((sports) => {
      if (vm.sportYearMode && sports && sports.length === 1) {
        // only one sport -> redirect to sport year summary
        $state.go('sportYearSummary', { sportId: sports[0].id });
        return;
      } else if (!sports || sports.length === 0) {
        vm.notificationKey = 'user.noSports';
      }
      loadSportsFromResponse(sports);
      $rootScope.showSpinner = false;
    }, onError);
  }

  function loadSportsFromResponse(result) {
    if (vm.sportYearMode) {
      vm.sports = result;
    } else {
      result.forEach((container) => {
        vm.sports.push(buildSportFromContainer(container));
      });
    }
  }

  function updateDisplayName() {
    lodash.forEach(vm.sports, (sport) => {
      sport.displayName = swoaTranslatableFilter(sport.translatedName, 'de');
      if (sport.organisation) {
        sport.organisation.displayName = swoaTranslatableFilter(
          sport.organisation.name,
          'de'
        );
      }
      if (!vm.sportYearMode) {
        sport.classificationTranslated = swoaTranslatableFilter(
          sport.classification,
          'de'
        );
        sport.olympicTranslated = swoaTranslatableFilter(sport.olympic, 'de');
        sport.seasonTranslated = swoaTranslatableFilter(sport.season, 'de');
        sport.sportTypeTranslated = swoaTranslatableFilter(
          sport.sportType,
          'de'
        );
      }
    });
  }

  function onSportAdded(event, sportContainer) {
    vm.sports.push(Restangular.copy(buildSportFromContainer(sportContainer)));
    updateDisplayName();
  }

  function onSportUpdated(event, sportContainer) {
    const sport = sportContainer.sport;
    const index = lodash.findIndex(vm.sports, { id: sport.id });
    if (index > -1) {
      vm.sports[index] = Restangular.copy(sport);
      vm.sports[index].organisation = { name: sportContainer.organisationName };
      vm.sports[index].supportPerson = sportContainer.person;
      updateDisplayName();
    }
  }

  function buildSportFromContainer(container) {
    const sport = container.sport;
    sport.supportPerson = container.person;
    sport.organisation = { name: container.organisationName };
    return sport;
  }
}
