import angular from 'angular';

export default angular
  .module('swoa.input-text.percentage', [])
  .directive('swoaPercentage', swoaPercentage)
  .name;

/** @ngInject */
function swoaPercentage() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: linkPercentage,
  };
}

function linkPercentage(scope, element, attr, ngModel) {
  ngModel.$parsers.unshift(percentageParser);
  ngModel.$formatters.unshift(percentageFormatter);

  function percentageParser(viewValue) {
    if (!scope.vm.percentage || !viewValue) {
      return viewValue;
    }

    const parsed = parsePercentage(viewValue);
    const valid = isValid(parsed);
    ngModel.$setValidity('percentage', valid);

    let modelValue;
    if (valid) {
      modelValue = parsed;
      ngModel.$setViewValue(`${parsed} %`);
      ngModel.$render();
    }
    return modelValue;
  }

  function percentageFormatter(modelValue) {
    if (!scope.vm.percentage || !modelValue) {
      return modelValue;
    }

    const parsed = parsePercentage(modelValue);
    const valid = isValid(parsed);
    ngModel.$setValidity('percentage', valid);

    let viewValue;
    if (valid) {
      viewValue = `${parsed} %`;
    }

    return viewValue;
  }

  function parsePercentage(value) {
    let parsed = null;
    try {
      parsed = parseInt(value.toString().replace(/\D/gi, ''), 10);
    } catch (e) {
      parsed = null;
    }
    return parsed;
  }

  function isValid(value) {
    return value !== null && value >= 0 && value <= 100 && !isNaN(value);
  }
}
