import angular from 'angular';
import swoaNwfVariableAmountPeriod from './nwf-variable-amount-period.component';
import swoaNwfVariableAmountPeriodRoutes from './nwf-variable-amount-period.routes';
import swoaNwfVariableAmountPeriodService from './nwf-variable-amount-period.service';
import swoaNwfVaribaleAmountPeriodFoundation from './nwf-variable-amount-period-foundation.component';
import swoaNwfVaribaleAmountPeriodContribution from './nwf-variable-amount-period-contribution.component';
import swoaNwfVariableAmountPeriodFoundationDetail from './nwf-variable-amount-period-foundation-detail.component';
import swoaNwfVaribaleAmountPeriodCreatePerson from './nwf-variable-amount-period-create-person.component';
import swoaNwfVariableAmountAddTrainer from './nwf-variable-amount-period-add-trainer.component';
import swoaNwfVariableAmountPeriodList from './nwf-variable-amount-period-list.component';
import swoaNwfTrainerInfoIcon from './nwf-trainer-information-icon.component';
import swoaNwfTrainerNationalPercentage from './nwf-trainer-national-percentage.component';
import swoaNwfTrainerComment from './nwf-trainer-comment.component';
import swoaNwfTrainerService from './nwf-trainer.service';

export default angular
  .module('swoa.nwf-contribution.variable-amount', [
    swoaNwfVariableAmountPeriod,
    swoaNwfVariableAmountPeriodRoutes,
    swoaNwfVariableAmountPeriodService,
    swoaNwfVaribaleAmountPeriodFoundation,
    swoaNwfVaribaleAmountPeriodContribution,
    swoaNwfVariableAmountPeriodFoundationDetail,
    swoaNwfVariableAmountAddTrainer,
    swoaNwfVaribaleAmountPeriodCreatePerson,
    swoaNwfVariableAmountPeriodList,
    swoaNwfTrainerInfoIcon,
    swoaNwfTrainerService,
    swoaNwfTrainerNationalPercentage,
    swoaNwfTrainerComment,
  ])
  .name;

