import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.admin.role.service', [
    'restangular',
  ])
  .factory('roleService', roleService)
  .name;

/** @ngInject */
function roleService(Restangular) {
  const ROLES_ROUTE = 'roles';
  const PERMISSIONS_ROUTE = 'permissions';

  return {
    getAllRoles,
    getRole,
    getAllPermissions,
    saveRole,
    getBreadcrumbTitle,
  };

  // //////////

  function getAllRoles() {
    return Restangular.all(ROLES_ROUTE).getList();
  }

  function getRole(roleId) {
    return Restangular.one(ROLES_ROUTE, roleId).get();
  }

  function getAllPermissions() {
    return Restangular.all(PERMISSIONS_ROUTE).getList(list => Restangular.stripRestangular(list));
  }

  function saveRole(role) {
    if (role.id !== null) {
      return role.put();
    }
    return Restangular.all(ROLES_ROUTE).post(role);
  }

  function getBreadcrumbTitle(id) {
    return Restangular.one(ROLES_ROUTE, id).get().then(role => role.name);
  }
}
