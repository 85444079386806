import angular from 'angular';
import template from './nwf-trainer-comment.component.html';

export default angular
  .module('swoa.nwf-contribution.variable-amount.nwf-trainer-comment', [])
  .component('swoaNwfTrainerComment', {
    template,
    controllerAs: 'vm',
    controller: NwfTrainerComment,
    bindings: {
      trainer: '=',
      editing: '<',
      periodStatus: '<',
    },
  }).name;


/** @ngInject */
function NwfTrainerComment() {

}

