import angular from 'angular';
import 'restangular';
import swoaPersonService from '../person.service';
import swoaStringUtil from '../../components/string-util/string-util.service';

export default angular
  .module('swoa.person.mutation.service', [
    'restangular',
    swoaPersonService,
    swoaStringUtil,
  ])
  .factory('personMutationService', personMutationService)
  .name;

/** @ngInject */
function personMutationService(Restangular, personService) {
  const basePersonMutations = Restangular.all('person-mutations');

  return {
    loadPersonMutations,
    confirm,
    loadPersonMutation,
    loadPendingMutation,
    saveSync,
    updatePersonMutation,
  };

  // //////////

  /** Load some person-mutations and return as a list */
  function loadPersonMutations(offset, max) {
    const options = {
      offset,
      max,
    };
    if (offset === undefined) {
      options.offset = 0;
    }
    if (max === undefined) {
      options.max = 10;
    }
    return basePersonMutations.customGET('pending', options);
  }

  function confirm(personMutationId) {
    return Restangular.one('person-mutations', personMutationId).customPOST({}, 'confirm');
  }

  function loadPersonMutation(personMutationId) {
    return Restangular.one('person-mutations', personMutationId).get();
  }

  function loadPendingMutation(personMutationId) {
    return basePersonMutations.all('pending').customGET(personMutationId);
  }

  function saveSync(personMutationId, syncResult) {
    const data = angular.copy(syncResult);
    data.foreignIds = personService.unhashifyForeignIds(data.foreignIds);
    data.originalPerson.foreignIds = personService.unhashifyForeignIds(data.originalPerson.foreignIds);
    delete data.configKey;

    return Restangular.one('person-mutations', personMutationId).customPUT(data, null);
  }

  function updatePersonMutation(personMutation) {
    return Restangular.one('person-mutations', personMutation.id).customPOST(personMutation, 'update');
  }
}
