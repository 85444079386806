import angular from 'angular';
import swoaPersonProfileRoutes from './person-profile.routes';
import swoaPersonProfile from './person-profile.component';
import swoaPersonProfileInfos from './person-profile-infos.component';
import swoaPersonProfileAdditional from './person-profile-additional.component';
import swoaPersonProfileSport from './person-profile-sport.component';
import swoaPersonProfileSportEntry from './person-profile-sport-entry.component';
import swoaPersonProfileHelperService from './person-profile-helper.service';
import swoaPersonProfileTimeline from './timeline/person-timeline.module';
import swoaPersonProfileImageChange from './person-profile-image-change.component';

export default angular
  .module('swoa.person.profile', [
    swoaPersonProfileRoutes,
    swoaPersonProfile,
    swoaPersonProfileInfos,
    swoaPersonProfileAdditional,
    swoaPersonProfileSport,
    swoaPersonProfileSportEntry,
    swoaPersonProfileHelperService,
    swoaPersonProfileTimeline,
    swoaPersonProfileImageChange,
  ])
  .name;

