import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaPersonService from '../person.service';
import swoaSportService from '../../sport/sport.service';
import swoaDate from '../../components/date/date.filter';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaOrganisationService from '../../organisation/organisation.service';
import htmlTemplate from './person-profile-sport.html';

export default angular
  .module('swoa.person.profile.person-profile-sport', [
    swoaConstants,
    swoaPersonService,
    swoaSportService,
    swoaDate,
    swoaTranslatable,
    swoaOrganisationService
  ])
  .component('swoaPersonProfileSport', {
    template: htmlTemplate,
    bindings: {
      person: '<',
      currentEditor: '=',
      isInNonReleasedCardYear: '<'
    },
    controller: PersonProfileSportController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonProfileSportController(
  $rootScope,
  $scope,
  $translate,
  lodash,
  swoaDateFilter,
  swoaTranslatableFilter,
  personService,
  sportService,
  organisationService
) {
  const vm = this;
  let sportPromise = null;

  vm.originalTabEntries = null;

  vm.tabEntries = null;
  vm.trainerData = null;
  vm.hasSportData = false;
  vm.isEditPossible = false;
  vm.sportEntry = null;
  vm.availableSports = null;
  vm.editingTabIndex = 0;

  vm.getMatchingSports = getMatchingSports;
  vm.clearAddSportForm = clearAddSportForm;
  vm.saveNewSport = saveNewSport;
  vm.showSportsForm = showSportsForm;
  vm.anyEntryEditing = anyEntryEditing;
  vm.hasUnassignedSports = hasUnassignedSports;
  vm.cancel = cancel;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    $scope.$watch('vm.person', initSportsAndPermissions, true);
  }

  function initSportsAndPermissions() {
    vm.isEditPossible = !(
      $rootScope.isAssociationUser() && vm.isInNonReleasedCardYear
    );
    loadAvailableSports();
    prepareAdditionalSportInformation();
    organisationService
      .getWithSports()
      .then(prepareTrainerActivity)
      .catch(angular.noop);
  }

  function prepareAdditionalSportInformation() {
    if (!vm.person) {
      return;
    }

    const sports = personService.sortedAdditionalInfoSportList(vm.person);

    vm.originalTabEntries = lodash.map(sports, sport => ({
      id: sport.id,
      sport,
      eliteSportData: lodash.find(vm.person.eliteSportData, {
        sport: { id: sport.id }
      })
    }));
    vm.tabEntries = vm.originalTabEntries.map(entry => entry);
  }

  function prepareTrainerActivity(organisations) {
    if (!vm.person) {
      return;
    }

    vm.trainerData = lodash.map(vm.person.trainerSportData, data => {
      const organisation = lodash.find(organisations, org =>
        lodash.some(org.sports, ['id', data.sport.id])
      );
      data.organisationName = swoaTranslatableFilter(organisation.name);
      data.sportName = swoaTranslatableFilter(data.sport.translatedName);
      if (!data.contractValidity) {
        if (data.hasContract) {
          data.contractValidityString = $translate.instant(
            'person.trainerSportData.temporaryContract.false'
          );
        } else {
          data.contractValidityString = '-';
        }
      } else {
        data.contractValidityString = swoaDateFilter(data.contractValidity);
      }
      return data;
    });
  }

  function isSwoaOrAssociationUser() {
    return $rootScope.isSwoaUser() || $rootScope.isAssociationUser();
  }

  function anyEntryEditing() {
    return lodash.some(vm.tabEntries, sport => sport.editing);
  }

  function getMatchingSports(searchText) {
    const assignedSports = personService.sortedAdditionalInfoSportList(
      vm.person
    );

    return lodash.filter(
      vm.availableSports,
      sport =>
        !lodash.find(assignedSports, { id: sport.id }) &&
        sportService.typeaheadMatch(sport, searchText)
    );
  }

  function hasUnassignedSports() {
    return (
      lodash.filter(
        vm.availableSports,
        sport => !lodash.find(vm.tabEntries, { sport })
      ).length > 0
    );
  }

  function showSportsForm() {
    vm.showAddSportForm = true;
    loadAvailableSports();
  }

  function loadAvailableSports() {
    if (!vm.availableSports && isSwoaOrAssociationUser() && !sportPromise) {
      sportPromise = sportService.loadSportsFiltered().then(sports => {
        vm.availableSports = sports;
      });
    }
  }

  function clearAddSportForm() {
    vm.sportEntry = null;
    vm.showAddSportForm = false;
    vm.searchTextSport = null;
  }

  function saveNewSport(valid) {
    vm.submitted = true;

    if (!valid) {
      return;
    }

    vm.tabEntries.push({
      sport: vm.sportEntry,
      editing: true
    });
    vm.editingTabIndex = vm.tabEntries.length - 1;
    clearAddSportForm();
  }

  function cancel() {
    vm.tabEntries = vm.originalTabEntries.map(entry => entry);
  }
}
