import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaConstants from '../../../app.constants';
import swoaNotification from '../../../components/notification/notification.module';
import swoaTranslatable from '../../../components/translatable/translatable.filter';
import swoaTranslatableDropdownValue from '../../../components/translatable/translatable-dropdown-value.filter';
import swoaDataTableHelper from '../../../components/data-table/data-table-helper.service';
import htmlTemplate from './nwf-institution.html';

export default angular
  .module('swoa.organisation.profile.nwf-institution.component', [
    uiRouter,
    swoaConstants,
    swoaNotification,
    swoaTranslatable,
    swoaTranslatableDropdownValue,
    swoaDataTableHelper
  ])
  .component('swoaNwfInstitution', {
    template: htmlTemplate,
    bindings: {
      organisation: '<'
    },
    controller: NwfInstitution,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfInstitution(
  $scope,
  lodash,
  swoaTranslatableFilter,
  dataTableHelper
) {
  const vm = this;

  vm.order = ['level', 'name'];
  vm.sports = null;
  vm.levels = null;
  vm.filteredLevel = null;
  vm.filteredSport = null;

  vm.filterInstitutions = filterInstitutions;

  vm.$onChanges = onChanges;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    $scope.$watch('vm.organisation.nwfInstitutions', updateFilterValues, true);
  }

  function onChanges(changesObj) {
    if (changesObj.organisation && changesObj.organisation.currentValue) {
      dataTableHelper.subscribeToUpdateEvents(
        $scope,
        'institution',
        vm.organisation,
        'nwfInstitutions'
      );
    }
  }

  function updateFilterValues() {
    if (vm.organisation) {
      const institutions = vm.organisation.nwfInstitutions;
      lodash.forEach(institutions, institution => {
        lodash.forEach(
          institution.sports,
          sport =>
            (sport.displayName = swoaTranslatableFilter(
              sport.translatedName,
              'de'
            ))
        );
        institution.sportList = lodash
          .map(institution.sports, 'displayName')
          .join(', ');
      });
      const sports = lodash.flatten(
        lodash.map(institutions, institution => institution.sports)
      );
      vm.sports = lodash.map(sports, sport => ({
        id: sport.id,
        displayName: swoaTranslatableFilter(sport.translatedName, 'de')
      }));
      vm.sports = lodash.uniqBy(lodash.sortBy(vm.sports, 'displayName'), 'id');
      vm.levels = lodash.uniq(
        lodash.map(institutions, institution => institution.level)
      );
      vm.filteredSport = null;
      vm.filteredLevel = null;
    }
  }

  function filterInstitutions(value) {
    let show = true;
    if (vm.filteredLevel) {
      show = value.level === vm.filteredLevel;
    }
    if (!lodash.isNil(vm.filteredSport)) {
      show =
        show &&
        lodash.find(value.sports, sport => sport.id === vm.filteredSport);
    }
    return show;
  }
}
