import angular from 'angular';
import swoaNwfTrainerService from './nwf-trainer.service';

export default angular
  .module('swoa.nwf-contribution.variable-amount.nwf-trainer-info-icon', [
    swoaNwfTrainerService
  ])
  .component('swoaNwfTrainerInfo', {
    template: `
        <div layout="row" ng-if="!vm.trainer.valid" class="swoa-icon-column">
          <swoa-warning-icon warning="{warningTextKey: vm.trainer.warning}"></swoa-warning-icon>
        </div>`,
    controllerAs: 'vm',
    controller: NwfTrainerInfoIcon,
    bindings: {
      trainer: '<'
    }
  }).name;

/** @ngInject */
function NwfTrainerInfoIcon(nwfTrainerService) {
  const vm = this;

  vm.$onInit = () => {
    activate();
  };

  function activate() {
    vm.trainer.valid = true;
    if (!nwfTrainerService.hasRecognition(vm.trainer)) {
      vm.trainer.valid = false;
      vm.trainer.warning = 'nwfContributions.trainer.noRecognition';
    } else if (!nwfTrainerService.checkLevelOfEmployment(vm.trainer)) {
      if (vm.trainer.nationalPercentageActive === true) {
        vm.trainer.valid = false; // todo: seems wrong. trainer is valid, but 30%-Rule applies...
        vm.trainer.warning = 'nwf.percentage.rule.active.message';
      } else {
        vm.trainer.valid = false;
        vm.trainer.warning =
          'nwfContributions.trainer.levelOfEmploymentRuleWarning';
      }
    }
  }
}
