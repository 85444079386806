import angular from 'angular';
import swoaUserService from '../../user/user.service';
import htmlTemplate from './comment.html';

export default angular
  .module('swoa.comment.comment', [swoaUserService])
  .component('swoaComment', {
    template: htmlTemplate,
    bindings: {
      entry: '<',
      type: '<',
      deleteAction: '&',
      canEditComments: '<',
      canEditOwnComments: '<',
      card: '<'
    },
    controller: CommentController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CommentController(userService) {
  const vm = this;

  vm.removeComment = removeComment;

  vm.$onInit = () => {
    vm.belongsToUser =
      userService.getCurrentUser().person.id === vm.entry.author.id;
  };

  // //////////

  function removeComment() {
    vm.deleteAction({ $comment: vm.entry });
  }
}
