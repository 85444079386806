import angular from 'angular';
import 'restangular';
import swoaFileService from '../components/file/file.service';
import swoaAsyncService from '../components/async/async-helper.service';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './debug.html';

export default angular
  .module('swoa.admin.debug', [
    'restangular',
    swoaFileService,
    swoaAsyncService,
    swoaNotification
  ])
  .component('swoaDebug', {
    template: htmlTemplate,
    bindings: {},
    controller: DebugController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function DebugController(
  $q,
  $scope,
  $translate,
  Restangular,
  asyncHelperService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      null,
      { httpOptions: { global: true } }
    );

  vm.log = null;

  vm.logClusterInfo = logClusterInfo;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    asyncHelper.getAsyncJobStatus();
  }

  function logClusterInfo() {
    Restangular.one('admin')
      .customGET('log-cluster-info')
      .then(data => {
        vm.log = data.log;
      });
  }
}
