import angular from 'angular';

export default angular
  .module('arrow-up.svg', [])
  .run(runBlock)
  .name;

/** @ngInject */
function runBlock($templateCache) {
  $templateCache.put('arrow-up.svg',
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">' +
    '<path d="M4 13l8-8 8 8z"></path></svg>');
}
