import angular from 'angular';
import swoaValidatorMessages from '../validator-messages/validator-messages.component';
import swoaEnsureExpression from '../ensure-expression/ensure-expression.directive';
import htmlTemplate from './input-social-media-link.html';

export default angular
  .module('swoa.input-social-media-link', [
    swoaValidatorMessages,
    swoaEnsureExpression
  ])
  .component('swoaInputSocialMediaLink', {
    template: htmlTemplate,
    bindings: {
      containerClass: '@',
      isReadOnly: '=',
      labelKey: '@',
      labelSuffix: '@',
      name: '@',
      ngModel: '=',
      linkType: '<',
      isRequired: '=',
      messages: '=',
      messagesIf: '=',
      customMessageKeys: '=',
      placeholder: '@',
      overrideAsterisk: '=',
      autocomplete: '@',
      id: '@',
      style: '@'
    },
    controller: InputSocialMediaLinkController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function InputSocialMediaLinkController($scope) {
  const vm = this;

  vm.messageKeys = [];

  vm.makeHref = makeHref;
  vm.looksLikeUrl = looksLikeUrl;

  vm.$onInit = () => {
    activate();
  };

  // //////////////////

  function activate() {
    $scope.$watch('vm.customMessageKeys', appendMessageKey);
  }

  function appendMessageKey() {
    vm.messageKeys = angular.extend({}, vm.customMessageKeys);
    vm.messageKeys.expression = 'form.input.validation.url';
  }

  function makeHref(link) {
    if (link.indexOf('http') === -1) {
      return `http://${link}`;
    }
    return link;
  }

  function looksLikeUrl(str) {
    if (vm.isRequired) {
      return (
        str &&
        angular.isString(str) &&
        (str.indexOf('http') === 0 || str.indexOf('www') === 0)
      );
    }
    return (
      !str ||
      (angular.isString(str) &&
        (str.indexOf('http') === 0 || str.indexOf('www') === 0))
    );
  }
}
