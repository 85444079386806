import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from './../../components/notification/notification.module';
import swoaSportService from '../../sport/sport.service';
import swoaOrganisationService from '../organisation.service';
import swoaOrganisationProfileHelper from './organisation-profile-helper.service';
import htmlTemplate from './organisation-profile-sport.html';
import swoaTranslatable from '../../components/translatable/translatable.filter';

export default angular
  .module('swoa.organisation.profile.organisation-profile-sport', [
    swoaConstants,
    swoaOrganisationService,
    swoaOrganisationProfileHelper,
    swoaSportService,
    swoaTranslatable,
    swoaNotification,
  ])
  .component('swoaOrganisationProfileSport', {
    template: htmlTemplate,
    bindings: {
      organisation: '<',
      organisationHeadChiefs: '=',
    },
    controller: OrganisationProfileSportController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function OrganisationProfileSportController(
  $q,
  $scope,
  lodash,
  organisationService,
  organisationProfileHelper,
  sportService,
  swoaTranslatableFilter,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    helper = organisationProfileHelper.createFormHelper(vm);

  vm.tabEntries = null;
  vm.hasSportData = false;
  vm.isEditPossible = false;
  vm.sportEntry = null;
  vm.availableSports = null;
  vm.availableOrganisations = null;

  vm.saveSportCostCenter = saveSportCostCenter;
  vm.initViewData = initViewData;
  vm.startEdit = helper.startEdit;
  vm.cancelEdit = cancelEdit;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    $scope.$watch('vm.organisation.sports', initSports, true);
  }

  function initSports() {
    loadAvailableOrganisationsAndSports();
    prepareTabEntries();
    initViewData();
  }

  function initViewData() {
    if (helper.needsInitialization()) {
      vm.originalOrganisation = angular.copy(vm.organisation);
      vm.initialized = true;
    }
  }

  function prepareTabEntries() {
    if (!vm.organisation) {
      return;
    }

    vm.canAddSports = lodash.includes(
      vm.organisation.categories,
      'NATIONAL_ASSOCIATION'
    );
    vm.tabEntries = prepareSportsDisplayNameAndSort();
  }

  function loadAvailableOrganisationsAndSports() {
    if (!vm.availableSports || !vm.availableOrganisations) {
      const promises = [];
      promises.push(sportService.loadSportsFiltered());
      promises.push(organisationService.getList());
      $q.all(promises).then((result) => {
        vm.availableSports = result[0];
        vm.availableOrganisations = result[1];
      }, onError);
    }
  }

  function prepareSportsDisplayNameAndSort() {
    vm.organisation.sports.forEach(
      (sport) =>
        (sport.displayName = swoaTranslatableFilter(sport.translatedName, 'de'))
    );
    return lodash.sortBy(vm.organisation.sports, ['displayName']);
  }

  function saveSportCostCenter(valid) {
    vm.submitted = true;
    helper.resetNotifications();

    if (!valid) {
      return;
    }

    const promises = [];
    vm.organisation.sports.forEach((sport) => {
      promises.push(
        sportService.persistSportCostCenter(sport.id, {
          costCenter: sport.costcenter,
        })
      );
    });
    $q.all(promises).then(() => helper.processSaved(vm.organisation), onError);
  }

  function cancelEdit() {
    vm.organisation = vm.originalOrganisation;
    helper.cancelEdit();
  }
}
