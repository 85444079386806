import angular from 'angular';
import 'restangular';
import swoaPersonService from '../../person/person.service';
import swoaPersonSyncService from '../../person/sync/person-sync.service';

export default angular
  .module('swoa.card.card-import.service', [
    'restangular',
    swoaPersonService,
    swoaPersonSyncService,
  ])
  .factory('cardImportService', cardImportService)
  .name;

/** @ngInject */
function cardImportService(Restangular, personService, personSyncService) {
  const service = {
    loadSync,
    loadSyncForPerson,
    loadCardImport,
    loadSimilarPersons,
    loadCardPair,
    copyImportData,
    removeCardImport,
    createPerson,
    saveSync,
  };

  activate();

  return service;

  // //////////

  function activate() {
    // extend the sync model to calculate the diff object
    Restangular.extendModel('sync', personSyncService.extendSyncModel);
  }

  function loadSync(cardImportId) {
    return Restangular.one('card-imports', cardImportId).one('sync').get();
  }

  function loadSyncForPerson(cardImportId, personId) {
    return Restangular.one('card-imports', cardImportId).one('sync', personId).get();
  }

  function loadCardImport(cardImportId) {
    return Restangular.one('card-imports', cardImportId).get();
  }

  function loadSimilarPersons(cardImportId, offset, limit) {
    const options = {
      offset,
      limit,
    };
    if (offset === undefined) {
      options.offset = 0;
    }
    if (limit === undefined) {
      options.limit = 10;
    }
    return Restangular.one('card-imports', cardImportId).customGET('similar-persons', options);
  }

  function loadCardPair(cardImportId) {
    return Restangular.one('card-imports', cardImportId).one('compare-cards').get();
  }

  function copyImportData(cardImportId, personId) {
    return Restangular.one('card-imports', cardImportId).one('copy-import-data', personId).customPOST();
  }

  function removeCardImport(cardImportId) {
    return Restangular.one('card-imports', cardImportId).remove();
  }

  function createPerson(cardImportId, person) {
    person.foreignIds = personService.unhashifyForeignIds(person.foreignIds);

    return Restangular.one('card-imports', cardImportId).customPOST(person, 'create-person');
  }

  function saveSync(cardImportId, syncResult) {
    // we need to create an array of the foreignIds first and remove things we only used for the UI
    const data = angular.copy(syncResult);
    data.foreignIds = personService.unhashifyForeignIds(data.foreignIds);
    delete data.configKey;

    return Restangular.one('card-imports', cardImportId).customPUT(data, 'sync');
  }
}
