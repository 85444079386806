import angular from 'angular';
import swoaChangeLogService from './change-log.service';
import swoaChangeLogChangeLog from './change-log.component';

export default angular
  .module('swoa.change-log', [
    swoaChangeLogService,
    swoaChangeLogChangeLog,
  ])
  .name;

