import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.news.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('newsList', {
      url: '/news',
      template: '<swoa-news-list></swoa-news-list>',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_news_r',
      },
    })
    .state('newsDetail', {
      url: '/news/:id',
      template: '<swoa-news-list></swoa-news-list>',
      data: {
        breadcrumb: {
          parent: 'newsList',
          ignore: true,
        },
        hasPermission: 'admin_user_w',
      },
    });
}
