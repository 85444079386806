import angular from 'angular';
import { APP_CONSTANTS } from '../../app.constants';

export const JSON_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_SELECT_YEARS_AHEAD = 2;

export const TOTAL_LABEL = 'TOTAL';
export const TOTAL_COLOR = '#000';

export const EVENT_NAME_SHOW_SAVE_FAVORITE_DIALOG = 'swoa:showSaveFavoriteDialog';
export const EVENT_NAME_SHOW_LOAD_FAVORITE_DIALOG = 'swoa:showLoadFavoriteDialog';

export const CHART_COLORS = [
  '#007ab5',
  '#e3312a',
  '#009440',
  '#8bafd5',
  '#f19073',
  '#89c28f',
  '#999',
  '#db3ae5',
  '#f9f112',
  '#00f917',
  '#8811ed',
];

export const AXIS_CONFIGS = [{
  id: 0,
  label: 'report.dimension.cardType',
  translatePrefix: 'card.type.',
  dataProperty: 'cardType',
  valueFn: null,
}, {
  id: 1,
  label: 'report.dimension.gender',
  translatePrefix: 'person.gender.',
  dataProperty: 'gender',
  valueFn: null,
}, {
  id: 2,
  label: 'report.dimension.state',
  translatePrefix: 'state.',
  dataProperty: 'state',
  valueFn: null,
}, {
  id: 3,
  label: 'report.dimension.ageAll',
  translatePrefix: '',
  dataProperty: 'age',
  valueFn: null,
}, {
  id: 4,
  label: 'report.dimension.age0to40',
  translatePrefix: '',
  dataProperty: 'age',
  valueFn: (age) => {
    if (age > 40) {
      return 'older';
    }
    return age;
  },
}, {
  id: 5,
  label: 'report.dimension.age20to60',
  translatePrefix: '',
  dataProperty: 'age',
  valueFn: (age) => {
    if (age > 60) {
      return 'older';
    } else if (age < 20) {
      return 'younger';
    }
    return age;
  },
}, {
  id: 6,
  label: 'report.dimension.ageGroup5',
  translatePrefix: '',
  dataProperty: 'age',
  valueFn: age => (Math.ceil(age / 5) * 5),
}, {
  id: 7,
  label: 'report.dimension.ageGroup10',
  translatePrefix: '',
  dataProperty: 'age',
  valueFn: age => (Math.ceil(age / 10) * 10),
}, {
  id: 8,
  label: 'report.dimension.year',
  translatePrefix: '',
  dataProperty: 'year',
  valueFn: null,
}, {
  id: 9,
  label: 'report.dimension.sportName',
  translatePrefix: '',
  dataProperty: 'sportName',
  valueFn: null,
}, {
  id: 10,
  label: 'report.dimension.sportOlympic',
  translatePrefix: '',
  dataProperty: 'sportOlympic',
  valueFn: null,
}, {
  id: 11,
  label: 'report.dimension.sportSeason',
  translatePrefix: '',
  dataProperty: 'sportSeason',
  valueFn: null,
}, {
  id: 12,
  label: 'report.dimension.sportType',
  translatePrefix: '',
  dataProperty: 'sportType',
  valueFn: null,
}, {
  id: 13,
  label: 'report.dimension.sportClassification',
  translatePrefix: '',
  dataProperty: 'sportClassification',
  valueFn: null,
}, {
  id: 14,
  label: 'report.dimension.isSportActive',
  translatePrefix: 'sport.active.',
  dataProperty: 'isSportActive',
  valueFn: null,
}, {
  id: 15,
  label: 'report.dimension.cardTypeNew',
  translatePrefix: 'card.type.',
  dataProperty: 'cardTypeNew',
  valueFn: null,
  isCardChange: true,
}, {
  id: 16,
  label: 'report.dimension.yearNew',
  translatePrefix: '',
  dataProperty: 'yearNew',
  valueFn: null,
  isCardChange: true,
}, {
  id: 17,
  label: 'report.dimension.sportNameNew',
  translatePrefix: '',
  dataProperty: 'sportNameNew',
  valueFn: null,
  isCardChange: true,
}];

export const X_AXIS_SORT_OPTIONS = [{
  id: 0,
  label: 'report.sort.nameAsc',
  sortFn: (prefixedTranslateFn, lodash) => obj => (lodash.isNumber(obj.label) ? parseInt(obj.label, 10) : prefixedTranslateFn(obj.label)),
  order: 'asc',
}, {
  id: 1,
  label: 'report.sort.nameDesc',
  sortFn: (prefixedTranslateFn, lodash) => obj => (lodash.isNumber(obj.label) ? parseInt(obj.label, 10) : prefixedTranslateFn(obj.label)),
  order: 'desc',
}, {
  id: 2,
  label: 'report.sort.valueAsc',
  sortFn: (prefixedTranslateFn, lodash) => obj => lodash.max(obj.data),
  order: 'asc',
}, {
  id: 3,
  label: 'report.sort.valueDesc',
  sortFn: (prefixedTranslateFn, lodash) => obj => lodash.max(obj.data),
  order: 'desc',
}, {
  id: 4,
  label: 'report.sort.cardTypeAsc',
  sortFn: () => obj => APP_CONSTANTS.cards.cardTypes.indexOf(obj.label),
  order: 'desc',
}, {
  id: 5,
  label: 'report.sort.cardTypeDesc',
  sortFn: () => obj => APP_CONSTANTS.cards.cardTypes.indexOf(obj.label),
  order: 'asc',
}];

export const CHART_PLUGIN_DATA_LABEL = Chart => ({
  beforeDraw: (chart) => {
    if (chart.config.options.backgroundColor) {
      const ctx = chart.chart.ctx;
      const chartArea = chart.chartArea;

      ctx.save();
      ctx.fillStyle = chart.config.options.backgroundColor;
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
      ctx.restore();
    }
  },
  afterDatasetsDraw: (chart) => {
    // To only draw at the end of animation, check for easing === 1
    const ctx = chart.ctx;
    const padding = 1;
    const horizontal = chart.config.type === 'horizontalBar';

    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden && chart.options.showDataLabels) {
        meta.data.forEach((element, index) => {
          const fontSize = 12;

          // Just naively convert to string for now
          const numValue = (dataset.data[index] || 0);
          const dataString = Math.abs(numValue).toString();
          const position = element.tooltipPosition();

          if (horizontal) {
            const alignment = numValue < 0 ? 'right' : 'left';
            const x = numValue < 0 ? (position.x - padding) : (position.x + padding);
            prepareContextForText(Chart, ctx, fontSize, 'normal', alignment, decideBlackOrWhite(ctx, position.x + 1, position.y));
            ctx.fillText(dataString, x, position.y);
          } else {
            const offset = (fontSize / 2) + padding;
            const y = numValue < 0 ? (position.y + offset) : (position.y - offset);
            const bgY = numValue < 0 ? (position.y + 1) : (position.y - 1);
            prepareContextForText(Chart, ctx, fontSize, 'normal', 'center', decideBlackOrWhite(ctx, position.x, bgY));
            ctx.fillText(dataString, position.x, y);
          }
        });
      }
    });

    if (chart.options.sectorLabels && chart.options.sectorLabels.length === 2) {
      const labels = chart.options.sectorLabels;
      const area = chart.chartArea;
      const fontSize = 18;
      prepareContextForText(Chart, ctx, fontSize, 'bold', 'left');

      // there is always a label on top left
      ctx.fillText(labels[horizontal ? 1 : 0], area.left + padding, area.top + (padding * 2));

      // the other label is top right for horizontal and bottom left for vertical
      if (horizontal) {
        prepareContextForText(Chart, ctx, fontSize, 'bold', 'right');
        ctx.fillText(labels[0], area.right - padding, area.top + (padding * 2));
      } else {
        ctx.fillText(labels[1], area.left + padding, area.bottom - (padding * 2));
      }
    }
  },
});

export const CHART_TICKS_ABS = value => (!isNaN(value) ? Math.round(Math.abs(value) * 10) / 10 : value);
export const CHART_TICK_EMPTY = () => '';
export const CHART_TOOLTIP_VERTICAL_ABS = (tooltipItem, data) => {
  let label = data.datasets[tooltipItem.datasetIndex].label || '';

  if (label) {
    label += ': ';
  }
  label += Math.abs(isNaN(tooltipItem.yLabel) ? 0 : tooltipItem.yLabel);
  return label;
};
export const CHART_TOOLTIP_HORIZONTAL_ABS = (item, data) => `${data.datasets[item.datasetIndex].label || ''}: ${Math.abs(item.xLabel)}`;

export const CHART_LEGEND = lodash => (chart) => {
  const legendShort = chart.options.legend.short;
  let datasets = angular.copy(chart.data.datasets);

  if (legendShort) {
    datasets = lodash.uniqBy(datasets, 'legendShort');
  }

  return lodash.map(datasets, (dataset, i) => ({
    text: legendShort ? dataset.legendShort : dataset.label,
    fillStyle: (!lodash.isArray(dataset.backgroundColor) ? dataset.backgroundColor : dataset.backgroundColor[0]),
    hidden: !chart.isDatasetVisible(i),
    lineCap: dataset.borderCapStyle,
    lineDash: dataset.borderDash,
    lineDashOffset: dataset.borderDashOffset,
    lineJoin: dataset.borderJoinStyle,
    lineWidth: dataset.borderWidth,
    strokeStyle: dataset.borderColor,
    pointStyle: dataset.pointStyle,

    // Below is extra data used for toggling the datasets
    datasetIndex: i,
  }));
};

function prepareContextForText(Chart, ctx, fontSize, fontStyle, textAlign = 'center', fillStyle = '#666') {
  const fontFamily = '\'Helvetica Neue\', \'Helvetica\', \'Arial\', sans-serif';

  // Draw the text in black, with the specified font
  ctx.fillStyle = fillStyle;
  ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

  // Make sure alignment settings are correct
  ctx.textAlign = textAlign;
  ctx.textBaseline = 'middle';
}

// from https://stackoverflow.com/questions/6735470/get-pixel-color-from-canvas-on-mouseover
// and https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
function decideBlackOrWhite(/* ctx, x, y */) {
  // Firefox has a problem and it seems the getImageData does not always work reliably. So we disable this functionality for now
  return '#000';

  // try {
  //   const p = ctx.getImageData(x, y, 1, 1).data;
  //   const r = p[0];
  //   const g = p[1];
  //   const b = p[2];
  //
  //   if ((r * 0.299) + (g * 0.587) + (b * 0.114) > 186) {
  //     return '#000';
  //   }
  // } catch (e) {
  //   // ignore and return default value
  // }
  // return '#fff';
}
