import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './translatable-input-text.html';

export default angular
  .module('swoa.translatable-input-text', [swoaConstants])
  .component('swoaTranslatableInputText', {
    template: htmlTemplate,
    bindings: {
      ngModel: '=',
      isReadOnly: '<',
      requiredLanguages: '<',
      labelKey: '@',
      messagesIf: '<'
    },
    controllerAs: 'vm',
    controller: SwoaTranslatableInputText
  }).name;

/** @ngInject */
function SwoaTranslatableInputText($scope, lodash) {
  const vm = this;

  vm.sortedLanguageList = ['GERMAN', 'FRENCH', 'ITALIAN', 'ENGLISH'];
  vm.viewModel = {};
  vm.messages = {};

  vm.isRequired = isRequired;
  vm.getMessages = getMessages;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.ngModel', updateViewModel, true);
    $scope.$watch('vm.viewModel', updateDataModel, true);
  }

  function isRequired(language) {
    return vm.requiredLanguages.indexOf(language) >= 0;
  }

  function getMessages(language) {
    if (vm.ngModel) {
      const translation = lodash.find(vm.ngModel.elementTranslations, {
        language
      });
      vm.messages[language] = vm.messages[language] || {};
      vm.messages[language].required =
        isRequired(language) && (!translation || !translation.text);
      return vm.messages[language];
    }
    return null;
  }

  function updateViewModel(dataModel) {
    // initially the data model can be undefined or empty
    if (dataModel && dataModel.elementTranslations) {
      // for each language copy values from data model to view model
      lodash.forEach(vm.sortedLanguageList, language => {
        const translation = lodash.find(dataModel.elementTranslations, {
          language
        });
        if (translation) {
          vm.viewModel[language] = translation.text;
        } else {
          vm.viewModel[language] = '';
        }
      });
    }
  }

  function updateDataModel(viewModel) {
    if (viewModel && vm.ngModel) {
      vm.ngModel.elementTranslations = vm.ngModel.elementTranslations || [];

      // for every language copy view value to more complex data model
      lodash.forEach(vm.sortedLanguageList, language => {
        // if there is a value set, update it in the data model, otherwise remove the whole elementTranslation
        if (viewModel[language]) {
          const translation = lodash.find(vm.ngModel.elementTranslations, {
            language
          });
          // new or existing value?
          if (translation) {
            translation.text = viewModel[language];
          } else {
            vm.ngModel.elementTranslations.push({
              language,
              text: viewModel[language]
            });
          }
        } else {
          const index = lodash.findIndex(vm.ngModel.elementTranslations, {
            language
          });
          if (index > -1) {
            lodash.pullAt(vm.ngModel.elementTranslations, index);
          }
        }
      });
    }
  }
}
