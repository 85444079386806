import angular from 'angular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.data-table.filter', [
    swoaConstants,
  ])
  .filter('swoaDataTable', swoaDataTableFilter)
  .name;

/** @ngInject */
function swoaDataTableFilter($filter, lodash) {
  return (inputArray, searchText, nameOnly = false) => {
    const searchTerms = (searchText || '').toLowerCase().split(/\s+/);

    let result = inputArray;
    searchTerms.forEach((searchTerm) => {
      if (nameOnly) {
        result = lodash.filter(result, item => lodash.includes(getFullNameFromItem(item), searchTerm));
      } else {
        result = $filter('filter')(result, searchTerm);
      }
    });

    return result;
  };

  function getFullNameFromItem(item) {
    const forename = lodash.get(item, 'forename') || lodash.get(item, 'person.forename') || lodash.get(item, 'athlete.person.forename');
    const surname = lodash.get(item, 'surname') || lodash.get(item, 'person.surname') || lodash.get(item, 'athlete.person.surname');
    return `${forename} ${surname}`.toLowerCase();
  }
}

