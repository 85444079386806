import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.ui-kitchensink.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('uiKitchensink', {
    url: '/ui-kitchensink',
    template: '<swoa-ui-kitchensink></swoa-ui-kitchensink>',
    data: {
      breadcrumb: true,
      hasPermission: 'admin_ssam_import_r'
    }
  });
}
