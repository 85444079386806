import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.nwf-contributions.controlling.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('nwfControlling', {
    url: '/nwf-periods/:organisationId/controlling/:calendarYearId',
    template: '<swoa-nwf-controlling></swoa-nwf-controlling>',
    controllerAs: 'vm',
    data: {
      breadcrumb: {
        parent: 'nwfContribution'
      },
      hasPermission: 'nwf_contribution_r'
    }
  });
}
