import angular from 'angular';
import swoaTranslatable from '../../components/translatable/translatable.filter';

export default angular
  .module('swoa.nwf-contribution.controlling.show', [swoaTranslatable])
  .component('swoaNwfControllingShow', {
    template: ` <md-card flex>
                    <md-card-content class="swoa-profile-data">
                        <div class="swoa-profile-data-list">
                            <p>
                                {{'nwfContributions.controlling.show' | translate}} {{::vm.calendarYear.controllingSentDate | swoaDate}}
                            </p>
                            
                            <swoa-data-table with-pagination="false">
                                <table md-table flex="80">
                                    <thead md-head md-order="vm.order">
                                      <tr md-row>
                                        <th md-column>{{'organisation.sport' | translate }}</th>
                                        <th md-column>{{'nwfInstitution' | translate }}</th>
                                        <th md-column>{{'amount.CHF' | translate }}*</th>
                                      </tr>
                                    </thead>
                                    <tbody md-body>
                                        <tr md-row
                                            ng-repeat="entry in vm.calendarYear.controllingEntries | orderBy: vm.order">
                                            <td md-cell>{{entry.sport.displayName}}</td>
                                            <td md-cell>{{entry.institution.name}}</td>
                                            <td md-cell>{{entry.amount | swoaFormatNumber}}</td>
                                        </tr>
                                        <tr md-row>
                                            <td md-cell></td>
                                            <td md-cell><strong>{{'TOTAL' | translate}}</strong></td>
                                            <td md-cell>{{vm.total | swoaFormatNumber}}</td>
                                        </tr>
                                        <tr md-row>
                                            <td md-cell></td>
                                            <td md-cell>
                                                <strong>{{'nwfContributions.controlling.payment' | translate}}</strong>
                                            </td>
                                            <td md-cell>{{vm.payment | swoaFormatNumber}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </swoa-data-table>
                            
                            <div flex layout="row" layout-align="end end">
                                <md-button class="md-raised md-hue-1 md-accent"
                                           ng-if="vm.isEditAllowed"
                                           ua-permission="nwf_contribution_controlling_w"
                                           ng-click="vm.onEdit()">
                                    <span translate="nwfContributions.controlling.backToEdit"></span>
                                </md-button>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>`,
    bindings: {
      calendarYear: '<',
      onEdit: '<'
    },
    controller: NwfControllingShow,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfControllingShow(lodash, swoaTranslatableFilter) {
  const vm = this;

  vm.order = '[sport.displayName, institution.name]';

  vm.isEditAllowed = false;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    vm.isEditAllowed = isEditAllowed(vm.calendarYear);

    lodash.forEach(vm.calendarYear.controllingEntries, entry => {
      entry.sport.displayName = swoaTranslatableFilter(
        entry.sport.translatedName,
        'de'
      );
      return entry;
    });

    vm.total = lodash.sumBy(vm.calendarYear.controllingEntries, 'amount');
    vm.payment =
      vm.calendarYear.payment1BaseAmount +
      vm.calendarYear.payment1VariableAmount +
      vm.calendarYear.payment2VariableAmount;
  }

  function isEditAllowed(calendarYear) {
    const currentYear = new Date().getFullYear();
    return currentYear <= calendarYear.year;
  }
}
