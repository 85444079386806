import angular from 'angular';

export default angular
  .module('swoa.admin.report.exports.buttons', [])
  .component('swoaExportButtons', {
    template: `
    <div layout="row" class="input-row">
      <div flex=45" layout="row" layout-align="end end">
        <md-button class="md-raised" 
                   ng-click="vm.onResetExportParams()" 
                   translate="report.resetParams">
        </md-button>
        <md-button class="md-raised md-accent md-hue-1"
                   translate="export"
                   id="export"
                   type="submit">
        </md-button>
      </div>
    </div>`,
    bindings: {
      onResetExportParams: '&',
    },
    controllerAs: 'vm',
    controller: () => {
    },
  }).name;

