import angular from 'angular';
import swoaConstants from '../app.constants';
import swoaOrganisationService from '../organisation/organisation.service';
import swoaCardService from './card.service';
import swoaSecurityService from '../security/security.service';
import swoaNotification from '../components/notification/notification.module';
import swoaCardSportYearService from './sport-year/sport-year.service';
import htmlTemplate from './card-detail-technician.html';

export default angular
  .module('swoa.card.card-detail-technician', [
    swoaConstants,
    swoaOrganisationService,
    swoaCardService,
    swoaSecurityService,
    swoaNotification,
    swoaCardSportYearService
  ])
  .component('swoaCardDetailTechnician', {
    template: htmlTemplate,
    bindings: {
      cardId: '<',
      cardYearId: '<'
    },
    controller: CardDetailTechnicianController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardDetailTechnicianController(
  $rootScope,
  $scope,
  $q,
  organisationService,
  cardService,
  securityService,
  notificationService,
  sportYearService,
  lodash
) {
  const vm = this;

  // view data
  vm.selection = null;
  vm.selectionModel = {};
  vm.isHeadChief = false;
  vm.canEdit = false;
  vm.editMode = false;
  vm.organisationSports = null;
  vm.card = null;
  vm.cardYear = null;
  vm.organisationRoles = [
    'CHIEF_LEISTUNGSSPORT',
    'CHIEF_NACHWUCHS',
    'CHIEF_AUSBILDUNG'
  ];
  vm.technicianRoles = null;
  vm.technicianRoleSelection = {};

  // error handling
  vm.notificationKey = null;

  // functions
  vm.disableEditMode = disableEditMode;
  vm.enableEditMode = enableEditMode;
  vm.save = save;

  // exposed for tests only
  vm._addResponsibility = addResponsibility;
  vm._getTechnicianRoleSelection = getTechnicianRoleSelection;
  vm._initSelection = initSelection;
  vm._removeResponsibility = removeResponsibility;
  vm._updateResponsibility = updateResponsibility;

  const headChiefRole = 'HEAD_CHIEF';

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.organisationRoles.forEach(role => {
      vm.selectionModel[role] = [];
    });

    sportYearService.loadSportYearDetails(vm.cardYearId).then(cardYear => {
      vm.cardYear = cardYear;
      if (isTechnicianYear()) {
        loadSportsAndCard();
      }
    }, notificationService.errorHandler(vm));

    $scope.$on('swoa:historyEntryChanged', onHistoryEntryChange);
  }

  function isTechnicianYear() {
    if (vm.cardYear) {
      return vm.cardYear.type === 'ORGANISATION' && vm.cardYear.organisation.id;
    }
    return false;
  }

  function loadSportsAndCard() {
    const promises = [];
    promises.push(
      organisationService.loadOrganisation(vm.cardYear.organisation.id)
    );
    promises.push(cardService.loadCard(vm.cardId));

    $q.all(promises).then(result => {
      const organisation = result[0];
      vm.organisationSports = lodash.filter(organisation.sports, {
        active: true
      });
      vm.organisationSports = lodash.sortBy(
        vm.organisationSports,
        sport => sport.name
      );
      if (!vm.organisationSports) {
        vm.organisationSports = [];
      }

      initCard(result[1]);
    }, notificationService.errorHandler(vm));
  }

  function initCard(card) {
    vm.card = card;
    securityService.extendCardModelWithEditAllowed(vm.cardYear, vm.card);
    vm.canEdit = vm.card.canChangeType;

    if (vm.card.technicianResponsibilities) {
      vm.technicianRoles = vm.card.technicianResponsibilities;
    } else {
      vm.technicianRoles = [];
    }
    vm.technicianRoleSelection = {};

    vm.selection = initSelection();
    vm.isHeadChief = !!vm.selection.HEAD_CHIEF;
  }

  function onHistoryEntryChange(event, historyEntry) {
    vm.cardYear = historyEntry.sportYear;
    vm.cardId = historyEntry.card.id;
    cardService.loadCard(historyEntry.card.id).then(card => {
      initCard(card);
    }, notificationService.errorHandler(vm));
  }

  function save(valid) {
    vm.submitted = true;

    if (!valid) {
      return;
    }

    if (vm.canEdit) {
      lodash.forEach(vm.organisationRoles, role => {
        lodash.forEach(vm.organisationSports, sport => {
          const roleSelected = vm.selection[role][sport.id];
          updateResponsibility(role, sport.id, roleSelected);
        });
      });

      updateResponsibility(headChiefRole, null, vm.isHeadChief);

      cardService
        .updateResponsibilities(vm.card.id, vm.card.technicianResponsibilities)
        .then(result => {
          initCard(result);
          $rootScope.$broadcast('swoa:cardUpdated', result);
        }, notificationService.errorHandler(vm));

      disableEditMode();
    }
    vm.submitted = false;
  }

  function updateResponsibility(responsibility, sportId, selected) {
    if (selected !== undefined) {
      if (selected) {
        addResponsibility(responsibility, sportId);
      } else {
        removeResponsibility(responsibility, sportId);
      }
    }
  }

  function addResponsibility(responsibility, sportId) {
    if (!vm.card.technicianResponsibilities) {
      vm.card.technicianResponsibilities = [];
    }

    const existingResponsibility = lodash.find(
      vm.card.technicianResponsibilities,
      r => {
        if (sportId) {
          if (r.sport) {
            if (r.sport.id !== sportId) {
              return false;
            }
          }
        }
        return r.responsibility === responsibility;
      }
    );

    if (!existingResponsibility) {
      if (sportId) {
        const sport = lodash.find(vm.organisationSports, s => s.id === sportId);
        if (sport) {
          vm.card.technicianResponsibilities.push({ responsibility, sport });
        }
      } else {
        vm.card.technicianResponsibilities.push({ responsibility });
      }
    }
  }

  function removeResponsibility(responsibility, sportId) {
    if (vm.card.technicianResponsibilities) {
      lodash.remove(vm.card.technicianResponsibilities, r => {
        if (sportId) {
          if (!r.sport) {
            return false;
          }
          if (r.sport.id !== sportId) {
            return false;
          }
        }
        return r.responsibility === responsibility;
      });
    }
  }

  function enableEditMode() {
    if (vm.canEdit) {
      vm.editMode = true;
    }
  }

  function disableEditMode() {
    vm.editMode = false;
    vm.selection = initSelection();
  }

  function initSelection() {
    const emptySelection = vm.organisationRoles.reduce((selection, role) => {
      selection[role] = {};

      if (vm.organisationSports) {
        vm.organisationSports.forEach(sport => {
          selection[role][sport.id] = false;
        });
      }
      return selection;
    }, {});

    const technicianRoleSelection = getTechnicianRoleSelection();
    return lodash.merge({}, emptySelection, technicianRoleSelection);
  }

  function getTechnicianRoleSelection() {
    return vm.technicianRoles.reduce((selection, role) => {
      if (!(role.responsibility in selection)) {
        selection[role.responsibility] = {};
      }

      if (role.sport) {
        selection[role.responsibility][role.sport.id] = true;
      } else {
        selection[role.responsibility] = true;
      }

      return selection;
    }, vm.technicianRoleSelection);
  }
}
