import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.template.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('translations.templates', {
    url: '/templates',
    data: {
      selectedTab: 3,
      breadcrumb: {
        parent: 'translations',
        ignore: true
      },
      hasPermission: 'admin_translation_r'
    },
    views: {
      templates: {
        template: '<swoa-templates></swoa-templates>'
      }
    }
  });
}
