import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.role.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('roleList', {
      url: '/roles',
      template: '<swoa-role-list></swoa-role-list>',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_role_r'
      }
    })
    .state('editRole', {
      url: '/roles/:roleId?notificationKey&isSuccess',
      template: '<swoa-role-edit></swoa-role-edit>',
      data: {
        breadcrumb: {
          parent: 'roleList',
          titlePlaceholder: 'roleName'
        },
        hasPermission: 'admin_role_r'
      },
      params: {
        notificationKey: {
          value: null,
          squash: true
        },
        isSuccess: {
          value: null,
          squash: true
        }
      }
    });
}
