import angular from 'angular';
import 'restangular';
import LocalStorageModule from 'angular-local-storage';

export default angular
  .module('swoa.city.service', [
    'restangular',
    LocalStorageModule,
  ])
  .factory('cityService', cityService)
  .name;

const STORAGE_KEY_NAME = 'cities.cache';

/** @ngInject */
function cityService($q, Restangular, localStorageService) {
  let cachedElementsPromise = null;

  const baseCities = Restangular.all('cities');

  return {
    fillCache,
    getAllCities,
    clearCache,
    getAllStates,
  };

  // //////////

  function fillCache(force) {
    if (force || !cachedElementsPromise) {
      loadFromServer(force);
    }
  }

  function loadFromServer(force) {
    const cachedElements = localStorageService.get(STORAGE_KEY_NAME);

    if (!force && cachedElements && cachedElements.length) {
      cachedElementsPromise = $q.when(cachedElements);
    } else {
      cachedElementsPromise = baseCities.getList().then((serverElements) => {
        localStorageService.set(STORAGE_KEY_NAME, Restangular.stripRestangular(serverElements));
        return serverElements;
      });
    }
  }

  function getAllCities() {
    fillCache();
    return localStorageService.get(STORAGE_KEY_NAME);
  }

  function clearCache() {
    localStorageService.remove(STORAGE_KEY_NAME);
    cachedElementsPromise = null;
  }

  function getAllStates() {
    return baseCities.one('states').getList();
  }
}
