import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaNotification from '../../components/notification/notification.module';
import swoaObjectProperty from '../../components/object-property/object-property.filter';
import swoaPersonSync from '../../person/sync/person-sync.module';
import swoaCardCardImportService from './card-import.service';
import swoaCardCardImportCardImportState from './card-import-state.service';
import htmlTemplate from './card-import-sync.html';

export default angular
  .module('swoa.card.card-import.card-import-sync', [
    uiRouter,
    swoaNotification,
    swoaObjectProperty,
    swoaPersonSync,
    swoaCardCardImportService,
    swoaCardCardImportCardImportState
  ])
  .component('swoaCardImportSync', {
    template: htmlTemplate,
    bindings: {},
    controller: CardImportSyncController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardImportSyncController(
  $state,
  $stateParams,
  notificationService,
  cardImportService,
  cardImportStateService
) {
  const vm = this;

  // state params
  vm.cardImportId = $stateParams.cardImportId;
  vm.personId = $stateParams.personId;

  // functions
  vm.saveSync = saveSync;

  // loaded data and error handling
  vm.syncData = null;
  vm.notificationKey = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    // load sync data
    if (vm.personId) {
      cardImportService
        .loadSyncForPerson(vm.cardImportId, vm.personId)
        .then(syncData => {
          vm.syncData = syncData;
          cardImportStateService.setName(
            syncData.person.forename,
            syncData.person.surname
          );
        }, notificationService.errorHandler(vm));
    } else {
      cardImportService.loadSync(vm.cardImportId).then(syncData => {
        vm.syncData = syncData;
        cardImportStateService.setName(
          syncData.person.forename,
          syncData.person.surname
        );
      }, notificationService.errorHandler(vm));
    }
  }

  function saveSync() {
    cardImportService
      .saveSync($stateParams.cardImportId, vm.syncData.syncResult)
      .then(() => {
        $state.go('cardImport.overview', $stateParams);
      }, notificationService.errorHandler(vm));
  }
}
