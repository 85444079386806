import angular from 'angular';
import swoaDialog from '../../components/dialog/dialog.module';
import swoaNotification from '../../components/notification/notification.module';
import swoaConstants from '../../app.constants';
import htmlTemplate from './sport-year-edit.html';

export default angular
  .module('swoa.card.sport-year.sport-year-edit', [
    swoaDialog,
    swoaNotification,
    swoaConstants
  ])
  .component('swoaSportYearEdit', {
    template: htmlTemplate,
    bindings: {
      onSave: '&',
      validFrom: '<',
      validUntil: '<'
    },
    controller: SportYearEditController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function SportYearEditController(
  $mdDialog,
  $translate,
  lodash,
  moment,
  Constants
) {
  const vm = this;

  vm.duration = [];
  vm.months = [];
  vm.years = [];
  vm.cardYear = {};

  vm.save = save;
  vm.closeDialog = closeDialog;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    initCardYear();
    initDurationList();
    initMonthsList();
    initYearList();
  }

  function initCardYear() {
    const from = moment(vm.validFrom);
    const to = moment(vm.validUntil);
    vm.cardYear.month = moment(from).month() + 1;
    vm.cardYear.year = moment(from).year();
    vm.cardYear.duration = to.diff(from, 'month') + 1;
  }

  function initDurationList() {
    const durationRange = lodash.range(0, 24, 1);
    let index = 0;
    durationRange.forEach(() => {
      index += 1;
      if (index === 1) {
        vm.duration.push({
          id: index,
          text: index + $translate.instant('MONTH')
        });
      } else {
        vm.duration.push({
          id: index,
          text: index + $translate.instant('MONTHS')
        });
      }
    });
  }

  function initMonthsList() {
    Constants.months.forEach(month => {
      vm.months.push({ id: month.id, month: $translate.instant(month.month) });
    });
  }

  function initYearList() {
    vm.years = lodash.rangeRight(vm.cardYear.year - 1, vm.cardYear.year + 4);
  }

  function save(valid) {
    vm.notificationKey = null;
    vm.submitted = true;

    if (!valid) {
      vm.notificationKey = 'form.invalid';
      return;
    }

    vm.onSave({
      $validFrom: format(getValidFrom()),
      $validUntil: format(getValidUntil())
    });
  }

  function getValidUntil() {
    return getValidFrom()
      .add(vm.cardYear.duration - 1, 'months')
      .endOf('month');
  }

  function getValidFrom() {
    const date = moment(vm.cardYear.year, 'YYYY');
    return date.month(vm.cardYear.month - 1).startOf('month');
  }

  function format(date) {
    return date.format('YYYY-MM-DD');
  }

  function closeDialog() {
    $mdDialog.hide();
  }
}
