import angular from 'angular';
import htmlTemplate from './banner.html';
import swoaPropertiesService from '../../properties/properties.service';

export default angular
  .module('swoa.banner.component', [swoaPropertiesService])
  .component('swoaBanner', {
    template: htmlTemplate,
    bindings: {},
    controller: BannerController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function BannerController(propertiesService) {
  const vm = this;

  vm.version = null;
  // eslint-disable-next-line no-undef
  vm.required = API_VERSION;

  vm.isCompatible = undefined;

  vm.$onInit = () => {
    activate();
  };

  // /////////

  function activate() {
    propertiesService.getProperties().then(properties => {
      vm.version = properties.version;
      vm.isCompatible = checkVersionCompatibility(vm.version, vm.required);
    });
  }

  function checkVersionCompatibility(backendVersion, requiredVersion) {
    const [backendMajor, backendMinor] = backendVersion.split('.');
    const [requiredMajor, requiredMinor] = requiredVersion.split('.');
    return backendMajor === requiredMajor && backendMinor === requiredMinor;
  }
}
