import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaNotification from '../components/notification/notification.module';
import swoaTranslatable from '../components/translatable/translatable.filter';
import swoaOrganisationService from './organisation.service';
import htmlTemplate from './organisation-list.html';

export default angular
  .module('swoa.organisation.organisation-list', [
    uiRouter,
    swoaNotification,
    swoaTranslatable,
    swoaOrganisationService
  ])
  .component('swoaOrganisationList', {
    template: htmlTemplate,
    bindings: {},
    controller: OrganisationListController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function OrganisationListController(
  $rootScope,
  $state,
  lodash,
  $translate,
  notificationService,
  organisationService,
  swoaTranslatableFilter
) {
  const vm = this;

  vm.organisations = [];
  vm.order = 'displayName';

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $rootScope.showSpinner = true;
    const loadFunc = organisationService.getAllowedOrganisations;
    loadFunc().then(organisations => {
      if (organisations && organisations.length === 1) {
        // only one organisation -> go directly to summary
        $state.go('organisationYearSummary', {
          organisationId: organisations[0].id
        });
      } else if (!organisations || organisations.length === 0) {
        vm.notificationKey = 'user.noOrganisations';
      }

      vm.organisations = organisations;
      updateDisplayName();
      $rootScope.showSpinner = false;
    }, notificationService.errorHandler(vm));
  }

  function updateDisplayName() {
    lodash.forEach(vm.organisations, organisation => {
      organisation.displayName = swoaTranslatableFilter(
        organisation.name,
        'de'
      );
      organisation.sports.forEach(
        sport =>
          (sport.displayName = swoaTranslatableFilter(
            sport.translatedName,
            'de'
          ))
      );
      organisation.categoryList = organisation.categories
        .map(category =>
          $translate.instant(`organisation.category.${category}`)
        )
        .join(',');
    });
  }
}
