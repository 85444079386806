import angular from 'angular';
import swoaReportRoutes from './report.routes';
import swoaReportService from './report.service';
import swoaReportExportsPage from './exports-page.component';
import swoaReportExportsCards from './exports-cards.component';
import swoaReportExportsSports from './exports-sports.component';
import swoaReportExportsUsers from './exports-users.component';
import swoaReportPage from './report-page.component';
import swoaReportReports from './reports.component';
import swoaExportService from './export.service';
import swoaCreateFavoriteDialog from './create-favorite.component';
import swoaFavoriteList from './favorite-list.component';
import swoaReportExportsNwfBaseAmount from './exports-nwf-base-amount.component';
import swoaReportExportsNwfVariableAmount from './exports-nwf-variable-amount.component';
import swoaReportExportsNwfTrainerCurrent from './exports-nwf-trainer-current.component';
import swoaReportExportsNwfTrainerPercentage from './exports-nwf-trainer-percentage.component';
import swoaReportExportsNwfBaseAmountFilter from './export-nwf-base-amount-filter.component';
import swoaReportExportsAttributeSelector from './exports-attribute-selector.component';
import swoaReportExportsButtons from './exports-buttons.component';
import swoaReportExportsNwfInstitutionsContent from './exports-nwf-common-content.component';

export default angular.module('swoa.admin.report', [
  swoaReportRoutes,
  swoaReportService,
  swoaReportExportsPage,
  swoaReportExportsCards,
  swoaReportExportsSports,
  swoaReportExportsUsers,
  swoaReportPage,
  swoaReportReports,
  swoaExportService,
  swoaCreateFavoriteDialog,
  swoaFavoriteList,
  swoaReportExportsNwfBaseAmount,
  swoaReportExportsNwfBaseAmountFilter,
  swoaReportExportsNwfVariableAmount,
  swoaReportExportsNwfTrainerCurrent,
  swoaReportExportsNwfTrainerPercentage,
  swoaReportExportsAttributeSelector,
  swoaReportExportsButtons,
  swoaReportExportsNwfInstitutionsContent
]).name;
