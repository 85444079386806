import angular from 'angular';
import ngMaterial from 'angular-material';
import swoaDialog from '../../components/dialog/dialog.module';
import htmlTemplate from './person-profile-image-change.html';

export default angular
  .module('swoa.person.profile.person-profile-image-change', [
    ngMaterial,
    swoaDialog
  ])
  .component('swoaPersonProfileImageChange', {
    template: htmlTemplate,
    bindings: {
      textKey: '@',
      upload: '&',
      remove: '&',
      preview: '=?',
      position: '=?',
      download: '&',
      areaType: '@',
      imageAvailable: '<',
      isDownloadable: '=',
      isUploadable: '=',
      isRemoveable: '='
    },
    controller: PersonProfileChangeImageController,
    controllerAs: 'vm'
  }).name;

export const MAX_IMAGE_SIZE = '10MB';

/** @ngInject */
function PersonProfileChangeImageController(
  $element,
  $rootScope,
  $scope,
  $mdDialog,
  dialogService
) {
  const vm = this;

  vm.maxSize = MAX_IMAGE_SIZE;

  vm.onSelect = onSelect;
  vm.onSelectionChange = onSelectionChange;
  vm.onCancel = onCancel;
  vm.onSave = onSave;
  vm.showSpinner = showSpinner;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.cropObject', onSelectionChange, true);
  }

  function onSelect(file, invalidFiles) {
    if (!file && invalidFiles.length === 0) {
      // Ignore selection reset
      return;
    }

    if (file) {
      vm.file = file;
      vm.inputImage = URL.createObjectURL(file);
      vm.preview = vm.inputImage;

      $mdDialog.show({
        scope: $scope,
        template: `<swoa-dialog closeable="false" dialog-title="'person.imageCropper.title' | translate">
                     <md-dialog-content class="layout-gray">
                       <div class="image-cropper">
                         <ui-cropper image="vm.inputImage"
                                     area-type="{{vm.areaType}}"
                                     cropject="vm.cropObject"
                                     result-image="ignore"></ui-cropper>
                       </div>
                     </md-dialog-content>
                     <md-dialog-actions layout="row">
                       <span flex></span>
                       <md-button class="md-raised" ng-click="vm.onCancel();">{{'cancel' | translate}}</md-button>
                       <md-button class="md-primary md-raised" ng-click="vm.onSave()">
                         {{'save' | translate}}
                       </md-button>
                     </md-dialog-actions>
                   </swoa-dialog>`,
        clickOutsideToClose: false,
        fullscreen: false,
        preserveScope: true
      });
    } else {
      const params = {},
        titleKey = 'personProfile.image.error.title',
        okKey = 'app.ok';
      let textKey;
      if (invalidFiles.length && invalidFiles[0].$error === 'maxSize') {
        textKey = 'personProfile.image.error.maxSize';
        params.maxSize = invalidFiles[0].$errorParam;
      } else {
        textKey = 'personProfile.image.error.default';
      }
      dialogService.showAlert(titleKey, textKey, okKey, params);
    }
  }

  function onSelectionChange(crop) {
    if (crop) {
      vm.position = {
        positionX:
          (crop.areaCoords.x / (crop.canvasSize.w - crop.cropWidth)) * 100,
        positionY:
          (crop.areaCoords.y / (crop.canvasSize.h - crop.cropHeight)) * 100,
        sizeX: (crop.canvasSize.w / crop.cropWidth) * 100,
        sizeY: (crop.canvasSize.h / crop.cropHeight) * 100
      };
      if (crop.canvasSize.w - crop.cropWidth === 0) {
        vm.position.positionX = 0;
      }
      if (crop.canvasSize.h - crop.cropHeight === 0) {
        vm.position.positionY = 0;
      }
    } else {
      vm.position = null;
    }
  }

  function onCancel() {
    vm.preview = null;
    vm.position = null;
    $mdDialog.hide();
  }

  function onSave() {
    showSpinner(
      vm.upload({
        $file: vm.file,
        $metadata: vm.position ? JSON.stringify(vm.position) : null
      })
    ).then(() => $mdDialog.hide());
  }

  function showSpinner(result) {
    if (result && angular.isFunction(result.finally)) {
      $element.find('md-menu').hide();
      $element.find('.spinner-inline').css('display', 'inline-block');

      result
        .finally(() => {
          $element.find('md-menu').show();
          $element.find('.spinner-inline').hide();
        })
        .catch(angular.noop);
    }
    return result;
  }
}
