import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaDialogService from '../../components/dialog/dialog.service';
import swoaPersonService from '../../person/person.service';
import swoaOrganisationService from '../../organisation/organisation.service';
import swoaNwfVariableAmountPeriodService from './nwf-variable-amount-period.service';
import swoaNwfTrainerService from './nwf-trainer.service';
import htmlTemplate from './nwf-variable-amount-period-foundation-detail.html';

export default angular
  .module(
    'swoa.nwf-contribution.variable-amount.nwf-variable-amount-period-foundation-detail',
    [
      uiRouter,
      swoaConstants,
      swoaNotification,
      swoaDialogService,
      swoaPersonService,
      swoaOrganisationService,
      swoaNwfVariableAmountPeriodService,
      swoaNwfTrainerService
    ]
  )
  .component('swoaNwfVariableAmountPeriodFoundationDetail', {
    template: htmlTemplate,
    bindings: {
      period: '<'
    },
    controller: NwfVariableAmountPeriodFoundationDetail,
    controllerAs: 'vm'
  }).name;

const ADD_INSTITUTION_TEMPLATE = `
<div layout="column" flex="90" layout-padding>
  <md-autocomplete flex
                   md-input-name="institution"
                   md-floating-label="{{'nwfInstitution' | translate}}"
                   md-selected-item="institution" 
                   md-search-text="vm.searchText"
                   md-items="i in institutions | filter: vm.searchText" 
                   md-item-text="i.fullName"
                   md-require-match="true"
                   md-min-length="0">
    <md-item-template>
      <span class="{{i.strong ? 'strong' : ''}}">{{ i.fullName }}</span>
    </md-item-template>
  </md-autocomplete>
  <div layout="row">
    <md-button ng-click="setValue('institution', institution); hide();" class="md-raised md-accent md-hue-1" translate="add"></md-button>
    <md-button ng-click="cancel()" class="md-raised" translate="cancel"></md-button>
  </div>
</div>`;

/** @ngInject */
function NwfVariableAmountPeriodFoundationDetail(
  $rootScope,
  $scope,
  $stateParams,
  $state,
  $q,
  $translate,
  lodash,
  moment,
  Constants,
  personService,
  notificationService,
  nwfVariableAmountPeriodService,
  dialogService,
  organisationService,
  nwfTrainerService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  let originalTrainer = null,
    originalInstitutions = null;

  vm.yearList = lodash.rangeRight(
    Constants.recognition.startYear,
    moment().year() + 1
  );
  vm.trainerId = $stateParams.trainerId;
  vm.currentInstitutions = [];
  vm.submitted = false;

  vm.save = save;
  vm.startEdit = startEdit;
  vm.cancelEdit = cancelEdit;
  vm.closeDetail = closeDetail;
  vm.remove = remove;
  vm.addInstitution = addInstitution;
  vm.deleteInstitution = deleteInstitution;
  vm.addRow = addRow;
  vm.deleteRow = deleteRow;
  vm.showNationalPercentage = false;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.editing = $stateParams.state === 'new' && vm.editing === undefined;
    nwfVariableAmountPeriodService.loadTrainer(vm.trainerId).then(trainer => {
      vm.trainer = trainer;
      vm.recognitionData = trainer.athlete.person.recognitionData;
      splitTrainerDataByInstitution(trainer.trainerData);
      originalTrainer = angular.copy(vm.trainer);
      originalInstitutions = angular.copy(vm.currentInstitutions);
      vm.showNationalPercentage = showNationalPercentage();
    }, onError);
  }

  function showNationalPercentage() {
    if (vm.recognitionData.recognition.technicalName === 'NO_DTA_BTA') {
      return false;
    }
    return (
      !nwfTrainerService.checkLevelOfEmployment(vm.trainer) &&
      $stateParams.state !== 'new'
    );
  }

  function splitTrainerDataByInstitution(trainerData) {
    vm.currentInstitutions = lodash
      .chain(trainerData)
      .map('institution')
      .uniqBy('id')
      .forEach(renderFullName)
      .sortBy('level', 'fullName')
      .value();
    vm.currentInstitutions.forEach(
      institution =>
        (institution.trainerData = trainerDataByInstitution(
          trainerData,
          institution
        ))
    );
  }

  function trainerDataByInstitution(trainerData, institution) {
    return lodash.sortBy(
      lodash.filter(trainerData, ['institution.id', institution.id]),
      'function'
    );
  }

  function save(valid) {
    vm.submitted = true;
    vm.notificationKey = null;

    if (!valid) {
      vm.notificationKey = 'nwfContributions.trainer.couldNotSaveForm';
      return null;
    }

    vm.trainer.trainerData = lodash.flatMap(
      vm.currentInstitutions,
      institution =>
        institution.trainerData.map(data => {
          data.institution = institution;
          return data;
        })
    );
    vm.trainer.trainerData.forEach(data => delete data.institution.trainerData);

    if (!vm.trainer.trainerData || vm.trainer.trainerData.length === 0) {
      vm.notificationKey =
        'nwfContributions.trainer.couldNotSaveFormWithoutTrainerData';
      return null;
    }

    const trainerDataContainer = {
      nationalPercentageActive: vm.trainer.nationalPercentageActive,
      comment: vm.trainer.comment,
      trainerData: vm.trainer.trainerData
    };

    const trainerPromise = nwfVariableAmountPeriodService.updateTrainerData(
      vm.trainer.id,
      trainerDataContainer
    );
    const personPromise = personService.updateAdditionalData(
      vm.trainer.athlete.person.id,
      { recognitionData: vm.recognitionData }
    );
    $stateParams.state = 'saved';
    return $q.all([trainerPromise, personPromise]).then(() => {
      vm.trainer.athlete.person.recognitionData = vm.recognitionData;
      originalTrainer.athlete.person.recognitionData = vm.recognitionData;
      $rootScope.$broadcast('swoa:nwfTrainerUpdated', vm.trainer);
      resetForm();
      activate();
    }, onError);
  }

  function startEdit() {
    vm.editing = true;
  }

  function cancelEdit() {
    if (vm.editing) {
      vm.notificationKey = null;
      resetForm();
      vm.trainer = angular.copy(originalTrainer);
      vm.currentInstitutions = angular.copy(originalInstitutions);
      vm.recognitionData = vm.trainer.athlete.person.recognitionData;
    }
  }

  function resetForm() {
    vm.editing = false;
    vm.submitted = false;
    vm.trainerForm.$setUntouched();
    vm.trainerForm.$setPristine();
  }

  function closeDetail() {
    $state.go('^', {
      organisationId: $stateParams.organisationId,
      periodId: vm.period.id
    });
  }

  function remove() {
    dialogService
      .showConfirmationDialog('nwfContributions.trainer.delete.confirmation')
      .then(() =>
        nwfVariableAmountPeriodService.removeTrainer(vm.trainer.id).then(() => {
          $rootScope.$broadcast('swoa:nwfTrainerDeleted', vm.trainer);
          closeDetail();
        })
      )
      .catch(onError);
  }

  function renderFullName(institution) {
    institution.fullName = institution.name;
    if (institution.address && institution.address.city) {
      institution.fullName += `, ${institution.address.city}`;
    }
  }

  function renderStyle(institution) {
    if (institution.level === 'NATIONAL') {
      institution.strong = true;
    }
  }

  function addInstitution() {
    organisationService.get(vm.period.organisation.id).then(organisation => {
      const scope = {
        institutions: prepareAddableInstitutions(organisation.nwfInstitutions)
      };
      if (scope.institutions.length === 0) {
        return dialogService.showAlert(
          'app.error',
          'nwfInstitution.noInstitutions',
          'app.ok'
        );
      }
      return dialogService
        .showDialog(
          ADD_INSTITUTION_TEMPLATE,
          false,
          $translate.instant('nwfInstitution.addInstitution'),
          scope,
          true
        )
        .then(() => {
          const selectedInstitution = scope.institution;
          if (selectedInstitution) {
            selectedInstitution.trainerData = [{}];
            vm.currentInstitutions.push(selectedInstitution);
            vm.selectedTab = vm.currentInstitutions.length - 1;
          }
        });
    }, onError);
  }

  function prepareAddableInstitutions(institutions) {
    const addableInstitutions = lodash
      .chain(institutions)
      .filter(institution => institution.active !== false)
      .filter(institution =>
        lodash.includes(
          institution.sports.map(s => s.id),
          vm.period.sport.id
        )
      )
      .forEach(renderFullName)
      .forEach(renderStyle)
      .sortBy('level', 'fullName')
      .value();
    vm.currentInstitutions.forEach(institution =>
      lodash.remove(addableInstitutions, ['id', institution.id])
    );
    return addableInstitutions;
  }

  function deleteInstitution(institution) {
    lodash.remove(vm.currentInstitutions, ['id', institution.id]);
  }

  function addRow(institution) {
    institution.trainerData.push({});
  }

  function deleteRow(institution, trainerData) {
    lodash.pull(institution.trainerData, trainerData);
    if (institution.trainerData.length === 0) {
      deleteInstitution(institution);
    }
  }
}
