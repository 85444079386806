import angular from 'angular';
import htmlTemplate from './password-strength.html';

export default angular
  .module('swoa.password-strength.component', [])
  .component('swoaPasswordStrength', {
    template: htmlTemplate,
    replace: true,
    bindings: {
      passwordModel: '<',
      strengthModel: '=',
    },
    controller: PasswordStrengthController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function PasswordStrengthController($scope, swoaPasswordStrengthFilter) {
  const vm = this;

  $scope.$watch('vm.passwordModel', () => {
    vm.strengthModel = swoaPasswordStrengthFilter(vm.passwordModel);
  });
}
