import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.person.search.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('search', {
      url: '/search',
      template:
        '<swoa-person-search-page current-tab="selectedTab"></swoa-person-search-page>',
      controller($scope, $state, $transitions) {
        if ($state.current.name === 'search') {
          $state.go('search.person');
          $scope.selectedTab = 'search';
        } else {
          $scope.selectedTab = $state.current.data.selectedTab;
        }

        $transitions.onSuccess({ to: 'search.**' }, transition => {
          if (transition.to().name === 'search') {
            $state.go('search.person');
            $scope.selectedTab = 'search';
          } else {
            $scope.selectedTab = $state.current.data.selectedTab;
          }
        });
      },
      controllerAs: 'vm'
    })
    .state('search.person', {
      url: '/persons/search?query&notificationKey&isSuccess',
      data: {
        selectedTab: 'search',
        breadcrumb: true,
        hasPermission: 'person_search_r'
      },
      reloadOnSearch: false,
      params: {
        query: {
          value: null,
          squash: true
        },
        notificationKey: {
          value: null,
          squash: true
        },
        isSuccess: {
          value: null,
          squash: true
        }
      },
      views: {
        person: {
          template:
            '<swoa-person-search without-selection="true"></swoa-person-search>'
        }
      }
    })
    .state('search.extended', {
      url: '/persons/search-extended',
      data: {
        selectedTab: 'extended',
        breadcrumb: {
          parent: 'search.person',
          ignore: true
        },
        hasPermission: 'person_search_r'
      },
      views: {
        extended: {
          template:
            '<swoa-person-extended-search without-selection="true"></swoa-person-extended-search>'
        }
      }
    })
    .state('personCreate', {
      url: '/persons/create',
      template: '<swoa-person-create-page></swoa-person-create-page>',
      data: {
        breadcrumb: {
          parent: 'search.person'
        },
        hasPermission: 'person_w'
      }
    });
}
