import angular from 'angular';
import swoaNotification from '../components/notification/notification.module';
import swoaTranslatable from '../components/translatable/translatable.filter';
import swoaOrganisationService from '../organisation/organisation.service';
import swoaNwfContributionService from './nwf-contribution.service';
import htmlTemplate from './nwf-contribution.html';

export default angular
  .module('swoa.nwf-contribution.component', [
    swoaNotification,
    swoaTranslatable,
    swoaOrganisationService,
    swoaNwfContributionService
  ])
  .component('swoaNwfContribution', {
    template: htmlTemplate,
    bindings: {},
    controller: NwfContribution,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfContribution(
  $rootScope,
  $scope,
  $q,
  lodash,
  moment,
  $translate,
  organisationService,
  notificationService,
  swoaTranslatableFilter,
  nwfContributionService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  const START_YEAR = 2018;

  vm.associations = [];
  vm.calendarYears = [];
  vm.calendarYear = moment().year();
  vm.years = [];
  vm.containers = [];

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    $scope.$watch('vm.calendarYear', onCalendarYearChanged);
  }

  function onCalendarYearChanged(calendarYear) {
    $rootScope.showSpinner = true;
    nwfContributionService
      .getCalendarYears(calendarYear)
      .then(overviewContainer => {
        vm.containers = overviewContainer.nwfOrganisationContainers;
        vm.years = lodash.rangeRight(START_YEAR, overviewContainer.maxYear + 1);
        updateDisplayNameAndHasBaseAmountPeriod();
        filterAssociationsByBaseAmountPeriodOrActive();
      })
      .catch(onError)
      .finally(() => ($rootScope.showSpinner = false));
  }

  function hasActiveSport(sports) {
    return lodash.some(sports, sport => sport.active === true);
  }

  function updateDisplayNameAndHasBaseAmountPeriod() {
    lodash.forEach(vm.containers, container => {
      container.organisation.displayName = swoaTranslatableFilter(
        container.organisation.name,
        'de'
      );
      container.organisation.hasBaseAmountPeriod =
        container.calendarYears !== null;
      container.warnings = addTrainerWarning(container.trainerWarnings);
    });
  }

  function getTranslatedSportList(sports) {
    const translatedSports = lodash.map(sports, sport =>
      swoaTranslatableFilter(sport.translatedName, 'de')
    );
    return translatedSports.join(', ');
  }

  function filterAssociationsByBaseAmountPeriodOrActive() {
    vm.containers = lodash.filter(
      vm.containers,
      container =>
        container.organisation.hasBaseAmountPeriod === true ||
        (container.organisation.active &&
          hasActiveSport(container.organisation.sports))
    );
  }

  function addTrainerWarning(trainerWarnings) {
    if (trainerWarnings && trainerWarnings.length > 0) {
      const warningsNationalPercentage = lodash.filter(
        trainerWarnings,
        warning => !warning.missingRecognition
      );
      const warningsNoRecognition = lodash.filter(
        trainerWarnings,
        warning => warning.missingRecognition
      );
      lodash.forEach(warningsNationalPercentage, w => {
        if (w.nationalPercentageActive) {
          w.person.remark = $translate.instant(
            'nwf.percentage.rule.active.message'
          );
        } else {
          w.person.remark = getTranslatedSportList(w.sports);
        }
      });

      const warning = [
        {
          warningTextKey: 'nwfContributions.trainer.lessThanThirtyPercent',
          personList: warningsNationalPercentage
        },
        {
          warningTextKey: 'nwfContributions.trainer.noRecognition.warningTitle',
          personList: warningsNoRecognition
        }
      ];

      return warning;
    }
    return null;
  }
}
