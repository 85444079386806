import angular from 'angular';
import 'restangular';
import swoaDialog from '../../../components/dialog/dialog.module';
import swoaConstants from '../../../app.constants';
import swoaTranslatableElementService from '../../../translation/translatable-element.service';
import swoaNotification from '../../../components/notification/notification.module';
import htmlTemplate from './translations-table.html';

export default angular
  .module('swoa.admin.translation.translations-table', [
    swoaDialog,
    'restangular',
    swoaConstants,
    swoaTranslatableElementService,
    swoaNotification
  ])
  .component('swoaTranslationsTable', {
    template: htmlTemplate,
    bindings: {
      entry: '<',
      technicalName: '<',
      selectedType: '<',
      customSortedType: '<',
      order: '<'
    },
    controller: TranslationsTableController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function TranslationsTableController(
  $scope,
  $translate,
  $state,
  lodash,
  dialogService,
  Restangular,
  translatableElementService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.order = vm.technicalName
    ? ['translationType', 'technicalName']
    : ['translationType'];

  // functions
  vm.getGerman = getGerman;
  vm.getFrench = getFrench;
  vm.getItalian = getItalian;
  vm.moveUp = moveUp;
  vm.moveDown = moveDown;
  vm.getDetailsRoute = getDetailsRoute;

  // only for testing
  vm._onSettingsTranslatableAdd = onSettingsTranslatableAdd;
  vm._onSettingsTranslatableUpdate = onSettingsTranslatableUpdate;

  vm.$onInit = () => {
    activate();
  };

  // /////

  function activate() {
    $scope.$on('swoa:settingsTranslatableUpdate', onSettingsTranslatableUpdate);
    $scope.$on('swoa:settingsTranslatableAdd', onSettingsTranslatableAdd);
  }

  function onSettingsTranslatableAdd(event, translatableElement) {
    const translatedTranslatableElementType = $translate.instant(
      `applicationParameter.${translatableElement.type}`
    );

    dialogService.showSuccessToast('applicationParameter.add.translation', {
      translatableElementSaved: translatedTranslatableElementType
    });
    translatableElement.translationType = translatedTranslatableElementType;
    vm.entry.push(translatableElement);
    translatableElementService.clearCache();
  }

  function onSettingsTranslatableUpdate(event, translatableElements) {
    const translatedTranslatableElementType = $translate.instant(
      `applicationParameter.${translatableElements[0].type}`
    );

    dialogService.showSuccessToast('applicationParameter.save.translation', {
      translatableElementSaved: translatedTranslatableElementType
    });
    updateTranslatableElements(translatableElements);
  }

  function getGerman(translations) {
    return getTranslation(translations, 'GERMAN');
  }

  function getFrench(translations) {
    return getTranslation(translations, 'FRENCH');
  }

  function getItalian(translations) {
    return getTranslation(translations, 'ITALIAN');
  }

  function getTranslation(elementTranslations, language) {
    const germanElement = lodash.filter(elementTranslations, { language });
    if (germanElement.length > 0) {
      return germanElement[0].text;
    }
    return '-';
  }

  function moveUp(translatableElement, event) {
    translatableElementService
      .moveSortUp(translatableElement)
      .then(translatableElements => {
        updateTranslatableElements(translatableElements);
      }, onError);
    event.stopPropagation();
  }

  function moveDown(translatableElement, event) {
    translatableElementService
      .moveSortDown(translatableElement)
      .then(translatableElements => {
        updateTranslatableElements(translatableElements);
      }, onError);
    event.stopPropagation();
  }

  function updateTranslatableElements(translatableElements) {
    lodash.forEach(translatableElements, element => {
      element.translationType = $translate.instant(
        `applicationParameter.${element.type}`
      );
      updateTranslatableElement(element);
    });
    translatableElementService.clearCache();
  }

  function updateTranslatableElement(translatableElement) {
    const index = lodash.findIndex(vm.entry, { id: translatableElement.id });
    if (index > -1) {
      vm.entry[index] = Restangular.copy(translatableElement);
    }
  }

  function getDetailsRoute() {
    return $state.is('translations.dropdowns')
      ? 'translations.dropdowns.dropdownsDetail({settingId: translatableElement.id})'
      : 'translations.technical.detail({settingId: translatableElement.id})';
  }
}
