import angular from 'angular';
import 'restangular';
import swoaTranslatable from '../../../components/translatable/translatable.filter';
import swoaNotification from '../../../components/notification/notification.module';
import swoaDialog from '../../../components/dialog/dialog.module';
import swoaOrganisationService from '../../organisation.service';
import swoaSportService from '../../../sport/sport.service';
import htmlTemplate from './nwf-institution-detail.html';

export default angular
  .module('sswoa.organisation.profile.nwf-institution-detail.component', [
    'restangular',
    swoaTranslatable,
    swoaNotification,
    swoaDialog,
    swoaOrganisationService,
    swoaSportService
  ])
  .component('swoaNwfInstitutionDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: NwfInstitutionDetailController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfInstitutionDetailController(
  $rootScope,
  $state,
  $stateParams,
  $q,
  moment,
  lodash,
  Restangular,
  Constants,
  sportService,
  organisationService,
  dialogService,
  notificationService,
  swoaTranslatableFilter
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  let originalInstitution = null;

  vm.levels = Constants.institutionLevels;
  vm.sports = [];
  vm.notificationInfo = false;

  vm.save = save;
  vm.startEdit = startEdit;
  vm.cancelEdit = cancelEdit;
  vm.remove = remove;
  vm.closeDetail = closeDetail;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.isNewIntsitution = $stateParams.institutionId === 'new';

    organisationService.get($stateParams.id).then(organisation => {
      vm.organisation = organisation;

      vm.sports = lodash.map(vm.organisation.sports, sport => {
        sport.displayName = swoaTranslatableFilter(sport.translatedName, 'de');
        return sport;
      });
      vm.sports = lodash.sortBy(
        lodash.filter(vm.sports, sport => sport.active),
        'displayName'
      );

      if (vm.isNewIntsitution) {
        vm.institution = {
          sports: [],
          address: {
            country: 'CH'
          }
        };
        vm.editing = true;
      } else {
        findInstitution(organisation);
        if (vm.institution.sports && vm.institution.sports.length > 0) {
          vm.institution.sports = lodash.map(vm.institution.sports, iSport =>
            lodash.find(vm.sports, oSport => iSport.id === oSport.id)
          );
        }
        if (!vm.institution.address) {
          vm.institution.address = {
            country: 'CH'
          };
        }
      }
    }, onError);
  }

  function save(valid) {
    vm.notificationKey = null;
    vm.submitted = true;

    if (!valid) {
      vm.notificationKey = 'form.invalid';
      return;
    }

    organisationService
      .persistInstitution(vm.organisation, vm.institution)
      .then(onSaveSuccess, onError);
  }

  function onSaveSuccess(institution) {
    dialogService.showSuccessToast('nwfInstitution.save.done');

    if (vm.isNewIntsitution) {
      $rootScope.$broadcast('swoa:institutionAdded', institution);
      closeDetail();
    } else {
      $rootScope.$broadcast('swoa:institutionUpdated', institution);
      vm.editing = false;
      originalInstitution = null;
      activate();
    }
  }

  function remove() {
    dialogService
      .showConfirmationDialog('nwfInstitution.delete.confirmation')
      .then(() => {
        organisationService
          .removeInstitution(vm.organisation, vm.institution)
          .then(onDeleteSuccess)
          .catch(error => {
            if (
              error &&
              error.data &&
              error.data.errors &&
              error.data.errors[0].messageKey === 'error.institution.inUse'
            ) {
              vm.institution.active = false;
              vm.editing = false;
              $rootScope.$broadcast('swoa:institutionUpdated', vm.institution);
              onError(error);
              vm.notificationInfo = true;
            } else {
              onError(error);
            }
          });
      });
  }

  function onDeleteSuccess() {
    $rootScope.$broadcast('swoa:institutionDeleted', vm.institution);
    closeDetail();
  }

  function startEdit() {
    vm.editing = true;
    originalInstitution = Restangular.copy(vm.institution);
  }

  function cancelEdit() {
    if (vm.editing) {
      if (vm.isNewIntsitution) {
        closeDetail();
      } else {
        vm.editing = false;
        vm.institution = originalInstitution;
        originalInstitution = null;
      }
    }
  }

  function closeDetail() {
    $state.go('organisationProfile');
  }

  function findInstitution(organisation) {
    lodash.forEach(organisation.nwfInstitutions, institution => {
      if (institution.id === parseInt($stateParams.institutionId, 10)) {
        vm.institution = institution;
      }
    });
  }
}
