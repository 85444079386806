import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaPersonService from '../person.service';
import swoaPersonSyncService from '../sync/person-sync.service';
import swoaPersonPersonMinimal from '../person-minimal.component';
import swoaObjectProperty from '../../components/object-property/object-property.filter';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './person-creation-detail.html';

export default angular
  .module('swoa.person.creation.person-creation-detail', [
    uiRouter,
    swoaPersonService,
    swoaPersonSyncService,
    swoaPersonPersonMinimal,
    swoaObjectProperty,
    swoaNotification
  ])
  .component('swoaPersonCreationDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: PersonCreationDetailController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonCreationDetailController(
  $state,
  $stateParams,
  personService,
  personSyncService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.personId = $stateParams.personId;
  vm.person = null;
  vm.config = null;

  vm.closeDetail = closeDetail;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.config = angular.copy(personSyncService.SYNC_CONFIGURATION);

    personService.loadNewPerson(vm.personId).then(personCreation => {
      vm.person = personCreation.person;
      vm.person.configKey = 'personKey';
    }, onError);
  }

  function closeDetail() {
    $state.go('personMutations');
  }
}
