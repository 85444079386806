import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import htmlTemplate from './organisation-search-details-table.html';

export default angular
  .module('swoa.organisation.organisation-search-details-table', [
    swoaConstants,
    swoaTranslatable
  ])
  .component('swoaOrganisationSearchDetailsTable', {
    template: htmlTemplate,
    bindings: {
      organisationDetailList: '<',
      selectedOrganisation: '=',
      withoutSelection: '<'
    },
    controller: OrganisationSearchDetailsTableController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function OrganisationSearchDetailsTableController(
  $scope,
  $translate,
  lodash,
  Constants,
  swoaTranslatableFilter
) {
  const vm = this,
    categoryToTypeMap = {};

  vm.order = 'displayName';

  vm.rowClicked = rowClicked;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    $scope.$watch('vm.organisationDetailList', resetTable);
    Constants.organisationTypes.forEach(type =>
      type.categories.forEach(
        category => (categoryToTypeMap[category] = type.type)
      )
    );
  }

  function resetTable() {
    vm.page = 1;
    if (!vm.withoutSelection) {
      vm.selectedOrganisation = null;
    }
    lodash.forEach(vm.organisationDetailList, organisation => {
      organisation.displayName = swoaTranslatableFilter(
        organisation.name,
        'de'
      );
      organisation.typeList = lodash.uniq(
        organisation.categories.map(category => categoryToTypeMap[category])
      );
      organisation.typeList = organisation.typeList
        .map(type => $translate.instant(`organisation.type.${type}`))
        .join(', ');
      organisation.categoryList = organisation.categories
        .map(category =>
          $translate.instant(`organisation.category.${category}`)
        )
        .join(', ');
    });
  }

  function rowClicked(event, organisation) {
    if (!vm.withoutSelection) {
      event.preventDefault();
      event.stopPropagation();
      vm.selectedOrganisation = organisation.id;
    }
  }
}
