import angular from 'angular';
import htmlTemplate from './my-sports.html';
import angularTranslate from 'angular-translate';
import swoaUserService from '../user/user.service';

export default angular
  .module('swoa.admin.my-sports', [angularTranslate, swoaUserService])
  .component('swoaMySports', {
    template: htmlTemplate,
    bindings: {
      onSave: '&',
    },
    controller: MySportsController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function MySportsController($scope, $q, $translate, $window, userService) {
  const vm = this;

  vm.$onInit = () => {
    vm.language = $translate.use() || $translate.proposedLanguage();
  };

  vm.$onDestroy = () => {
    $window.removeEventListener('message', handleIncomingMessage);
  };

  $window.addEventListener('message', handleIncomingMessage);

  function handleIncomingMessage({ data }) {
    switch (data.type) {
      case 'ui-config-changed': {
        userService.getUiConfig().then((response) => {
          userService.getCurrentUser().uiConfig = response;
          vm.onSave({ $sports: JSON.parse(response.jsonString).sports });
        });
      }
    }
  }
}
