import angular from 'angular';

export default angular
  .module('swoa.card.card-import.card-import-state', [])
  .factory('cardImportStateService', cardImportStateService)
  .name;

/** @ngInject */
function cardImportStateService() {
  let parentVm;

  const service = {
    registerParentVm,
    setImportedCards,
    setName,
  };

  return service;

  // //////////

  function registerParentVm(vm) {
    parentVm = vm;
  }

  function setImportedCards(importedCards) {
    if (parentVm) {
      parentVm.importedCards = importedCards;
    }
  }

  function setName(forename, surname) {
    if (parentVm) {
      parentVm.forename = forename;
      parentVm.surname = surname;
    }
  }
}
