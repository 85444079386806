import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaUserService from '../../user/user.service';
import swoaPersonService from '../../person/person.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaChangelogService from './change-log.service';
import htmlTemplate from './change-log.html';
import { DATE_FORMAT_DATE_ONLY } from '../date/date.filter';
import { DATE_FORMAT_JSON } from '../input-date/input-date.component';

export default angular
  .module('swoa.change-log.change-log', [
    angularTranslate,
    swoaConstants,
    swoaNotification,
    swoaUserService,
    swoaPersonService,
    swoaChangelogService
  ])
  .component('swoaChangeLog', {
    template: htmlTemplate,
    bindings: {
      changeLogs: '<',
      logType: '<',
      auditEntityId: '<'
    },
    controller: ChangeLogController,
    controllerAs: 'vm'
  }).name;

export const EMPTY_VALUE = ' - ';

/** @ngInject */
function ChangeLogController(
  $scope,
  $translate,
  moment,
  lodash,
  $mdMedia,
  Constants,
  changeLogService,
  swoaDateFilter,
  userService,
  personService,
  swoaTranslatableFilter,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.logs = [];
  vm.order = '-changedOn';
  vm.diffMode = false;

  vm.toggleEntry = toggleEntry;
  vm.hasNoAttributes = hasNoAttributes;
  vm.changeDiffMode = changeDiffMode;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.userPerson = userService.getCurrentUser().person;
    personService
      .loadCards(vm.userPerson.id)
      .then(cards => personService.extendPersonModel(vm.userPerson, cards))
      .then(() => (vm.logs = getGroupedChangeLog(vm.changeLogs)))
      .catch(onError);
    $scope.$watch(
      () => $mdMedia('xs'),
      isVerySmall => (vm.isVerySmall = isVerySmall)
    );
    $scope.$watch(
      () => $mdMedia('sm'),
      isSmall => (vm.isSmall = isSmall)
    );
  }

  function getGroupedChangeLog(original) {
    const changeLogs = lodash
      .chain(angular.copy(original))
      .map(changeLog => getDecoratedChangeLog(changeLog))
      .filter(changeLog => changeLog.groupingKey !== null)
      .value();

    // group decorated logs by date and activity
    const groupedChangeLogs = lodash.groupBy(changeLogs, changeLog => {
      const dateTruncated = swoaDateFilter(
        changeLog.changedOn,
        DATE_FORMAT_JSON
      );
      return `${dateTruncated}|${changeLog.groupingKey}`;
    });

    // create sortable array
    return lodash.map(groupedChangeLogs, (group, key) => {
      const delimIndex = key.indexOf('|');
      const activityKey = key.substring(delimIndex + 1);
      return {
        changedOn: key.substring(0, delimIndex),
        activityKey,
        activityKeyTranslated: $translate.instant(
          `changeLog.${vm.logType}.${activityKey}`
        ),
        entries: lodash.orderBy(group, ['changedOn'], ['desc'])
      };
    });
  }

  function getDecoratedChangeLog(changeLog) {
    changeLog.decoratedUser = getDecoratedUser(changeLog.user);
    changeLog.decoratedUserType = getDecoratedUserType(changeLog.user);
    changeLog.decoratedNonAttributeChange = getDecoratedNonAttributeChange(
      changeLog
    );
    changeLog.groupingKey = changeLogService.buildGroupingKey(
      vm.logType,
      changeLog
    );

    if (!hasNoAttributes(changeLog)) {
      changeLog.attributes.forEach(att => {
        att.decoratedName = translateAttributeName(
          changeLog.entityName,
          changeLog.entityTranslatableElement,
          att.name
        );
        if (att.oldValue || att.newValue) {
          att.decoratedChange = {
            oldValue: getValue(
              dotJoinUniqueNotNullElements([changeLog.entityName, att.name]),
              att.oldValue
            ),
            newValue: getValue(
              dotJoinUniqueNotNullElements([changeLog.entityName, att.name]),
              att.newValue
            ),
            diffModeAvailable:
              lodash.isArray(att.oldValue) || lodash.isArray(att.newValue)
          };
          if (att.decoratedChange.diffModeAvailable) {
            const diff = lodash.xorBy(att.oldValue, att.newValue, 'id');
            att.diffedChange = {
              oldValue: `[...] ${translateAttributeList(
                lodash.intersectionBy(att.oldValue, diff, 'id')
              )}`,
              newValue: `[...] ${translateAttributeList(
                lodash.intersectionBy(att.newValue, diff, 'id')
              )}`
            };
          }
        }
      });

      changeLog.attributes = lodash.filter(changeLog.attributes, attr =>
        changeLogService.canSeeAttribute(
          vm.auditEntityId,
          vm.logType,
          changeLog.entityName,
          attr.name
        )
      );

      // if there is no more attribute left, set groupingKey to null so it will be filtered in the next step
      if (changeLog.attributes.length === 0) {
        changeLog.groupingKey = null;
      }
    } else {
      changeLog.decoratedActivity = translateActivity(changeLog);
    }
    return changeLog;
  }

  function getValue(name, value) {
    if (
      !lodash.isBoolean(value) &&
      !lodash.isNumber(value) &&
      lodash.isEmpty(value)
    ) {
      return EMPTY_VALUE;
    } else if (lodash.isArray(value)) {
      return translateAttributeList(value);
    } else if (
      value &&
      lodash.some(Constants.organisationTypes, type => !!value[type.type])
    ) {
      return lodash
        .filter(Constants.organisationTypes, type => !!value[type.type])
        .map(type => {
          const translatedType = $translate.instant(
            `organisation.type.${type.type}`
          );
          const translatedName = swoaTranslatableFilter(
            value[type.type].name,
            'de'
          );
          return `${translatedType}: ${translatedName}`;
        })
        .join(', ');
    }
    const isPassword = name === 'user.password';
    return isPassword ? '*' : translateValue(name, value);
  }

  function translateAttributeList(arr) {
    return arr
      .map(v =>
        v.translatedName
          ? swoaTranslatableFilter(v.translatedName, 'de')
          : v.name
      )
      .join(', ');
  }

  function getDecoratedUser(user) {
    if (!user.person) {
      return user.login;
    }
    const decoratedUser = [user.person.surname, user.person.forename];
    return decoratedUser.join(' ');
  }

  function getDecoratedNonAttributeChange(changeLog) {
    if (changeLog.fileName) {
      return changeLog.fileName;
    }
    if (changeLog.mailType) {
      return $translate.instant(`mailType.${changeLog.mailType}`);
    }
    return null;
  }

  function getDecoratedUserType(user) {
    if (lodash.some(user.roles, role => role.roleType === 'SWOA')) {
      return $translate.instant('user.type.SWOA');
    } else if (
      lodash.some(user.roles, role => role.roleType === 'ASSOCIATION')
    ) {
      if (user.organisations && user.organisations.ASSOCIATION) {
        return swoaTranslatableFilter(
          user.organisations.ASSOCIATION.name,
          'de'
        );
      }
    }
    return null;
  }

  function translateActivity(changeLog) {
    if (changeLog.mailType || changeLog.fileName) {
      return $translate.instant(`changeLog.${changeLog.activity}`);
    }

    return `${translateAttributeName(
      changeLog.entityName
    )}: ${$translate.instant(`changeLog.${changeLog.activity}`)}`;
  }

  function translateValue(name, value) {
    if (isBooleanValue(value)) {
      return $translate.instant(buildKeyName(name, value));
    }

    if (!value) {
      return EMPTY_VALUE;
    }

    if (value.toString().match(/\d{4}-\d{2}-\d{2}/)) {
      return moment(value, DATE_FORMAT_JSON).format(DATE_FORMAT_DATE_ONLY);
    }

    if (name && name.indexOf('seasonStartMonth') >= 0) {
      return $translate.instant(
        lodash.find(Constants.months, ['id', value]).month
      );
    }

    if (angular.isString(value)) {
      const keyName = buildKeyName(name, value);
      const translation = $translate.instant(keyName);
      if (translation === keyName) {
        return value;
      }
      return translation;
    }

    return swoaTranslatableFilter(value, 'de');
  }

  function dotJoinUniqueNotNullElements(elements) {
    if (elements) {
      return lodash
        .uniq(elements.filter(e => e !== null && e !== undefined))
        .join('.');
    }
    return null;
  }

  function translateAttributeName(entityName, entityTranslatableElement, name) {
    const attributeName = ['changeLog', vm.logType, entityName, name];
    const translatedName = $translate.instant(
      dotJoinUniqueNotNullElements(attributeName)
    );
    return entityTranslatableElement
      ? `${translatedName} (${swoaTranslatableFilter(
          entityTranslatableElement,
          'de'
        )})`
      : translatedName;
  }

  function toggleEntry(changeLog) {
    changeLog.open = !changeLog.open;
  }

  function hasNoAttributes(changeLog) {
    return !changeLog.attributes || changeLog.attributes.length === 0;
  }

  function isBooleanValue(value) {
    return value === true || value === false || value instanceof Boolean;
  }

  function buildKeyName(name, value) {
    const elements = [vm.logType].concat(name.split('.')).concat(value);
    return dotJoinUniqueNotNullElements(elements);
  }

  function changeDiffMode() {
    vm.diffMode = !vm.diffMode;
  }
}
