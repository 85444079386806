import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaCardSportYearService from './sport-year.service';
import swoaFileService from '../../components/file/file.service';
import swoaAsyncService from '../../components/async/async-helper.service';
import htmlTemplate from './sport-year-print.html';

export default angular
  .module('swoa.card.sport-year.sport-year-print', [
    uiRouter,
    swoaConstants,
    swoaNotification,
    swoaCardSportYearService,
    swoaFileService,
    swoaAsyncService,
  ])
  .component('swoaSportYearPrint', {
    template: htmlTemplate,
    bindings: {},
    controller: SportYearPrintController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function SportYearPrintController(
  $http,
  $scope,
  $timeout,
  $stateParams,
  $filter,
  lodash,
  ConfigConstants,
  notificationService,
  sportYearService,
  fileService,
  asyncHelperService,
  dialogService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      downloadPdf
    );

  // state params
  vm.params = $stateParams;
  vm.yearId = $stateParams.yearId;

  vm.sportYear = null;
  vm.cardTypes = null;
  vm.selectedCards = {};
  vm.selectedCardsCount = 0;
  vm.printedCardsCount = 0;
  vm.selectionParams = {};
  vm.highlightSelected = false;
  vm.canBeUpdatedToClosed = false;
  vm.order = '[person.surname,person.forename]';
  vm.limit = 100;
  vm.availableLanguages = ConfigConstants.translation.availableLanguagesEnum;

  // error handling
  vm.notificationKey = null;
  vm.notificationValues = null;
  vm.isSuccess = false;

  vm.toggleCard = toggleCard;
  vm.applySelection = applySelection;
  vm.selectAll = selectAll;
  vm.printCards = printCards;
  vm.createDigitalCards = createDigitalCards;
  vm.updateStatusToClosed = updateStatusToClosed;
  vm.cardPrintable = cardPrintable;
  vm.onPaginate = onPaginate;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    loadCards();
    $scope.$watchCollection('vm.selectedCards', updateSelectedCardsCount);
    asyncHelper.getAsyncJobStatus();
  }

  function loadCards() {
    vm.selectedCards = {};
    asyncHelper.showSpinner();

    return sportYearService
      .loadSportYearDetailsWithAddressFlag(vm.yearId)
      .then((sportYear) => {
        vm.sportYear = sportYear;
        vm.cardTypes = sportYear.cardTypeSummaries.map((card) => card.type);

        initSelectionParams();
        updatePrintedCardsCount();
        vm.canBeUpdatedToClosed =
          vm.sportYear.status === 'RELEASED' &&
          isEmptyOrContainsOnlyEmptyCards(vm.sportYear);

        $timeout(() => {
          onPaginate(vm.page, vm.limit);
          applySelection();
        });
      }, onError)
      .finally(asyncHelper.hideSpinner);
  }

  function onPaginate(page, limit) {
    if (!vm.filteredCards) {
      return;
    }

    const displayedCards = $filter('orderBy')(vm.filteredCards, vm.order);
    const cards = lodash.filter(
      displayedCards,
      (card, idx) =>
        idx >= (page - 1) * limit &&
        // eslint-disable-next-line no-mixed-operators
        idx < (page - 1) * limit + limit &&
        !card.warning
    );

    if (cards.length > 0) {
      const cardIds = lodash.map(cards, (card) => card.id);

      // load the card warnings for the cards that are currently displayed and don't have the warnings loaded yet
      sportYearService
        .loadSportYearCardWarnings(vm.yearId, { cards: cardIds })
        .then((warnings) => {
          lodash.forEach(warnings, (warning) => {
            const card = lodash.find(vm.sportYear.cards, { id: warning.id });
            if (card) {
              card.warning = warning;
            }
          });
        });
    }
  }

  function isEmptyOrContainsOnlyEmptyCards(sportYear) {
    return sportYear.cards.length === 0 || containsOnlyEmptyCards(sportYear);
  }

  function containsOnlyEmptyCards(sportYear) {
    return (
      sportYear.cards.filter((card) => card.status !== 'EMPTY').length === 0
    );
  }

  function toggleCard(card) {
    if (cardPrintable(card)) {
      vm.selectedCards[card.id] = !vm.selectedCards[card.id];
    }
  }

  function applySelection() {
    const noType = vm.cardTypes.every(
      (type) => !vm.selectionParams[`cardType_${type}`]
    );
    const noLang = vm.availableLanguages.every(
      (lang) => !vm.selectionParams[`language_${lang}`]
    );
    const noPrinted =
      !vm.selectionParams.printed_true && !vm.selectionParams.printed_false;

    const noDigitalCard =
      !vm.selectionParams.digitalCard_true &&
      !vm.selectionParams.digitalCard_false;
    vm.sportYear.cards.forEach((card) => {
      vm.selectedCards[card.id] =
        (!noType || !noPrinted || !noLang) &&
        cardPrintable(card) &&
        (noType || vm.selectionParams[`cardType_${card.type}`]) &&
        (noLang || vm.selectionParams[`language_${card.person.language}`]) &&
        (noPrinted ||
          (vm.selectionParams.printed_true && card.printed) ||
          (vm.selectionParams.printed_false && !card.printed)) &&
        (noDigitalCard ||
          (vm.selectionParams.digitalCard_true && card.digitalCard !== null) ||
          (vm.selectionParams.digitalCard_false && card.digitalCard === null));
    });
    highlightSelected();
  }

  function selectAll() {
    initSelectionParams();
    applySelection();
  }

  function cardPrintable(card) {
    return (
      card.status !== 'EMPTY' && card.active !== false && card.hasPersonAddress
    );
  }

  function printCards() {
    const cardIds = getSelectedCardKeys();
    const url = `${ConfigConstants.api.url}/sportyears/${vm.yearId}/print`;
    asyncHelper.showSpinner();
    const config = {
      params: { fileName: `cards_${vm.sportYear.category}.zip` },
    };

    $http
      .post(url, cardIds, config)
      .then(asyncHelper.getAsyncJobStatus)
      .catch(asyncHelper.errorHandler);
  }

  function createDigitalCards() {
    const cardIds = getSelectedCardKeys();
    dialogService
      .showConfirmationDialog('card.digitalCard.confirmation')
      .then(() => {
        asyncHelper.showSpinner();
        sportYearService
          .createDigitalCards(vm.yearId, cardIds)
          .then(digitalCardCreationSucceeded, onError);
      });
  }

  function digitalCardCreationSucceeded(cardholderWithoutEmail) {
    if (cardholderWithoutEmail.length > 0) {
      vm.notificationKey = 'cardholder.missing.email';
      vm.notificationValues = {
        persons: cardholderWithoutEmail
          .map((cardholder) => `${cardholder.surname} ${cardholder.forename}`)
          .join(', '),
      };
      vm.isSuccess = false;
    } else {
      vm.isSuccess = true;
      vm.notificationKey = 'sportyear.digitalCard.creation.success';
    }
    return loadCards();
  }

  function updateStatusToClosed() {
    notificationService.resetErrors(vm);
    return sportYearService
      .goToStatus(vm.yearId, 'CLOSED')
      .then(statusSuccessChanged, onError);
  }

  function statusSuccessChanged() {
    dialogService.showSuccessToast('sportYear.status.change.success');
    return loadCards();
  }

  function downloadPdf(resultUrl, result, options) {
    return fileService.download(resultUrl, result.resultHint, options);
  }

  function initSelectionParams() {
    vm.sportYear.cardTypeSummaries.forEach((card) => {
      vm.selectionParams[`cardType_${card.type}`] = true;
    });
    vm.availableLanguages.forEach((lang) => {
      vm.selectionParams[`language_${lang}`] = true;
    });
    vm.selectionParams.printed_true = false;
    vm.selectionParams.printed_false = true;

    vm.selectionParams.digitalCard_true = false;
    vm.selectionParams.digitalCard_false = true;
  }

  function updateSelectedCardsCount() {
    vm.selectedCardsCount = getSelectedCardKeys().length;
  }

  function getSelectedCardKeys() {
    return Object.keys(vm.selectedCards).filter((key) => vm.selectedCards[key]);
  }

  function updatePrintedCardsCount() {
    vm.printedCardsCount = vm.sportYear.cards.filter(
      (card) => card.printed
    ).length;
  }

  function highlightSelected() {
    vm.highlightSelected = true;
    $timeout(() => {
      vm.highlightSelected = false;
    }, 1000);
  }
}
