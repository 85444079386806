import angular from 'angular';

export default angular
  .module('swoa.admin.ui-kitchensink.compile', [])
  .directive('compile', compileDirective)
  .name;

/** @ngInject */
function compileDirective($compile) {
  return (scope, element, attrs) => {
    scope.$watch(
      watchScope => watchScope.$eval(attrs.compile),
      (value) => {
        element.html(value);
        $compile(element.contents())(scope);
      },
    );
  };
}
