import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import htmlTemplate from './input-item-selector.html';

export default angular
  .module('swoa.input-item-selector', [swoaConstants, swoaTranslatable])
  .component('swoaInputItemSelector', {
    template: htmlTemplate,
    bindings: {
      ngModel: '=',
      availableItems: '<',
      areTranslatableElements: '<',
      itemTranslatePrefix: '@',
      leftTitleKey: '@',
      rightTitleKey: '@'
    },
    controller: InputItemSelectorController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function InputItemSelectorController(
  $scope,
  lodash,
  translateFilter,
  swoaTranslatableFilter
) {
  const vm = this;

  vm.leftSide = angular.copy(vm.availableItems);
  vm.sortableOptions = {
    containment: '#containmentArea',
    containerPositioning: 'relative'
  };
  vm.selectedLeft = [];
  vm.selectedRight = [];

  vm.moveRight = moveRight;
  vm.moveLeft = moveLeft;
  vm.addAll = addAll;
  vm.removeAll = removeAll;
  vm.selectLeft = selectLeft;
  vm.selectRight = selectRight;
  vm.isSelectedLeft = isSelectedLeft;
  vm.isSelectedRight = isSelectedRight;

  vm.$onChanges = updateLeftSide;
  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.availableItems', updateLeftSide, true);
  }

  function updateLeftSide() {
    vm.leftSide = angular.copy(vm.availableItems);
    sortInline(vm.leftSide);
    if (vm.leftSide && vm.leftSide.length > 0) {
      vm.leftSide = vm.leftSide.filter(
        item =>
          !lodash.find(
            vm.ngModel,
            attr => attr.technicalName === item.technicalName
          )
      );
      sortInline(vm.leftSide);
    }
  }

  function moveRight(item) {
    vm.ngModel.push(item);
    vm.leftSide.splice(vm.leftSide.indexOf(item), 1);
    sortInline(vm.leftSide);
  }

  function moveLeft(item) {
    vm.leftSide.unshift(item);
    sortInline(vm.leftSide);
    vm.ngModel.splice(vm.ngModel.indexOf(item), 1);
  }

  function addAll() {
    if (vm.selectedLeft.length > 0) {
      vm.ngModel = lodash.union(vm.ngModel, vm.selectedLeft);
      vm.leftSide = lodash.pullAll(vm.leftSide, vm.selectedLeft);
      sortInline(vm.leftSide);
      vm.selectedLeft = [];
    } else {
      vm.ngModel = angular.copy(vm.availableItems);
      vm.leftSide = [];
    }
  }

  function removeAll() {
    if (vm.selectedRight.length > 0) {
      vm.leftSide = lodash.union(vm.leftSide, vm.selectedRight);
      sortInline(vm.leftSide);
      vm.ngModel = lodash.pullAll(vm.ngModel, vm.selectedRight);
      vm.selectedRight = [];
    } else {
      vm.ngModel = [];
      vm.leftSide = angular.copy(vm.availableItems);
    }
  }

  function selectLeft(item) {
    vm.selectedLeft = lodash.xor(vm.selectedLeft, [item]);
  }

  function selectRight(item) {
    vm.selectedRight = lodash.xor(vm.selectedRight, [item]);
  }

  function isSelectedLeft(item) {
    return lodash.findIndex(vm.selectedLeft, item) >= 0;
  }

  function isSelectedRight(item) {
    return lodash.findIndex(vm.selectedRight, item) >= 0;
  }

  function sortInline(arr) {
    arr.sort((a, b) => {
      let textA = translateFilter(vm.itemTranslatePrefix || '') + a;
      let textB = translateFilter(vm.itemTranslatePrefix || '') + b;
      if (vm.areTranslatableElements) {
        textA = swoaTranslatableFilter(a);
        textB = swoaTranslatableFilter(b);
      }
      return textA.toLowerCase().localeCompare(textB.toLowerCase());
    });
  }
}
