import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaCardSportYearService from '../sport-year/sport-year.service';
import swoaDate from '../../components/date/date.filter';
import swoaCardCardImportCardImportState from './card-import-state.service';
import swoaPersonPersonMinimal from '../../person/person-minimal.component';
import htmlTemplate from './card-import.html';

export default angular
  .module('swoa.card.card-import.card-import', [
    uiRouter,
    swoaCardSportYearService,
    swoaDate,
    swoaCardCardImportCardImportState,
    swoaPersonPersonMinimal
  ])
  .component('swoaCardImport', {
    template: htmlTemplate,
    bindings: {},
    controller: CardImportController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardImportController(
  $scope,
  $timeout,
  $stateParams,
  $state,
  $transitions,
  sportYearService,
  cardImportStateService
) {
  const vm = this;

  vm.sportId = $stateParams.sportId;
  vm.yearId = $stateParams.yearId;
  vm.stateName = $state.current.name;
  vm.sportYear = null;
  vm.importedCards = null;
  vm.autoMatchAllowed = false;
  vm.cancelAllowed = false;

  vm.autoMatch = autoMatch;
  vm.cancel = cancel;

  // expose for tests only
  vm._onStateChangeSuccess = onStateChangeSuccess;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    cardImportStateService.registerParentVm(vm);

    sportYearService.loadSportYearDetails(vm.yearId).then(sportYear => {
      vm.sportYear = sportYear;
      $timeout(() => {
        $scope.$broadcast('swoa:cardImportMatchAgain');
      });
    });

    const unregisterAutoMatchAllowed = $scope.$on(
      'swoa:cardImportAutoMatchAllowed',
      (event, allowed) => {
        vm.autoMatchAllowed = allowed;
      }
    );

    const unregisterCancelAllowed = $scope.$on(
      'swoa:cardImportCancelAllowed',
      (event, allowed) => {
        vm.cancelAllowed = allowed;
      }
    );

    const unregisterStateChangeSuccess = $transitions.onSuccess(
      {},
      onStateChangeSuccess
    );

    $scope.$on('$destroy', () => {
      unregisterAutoMatchAllowed();
      unregisterCancelAllowed();
      unregisterStateChangeSuccess();
    });
  }

  function autoMatch() {
    $scope.$broadcast('swoa:cardImportAutoMatch');
  }

  function cancel() {
    $scope.$broadcast('swoa:cardImportCancel');
  }

  // Private helpers

  function onStateChangeSuccess() {
    vm.stateName = $state.current.name;

    if ($state.current.name !== 'cardImport.overview') {
      vm.autoMatchAllowed = false;
      vm.cancelAllowed = false;
    }
  }
}
