import angular from 'angular';
import ngMaterial from 'angular-material';
import swoaConstants from '../../app.constants';
import swoaFileService from '../file/file.service';
import htmlTemplate from './sidenav.html';

export default angular
  .module('swoa.sidenav', [ngMaterial, swoaConstants, swoaFileService])
  .component('swoaSidenav', {
    template: htmlTemplate,
    bindings: {},
    controller: SidenavController,
    controllerAs: 'vm',
  }).name;

const NAVIGATION_ITEMS = [
  {
    stateName: 'cockpit',
    customName: 'home',
    icon: 'home',
    requirePermission: ['token_r'],
  },
  {
    stateName: 'misc',
    requireAnyPermission: [
      'person_search_r',
      'admin_organisation_w',
      'admin_sport_r',
      'admin_reports_r',
      'admin_exports_cards_r',
      'admin_exports_sports_r',
      'admin_exports_users_r',
    ],
    childItems: [
      {
        stateName: 'search.person',
        requirePermission: ['person_search_r'],
        icon: 'person',
      },
      {
        stateName: 'organisationSearch',
        requirePermission: ['admin_organisation_r'],
        icon: 'domain',
      },
      {
        stateName: 'sportManager',
        requirePermission: ['admin_sport_r'],
        icon: 'directions_run',
      },
      {
        stateName: 'report',
        requireAnyPermission: [
          'admin_reports_r',
          'admin_exports_cards_r',
          'admin_exports_sports_r',
          'admin_exports_users_r',
          'admin_exports_nwf_base_amount_r',
          'admin_exports_nwf_variable_amount_r',
          'admin_exports_nwf_trainer_r',
        ],
        icon: 'report',
      },
    ],
  },
  {
    stateName: 'cards',
    requireAnyPermission: [
      'cardlist_r_elite',
      'cardlist_r_talent',
      'cardlist_r_trainer',
      'cardlist_r_technician',
      'cardlist_r_functionary',
    ],
    childItems: [
      {
        stateName: 'cardSportList',
        icon: 'card',
        requireAnyPermission: [
          'cardlist_r_elite',
          'cardlist_r_talent',
          'cardlist_r_trainer',
        ],
      },
      {
        stateName: 'cardsOrganisationList',
        icon: 'card_run',
        requirePermission: ['cardlist_r_technician'],
      },
      {
        stateName: 'functionaryCards',
        icon: 'card_admin',
        requirePermission: ['cardlist_r_functionary'],
      },
    ],
  },
  {
    stateName: 'contribution',
    requireAnyPermission: ['nwf_contribution_navigation_r'],
    childItems: [
      {
        stateName: 'nwfContribution',
        icon: 'euro',
        requirePermission: ['nwf_contribution_r'],
      },
    ],
  },
  {
    stateName: 'administration',
    requireAnyPermission: [
      'admin_personmutation_r',
      'admin_user_r',
      'admin_role_r',
      'admin_news_r',
      'admin_translation_r',
      'admin_cardparameter_r',
      'admin_nwf_contribution_r',
      'admin_mysports_r',
      'admin_jobs_r',
      'admin_kitchensink_r',
    ],
    childItems: [
      {
        stateName: 'personMutations',
        requirePermission: ['admin_personmutation_r'],
        icon: 'file_check',
      },
      {
        stateName: 'userList',
        requirePermission: ['admin_user_r'],
        icon: 'group',
      },
      {
        stateName: 'roleList',
        requirePermission: ['admin_role_r'],
        icon: 'assignment_ind',
      },
      {
        stateName: 'newsList',
        requirePermission: ['admin_news_r'],
        icon: 'announcement',
      },
      {
        stateName: 'translations',
        requirePermission: ['admin_translation_r'],
        icon: 'translate',
      },
      {
        stateName: 'cardParameter',
        requirePermission: ['admin_cardparameter_r'],
        icon: 'card_settings',
      },
      {
        stateName: 'nwfParameter',
        requirePermission: ['admin_nwf_contribution_r'],
        icon: 'euro',
      },
      {
        stateName: 'mySports',
        requirePermission: ['admin_mysports_r'],
        icon: 'tag_multiple',
      },
      {
        stateName: 'personImport',
        requirePermission: ['admin_jobs_r'],
        icon: 'import_export',
      },
      {
        stateName: 'jobsList',
        requirePermission: ['admin_jobs_r'],
        icon: 'jobs',
      },
      {
        stateName: 'uiKitchensink',
        requirePermission: ['admin_kitchensink_r'],
        icon: 'assignment',
      },
    ],
  },
];

/** @ngInject */
function SidenavController(
  $transitions,
  $scope,
  $mdSidenav,
  lodash,
  ngUserAuthInfoService,
  fileService
) {
  const vm = this;

  // UI data
  vm.navigationItems = NAVIGATION_ITEMS;

  // functions
  vm.closeSidenav = closeSidenav;
  vm.checkItem = checkItem;
  vm.downloadHelpFile = downloadHelpFile;
  vm.getRoleTypes = getRoleTypes;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function closeSidenav() {
    $mdSidenav('left').close();
  }

  function activate() {
    const unregisterFn = $transitions.onSuccess({}, () => {
      vm.closeSidenav();
    });

    $scope.$on('$destroy', unregisterFn);
  }

  function checkItem(item) {
    return ngUserAuthInfoService.checkPermissions(
      item.requirePermission,
      item.requireAnyPermission,
      item.lackPermission
    );
  }

  function getRoleTypes() {
    return lodash
      .chain((ngUserAuthInfoService.getUser() || {}).roles)
      .map((role) => role.roleType)
      .uniq()
      .filter((role) => role !== 'PERSON')
      .value();
  }

  function downloadHelpFile(language, type) {
    const helpFile = {
      category: 'HELP',
      fileName: `help_${type}_${language}.pdf`,
    };
    return fileService.downloadPersistentStorage(helpFile);
  }
}
