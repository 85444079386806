import angular from 'angular';
import swoaConstants from '../app.constants';

export default angular
  .module('swoa.properties.service', [swoaConstants])
  .factory('propertiesService', propertiesService).name;

/** @ngInject */
function propertiesService(Restangular) {
  return { getProperties };

  // /////////////////

  function getProperties() {
    return Restangular.all('properties')
      .withHttpConfig({ noCancelOnRouteChange: true })
      .customGET();
  }
}
