import angular from 'angular';
import 'restangular';
import swoaConstants from '../app.constants';
import swoaUserService from '../user/user.service';

export default angular
  .module('swoa.card.service', ['restangular', swoaConstants, swoaUserService])
  .factory('cardService', cardService).name;

/** @ngInject */
function cardService($q, moment, lodash, Restangular) {
  const baseCards = Restangular.all('cards');

  // extend the cards history to calculate some shortcuts
  Restangular.extendModel('history', extendHistoryModel);

  return {
    activateCard,
    loadCard,
    loadCardHistory,
    findCard,
    addComment,
    deleteComment,
    changeCardType,
    changeCardFtem,
    changeCardStatus,
    updateCardResult,
    updateResponsibilities,
    updateOrganisation,
    removeCard,
    purgeCard,
    getChangeLog,
    isStaffCategory
  };

  // //////////

  function loadCard(id) {
    return baseCards.get(id);
  }

  function loadCardHistory(cardId) {
    return Restangular.one('cards', cardId).getList('history');
  }

  function findCard(collection, cardId) {
    return lodash.find(collection, { id: parseInt(cardId, 10) });
  }

  function addComment(cardId, comment) {
    return Restangular.one('cards', cardId).customPOST(comment, 'comments');
  }

  function deleteComment(cardId, commentId) {
    return Restangular.one('cards', cardId)
      .one('comments', commentId)
      .remove();
  }

  function changeCardType(cardId, cardType) {
    return Restangular.one('cards', cardId).customPUT(cardType, 'type');
  }

  function changeCardFtem(cardId, ftem) {
    return Restangular.one('cards', cardId).customPUT(ftem, 'ftem');
  }

  function changeCardStatus(cardId, cardStatus) {
    return Restangular.one('cards', cardId).customPUT(cardStatus, 'status');
  }

  function updateCardResult(cardId, cardResult) {
    return Restangular.one('cards', cardId).customPUT(cardResult, 'result');
  }

  function updateResponsibilities(cardId, responsibilities) {
    return Restangular.one('cards', cardId).customPUT(
      responsibilities,
      'responsibilities'
    );
  }

  function updateOrganisation(cardId, organisation) {
    return Restangular.one('cards', cardId).customPUT(
      organisation,
      'organisation'
    );
  }

  function removeCard(cardId) {
    return Restangular.one('cards', cardId).customPOST({}, 'remove');
  }

  function activateCard(cardId) {
    return Restangular.one('cards', cardId).customPOST({}, 'active');
  }

  function purgeCard(cardId) {
    return Restangular.one('cards', cardId).remove();
  }

  function getChangeLog(cardId) {
    return Restangular.one('cards', cardId).getList('changelog');
  }

  function extendHistoryModel(cardHistory) {
    // we only want cards history
    if (cardHistory.parentResource.route === 'cards') {
      cardHistory.year = moment(cardHistory.sportYear.validFrom).year();
    }
    return cardHistory;
  }

  function isStaffCategory(card) {
    return card.type === 'TECHNICIAN' || card.type === 'FUNCTIONARY';
  }
}
