import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import swoaEscape from '../../../components/escape/escape.filter';
import swoaConstants from '../../../app.constants';
import swoaNotification from '../../../components/notification/notification.module';
import swoaAdminTranslationsService from './../translations.service';
import htmlTemplate from './labels.html';

export default angular
  .module('swoa.admin.translations.label.component', [
    uiRouter,
    angularTranslate,
    swoaEscape,
    swoaConstants,
    swoaNotification,
    swoaAdminTranslationsService
  ])
  .component('swoaTranslationsLabel', {
    template: htmlTemplate,
    bindings: {},
    controller: LabelsController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function LabelsController(
  $rootScope,
  $scope,
  $window,
  lodash,
  ConfigConstants,
  notificationService,
  translationsService
) {
  const vm = this;

  const onError = notificationService.errorHandler(vm);

  vm.search = '';
  vm.languages = ConfigConstants.translation.availableLanguages;
  vm.hideTranslated = false;
  vm.limit = 100;

  vm.modelChanged = modelChanged;
  vm.filter = filter;
  vm.onPaginate = onPaginate;

  vm.$onInit = () => {
    activate();
  };

  // //////////////

  function activate() {
    $scope.$on('swoa:onLoadTranslations', onLoadTranslations);
    initData();
  }

  function onLoadTranslations(event, translations) {
    if (translations) {
      onTranslationsChanged(translations);
    } else {
      initData();
    }
  }

  function initData() {
    $rootScope.showSpinner = true;
    translationsService
      .loadAllTranslations()
      .then(onTranslationsChanged, onError)
      .finally(() => ($rootScope.showSpinner = false));
  }

  function onTranslationsChanged(translations) {
    vm.translations = translations;
    vm.originalTranslations = lodash.cloneDeep(vm.translations);
    vm.allKeys = getAllKeys(vm.translations);
  }

  function getAllKeys(translations) {
    // find all keys from all languages and merge/sort them
    let allKeys = [];
    lodash.forEach(translations, lang => {
      lodash.forEach(lang, (value, key) => {
        allKeys.push(key);
      });
    });
    allKeys = lodash.uniq(lodash.sortBy(allKeys));
    return allKeys;
  }

  function filter(value) {
    // hide fully translated keys?
    const allTranslated = lodash.every(vm.languages, lang =>
      // look in original translation so it does not disappear once every line is filled in
      valueSet(vm.originalTranslations[lang][value])
    );
    if (vm.hideTranslated && allTranslated) {
      return false;
    }

    // don't search if there is no search term
    if (!vm.search) {
      return true;
    }

    // search in key and value
    const valueFound = lodash.some(vm.languages, lang =>
      containsIgnoreCase(vm.translations[lang][value], vm.search)
    );
    return containsIgnoreCase(value, vm.search) || valueFound;
  }

  function containsIgnoreCase(haystack, needle) {
    if (!needle) {
      return false;
    }
    return (
      haystack && haystack.toLowerCase().indexOf(needle.toLowerCase()) >= 0
    );
  }

  function valueSet(str) {
    return str && str.trim && str.trim().length > 0;
  }

  function modelChanged() {
    return !lodash.isEqual(vm.translations, vm.originalTranslations);
  }

  function onPaginate() {
    $window.scrollTo(0, 0);
  }
}
