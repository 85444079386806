import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaFileService from '../../components/file/file.service';
import swoaAsyncService from '../../components/async/async-helper.service';
import swoaDate from '../../components/date/date.filter';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaExportService from './export.service';
import swoaUserService from '../../user/user.service';
import swoaReportHelper from './report-helper.service';
import htmlTemplate from './exports-cards.html';

export default angular
  .module('swoa.admin.report.exports.cards', [
    angularTranslate,
    swoaConstants,
    swoaNotification,
    swoaFileService,
    swoaAsyncService,
    swoaDate,
    swoaTranslatable,
    swoaExportService,
    swoaUserService,
    swoaReportHelper
  ])
  .component('swoaExportsCards', {
    template: htmlTemplate,
    bindings: {},
    controller: ExportsCardsController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function ExportsCardsController(
  $rootScope,
  $scope,
  $q,
  lodash,
  $translate,
  Constants,
  notificationService,
  exportService,
  userService,
  asyncHelperService,
  cityService,
  sportService,
  swoaDateFilter,
  swoaTranslatableFilter,
  reportHelperService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    reportHelper = reportHelperService.createReportHelper(
      vm,
      $scope,
      'exportCardFavorites',
      {
        getInitialExportParamState,
        unserializeAndLoadFavorite
      }
    ),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      reportHelper.downloadReport
    );

  // error handling
  vm.notificationKey = null;
  vm.log = null;
  vm.fileName = null;

  // loaded data
  vm.states = [];
  vm.organisations = [];
  vm.sports = [];
  vm.cardTypes = [];
  vm.ftemTypes = Constants.cards.ftemTypes;
  vm.startDates = undefined;
  vm.endDates = undefined;
  vm.attributes = { selected: [], all: [], saved: [] };
  vm.uiConfig = undefined;
  vm.originalColumnsCards = null;
  vm.originalColumnsCardChanges = null;
  vm.selectionTypes = reportHelper.filterSelectionTypesByPermission(
    Constants.reports.selectionTypes
  );

  // selections
  vm.exportParams = null;

  // functions
  vm.generate = generate;
  vm.resetExportParams = reportHelper.resetExportParams;

  // for tests only!
  vm._buildFileName = buildFileName;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    $rootScope.showSpinner = true;
    initializeDataFromServices()
      .then(reportHelper.resetExportParams)
      .finally(() => ($rootScope.showSpinner = false));

    $scope.$watch('vm.exportParams.selectionType', switchSelectionType);

    asyncHelper.getAsyncJobStatus();
    vm.uiConfig = userService.getCurrentUser().uiConfig;
    initCardTypes();
  }

  function initCardTypes() {
    const allowedCategories = lodash.filter(Constants.cards.categories, c =>
      $rootScope.uaPermission(`person_profile_access_r_${c}`)
    );
    vm.cardTypes = lodash.flatten(
      lodash.map(
        allowedCategories,
        allowedCategory => Constants.cards.category[allowedCategory].types
      )
    );
  }

  function initializeDataFromServices() {
    const promises = [];
    promises.push(
      exportService
        .loadExportableColumnsCards()
        .then(columns => (vm.originalColumnsCards = columns))
    );
    promises.push(
      exportService
        .loadExportableColumnsCardChanges()
        .then(columns => (vm.originalColumnsCardChanges = columns))
    );
    promises.push(
      exportService
        .loadOrganisations()
        .then(organisations => (vm.organisations = organisations), onError)
    );
    promises.push(
      cityService.getAllStates().then(states => (vm.states = states), onError)
    );
    promises.push(
      exportService
        .loadEarliestYear()
        .then(reportHelper.generateMonthsEntries, onError)
    );
    promises.push(
      exportService
        .loadExportableColumnsCards()
        .then(reportHelper.translateAttributes, onError)
    );
    promises.push(
      sportService.loadSports().then(sports => {
        vm.sports = lodash.map(sports, sport => ({
          id: sport.id,
          displayName: swoaTranslatableFilter(sport.translatedName, 'de')
        }));
        vm.sports = lodash.sortBy(vm.sports, 'displayName');
      }, onError)
    );
    return $q.all(promises);
  }

  function switchSelectionType() {
    // add/remove attributes according to selection
    if (vm.exportParams && vm.exportParams.selectionType === 'CARD_CHANGES') {
      reportHelper.translateAttributes(vm.originalColumnsCardChanges);
    } else {
      reportHelper.translateAttributes(vm.originalColumnsCards);
    }
    const all = vm.attributes.all;
    const selected = vm.attributes.selected;
    vm.attributes.selected = lodash.filter(selected, s =>
      lodash.some(all, attr => attr.technicalName === s.technicalName)
    );

    // reset dates so save favourite button disappears if reset
    reportHelper.setExportDateRange(vm.exportParams || {});
  }

  function getInitialExportParamState() {
    const exportParams = {
      states: [],
      organisationsIds: [],
      sportIds: [],
      cardTypes: [],
      ftemTypes: [],
      selectionType: 'CURRENTLY_RELEASED_CARDS'
    };
    reportHelper.setExportDateRange(exportParams);
    return exportParams;
  }

  function generate() {
    const parameters = reportHelper.prepareToSerializeAsFavorite();
    asyncHelper.showSpinner();
    if (vm.exportParams.selectionType === 'CARD_CHANGES') {
      exportService
        .loadCardChangeExport(
          $translate.use(),
          buildFileName(parameters),
          parameters
        )
        .then(asyncHelper.getAsyncJobStatus)
        .catch(asyncHelper.errorHandler);
    } else {
      exportService
        .loadExport(
          `cards/${$translate.use()}`,
          buildFileName(parameters),
          parameters
        )
        .then(asyncHelper.getAsyncJobStatus)
        .catch(asyncHelper.errorHandler);
    }
  }

  function buildFileName(parameters) {
    const language = $translate.use();
    const fileNameElements = [];
    fileNameElements.push(
      $translate.instant(
        `report.exportCards.filename.${vm.exportParams.selectionType}`
      )
    );
    if (parameters.cardTypes) {
      fileNameElements.push(
        parameters.cardTypes
          .map(cardType => $translate.instant(`card.type.${cardType}`))
          .join('_')
      );
    }
    const untilLabel = $translate.instant('sportYear.exportCards.until');
    if (vm.exportParams.selectionType === 'CARD_CHANGES') {
      fileNameElements.push(
        `01.01.${parameters.exportYear}_${untilLabel}_31.12.${parameters.exportYear}`
      );
    } else if (vm.exportParams.selectionType === 'CURRENTLY_RELEASED_CARDS') {
      fileNameElements.push(swoaDateFilter(new Date(), 'DD.MM.YYYY'));
    } else {
      fileNameElements.push(
        swoaDateFilter(parameters.exportFrom, 'DD.MM.YYYY')
      );
      fileNameElements.push(untilLabel);
      fileNameElements.push(
        swoaDateFilter(parameters.exportUntil, 'DD.MM.YYYY')
      );
    }
    fileNameElements.push(language.toUpperCase());

    return `${fileNameElements.filter(e => !!e).join('_')}.xlsx`;
  }

  function unserializeAndLoadFavorite($favorite) {
    const selected = angular.copy($favorite);
    reportHelper.resetExportParams();
    const attributes = angular.copy(selected.columns);
    delete selected.name;
    delete selected.columns;
    vm.exportParams = selected;
    vm.attributes.selected = [];
    switchSelectionType();
    vm.attributes.selected = lodash.map(attributes, name =>
      lodash.find(vm.attributes.all, a => a.technicalName === name)
    );
  }
}
