import angular from 'angular';
import 'restangular';
import swoaFileService from '../components/file/file.service';
import swoaConstants from '../app.constants';
import swoaUserService from '../user/user.service';
import swoaSportService from '../sport/sport.service';
import swoaNotification from '../components/notification/notification.module';
import swoaAsyncService from '../components/async/async-helper.service';
import htmlTemplate from './person-import.html';

export default angular
  .module('swoa.admin.person-import', [
    'restangular',
    swoaFileService,
    swoaConstants,
    swoaUserService,
    swoaSportService,
    swoaNotification,
    swoaAsyncService
  ])
  .component('swoaPersonImport', {
    template: htmlTemplate,
    bindings: {},
    controller: PersonImportController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonImportController(
  $scope,
  $q,
  lodash,
  Restangular,
  $translate,
  ConfigConstants,
  fileService,
  notificationService,
  asyncHelperService,
  userService,
  sportService,
  swoaDateFilter,
  swoaTranslatableFilter
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      null,
      { httpOptions: { global: true } }
    );

  vm.selectedFileTalentCards = null;
  vm.availableSportYears = [];
  vm.sportYearId = null;
  vm.log = null;

  vm.saveTalentCards = saveTalent;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    asyncHelper.getAsyncJobStatus();
    const newPromise = sportService.loadCockpitSportSummaries({
      category: 'TALENT',
      sports: getSportIds(),
      status: 'NEW'
    });
    const revisionPromise = sportService.loadCockpitSportSummaries({
      category: 'TALENT',
      sports: getSportIds(),
      status: 'REVISION_NEEDED'
    });
    $q.all([newPromise, revisionPromise]).then(arr => {
      const allYears = lodash.sortBy(
        lodash.concat(arr[0], arr[1]),
        'spot.name'
      );
      vm.availableSportYears = lodash.map(allYears, year => {
        const statusTranslated = $translate.instant(`status.${year.status}`);
        const from = swoaDateFilter(year.validFrom);
        const until = swoaDateFilter(year.validUntil);
        return {
          id: year.id,
          label: `${swoaTranslatableFilter(
            year.sport.translatedName
          )} ${statusTranslated} ${from}-${until}`
        };
      });
    });
  }

  function saveTalent() {
    doImport(
      `${ConfigConstants.upload.url.TALENT_CARDS}?sportYear=${vm.sportYearId}`,
      vm.selectedFileTalentCards
    );
  }

  function doImport(url, file) {
    vm.log = asyncHelper.getSpinnerContent();

    fileService
      .upload(url, file)
      .then(asyncHelper.getAsyncJobStatus)
      .catch(asyncHelper.errorHandler);
  }

  function getSportIds() {
    const uiConfig = userService.getCurrentUser().uiConfig;
    return uiConfig && uiConfig.sports;
  }
}
