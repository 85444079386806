import angular from 'angular';
import swoaNwfParameterRoutes from './nwf-parameter.routes';
import swoaNwfParameterPage from './nwf-parameter-page.component';
import swoaNwfParameterBaseAmount from './nwf-parameter-base-amount.component';
import swoaNwfParameterVariableAmount from './nwf-parameter-variable-amount.component';

export default angular
  .module('swoa.admin.nwf-parameter', [
    swoaNwfParameterRoutes,
    swoaNwfParameterPage,
    swoaNwfParameterBaseAmount,
    swoaNwfParameterVariableAmount,
  ])
  .name;

