import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaTranslatableElementService from '../translation/translatable-element.service';
import swoaCityService from '../city/city.service';
import htmlTemplate from './login.html';
import swoaCockpitCockpitConfigService from '../cockpit/cockpit-config.service';

export default angular
  .module('swoa.login.component', [
    uiRouter,
    swoaTranslatableElementService,
    swoaCityService,
    swoaCockpitCockpitConfigService
  ])
  .component('swoaLogin', {
    template: htmlTemplate,
    bindings: {},
    controller: LoginController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function LoginController(
  $location,
  $state,
  $stateParams,
  ngUserAuthService,
  translatableElementService,
  cityService,
  cockpitConfigService
) {
  const vm = this;

  vm.login = login;

  vm.credentials = {
    username: null,
    password: null
  };
  vm.notificationKey = null;
  vm.isSuccess = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.notificationKey = $stateParams.notificationKey;
    vm.isSuccess = $stateParams.isSuccess;

    // reset the local storage with the translatable elements and cities so they are refreshed every login
    translatableElementService.clearCache();
    cityService.clearCache();
    cockpitConfigService.clearCache();

    if (ngUserAuthService.isLoggedIn()) {
      redirectAfterLogin();
    }
  }

  function login() {
    vm.notificationKey = null;

    ngUserAuthService
      .login(vm.credentials)
      .then(redirectAfterLogin)
      .catch(() => {
        vm.notificationKey = 'login.error';
      });
  }

  function redirectAfterLogin() {
    if ($stateParams.requestedPath) {
      $location.url($stateParams.requestedPath);
    } else {
      $state.go('cockpit');
    }
  }
}
