import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaDate from '../../components/date/date.filter';
import swoaPersonService from '../person.service';
import swoaUserService from '../../user/user.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './person-mutations.html';

export default angular
  .module('swoa.person.mutation.person-mutations', [
    uiRouter,
    angularTranslate,
    swoaConstants,
    swoaDate,
    swoaPersonService,
    swoaUserService,
    swoaNotification
  ])
  .component('swoaPersonMutations', {
    template: htmlTemplate,
    bindings: {},
    controller: PersonMutationsController,
    controllerAs: 'vm'
  }).name;

export const MAX_ITEMS = 50;

/** @ngInject */
function PersonMutationsController(
  $rootScope,
  $stateParams,
  $state,
  $q,
  lodash,
  personMutationService,
  personService,
  notificationService,
  userService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  // pending mutations
  vm.pendingTotal = null;
  vm.pendingPersonMutationDetails = null;
  vm.pendingOrder = 'personMutationDetail.personMutation.editDate';
  vm.selectAllPendingValue = false;

  // new mutations
  vm.newTotal = null;
  vm.personCreationDetails = null;
  vm.newOrder = 'personMutationDetail.personMutation.editDate';
  vm.selectAllNewValue = false;
  vm.maxItems = userService.getCurrentUser().uiConfig.maxMutations || MAX_ITEMS;

  // error handling
  vm.notificationKey = null;

  vm.confirm = confirm;
  vm.select = select;
  vm.edit = edit;
  vm.click = click;
  vm.selectAll = selectAll;
  vm.confirmAllSelected = confirmAllSelected;
  vm.selectionPendingChanged = selectionPendingChanged;
  vm.selectionNewChanged = selectionNewChanged;
  vm.toBeUpdated = toBeUpdated;
  vm.saveMaxItems = saveMaxItems;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $rootScope.showSpinner = true;
    const promises = [];

    promises.push(
      personService.count('PENDING').then(pendingTotal => {
        vm.selectAllPendingValue = false;
        vm.pendingTotal = pendingTotal || 0;
        if (vm.pendingTotal > 0) {
          // load pending personMutations
          return loadPendingData();
        }
        return null;
      }, onError)
    );

    promises.push(
      personService.count('NEW').then(newTotal => {
        vm.selectAllNewValue = false;
        vm.newTotal = newTotal || 0;
        if (vm.newTotal > 0) {
          // load new personMutations
          return loadNewData();
        }
        return null;
      }, onError)
    );

    $q.all(promises).finally(() => ($rootScope.showSpinner = false));
  }

  function loadPendingData() {
    return personMutationService
      .loadPersonMutations(0, vm.maxItems)
      .then(pendingPersonMutationDetails => {
        vm.pendingPersonMutationDetails = pendingPersonMutationDetails;
      }, onError);
  }

  function loadNewData() {
    return personService
      .loadNewPersons(0, vm.maxItems)
      .then(personCreationDetails => {
        vm.personCreationDetails = personCreationDetails;
        lodash.forEach(vm.personCreationDetails, detail => {
          if (detail.person) {
            detail.person.hasAvatar = false;
          }
        });
      }, onError);
  }

  function confirm(personMutationDetail, event) {
    if (event) {
      event.stopPropagation();
    }
    if (personMutationDetail.person) {
      return personService.confirm(personMutationDetail.person.id).then(() => {
        removeDetail(personMutationDetail);
      }, onError);
    }
    return personMutationService
      .confirm(personMutationDetail.personMutation.id)
      .then(() => {
        removeDetail(personMutationDetail);
      }, onError);
  }

  function selectAll(type) {
    if (type === 'existing') {
      lodash.forEach(vm.pendingPersonMutationDetails, value => {
        value.selected = vm.selectAllPendingValue;
      });
    } else {
      lodash.forEach(vm.personCreationDetails, value => {
        value.selected = vm.selectAllNewValue;
      });
    }
  }

  function click(clicked, event) {
    event.stopPropagation();
    clicked.selected = !clicked.selected;
    if (clicked.person) {
      selectionPendingChanged();
    } else {
      selectionNewChanged();
    }
  }

  function selectionPendingChanged() {
    if (
      vm.pendingPersonMutationDetails &&
      vm.pendingPersonMutationDetails.length > 0
    ) {
      vm.selectAllPendingValue = lodash.every(vm.pendingPersonMutationDetails, {
        selected: true
      });
    }
  }

  function selectionNewChanged() {
    if (vm.personCreationDetails && vm.personCreationDetails.length > 0) {
      vm.selectAllNewValue = lodash.every(vm.personCreationDetails, {
        selected: true
      });
    }
  }

  function confirmAllSelected(type, event) {
    event.stopPropagation();
    $rootScope.showSpinner = true;
    lodash.forEach(toBeUpdated(type), item => {
      confirm(item);
    });
    activate();
  }

  function toBeUpdated(type) {
    if (type === 'existing') {
      return lodash.filter(vm.pendingPersonMutationDetails, { selected: true });
    }
    return lodash.filter(vm.personCreationDetails, { selected: true });
  }

  function select(personMutationDetail, event) {
    event.stopPropagation();
    $stateParams.personMutationId = personMutationDetail.personMutation.id;
    $state.go('personMutationEdit', $stateParams);
  }

  function edit(personCreationDetail, event) {
    event.stopPropagation();
    $stateParams.personCreationId = personCreationDetail.person.id;
    $state.go('personCreationAssignSimilar', $stateParams);
  }

  function removeDetail(personMutationDetail) {
    let idx = 0;
    if (personMutationDetail.person) {
      idx = vm.personCreationDetails.indexOf(personMutationDetail);
      vm.personCreationDetails.splice(idx, 1);
      if (vm.newTotal > 0) {
        vm.newTotal -= 1;
      }
    } else {
      idx = vm.pendingPersonMutationDetails.indexOf(personMutationDetail);
      vm.pendingPersonMutationDetails.splice(idx, 1);
      if (vm.pendingTotal > 0) {
        vm.pendingTotal -= 1;
      }
    }
  }

  function saveMaxItems() {
    const uiConfig = userService.getCurrentUser().uiConfig;
    uiConfig.maxMutations = vm.maxItems;
    userService.saveUiConfig(uiConfig);
    activate();
  }
}
