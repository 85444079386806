import angular from 'angular';

export default angular
  .module('swoa.ahv.ahv-number-validator', [])
  .factory('ahvNumberValidator', ahvNumberValidator)
  .name;

export const AHV_NUMBER_LENGTH_WITHOUT_SEPARATORS = 13;
export const AHV_SEPARATOR = '.';
export const FACTOR_3 = 3;
export const FACTOR_1 = 1;

/** @ngInject */
function ahvNumberValidator() {
  return {
    isValidAHVNumber,
    getUnformattedAHVNumber,
    getFormattedAHVNumber,
  };

  // //////////

  function isValidAHVNumber(ahvNumber) {
    if (!ahvNumber) {
      return false;
    }

    if (ahvNumber.toString().indexOf(AHV_SEPARATOR) !== -1) {
      return isValidFormattedAHVNumber(ahvNumber);
    }
    return isValidUnformattedAHVNumber(ahvNumber);
  }

  function getUnformattedAHVNumber(str) {
    return str.replace(/[^0-9]/g, '');
  }

  function getFormattedAHVNumber(unformattedAhvNumber) {
    const ahv1 = unformattedAhvNumber.substring(0, 3);
    const ahv2 = unformattedAhvNumber.substring(3, 7);
    const ahv3 = unformattedAhvNumber.substring(7, 11);
    const ahv4 = unformattedAhvNumber.substring(11);

    return [ahv1, ahv2, ahv3, ahv4].join(AHV_SEPARATOR);
  }

  // Private helpers

  function isValidFormattedAHVNumber(ahvNumber) {
    return hasAHVNumberFormat(ahvNumber) && isControlDigitCorrect(ahvNumber);
  }

  function isValidUnformattedAHVNumber(ahvNumber) {
    const containsOnlyNumbers = containsOnlyNumbersWithLength(ahvNumber, AHV_NUMBER_LENGTH_WITHOUT_SEPARATORS);
    if (containsOnlyNumbers) {
      const formattedAHVNumber = getFormattedAHVNumber(ahvNumber);
      return isControlDigitCorrect(formattedAHVNumber);
    }
    return false;
  }

  function getControlDigit(ahvNumber) {
    return parseInt(ahvNumber.toString().charAt(ahvNumber.length - 1), 10);
  }

  function isControlDigitCorrect(ahvNumber) {
    const unformattedAHVNumber = getUnformattedAHVNumber(ahvNumber);
    const controlDigit = getControlDigit(ahvNumber);
    let factor = FACTOR_3;
    let total = 0;
    const nextToLastIndex = AHV_NUMBER_LENGTH_WITHOUT_SEPARATORS - 2;

    for (let i = nextToLastIndex; i >= 0; i--) {
      const value = parseInt(unformattedAHVNumber.toString().charAt(i), 10);
      total += (value * factor);
      if (factor === FACTOR_3) {
        factor = FACTOR_1;
      } else {
        factor = FACTOR_3;
      }
    }

    const nextMultipleOfTen = Math.ceil(parseFloat(total) / 10);
    const expectedControlDigit = (nextMultipleOfTen * 10) - total;

    return controlDigit === expectedControlDigit;
  }

  function hasAHVNumberFormat(ahvNumber) {
    return ahvNumber.match(/[0-9]{3}\.[0-9]{4}\.[0-9]{4}\.[0-9]{2}/) !== null;
  }

  function containsOnlyNumbersWithLength(value, length) {
    return value.match(new RegExp(`[0-9]{${length}}`)) !== null;
  }
}
