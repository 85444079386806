import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaPersonService from '../../person/person.service';
import htmlTemplate from './input-select-country.html';

export default angular
  .module('swoa.input-select-country', [swoaPersonService, angularTranslate])
  .component('swoaInputSelectCountry', {
    template: htmlTemplate,
    bindings: {
      containerClass: '@',
      isReadOnly: '<',
      isRequired: '<',
      labelKey: '@',
      name: '@',
      ngModel: '=',
      ngChange: '&',
      allowEmpty: '<',
      emptyLabel: '@',
      multiple: '<'
    },
    controller: SwoaInputSelectCountryController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function SwoaInputSelectCountryController(
  $scope,
  $translate,
  personService,
  lodash
) {
  const vm = this;

  vm.enumList = [];
  vm.enumTranslatePrefix = 'country.';
  vm.defaultCountry = 'CH';

  vm.sorterFunction = sorterFunction;
  vm.selectAll = selectAll;
  vm.allSelected = allSelected;
  vm.isSelected = isSelected;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    personService.loadCountries().then(countryCodes => {
      vm.enumList = countryCodes;
    });
    $scope.$watch('vm.ngModel', removeUndefined, true);
  }

  function removeUndefined() {
    if (vm.multiple) {
      vm.ngModel = lodash.flatten(
        lodash.filter(vm.ngModel, elem => !lodash.isNil(elem))
      );
    }
  }

  function sorterFunction(country) {
    return $translate.instant(vm.enumTranslatePrefix + country);
  }

  function selectAll() {
    if (vm.multiple) {
      toggleAll(vm.enumList);
    }
  }

  function toggleAll(arr, prop) {
    if (vm.ngModel.length === arr.length) {
      vm.ngModel = [];
    } else {
      arr.forEach(elem => vm.ngModel.push(prop ? elem[prop] : elem));
    }
  }

  function allSelected() {
    return (
      vm.ngModel && vm.enumList && vm.ngModel.length === vm.enumList.length
    );
  }

  function isSelected(option) {
    if (vm.multiple) {
      return vm.ngModel.includes(option);
    }
    return (
      option === (vm.ngModel || (vm.allowEmpty ? null : vm.defaultCountry))
    );
  }
}
