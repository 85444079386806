import angular from 'angular';
import swoaLogoutRoutes from './logout.routes';
import swoaLogout from './logout.component';

export default angular
  .module('swoa.logout', [
    swoaLogoutRoutes,
    swoaLogout,
  ])
  .name;

