import angular from 'angular';
import toastrModule from 'angular-toastr';
import ngMaterial from 'angular-material';
import angularTranslate from 'angular-translate';
import uiRouter from '@uirouter/angularjs';
import swoaConstants from '../../app.constants';
import swoaDialogDialog from './dialog.component';

export default angular
  .module('swoa.dialog.service', [
    toastrModule,
    ngMaterial,
    angularTranslate,
    uiRouter,
    swoaConstants,
    swoaDialogDialog
  ])
  .factory('dialogService', dialogService).name;

export const KEY_ARE_YOU_SURE_TITLE = 'app.confirm.are.you.sure';
export const KEY_YES = 'app.confirm.yes';
export const KEY_NO = 'app.confirm.no';

/** @ngInject */
function dialogService($mdDialog, $translate, $state, toastr, lodash) {
  return {
    showConfirmationDialog,
    showSuccessToast,
    showErrorToast,
    showDialog,
    showAlert,
    hideDialog
  };

  // //////////

  /**
   * Show a classic "Are you sure?" dialog with yes and no answer.
   * @param contentKey Translation key of the main message/question.
   * @param params Translation params
   * @returns {*} Promise that is only resolved when user clicks yes, otherwise it's rejected.
   */
  function showConfirmationDialog(contentKey, params) {
    return $translate(
      [contentKey, KEY_YES, KEY_NO, KEY_ARE_YOU_SURE_TITLE],
      params
    ).then(translations => {
      // prepare dialog
      const confirm = $mdDialog
        .confirm()
        .title(translations[KEY_ARE_YOU_SURE_TITLE])
        .content(translations[contentKey])
        .ariaLabel(translations[contentKey])
        .ok(translations[KEY_YES])
        .cancel(translations[KEY_NO]);

      // show dialog
      return $mdDialog.show(confirm);
    });
  }

  /**
   * Show a translated success toast.
   * @param messageKey Translation key of the message to toast.
   * @param variables Replacement variables (if any) in the message to translate.
   */
  function showSuccessToast(messageKey, variables) {
    $translate(messageKey, variables)
      .then(message => {
        toastr.success(message);
      })
      .catch(angular.noop);
  }

  /**
   * Show a translated error toast.
   * @param messageKey Translation key of the message to toast.
   * @param variables Replacement variables (if any) in the message to translate.
   */
  function showErrorToast(messageKey, variables) {
    $translate(messageKey, variables)
      .then(message => {
        toastr.error(message);
      })
      .catch(angular.noop);
  }

  /**
   * Shows a dialog using the given template and provides the functions hide() and
   * cancel() in the scope.
   *
   * Example:
   *   dialogService.showDialog('<foo-component></foo-component>');
   */
  function showDialog(template, uncloseable, title, scope, windowed) {
    return $mdDialog.show({
      controller($scope) {
        const setValue = (prop, value) => {
          $scope[prop] = value;
          scope[prop] = value;
        };

        lodash.assign(
          $scope,
          {
            closeable: !uncloseable,
            title,
            hide: $mdDialog.hide,
            cancel: $mdDialog.cancel,
            setValue
          },
          scope
        );
      },
      template: `<swoa-dialog closeable="closeable" dialog-title="title">${template}</swoa-dialog>`,
      clickOutsideToClose: !uncloseable,
      escapeToClose: !uncloseable,
      fullscreen: !windowed
    });
  }

  /**
   * Shows an alert dialog with OK-Button
   * @param titleKey
   * @param textKey
   * @param okKey
   * @param params params for translation
   */
  function showAlert(titleKey, textKey, okKey, params) {
    $translate([titleKey, textKey, okKey], params).then(translations => {
      const alert = $mdDialog.alert({
        title: translations[titleKey],
        textContent: translations[textKey],
        ok: translations[okKey]
      });

      $mdDialog.show(alert);
    });
  }

  function hideDialog() {
    $mdDialog.hide();
  }
}
