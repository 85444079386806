import angular from 'angular';
import swoaCardSportYearRoutes from './sport-year.routes';
import swoaCardSportYearService from './sport-year.service';
import swoaCardSportYearSportYearAddAthlete from './sport-year-add-athlete.component';
import swoaCardSportYearSportYearCreatePerson from './sport-year-create-person.component';
import swoaCardSportYearSportYearComments from './sport-year-comments.component';
import swoaCardSportYearSportYearContact from './sport-year-contact.component';
import swoaCardSportYearSportYearDetail from './sport-year-detail.component';
import swoaCardSportYearSportYearPrint from './sport-year-print.component';
import swoaCardSportYearSportYearSummary from './sport-year-summary.component';
import swoaCardSportYearCardContingent from './card-contingent.component';
import swoaCardSportYearSportYearEdit from './sport-year-edit.component';
import swoaDigitalCardStateIndicator from './digital-card-state-indicator.component';

export default angular.module('swoa.card.sport-year', [
  swoaCardSportYearRoutes,
  swoaCardSportYearService,
  swoaCardSportYearSportYearAddAthlete,
  swoaCardSportYearSportYearCreatePerson,
  swoaCardSportYearSportYearComments,
  swoaCardSportYearSportYearContact,
  swoaCardSportYearSportYearDetail,
  swoaCardSportYearSportYearPrint,
  swoaCardSportYearSportYearSummary,
  swoaCardSportYearCardContingent,
  swoaCardSportYearSportYearEdit,
  swoaDigitalCardStateIndicator
]).name;
