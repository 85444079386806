import angular from 'angular';
import htmlTemplate from './input-configured.html';

export default angular
  .module('swoa.input-configured', [])
  .component('swoaInputConfigured', {
    template: htmlTemplate,
    bindings: {
      isReadOnly: '<',
      entry: '<',
      ngModel: '=',
      name: '@',
      labelKey: '@',
      placeholder: '@',
      messages: '<',
      messagesIf: '<',
      containerClass: '@',
      enumList: '<',
      enumTranslatePrefix: '@',
      enumTranslateSuffix: '@',
      idValueList: '<',
      valueProperty: '@',
      ngChange: '&',
      customMessageKeys: '<',
      autocompleteType: '<',
      itemText: '<',
      searchText: '=',
      updatableElement: '=',
      overrideDatatype: '@',
      allowEmpty: '<',
      emptyLabel: '@',
      allowExplicitNull: '<',
      explicitNullLabel: '@'
    },
    controller: InputConfiguredController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function InputConfiguredController($scope) {
  const vm = this;

  vm.readOnly = false;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.isReadOnly', updateReadOnly, true);
  }

  function updateReadOnly() {
    vm.readOnly = vm.isReadOnly === true || vm.entry.infoOnly;
  }
}
