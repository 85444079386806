import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.organisation.search.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('organisationSearch', {
      url: '/organisations/search',
      template:
        '<swoa-organisation-search-page current-tab="selectedTab"></swoa-organisation-search-page>',
      controller($scope, $state, $transitions) {
        if ($state.current.name === 'organisationSearch') {
          $state.go('organisationSearch.query');
          $scope.selectedTab = 'search';
        } else {
          $scope.selectedTab = $state.current.data.selectedTab;
        }

        $transitions.onSuccess({ to: 'organisationSearch.**' }, transition => {
          if (transition.to().name === 'organisationSearch') {
            $state.go('organisationSearch.query');
            $scope.selectedTab = 'search';
          } else {
            $scope.selectedTab = $state.current.data.selectedTab;
          }
        });
      },
      controllerAs: 'vm'
    })
    .state('organisationSearch.query', {
      url: '/query?query&notificationKey&isSuccess',
      data: {
        selectedTab: 'search',
        breadcrumb: true,
        hasPermission: 'admin_organisation_r'
      },
      reloadOnSearch: false,
      params: {
        query: {
          value: null,
          squash: true
        },
        notificationKey: {
          value: null,
          squash: true
        },
        isSuccess: {
          value: null,
          squash: true
        }
      },
      views: {
        query: {
          template:
            '<swoa-organisation-search without-selection="true"></swoa-organisation-search>'
        }
      }
    })
    .state('organisationSearch.extended', {
      url: '/extended',
      data: {
        selectedTab: 'extended',
        breadcrumb: {
          parent: 'organisationSearch.query',
          ignore: true
        },
        hasPermission: 'admin_organisation_r'
      },
      views: {
        extended: {
          template:
            '<swoa-organisation-extended-search without-selection="true"></swoa-organisation-extended-search>'
        }
      }
    });
}
