import angular from 'angular';
import swoaJobsList from './jobs-list.component';
import swoaJobsRoutes from './jobs.routes';
import swoaJobsService from './jobs.service';

export default angular
  .module('swoa.admin.jobs', [
    swoaJobsList,
    swoaJobsRoutes,
    swoaJobsService,
  ])
  .name;
