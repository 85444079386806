import angular from 'angular';
import swoaDataTableFilter from '../../components/data-table/data-table.filter';
import swoaNotification from '../../components/notification/notification.module';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaNwfVariableAmountPeriodService from './nwf-variable-amount-period.service';
import swoaNwfTrainerService from './nwf-trainer.service';

import htmlTemplate from './nwf-variable-amount-period-foundation.html';

export default angular
  .module(
    'swoa.nwf-contribution.variable-amount.nwf-variable-amount-period-foundation',
    [
      swoaDataTableFilter,
      swoaNotification,
      swoaTranslatable,
      swoaNwfVariableAmountPeriodService,
      swoaNwfTrainerService
    ]
  )
  .component('swoaNwfVariableAmountPeriodFoundation', {
    template: htmlTemplate,
    bindings: {
      period: '<'
    },
    controller: NwfVariableAmountPeriodFoundation,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfVariableAmountPeriodFoundation(
  $q,
  $scope,
  $rootScope,
  $stateParams,
  lodash,
  Restangular,
  notificationService,
  swoaTranslatableFilter,
  $translate,
  nwfTrainerService
) {
  const vm = this;
  vm.trainers = [];
  vm.order = 'athlete.person.surname';

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    $scope.$on('swoa:nwfTrainerUpdated', onTrainerUpdated);
    $scope.$on('swoa:nwfTrainerDeleted', onTrainerDeleted);
    $scope.$on('swoa:nwfTrainerImported', onTrainerImported);
    $scope.$watch('vm.period', onPeriodChanged);
  }

  function onPeriodChanged() {
    if (vm.period) {
      vm.trainers = vm.period.trainers;
      lodash.forEach(vm.trainers, trainer => {
        initTrainerData(trainer);
      });
    }
  }

  function initTrainerData(trainer) {
    const person = trainer.athlete.person;
    const data = person.recognitionData;
    trainer.recognitionDisplayName = data
      ? swoaTranslatableFilter(data.recognition, 'de')
      : '';
    trainer.percentage = nwfTrainerService.calculateLevelOfEmployment(trainer);
  }

  function onTrainerUpdated(event, trainer) {
    const index = lodash.findIndex(vm.trainers, { id: trainer.id });
    if (index > -1) {
      vm.trainers[index] = Restangular.copy(trainer);
      $rootScope.$broadcast('swoa:nwfTrainersUpdated', vm.trainers);
      initTrainerData(vm.trainers[index]);
    }
  }

  function onTrainerDeleted(event, trainer) {
    const index = lodash.findIndex(vm.trainers, { id: trainer.id });
    if (index > -1) {
      lodash.pullAt(vm.trainers, index);
    }
  }

  function onTrainerImported(event, imported) {
    vm.notificationKey = $translate.instant(
      'nwfContributions.trainer.imported',
      { imported }
    );
    vm.notificationSuccess = true;
  }
}
