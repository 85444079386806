import angular from 'angular';
import swoaCard from '../../../card/card.module';
import swoaCardSportYearService from '../../../card/sport-year/sport-year.service';
import swoaConstants from '../../../app.constants';
import htmlTemplate from './person-timeline-entry-card.html';

export default angular
  .module('swoa.person.timeline.person-timeline-entry-card', [
    swoaCard,
    swoaCardSportYearService,
    swoaConstants
  ])
  .component('swoaPersonTimelineEntryCard', {
    template: htmlTemplate,
    bindings: {
      person: '<',
      timelineEntry: '<'
    },
    controller: PersonTimelineEntryCard,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonTimelineEntryCard(
  $window,
  $state,
  $translate,
  sportYearService,
  swoaTranslatableFilter
) {
  const vm = this;

  vm.navigationParams = navigationParams;
  vm.openCardList = openCardList;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    if (vm.timelineEntry.card.type === 'TECHNICIAN') {
      vm.sportOrOrganisationOrFunctionary = getDefaultOrganisationName();
    } else if (vm.timelineEntry.card.type === 'FUNCTIONARY') {
      vm.sportOrOrganisationOrFunctionary = getDefaultFunctionaryOrganisationName();
    } else {
      vm.sportOrOrganisationOrFunctionary = getDefaultSportName();
    }
    vm.cardType = getTranslatedCardType(vm.timelineEntry.card.type);
  }

  function getTranslatedCardType(entry) {
    return $translate.instant(`card.type.${entry}`);
  }

  function getDefaultSportName() {
    return getDefaultTranslatedEntry(
      vm.timelineEntry.sportYear.sport.translatedName
    );
  }

  function getDefaultOrganisationName() {
    return getDefaultTranslatedEntry(
      vm.timelineEntry.sportYear.organisation.name
    );
  }

  function getDefaultFunctionaryOrganisationName() {
    return getDefaultTranslatedEntry(
      vm.timelineEntry.card.functionaryOrganisation
    );
  }

  function getDefaultTranslatedEntry(entry) {
    return swoaTranslatableFilter(entry, 'de');
  }

  function navigationParams(timelineEntry) {
    const params = sportYearService.getCardListDetailRouteParameter(
      timelineEntry.sportYear
    );
    params.cardId = timelineEntry.card.id;
    return params;
  }

  function openCardList() {
    const href = $state.href(
      'sportYearDetail.cardDetail',
      vm.navigationParams(vm.timelineEntry),
      { absolute: true }
    );
    if ($window.opener && $window.opener.location) {
      $window.opener.location.href = href;
      $window.opener.focus();
    }
  }
}
