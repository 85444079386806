import angular from 'angular';
import swoaTranslatable from '../../components/translatable/translatable.filter';

export default angular
  .module('swoa.nwf-contribution.base-amount.nwf-base-amount-period-list', [
    swoaTranslatable
  ])
  .component('swoaNwfBaseAmountPeriodList', {
    template: `
    <div class="sport-detail-category">
    <h2 translate="nwfContributions.baseAmount" class="swoa-offset"></h2>
    <swoa-data-table ng-if="vm.periods.length > 0">
      <table md-table class="swoa-periods-table">
        <thead md-head md-order="vm.orderBaseAmount">
        <tr md-row>
          <th md-column md-order-by="validFrom">{{'sportYearSummary.table.duration.from' | translate}}</th>
          <th md-column md-order-by="validUntil">{{'sportYearSummary.table.duration.to' | translate}}</th>
          <th md-column md-order-by="status">{{'sportYearSummary.table.state' | translate}}</th>
        </tr>
        </thead>
        <tbody md-body>
        <tr md-row
            ng-repeat="period in vm.periods | orderBy: vm.order"
            ui-sref="nwfBaseAmountPeriod(::{organisationId: vm.organisationId, periodId: period.id})">
          <td md-cell ng-bind="period.validFrom | swoaDate"></td>
          <td md-cell ng-bind="period.validUntil | swoaDate"></td>
          <td md-cell>
            <swoa-status status="period.status"></swoa-status>
          </td>
        </tr>
        </tbody>
      </table>
    </swoa-data-table>
    <p ng-if="vm.periods.length === 0" class="is-caption swoa-offset" flex
       translate="nwfContributions.noPeriods"></p>
  </div>`,
    controllerAs: 'vm',
    controller: NwfBaseAmountPeriodlist,
    bindings: {
      periods: '<',
      organisationId: '<'
    }
  }).name;

function NwfBaseAmountPeriodlist() {
  const vm = this;
  vm.order = '-validFrom';
}
