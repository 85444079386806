import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaDialogService from '../../components/dialog/dialog.service';
import swoaFormatNumber from '../../components/format-number/format-number.filter';
import swoaNwfContributionService from '../../nwf-contribution/nwf-contribution.service';
import htmlTemplate from './nwf-parameter-base-amount.html';

export default angular
  .module('swoa.admin.nwf-parameter.base-amount', [
    swoaConstants,
    swoaNotification,
    swoaDialogService,
    swoaFormatNumber,
    swoaNwfContributionService
  ])
  .component('swoaNwfParameterBaseAmount', {
    template: htmlTemplate,
    bindings: {},
    controller: NwfParameterBaseAmountController,
    controllerAs: 'vm'
  }).name;

const NUM_CATEGORIES = 10;

/** @ngInject */
function NwfParameterBaseAmountController(
  $rootScope,
  $scope,
  $q,
  $sce,
  lodash,
  $translate,
  Constants,
  notificationService,
  dialogService,
  nwfContributionService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.categories = [];
  vm.forecastValues = {};
  vm.$sce = $sce;

  vm.calculateForecast = calculateForecast;
  vm.saveForecast = saveForecast;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    const categoriesPromise = nwfContributionService.currentBaseAmountCategories();
    const countByCategoriesPromise = nwfContributionService.countByBaseAmountCategories();

    $q.all([categoriesPromise, countByCategoriesPromise]).then(results => {
      vm.categories = results[0];
      const counts = results[1];

      lodash.forEach(vm.categories, category => {
        category.count = counts[category.id] || 0;
      });
      calculateForecast();
      calculateCurrent();
    }, onError);
  }

  function calculateForecast() {
    vm.forecast = '-';
    vm.forecastValid = true;
    for (let i = 1; i <= NUM_CATEGORIES; i++) {
      const parsedFloat = parseFloat(vm.forecastValues[i]);
      if (isNaN(parsedFloat) || !parsedFloat || parsedFloat < 0) {
        vm.forecastValid = false;
      }
    }
    if (vm.forecastValid) {
      vm.forecast = 0;
      for (let i = 1; i <= NUM_CATEGORIES; i++) {
        const category = lodash.find(vm.categories, ['id', i]);
        vm.forecast += parseFloat(vm.forecastValues[i]) * category.count;
      }
      vm.forecast = Math.round(vm.forecast);
    }
  }

  function calculateCurrent() {
    vm.current = 0;
    for (let i = 1; i <= NUM_CATEGORIES; i++) {
      const category = lodash.find(vm.categories, ['id', i]);
      vm.current += category.amount * category.count;
    }
    vm.current = Math.round(vm.current);
  }

  function saveForecast() {
    dialogService
      .showConfirmationDialog(
        'nwfBaseAmountPeriod.useForecastParameter.confirmation'
      )
      .then(() => {
        for (let i = 1; i <= NUM_CATEGORIES; i++) {
          const category = lodash.find(vm.categories, ['id', i]);
          category.amount = parseFloat(vm.forecastValues[i]);
          delete category.count;
        }
        return nwfContributionService
          .updateBaseAmountCategories(vm.categories)
          .then(activate);
      });
  }
}
