import angular from 'angular';

export default angular.module('swoa.icon', []).component('swoaIcon', {
  template: `<div class="icon {{vm.additionalClasses}}"
                    id="icon-{{vm.name}}"
                    ng-bind-html="vm.svgSource">
               </div>`,
  bindings: {
    name: '@',
    additionalClasses: '@'
  },
  controller: IconController,
  controllerAs: 'vm'
}).name;

/** @ngInject */
function IconController($sce) {
  const vm = this;

  vm.$onInit = () => {
    vm.svgSource = $sce.trustAsHtml(
      // eslint-disable-next-line
      require(`../../../assets/icons/${vm.name}.svg`)
    );
  };
}
