import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaTranslatable from './translatable.filter';
import swoaTranslatableElementService from '../../translation/translatable-element.service';

export default angular
  .module('swoa.translatable-dropdown-value', [
    swoaConstants,
    swoaTranslatable,
    swoaTranslatableElementService,
  ])
  .filter('swoaTranslatableDropdownValue', swoaTranslatableDropdownValue)
  .name;

/** @ngInject */
function swoaTranslatableDropdownValue($translate, lodash, translatableElementService, swoaTranslatableFilter) {
  return (technicalName, translatableType) => {
    if (!translatableType || !technicalName) {
      return undefined;
    }

    const translatableElements = translatableElementService.getCached(translatableType);
    const enumValue = lodash.find(translatableElements, ['technicalName', technicalName]);
    return swoaTranslatableFilter(enumValue, $translate.use());
  };
}
