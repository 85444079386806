import angular from 'angular';
import 'restangular';
import LocalStorageModule from 'angular-local-storage';
import swoaConstants from '../app.constants';

export default angular
  .module('swoa.translatable-element.service', [
    'restangular',
    swoaConstants,
    LocalStorageModule,
  ])
  .factory('translatableElementService', translatableElementService)
  .name;

const STORAGE_KEY_NAME = 'translatable-element.cache';

/** @ngInject */
function translatableElementService($q, Restangular, lodash, localStorageService, $translate) {
  let cachedElementsPromise = null;

  // expose public service API
  return {
    fillCache,
    clearCache,
    deactivate,
    loadTranslatableElementsFromType,
    loadEditableTranslatableElements,
    loadTranslatableElementsFiltered,
    loadById,
    updateTranslatableElement,
    addTranslatableElement,
    loadTranslatableTypes,
    loadMaxSortByType,
    moveSortUp,
    moveSortDown,
    updateCustomSortForType,
    getCached,
  };

  // //////////

  function loadById(id) {
    return Restangular.all('translatable-elements').get(id);
  }

  function fillCache(force) {
    if (force || !cachedElementsPromise) {
      loadFromServer(force);
    }
  }

  function loadFromServer(force) {
    const cachedElements = localStorageService.get(STORAGE_KEY_NAME);

    if (!force && cachedElements && cachedElements.length) {
      cachedElementsPromise = $q.when(cachedElements);
    } else {
      cachedElementsPromise = Restangular.one('translatable-elements')
        .getList()
        .then((serverElements) => {
          localStorageService.set(STORAGE_KEY_NAME, serverElements);

          return serverElements;
        });
    }
  }

  function getCached(type) {
    const cachedElements = localStorageService.get(STORAGE_KEY_NAME);
    const upperCaseType = type.toUpperCase();
    return lodash.filter(cachedElements, elem => elem.type === upperCaseType);
  }

  function loadEditableTranslatableElements() {
    return Restangular.one('translatable-elements').customGET('translatable');
  }

  function updateTranslatableElement(translatableElement) {
    return Restangular.one('translatable-elements').customPUT(translatableElement, 'translatable-elements');
  }

  function addTranslatableElement(translatableElement) {
    return Restangular.one('translatable-elements').customPOST(translatableElement, 'translatable-elements');
  }

  function loadTranslatableTypes() {
    return Restangular.one('translatable-elements').customGET('translatable-type');
  }

  function deactivate(translatableElement) {
    return Restangular.one('translatable-elements', translatableElement.id).customPUT('', 'deactivate');
  }

  function loadMaxSortByType(type) {
    return Restangular.one('translatable-elements', type).customGET('max-sort');
  }

  function moveSortUp(translatableElement) {
    return Restangular.one('translatable-elements').customPUT(translatableElement, 'move-sort-up');
  }

  function moveSortDown(translatableElement) {
    return Restangular.one('translatable-elements').customPUT(translatableElement, 'move-sort-down');
  }

  function updateCustomSortForType(type, isCustomSorted) {
    return Restangular.one('translatable-elements', type).customPUT({}, 'set-custom-sort', { custom: isCustomSorted });
  }

  function loadTranslatableElementsFromType(type) {
    fillCache();

    const upperCaseType = type.toUpperCase();
    return cachedElementsPromise.then(data => lodash.filter(data, elem => elem.type === upperCaseType));
  }

  function clearCache() {
    localStorageService.remove(STORAGE_KEY_NAME);
    cachedElementsPromise = null;
  }

  function loadTranslatableElementsFiltered(filterFn) {
    const defer = $q.defer();
    loadEditableTranslatableElements()
      .then((translatableElements) => {
        const elements = lodash.filter(translatableElements, filterFn);
        lodash.forEach(elements, (translatableElement) => {
          translatableElement.translationType = $translate.instant(`applicationParameter.${translatableElement.type}`);
        });
        defer.resolve(elements);
      }, error => defer.reject(error));
    return defer.promise;
  }
}
