import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngMaterial from 'angular-material';
import swoaConstants from '../app.constants';
import swoaDate from '../components/date/date.filter';
import swoaCardSportYearService from '../card/sport-year/sport-year.service';
import swoaFileService from '../components/file/file.service';
import swoaPersonService from '../person/person.service';
import swoaFileStorageService from './file-storage.service';
import swoaTemplateService from '../admin/translations/templates/templates.service';
import swoaDialog from '../components/dialog/dialog.module';
import swoaNotification from '../components/notification/notification.module';
import swoaUserService from '../user/user.service';
import swoaPageSpinner from '../components/spinner/page-spinner.component';
import htmlTemplate from './file-storage.html';

export default angular
  .module('swoa.file-storage.file-storage', [
    uiRouter,
    ngMaterial,
    swoaConstants,
    swoaDate,
    swoaCardSportYearService,
    swoaFileService,
    swoaPersonService,
    swoaFileStorageService,
    swoaTemplateService,
    swoaDialog,
    swoaNotification,
    swoaPageSpinner,
    swoaUserService
  ])
  .component('swoaFileStorage', {
    template: htmlTemplate,
    bindings: {
      canUpload: '<',
      canUploadOwn: '<',
      canDelete: '<',
      canDeleteOwn: '<',
      personId: '<',
      maxSize: '@',
      template: '<',
      backToDialog: '='
    },
    controller: FileStorageController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function FileStorageController(
  $rootScope,
  $stateParams,
  $mdDialog,
  lodash,
  ConfigConstants,
  fileService,
  sportYearService,
  personService,
  fileStorageService,
  templateService,
  dialogService,
  notificationService,
  userService
) {
  const vm = this;

  const sportYearId = parseInt($stateParams.yearId, 10);

  // loaded data and error handling
  vm.existingFiles = null;
  vm.notificationKey = null;

  vm.selectedFile = null;
  vm.stepValid = false;
  vm.successInfo = null;
  vm.userPersonId = userService.getCurrentUser().person.id;

  // functions
  vm.checkStep = checkStep;
  vm.closeDialog = closeDialog;
  vm.deleteFile = deleteFile;
  vm.downloadFile = downloadFile;
  vm.getFileSuffix = getFileSuffix;
  vm.doUpload = doUpload;

  // for tests only!
  vm._deleteFileAndFileStorage = deleteFileAndFileStorage;
  vm._getImportUrl = getImportUrl;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    const storeExistingFiles = files => {
      vm.existingFiles = files;
    };
    if (vm.personId) {
      personService.getProfileFiles(vm.personId).then(storeExistingFiles);
    } else if (vm.template) {
      templateService
        .getTemplatesWithTypeAndLanguage(vm.template)
        .then(storeExistingFiles);
    } else {
      sportYearService.getFiles(sportYearId).then(storeExistingFiles);
    }

    // initial values
    vm.stepValid = false;
  }

  // called by file-upload-component after file selection
  function checkStep() {
    vm.successInfo = null;
    vm.stepValid = vm.selectedFile;
  }

  function deleteFile(fileStorage) {
    dialogService
      .showConfirmationDialog('sportYear.files.delete', {
        fileName: fileStorage.fileName
      })
      .then(() => deleteFileAndFileStorage(fileStorage))
      .finally(() => {
        if (vm.backToDialog && angular.isFunction(vm.backToDialog)) {
          vm.backToDialog();
        }
      });
  }

  function deleteFileAndFileStorage(fileStorage) {
    let deleteFilePromise;
    if (vm.template) {
      deleteFilePromise = templateService.deleteTemplate(fileStorage);
    } else {
      deleteFilePromise = fileStorageService.deleteFileStorage(fileStorage.id);
    }

    return deleteFilePromise.then(() => {
      vm.successInfo = 'sportYear.files.deleted';
      lodash.remove(vm.existingFiles, file => file.id === fileStorage.id);
      if (vm.personId) {
        $rootScope.$broadcast(
          'swoa:personProfileFilesChanged',
          vm.personId,
          vm.existingFiles.length
        );
      } else {
        $rootScope.$broadcast(
          'swoa:sportYearFilesChanged',
          sportYearId,
          vm.existingFiles.length
        );
      }
    }, notificationService.errorHandler(vm));
  }

  function doUpload() {
    $rootScope.showSpinner = true;
    fileService
      .upload(getImportUrl(vm.selectedFile.name), vm.selectedFile)
      .then(response => {
        if (response.status === 200) {
          vm.successInfo = 'sportYear.files.added';
          if (vm.personId) {
            $rootScope.$broadcast(
              'swoa:personProfileFilesChanged',
              vm.personId,
              (vm.existingFiles || []).length + 1
            );
          } else {
            $rootScope.$broadcast(
              'swoa:sportYearFilesChanged',
              sportYearId,
              (vm.existingFiles || []).length + 1
            );
          }
        }

        activate();
      }, notificationService.errorHandler(vm))
      .finally(() => ($rootScope.showSpinner = false));
  }

  function getImportUrl(fileName) {
    if (vm.personId) {
      return `${ConfigConstants.api.url}/persons/${vm.personId}/profile-files?fileName=${fileName}`;
    } else if (vm.template) {
      return buildTemplateUploadUrl(fileName);
    }
    return `${ConfigConstants.api.url}/sportyears/${sportYearId}/files?fileName=${fileName}`;
  }

  function downloadFile(fileStorage) {
    $rootScope.showSpinner = true;
    let downloadPromise;
    if (vm.template) {
      downloadPromise = fileService.downloadPersistentStorage(fileStorage);
    } else {
      downloadPromise = fileService.downloadFileStorage(fileStorage);
    }
    downloadPromise.finally(() => ($rootScope.showSpinner = false));
  }

  function getFileSuffix(file) {
    if (file && file.extension) {
      return file.extension.toLowerCase();
    } else if (file && file.fileName) {
      return file.fileName.substring(file.fileName.lastIndexOf('.') + 1);
    }
    return '_page';
  }

  function closeDialog() {
    $mdDialog.hide();
  }

  function buildTemplateUploadUrl(fileName) {
    let importUrl = `${ConfigConstants.api.url}/templates/${vm.template.type}`;
    if (vm.template.language) {
      importUrl += `/${vm.template.language.toLowerCase()}`;
    }
    importUrl += `?fileName=${fileName}`;
    return importUrl;
  }
}
