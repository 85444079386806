import angular from 'angular';
import swoaDialog from '../../components/dialog/dialog.module';
import swoaCardSportYearService from './sport-year.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './sport-year-contact.html';

export default angular
  .module('swoa.card.sport-year.sport-year-contact', [
    swoaDialog,
    swoaCardSportYearService,
    swoaNotification
  ])
  .component('swoaSportYearContact', {
    template: htmlTemplate,
    bindings: {
      cardYearId: '<',
      sportYearCategory: '<'
    },
    controller: CardDetailTechnicianController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardDetailTechnicianController(
  $mdDialog,
  sportYearService,
  notificationService
) {
  const vm = this;

  const onError = notificationService.errorHandler(vm);

  // view data
  vm.organisation = null;
  vm.headChiefs = null;
  vm.chiefsNachwuchs = null;
  vm.chiefsLeistungssport = null;

  // error handling
  vm.notificationKey = null;

  // functions
  vm.showCardListContacts = showCardListContacts;
  vm.showLeistungssport = showLeistungssport;
  vm.showNachwuchs = showNachwuchs;

  vm.closeDialog = closeDialog;

  // for tests only!
  vm._initContactInfo = initContactInfo;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    sportYearService.loadSportYearContacts(vm.cardYearId).then(contactInfo => {
      initContactInfo(contactInfo);
    }, onError);
  }

  function initContactInfo(contactInfo) {
    vm.organisation = contactInfo.organisation;
    if (contactInfo.headChiefs && contactInfo.headChiefs.length > 0) {
      vm.headChiefs = contactInfo.headChiefs;
    }
    if (contactInfo.chiefsNachwuchs && contactInfo.chiefsNachwuchs.length > 0) {
      vm.chiefsNachwuchs = contactInfo.chiefsNachwuchs;
    }
    if (
      contactInfo.chiefsLeistungssport &&
      contactInfo.chiefsLeistungssport.length > 0
    ) {
      vm.chiefsLeistungssport = contactInfo.chiefsLeistungssport;
    }
  }

  function showCardListContacts() {
    if (vm.chiefsLeistungssport || vm.chiefsNachwuchs) {
      return true;
    }
    return false;
  }

  function showNachwuchs() {
    if (vm.sportYearCategory === 'TALENT' && vm.chiefsNachwuchs) {
      return true;
    }
    return false;
  }

  function showLeistungssport() {
    if (
      (vm.sportYearCategory === 'ELITE' ||
        vm.sportYearCategory === 'TRAINER') &&
      vm.chiefsLeistungssport
    ) {
      return true;
    }
    return false;
  }

  function closeDialog() {
    $mdDialog.hide();
  }
}
