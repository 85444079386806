import angular from 'angular';

export default angular
  .module('swoa.admin.report.exports.nwfTrainerPercentage', [])
  .component('swoaExportsNwfTrainerPercentage', {
    template: `
<swoa-exports-nwf-common-content favorite-name="exportNwfTrainerPercentageFavorites"
                                       export-url="nwf-trainer-percentage"
                                       export-name="nwf-trainer-percentage"
                                       endpoint-for-attributes="exportable-columns-nwf-trainer"
                                       show-years="true">
</swoa-exports-nwf-common-content>`
  }).name;
