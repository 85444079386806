import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './person-search-page.html';

export default angular
  .module('swoa.person.search.person-search-page', [
    swoaConstants,
  ])
  .component('swoaPersonSearchPage', {
    template: htmlTemplate,
    bindings: {
      currentTab: '<',
    },
    controller: SwoaPersonSearchPageController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function SwoaPersonSearchPageController($rootScope, $stateParams) {
  const vm = this;

  vm.notificationKey = $stateParams.notificationKey;
  vm.isSuccess = $stateParams.isSuccess;
  vm.canCreateNewPersons = $rootScope.uaPermission('person_create_w');
}

