import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaDate from '../../components/date/date.filter';
import htmlTemplate from './person-creation-similar-persons-table.html';

export default angular
  .module('swoa.person.creation.person-creation-similar-persons-table', [
    swoaConstants,
    swoaDate
  ])
  .component('swoaPersonCreationSimilarPersonsTable', {
    template: htmlTemplate,
    bindings: {
      personDetailList: '<',
      selectedPerson: '='
    },
    controller: PersonCreationSimilarPersonsTableController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonCreationSimilarPersonsTableController($scope) {
  const vm = this;

  vm.rowClicked = rowClicked;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    $scope.$watch('vm.personDetailList', resetTable);
  }

  function resetTable() {
    vm.page = 1;
    vm.selectedPerson = null;
  }

  function rowClicked(event, person) {
    event.preventDefault();
    event.stopPropagation();
    vm.selectedPerson = person.id;
  }
}
