import angular from 'angular';

export default angular
  .module('swoa.get.service', [])
  .factory('get', getService)
  .name;

/** @ngInject */
function getService() {
  function isGettable(obj) {
    if (!obj) {
      return false;
    }

    return isGettable.GETTABLE[typeof obj];
  }

  isGettable.GETTABLE = {
    object: true,
    function: true,
    string: true,
    boolean: false,
    symbol: false,
  };

  function getPath(root, path) {
    const segments = path.split('.');
    let obj = root;

    for (let i = 0; i < segments.length; i++) {
      if (!isGettable(obj)) {
        return undefined;
      }

      obj = obj[segments[i]];
    }

    return obj;
  }

  function get(obj, ...args) {
    return getPath(obj, args.join('.'));
  }

  get.withDefault = function getWithDefault(obj, path, defaultValue) {
    const value = get(obj, path);

    return value === undefined ? defaultValue : value;
  };

  return get;
}
