import angular from 'angular';
import 'restangular';
import { CALENDAR_YEAR_ROUTE, TRAINER_ROUTE, VARIABLE_AMOUNT_PERIOD_ROUTE } from '../nwf-contribution.service';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaPersonService from '../../person/person.service';

export default angular
  .module('swoa.nwf-contribution.variable-amount.nwf-variable-amount-period.service', [
    'restangular',
    swoaTranslatable,
    swoaPersonService,
  ])
  .factory('nwfVariableAmountPeriodService', nwfVariableAmountPeriodService)
  .name;

/** @ngInject */
function nwfVariableAmountPeriodService(moment, Restangular, swoaTranslatableFilter, $translate, personService) {
  const service = Restangular.all(VARIABLE_AMOUNT_PERIOD_ROUTE);
  const trainerService = Restangular.all(TRAINER_ROUTE);

  return {
    getBreadcrumbTitle,
    loadPeriod,
    loadPeriodWithTrainers,
    goToStatus,
    loadTrainer,
    removeTrainer,
    updateTrainerData,
    addPerson,
    getLastYearFullVariableAmount,
    getCategories,
    getCalendarYearPayments,
    getInstitutionPayments,
    importFromPreviousPeriod,
  };

  // //////////

  function loadPeriod(id) {
    return service.get(id);
  }

  function loadPeriodWithTrainers(id) {
    return service.get(id, { withTrainers: 'true' });
  }

  function getBreadcrumbTitle(periodId) {
    return service.get(periodId).then((period) => {
      const translatedName = $translate.instant('nwfContributions.variableAmount.breadcrumb');
      const translatedSport = swoaTranslatableFilter(period.sport.translatedName, 'de');
      const fromYear = moment(period.validFrom).year();
      const untilYear = moment(period.validUntil).year();
      return `${translatedName} ${fromYear} - ${untilYear} ${translatedSport}`;
    });
  }

  function goToStatus(periodId, status) {
    return Restangular.one(VARIABLE_AMOUNT_PERIOD_ROUTE, periodId).customPUT(status, 'status');
  }

  function loadTrainer(trainerId) {
    return trainerService.get(trainerId);
  }

  function removeTrainer(trainerId) {
    return Restangular.one(TRAINER_ROUTE, trainerId).remove();
  }

  function updateTrainerData(trainerId, trainerData) {
    return Restangular.one(TRAINER_ROUTE, trainerId).customPOST(trainerData, 'trainer-data');
  }

  function addPerson(periodId, person) {
    if (!person.id) {
      person.foreignIds = personService.unhashifyForeignIds(person.foreignIds);
    }
    return Restangular.one(VARIABLE_AMOUNT_PERIOD_ROUTE, periodId).customPOST(person, 'add-trainer');
  }

  function getLastYearFullVariableAmount(calendarYear, sportId) {
    return Restangular.one(CALENDAR_YEAR_ROUTE, calendarYear).one('last-year-full-variable-amount', sportId).get();
  }

  function getCategories(periodId) {
    return Restangular.one(VARIABLE_AMOUNT_PERIOD_ROUTE, periodId).customGET('categories');
  }

  function getCalendarYearPayments(periodId) {
    return Restangular.one(VARIABLE_AMOUNT_PERIOD_ROUTE, periodId).customGET('calendar-year-payments');
  }

  function getInstitutionPayments(year, periodId) {
    return Restangular.one(CALENDAR_YEAR_ROUTE, year).one('institution-payments', periodId).get();
  }

  function importFromPreviousPeriod(periodId) {
    return Restangular.one(VARIABLE_AMOUNT_PERIOD_ROUTE, periodId).customPOST(periodId, 'import-trainer');
  }
}
