import angular from 'angular';

export default angular
  .module('swoa.admin.report.exports.nwfVariableAmount', [])
  .component('swoaExportsNwfVariableAmount', {
    template: `
<swoa-exports-nwf-common-content favorite-name="exportNwfVariableAmountFavorites"
                                       export-url="nwf-variable-amount"
                                       export-name="nwf-variable-amount"
                                       endpoint-for-attributes="exportable-columns-nwf-variable-amount"
                                       show-years="true">
</swoa-exports-nwf-common-content>`
  }).name;
