import angular from 'angular';
import swoaDate from '../../components/date/date.filter';
import swoaNotification from '../../components/notification/notification.module';
import swoaPersonService from '../person.service';
import swoaPersonSync from '../sync/person-sync.module';
import htmlTemplate from './person-creation-sync.html';

export default angular
  .module('swoa.person.creation.person-creation-sync', [
    swoaDate,
    swoaNotification,
    swoaPersonService,
    swoaPersonSync
  ])
  .component('swoaPersonCreationSync', {
    template: htmlTemplate,
    bindings: {},
    controller: PersonCreationSyncController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonCreationSyncController(
  $q,
  $stateParams,
  $state,
  personService,
  notificationService,
  personSyncService
) {
  const vm = this;

  vm.personCreationId = null;
  vm.personId = null;
  vm.syncData = null;

  // error handling
  vm.notificationKey = null;

  vm.saveSync = saveSync;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.personCreationId = $stateParams.personCreationId;
    vm.personId = $stateParams.personId;

    if (vm.personCreationId && vm.personId) {
      const calls = [];
      calls.push(personService.loadPerson(vm.personCreationId));
      calls.push(personService.loadPerson(vm.personId));

      $q.all(calls).then(result => {
        const personCreation = result[0];
        const person = result[1];

        // if you go through manual search, it's possible to select the same person from the result that
        // we are trying to edit the request for. so in that case just show an error message
        if (personCreation.swissOlympicId === person.swissOlympicId) {
          vm.notificationKey = 'personCreation.sync.samePersonSelected';
          return;
        }

        personCreation.configKey = 'personKey';
        vm.syncData = personSyncService.extendSyncModel({
          cardImport: personCreation,
          person
        });

        // set ID and version of the person to replace the new entry with
        vm.syncData.syncResult.id = person.id;
        vm.syncData.syncResult.version = person.version;
      }, notificationService.errorHandler(vm));
    }
  }

  function saveSync() {
    personService
      .saveSync(vm.personCreationId, vm.syncData.syncResult)
      .then(() => {
        $state.go('personMutations', $stateParams);
      }, notificationService.errorHandler(vm));
  }
}
