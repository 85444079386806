import angular from 'angular';

export default angular
  .module('swoa.admin.reports.exports.attribute-selector', [])
  .component('swoaExportsAttributesSelector', {
    template: `<div layout="row" class="input-row">
      <swoa-input-item-selector flex
                                ng-model="vm.attributes.selected"
                                available-items="vm.attributes.all"
                                are-translatable-elements="true"
                                left-title-key="report.allAttributes"
                                right-title-key="report.selectedAttributes">
      </swoa-input-item-selector>
    </div>`,
    bindings: {
      attributes: '=',
    },
    controllerAs: 'vm',
    controller: ExportsAttributeSelector,
  }).name;

/** @ngInject */
function ExportsAttributeSelector() {
  const vm = this;

  vm.attributes = {
    selected: [],
    all: [],
  };
}
