import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaOrganisationService from '../organisation.service';
import htmlTemplate from './organisation-search.html';

export default angular
  .module('swoa.organisation.organisation-search', [
    swoaConstants,
    swoaNotification,
    swoaOrganisationService,
  ])
  .component('swoaOrganisationSearch', {
    template: htmlTemplate,
    bindings: {
      selectButtonTextKey: '@',
      selectAction: '&',
      cancelAction: '&',
      withoutSelection: '<',
    },
    controller: OrganisationSearchController,
    controllerAs: 'vm',
  })
  .name;

export const MAX_RESULTS = 1000;

/** @ngInject */
function OrganisationSearchController($location, $stateParams, organisationService, notificationService) {
  const vm = this;

  // loaded data
  vm.foundOrganisations = null;
  vm.totalOrganisationCount = null;
  vm.hintKey = 'organisationSearch.enterQuery';

  // selections
  vm.selectedOrganisation = null;
  vm.searchText = $stateParams.query;

  // error handling
  vm.notificationKey = null;

  // functions
  vm.search = search;
  vm.keyPress = keyPress;

  search();

  // //////////

  function search() {
    if (vm.searchText && vm.searchText.length > 2) {
      $location.search('query', vm.searchText);

      vm.hintKey = 'organisationSearch.searching';
      vm.foundOrganisations = null;

      organisationService.queryOrganisations(vm.searchText, 0, MAX_RESULTS).then((result) => {
        vm.foundOrganisations = result.result;
        vm.totalOrganisationCount = result.totalCount;
      }, notificationService.errorHandler(vm)).finally(() => {
        vm.hintKey = 'organisationSearch.found';
      });
    } else {
      vm.hintKey = 'organisationSearch.enterQuery';
      vm.foundOrganisations = null;
      vm.totalOrganisationCount = null;
    }
  }

  function keyPress(event) {
    if (event.keyCode === 13 /* ENTER */) {
      // circumvent the debounce when pressing enter
      if (event.target && event.target.value) {
        vm.searchText = event.target.value;
      }
      // search again on enter
      search();
    } else if (event.charCode > 0 || event.keyCode === 8 /* BACKSPACE */ || event.keyCode === 27 /* ESC */) {
      // only clear when typing or deleting characters or pressing esc
      vm.hintKey = 'organisationSearch.searching';
      vm.foundOrganisations = null;
      vm.totalOrganisationCount = null;
    }
  }
}
