import angular from 'angular';
import swoaPersonMutationRoutes from './person-mutation.routes';
import swoaPersonMutationService from './person-mutation.service';
import swoaPersonMutationPersonMutationSync from './person-mutation-sync.component';
import swoaPersonMutationPersonMutationDetail from './person-mutation-detail.component';
import swoaPersonMutationPersonMutations from './person-mutations.component';

export default angular
  .module('swoa.person.mutation', [
    swoaPersonMutationRoutes,
    swoaPersonMutationService,
    swoaPersonMutationPersonMutationSync,
    swoaPersonMutationPersonMutationDetail,
    swoaPersonMutationPersonMutations,
  ])
  .name;

