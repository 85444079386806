import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaDate from '../date/date.filter';

export default angular
  .module('swoa.object-property', [
    swoaConstants,
    swoaDate,
    angularTranslate,
  ])
  .filter('swoaObjectProperty', swoaObjectPropertyFilter)
  .name;

/** @ngInject */
function swoaObjectPropertyFilter(lodash, swoaDateFilter, $translate, $sce) {
  function formatDataByType(config, value) {
    if (config.dataType === 'date') {
      return swoaDateFilter(value);
    } else if (config.dataType === 'enum') {
      return $translate.instant(config.translationPrefix + value);
    } else if (config.dataType === 'foreignId') {
      return value.idValue;
    }
    return value;
  }

  function trust(value) {
    return $sce.trustAsHtml(`${value}`);
  }

  return (obj, config) => {
    // we need to have valid input, otherwise we can't do a lot
    if (!obj || !obj.configKey || !config) {
      return undefined;
    }
    const objKey = config[obj.configKey];
    const value = lodash.get(obj, objKey);

    if (value === null || value === undefined || value.length === 0) {
      return trust($translate.instant('app.emptyValue'));
    }

    if (config.dataType) {
      return trust(formatDataByType(config, value));
    }
    return trust(value);
  };
}

