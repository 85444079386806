import angular from 'angular';
import htmlTemplate from './comment-form.html';

export default angular
  .module('swoa.comment.comment-form', [])
  .component('swoaCommentForm', {
    template: htmlTemplate,
    bindings: {
      addAction: '&',
    },
    controller: CommentFormController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function CommentFormController() {
  const vm = this;

  vm.comment = {};

  vm.submit = submit;

  // //////////

  function submit(event) {
    event.preventDefault();
    if (vm.comment.text && vm.comment.text.length) {
      vm.addAction({ $comment: vm.comment });

      vm.comment = {};
      angular.element(event.target).find('input').blur();
      vm.commentForm.$setUntouched();
    }
  }
}
