import angular from 'angular';
import swoaNwfContributionComponent from './nwf-contribution.component';
import swoaNwfContributionRoutes from './nwf-contribution.routes';
import swoaNwfContributionService from './nwf-contribution.service';
import swoaNwfPeriods from './nwf-periods.component';
import swoaNwfBaseAmountPeriod from './base-amount/nwf-base-amount-period.module';
import swoaNwfVariableAmountPeriod from './variable-amount/nwf-variable-amount-period.module';
import swoaNwfContributionOrganisations from './nwf-contribution-associations.component';
import swoaNwfControlling from './controlling/nwf-controlling.module';
import swoaNwfAbacus from './abacus/nwf-abacus.module';

export default angular
  .module('swoa.nwf-contribution', [
    swoaNwfContributionComponent,
    swoaNwfContributionRoutes,
    swoaNwfContributionService,
    swoaNwfPeriods,
    swoaNwfBaseAmountPeriod,
    swoaNwfVariableAmountPeriod,
    swoaNwfContributionOrganisations,
    swoaNwfControlling,
    swoaNwfAbacus,
  ])
  .name;
