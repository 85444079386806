import angular from 'angular';

export default angular
  .module('swoa.admin.report.exports.nwfTrainerCurrent', [])
  .component('swoaExportsNwfTrainerCurrent', {
    template: `
<swoa-exports-nwf-common-content favorite-name="exportNwfTrainerFavorites"
                                       export-url="nwf-trainer-current"
                                       export-name="nwf-trainer-current"
                                       endpoint-for-attributes="exportable-columns-nwf-trainer"
                                       show-years="false">
</swoa-exports-nwf-common-content>`
  }).name;
