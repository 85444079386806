import angular from 'angular';

export default angular
  .module('swoa.positive-negative', [])
  .filter('swoaPositiveNegative', swoaPositiveNegative)
  .name;

/** @ngInject */
function swoaPositiveNegative() {
  return (someNumber) => {
    if (someNumber === null || someNumber === undefined || isNaN(someNumber)) {
      return undefined;
    }
    if (someNumber === 0) {
      return 0;
    }
    return someNumber > 0 ? `+${someNumber}` : `-${Math.abs(someNumber)}`;
  };
}

