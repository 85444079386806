import angular from 'angular';
import htmlTemplate from './page-spinner.html';

export default angular
  .module('swoa.page-spinner', [])
  .component('swoaPageSpinner', {
    template: htmlTemplate,
    bindings: {
      progress: '<',
    },
    controllerAs: 'vm',
  })
  .name;
