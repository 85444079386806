import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaCardCardsSummary from '../../card/cards-summary.component';
import htmlTemplate from './warning-icon.html';

export default angular
  .module('swoa.warning-icon', [
    angularTranslate,
    swoaCardCardsSummary,
  ])
  .component('swoaWarningIcon', {
    template: htmlTemplate,
    bindings: {
      warning: '<',
      warnings: '<',
      showFullCardValidity: '<',
    },
    controllerAs: 'vm',
  })
  .name;
