import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaCityService from '../../city/city.service';
import swoaValidatorMessages from '../validator-messages/validator-messages.component';
import htmlTemplate from './input-city-autocomplete.html';

export default angular
  .module('swoa.input-city-autocomplete', [
    swoaConstants,
    swoaCityService,
    swoaValidatorMessages
  ])
  .component('swoaInputCityAutocomplete', {
    template: htmlTemplate,
    bindings: {
      name: '@',
      autocompleteType: '@',
      labelKey: '@?',
      ngModel: '=',
      placeholder: '@',
      isRequired: '<',
      isReadOnly: '<',
      searchText: '=',
      selectedItem: '=',
      updatableElement: '=',
      messages: '=',
      messagesIf: '<',
      customMessageKeys: '<'
    },
    controller: InputCityAutocompleteController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function InputCityAutocompleteController(lodash, cityService) {
  const vm = this;

  vm.findCityOrZip = findCityOrZip;
  vm.onSelectedItemChanged = onSelectedItemChanged;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.allCities = cityService.getAllCities();
  }

  function findCityOrZip(searchText) {
    const theSearchText = (searchText || '').toLowerCase();
    return lodash.filter(vm.allCities, elem => {
      const text = (getDisplayAttribute(elem) || '').toString();
      return lodash.includes(text.toLowerCase(), theSearchText);
    });
  }

  function onSelectedItemChanged() {
    const valueToUpdate = getUpdatableAttribute(vm.selectedItem);
    const valueToDisplay = getDisplayAttribute(vm.selectedItem);
    if (vm.selectedItem && valueToUpdate) {
      vm.updatableElement = valueToUpdate;
    }
    if (vm.selectedItem && valueToDisplay) {
      vm.ngModel = valueToDisplay;
    }
  }

  function getUpdatableAttribute(item) {
    if (item && vm.autocompleteType === 'zip') {
      return item.name;
    } else if (item && vm.autocompleteType === 'city') {
      return item.zip;
    }
    return item;
  }

  function getDisplayAttribute(item) {
    if (item && vm.autocompleteType === 'zip') {
      return item.zip;
    } else if (item && vm.autocompleteType === 'city') {
      return item.name;
    }
    return item;
  }
}
