import angular from 'angular';

import ngMaterial from 'angular-material';
import swoaConstants from '../../app.constants';
import htmlTemplate from './ui-kitchensink.html';

export default angular
  .module('swoa.admin.ui-kitchensink.component', [ngMaterial, swoaConstants])
  .component('swoaUiKitchensink', {
    template: htmlTemplate,
    bindings: {},
    controller: UiKitchensinkController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function UiKitchensinkController($window, $q, $timeout, moment, dialogService) {
  const vm = this;

  vm.modules = loadFile('modules.json');
  vm.icons = [];
  vm.usStateList = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Federated States of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];
  vm.dialogService = dialogService;

  vm.loadFile = loadFile;
  vm.asDate = asDate;
  vm.alert = alert;
  vm.resolveInSeconds = resolveInSeconds;
  vm.setInSeconds = setInSeconds;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    loadIcons();
  }

  function loadFile(fileName) {
    // eslint-disable-next-line
    return require(`./modules/${fileName}`);
  }

  function loadIcons() {
    const context = require.context('../../../assets/icons/', true, /\.svg/);
    vm.icons = context.keys().map(key =>
      // key looks like this ./icon_name.svg but we want just icon_name
      key.substring(2, key.lastIndexOf('.'))
    );
  }

  function asDate(dateStr) {
    return moment(dateStr, 'YYYY-MM-DD').toDate();
  }

  function alert(str) {
    $window.alert(str);
  }

  function resolveInSeconds(seconds) {
    const promise = $q.defer();
    $timeout(() => {
      promise.resolve();
    }, seconds * 1000);
    return promise.promise;
  }

  function setInSeconds(property, value, seconds) {
    resolveInSeconds(seconds).then(() => {
      vm[property] = value;
    });
  }
}
