import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.admin.news.service', ['restangular'])
  .factory('newsService', newsService).name;

/** @ngInject */
function newsService(Restangular) {
  const NEWS_ROUTE = 'news';
  const service = Restangular.all(NEWS_ROUTE);

  service.loadByRoleTypes = loadByRoleTypes;
  return service;

  // //////////

  function loadByRoleTypes(roleTypes) {
    return Restangular.all(NEWS_ROUTE).customGET('list', {
      roleType: roleTypes,
    });
  }
}
