import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import 'restangular';
import swoaJobsService from './jobs.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import htmlTemplate from './jobs-list.html';

export default angular
  .module('swoa.admin.jobs.jobs-list', [
    uiRouter,
    'restangular',
    swoaJobsService,
    swoaNotification,
    swoaTranslatable
  ])
  .component('swoaJobsList', {
    template: htmlTemplate,
    bindings: {},
    controller: JobsListController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function JobsListController(
  $state,
  $scope,
  $rootScope,
  $interval,
  lodash,
  Restangular,
  jobsService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    jobsWithStartDate = [
      'ProfileActivationMailService',
      'NwfContainerGeneratorService',
      'NwfPayment1GeneratorService',
      'NwfPayment2GeneratorService'
    ];

  vm.jobs = [];
  vm.notificationKey = null;
  vm.order = 'name';

  // Public methods
  vm.canStartJob = canStartJob;
  vm.canRescheduleTimer = canRescheduleTimer;
  vm.startJob = startJob;
  vm.rescheduleTimer = rescheduleTimer;
  vm.showRunningInfo = showRunningInfo;
  vm.showStartDate = showStartDate;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    loadJobs();
    const promise = $interval(loadJobs, 2000);

    $scope.$on('$destroy', () => {
      $interval.cancel(promise);
    });
  }

  function loadJobs() {
    jobsService.getList().then(updateJobs, onError);
  }

  function updateJobs(elems) {
    if (!vm.jobs) {
      vm.jobs = elems;
    } else if (elems.length >= vm.jobs.length) {
      elems.forEach(newJob => {
        const jobToBeUpdated = lodash.find(
          vm.jobs,
          existingJob => existingJob.id === newJob.id
        );
        if (jobToBeUpdated) {
          jobToBeUpdated.status = newJob.status;
          jobToBeUpdated.nextRun = newJob.nextRun;
          jobToBeUpdated.startedAt = newJob.startedAt;
        } else {
          vm.jobs.push(newJob);
        }
      });
    } else {
      vm.jobs = lodash.intersectionBy(vm.jobs, elems, 'id');
    }
  }

  function canStartJob(job) {
    return (
      !existManualTimerWithName(job.name) &&
      job.status === 'ACTIVE' &&
      job.canTimerBeCreated === true
    );
  }

  function canRescheduleTimer(job) {
    return (
      job.canTimerBeCreated === true &&
      job.calendarTimer === true &&
      job.status === 'IN_TIMEOUT'
    );
  }

  function startJob(job) {
    vm.notificationKey = null;
    if (lodash.includes(jobsWithStartDate, job.name) && !job.startDate) {
      vm.notificationKey = 'jobs.error.startJobWithoutDate';
      return null;
    }
    return jobsService.startJob(job.id, job.startDate).then(() => {
      job.startDate = null;
      loadJobs();
    }, onError);
  }

  function rescheduleTimer(job) {
    jobsService.rescheduleTimer(job).then(loadJobs(), onError);
  }

  function existManualTimerWithName(name) {
    return lodash.find(
      vm.jobs,
      job => job.name === name && job.calendarTimer === false
    );
  }

  function showRunningInfo(job) {
    return job.status === 'IN_TIMEOUT';
  }

  function showStartDate(job) {
    return canStartJob(job) && lodash.includes(jobsWithStartDate, job.name);
  }
}
