import angular from 'angular';
import template from './nwf-trainer-national-percentage.component.html';

export default angular
  .module('swoa.nwf-contribution.variable-amount.nwf-trainer-national-percentage', [])
  .component('swoaNwfTrainerNationalPercentage', {
    template,
    controllerAs: 'vm',
    controller: NwfTrainerNationalPercentageActive,
    bindings: {
      trainer: '=',
      editing: '<',
      periodStatus: '<',
    },
  }).name;


/** @ngInject */
function NwfTrainerNationalPercentageActive() {

}
