import angular from 'angular';
import swoaCardService from './card.service';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './card-comments.html';

export default angular
  .module('swoa.card.card-comments', [swoaCardService, swoaNotification])
  .component('swoaCardComments', {
    template: htmlTemplate,
    bindings: {
      cardId: '=',
      canAddComments: '<',
      canEditComments: '<',
      canEditOwnComments: '<'
    },
    controller: CardCommentsController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardCommentsController(
  $rootScope,
  $scope,
  cardService,
  notificationService
) {
  const vm = this;
  const onError = notificationService.errorHandler(vm);

  vm.history = null;
  vm.selectedHistoryEntry = null;

  vm.addComment = addComment;
  vm.deleteComment = deleteComment;
  vm.selectHistoryEntry = selectHistoryEntry;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    cardService.loadCardHistory(vm.cardId).then(history => {
      vm.history = history;
      vm.selectedHistoryEntry =
        history && cardService.findCard(history, vm.cardId);
      if (vm.selectedHistoryEntry) {
        vm.cardId = vm.selectedHistoryEntry.card.id;
      }
    }, onError);

    $scope.$on('swoa:cardUpdated', onCardUpdated);
    $scope.$on('swoa:cardCommentAdded', onCommentAdded);
    $scope.$on('swoa:cardCommentDeleted', onCommentDeleted);
    $scope.$on('swoa:cardResultUpdated', onResultUpdated);
  }

  function addComment(newComment) {
    const cardId = vm.selectedHistoryEntry.card.id;
    return cardService.addComment(cardId, newComment).then(comment => {
      $rootScope.$broadcast('swoa:cardCommentAdded', cardId, comment);
      return comment;
    }, onError);
  }

  function deleteComment(comment) {
    const cardId = vm.selectedHistoryEntry.card.id;
    return cardService.deleteComment(cardId, comment.id).then(() => {
      $rootScope.$broadcast('swoa:cardCommentDeleted', cardId, comment);
      return comment;
    }, onError);
  }

  function selectHistoryEntry(historyEntry) {
    vm.selectedHistoryEntry = historyEntry;
    $rootScope.$broadcast('swoa:historyEntryChanged', historyEntry);
  }

  function onCardUpdated(event, card) {
    const historyEntry = getHistoryEntry(card.id);
    if (historyEntry && historyEntry.card) {
      historyEntry.card.active = card.active;
      historyEntry.card.type = card.type;
    }
  }

  function onCommentAdded(event, cardId, comment) {
    const historyEntry = getHistoryEntry(cardId);
    if (historyEntry && historyEntry.card) {
      historyEntry.card.comments = historyEntry.card.comments || [];
      historyEntry.card.comments.push(comment);
      historyEntry.card.comments.sort(compareComments).reverse();
    }
  }

  function onResultUpdated(event, cardId, cardResult) {
    const historyEntry = getHistoryEntry(cardId);
    if (historyEntry && historyEntry.card) {
      historyEntry.card.eliteResult = cardResult;
    }
  }

  function onCommentDeleted(event, cardId, comment) {
    const historyEntry = getHistoryEntry(cardId);
    if (historyEntry && historyEntry.card && historyEntry.card.comments) {
      const commentIndex = historyEntry.card.comments.indexOf(comment);
      if (commentIndex > -1) {
        historyEntry.card.comments.splice(commentIndex, 1);
      }
    }
  }

  function getHistoryEntry(cardId) {
    for (let i = 0; i < vm.history.length; i++) {
      if (vm.history[i].card.id === cardId) {
        return vm.history[i];
      }
    }

    return null;
  }

  function compareComments(a, b) {
    if (a.creationTime > b.creationTime) {
      return 1;
    }
    if (a.creationTime < b.creationTime) {
      return -1;
    }
    return 0;
  }
}
