import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.logout.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('logout', {
    url: '/logout',
    template: '<swoa-logout></swoa-logout>',
    data: {
      anonymousAccessAllowed: true
    }
  });
}
