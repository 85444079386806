import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaDialog from '../../components/dialog/dialog.module';
import swoaSportYearSettingService from './sport-year-setting.service';
import htmlTemplate from './card-parameter-detail.html';

export default angular
  .module('swoa.admin.card-parameter-detail', [
    swoaConstants,
    swoaNotification,
    swoaDialog,
    swoaSportYearSettingService
  ])
  .component('swoaCardParameterDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: CardParameterDetailsController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardParameterDetailsController(
  $q,
  $rootScope,
  $state,
  $stateParams,
  $translate,
  notificationService,
  sportYearSettingService,
  dialogService,
  changeLogService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.sportId = $stateParams.sportId;
  vm.setting = {};
  vm.originalSetting = null;
  vm.editing = false;

  // functions
  vm.sorterFunction = sorterFunction;
  vm.startEdit = startEdit;
  vm.cancelEdit = cancelEdit;
  vm.closeDetail = closeDetail;
  vm.save = save;
  vm.showChangeLogDialog = showChangeLogDialog;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    let promise;
    if (vm.sportId === 'TECHNICIAN' || vm.sportId === 'FUNCTIONARY') {
      promise = sportYearSettingService.loadSportYearSettingByCategory(
        vm.sportId
      );
    } else {
      promise = sportYearSettingService.loadSportYearSettingBySportId(
        vm.sportId
      );
    }
    return $q.when(promise).then(setting => {
      vm.originalSetting = setting;
      angular.copy(setting, vm.setting);
    });
  }

  function sorterFunction(setting) {
    return setting.sportYearCategory;
  }

  function save(valid) {
    if (!valid) {
      return null;
    }
    return sportYearSettingService
      .saveSportYearSetting(vm.setting)
      .then(onSaveSuccess, onError);
  }

  function onSaveSuccess(result) {
    vm.editing = false;
    vm.setting = result;
    triggerSportYearSettingUpdate(vm.setting);
  }

  function triggerSportYearSettingUpdate(sportYearSetting) {
    if (sportYearSetting.sport) {
      dialogService.showSuccessToast('cardParameter.save.sport.done', {
        sportName: sportYearSetting.sport.name
      });
    } else {
      const orgTranslation = $translate.instant(
        `sportYear.category.${sportYearSetting.cardYearCategorySettings[0].sportYearCategory}`
      );
      dialogService.showSuccessToast('cardParameter.save.organisation.done', {
        organisationName: orgTranslation
      });
    }
    $rootScope.$broadcast('swoa:sportYearSettingUpdated', sportYearSetting);
    return sportYearSetting;
  }

  function closeDetail() {
    vm.editing = false;
    $state.go('cardParameter');
  }

  function cancelEdit() {
    vm.editing = false;
    vm.setting = vm.originalSetting;
  }

  function startEdit() {
    vm.editing = $rootScope.isSwoaUser();
  }

  function showChangeLogDialog() {
    sportYearSettingService.getChangeLog(vm.setting.id).then(changeLog => {
      changeLogService.showDialog(changeLog, 'sportYearSetting');
    }, onError);
  }
}
