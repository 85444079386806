import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaCardService from '../card.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaCardCardImportService from './card-import.service';
import swoaCardCardImportCardImportState from './card-import-state.service';
import htmlTemplate from './card-import-card-confirm.html';

export default angular
  .module('swoa.card.card-import.card-import-card-confirm', [
    uiRouter,
    swoaCardService,
    swoaNotification,
    swoaCardCardImportService,
    swoaCardCardImportCardImportState
  ])
  .component('swoaCardImportCardConfirm', {
    template: htmlTemplate,
    bindings: {},
    controller: CardImportCardConfirmController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardImportCardConfirmController(
  $stateParams,
  $state,
  cardService,
  notificationService,
  cardImportService,
  cardImportStateService
) {
  const vm = this;

  // loaded data and error handling
  vm.cardPair = null;
  vm.notificationKey = null;

  // functions
  vm.change = change;
  vm.keep = keep;
  vm.cancel = cancel;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    cardImportService.loadCardPair($stateParams.cardImportId).then(cardPair => {
      vm.cardPair = cardPair;
      cardImportStateService.setName(
        cardPair.current.athlete.person.forename,
        cardPair.current.athlete.person.surname
      );
    }, notificationService.errorHandler(vm));
  }

  function change() {
    return cardService
      .changeCardType(vm.cardPair.current.id, vm.cardPair.imported)
      .then(() =>
        cardImportService.copyImportData(
          $stateParams.cardImportId,
          vm.cardPair.current.athlete.person.id
        )
      )
      .then(() => cardImportService.removeCardImport($stateParams.cardImportId))
      .then(result => {
        $state.go('cardImport.overview', $stateParams);
        return result;
      }, notificationService.errorHandler(vm));
  }

  function keep() {
    return cardImportService
      .copyImportData(
        $stateParams.cardImportId,
        vm.cardPair.current.athlete.person.id
      )
      .then(() => cardImportService.removeCardImport($stateParams.cardImportId))
      .then(result => {
        $state.go('cardImport.overview', $stateParams);
        return result;
      }, notificationService.errorHandler(vm));
  }

  function cancel() {
    $state.go('cardImport.overview', $stateParams);
  }
}
