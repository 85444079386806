import angular from 'angular';
import swoaNotification from '../../components/notification/notification.module';
import swoaNwfVaribleAmountPeriodService from './nwf-variable-amount-period.service';
import htmlTemplate from './nwf-variable-amount-period-add-trainer.html';

export default angular
  .module('swoa.nwf-contribution.variable-amount.nwf-variable-amount-period-add-trainer', [
    swoaNwfVaribleAmountPeriodService,
    swoaNotification,
  ])
  .component('swoaNwfVariableAmountPeriodAddTrainer', {
    template: htmlTemplate,
    bindings: {},
    controller: NwfVariableAmountAddTrainerController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function NwfVariableAmountAddTrainerController($state, $stateParams, notificationService, nwfVariableAmountPeriodService) {
  const vm = this;

  vm.params = $stateParams;

  vm.selectPerson = selectPerson;
  vm.cancelAction = cancelAction;

  // //////////

  function selectPerson(personId) {
    nwfVariableAmountPeriodService.addPerson($stateParams.periodId, { id: personId }).then((trainer) => {
      const params = $stateParams;
      params.trainerId = trainer.id;
      params.state = 'new';
      $state.go('nwfVariableAmountPeriod.foundation.detail', params);
    }, notificationService.errorHandler(vm));
  }

  function cancelAction() {
    $state.go('nwfVariableAmountPeriod.foundation', $stateParams);
  }
}
