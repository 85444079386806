import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaDialog from '../../components/dialog/dialog.module';
import swoaRoleService from './role.service';
import htmlTemplate from './role-list.html';

export default angular
  .module('swoa.admin.role.role-list', [
    swoaConstants,
    swoaNotification,
    swoaDialog,
    swoaRoleService
  ])
  .component('swoaRoleList', {
    template: htmlTemplate,
    bindings: {},
    controller: RoleListController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function RoleListController(
  notificationService,
  dialogService,
  Constants,
  roleService
) {
  const vm = this,
    errorHandler = notificationService.errorHandler(vm);

  vm.roles = [];
  vm.roleType = null;
  vm.roleTypes = Constants.roleTypes;

  vm.deleteRole = deleteRole;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    roleService.getAllRoles().then(roles => {
      vm.roles = roles;
    }, errorHandler);
  }

  function deleteRole(role, event) {
    event.stopPropagation();
    dialogService.showConfirmationDialog('role.reallyDelete').then(() => {
      role.remove().then(activate, errorHandler);
    });
  }
}
