import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.translatable', [
    angularTranslate,
    swoaConstants,
  ])
  .filter('swoaTranslatable', swoaTranslatableFilter)
  .name;

/** @ngInject */
function swoaTranslatableFilter(lodash, $translate) {
  const languageFilter = {
    de: { language: 'GERMAN' },
    fr: { language: 'FRENCH' },
    it: { language: 'ITALIAN' },
  };

  return (translatable, fallbackLanguage) => {
    if (!translatable) {
      return undefined;
    }
    if (!translatable.elementTranslations) {
      return translatable;
    }
    let element = getElement($translate.use(), translatable);
    if ((!element || !element.text) && fallbackLanguage) {
      element = getElement(fallbackLanguage, translatable) ||
        translatable.elementTranslations[0];
    }
    return element ? element.text : '';
  };

  function getElement(language, translatable) {
    const filter = languageFilter[language];

    if (!filter) {
      return null;
    }

    return lodash.find(translatable.elementTranslations, filter);
  }
}

