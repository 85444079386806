import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngMaterial from 'angular-material';
import swoaConstants from '../../app.constants';
import swoaFileService from '../../components/file/file.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaDate from '../../components/date/date.filter';
import swoaCardSportYearService from './sport-year.service';
import swoaSecurityService from '../../security/security.service';
import swoaChangeLogService from '../../components/change-log/change-log.service';
import swoaPageSpinner from '../../components/spinner/page-spinner.component';
import swoaAsyncService from '../../components/async/async-helper.service';
import htmlTemplate from './sport-year-detail.html';

export default angular
  .module('swoa.card.sport-year.sport-year-detail', [
    uiRouter,
    ngMaterial,
    swoaConstants,
    swoaFileService,
    swoaNotification,
    swoaDate,
    swoaCardSportYearService,
    swoaSecurityService,
    swoaChangeLogService,
    swoaPageSpinner,
    swoaAsyncService
  ])
  .component('swoaSportYearDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: SportYearDetailController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function SportYearDetailController(
  $rootScope,
  $scope,
  $document,
  $window,
  $filter,
  $timeout,
  $http,
  $state,
  $stateParams,
  $q,
  $mdDialog,
  $translate,
  lodash,
  sportYearService,
  ConfigConstants,
  fileService,
  notificationService,
  dialogService,
  securityService,
  changeLogService,
  asyncHelperService,
  swoaDateFilter
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    exportAsyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      downloadExcel
    ),
    statusChangeAsyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      statusSuccessChanged,
      { fetchResult: true }
    );

  // state params
  vm.yearId = parseInt($stateParams.yearId, 10);
  vm.type = $stateParams.type;
  vm.typedId = parseInt($stateParams.typedId, 10);

  // loaded data
  vm.sportYear = null;
  vm.cardTypes = null;
  vm.fileCount = null;
  vm.order = '[person.surname,person.forename]';
  vm.selectAllValue = false;
  vm.cardStatusReleased = 'RELEASED';
  vm.showCardOrganisation = false;
  vm.canBulkUpdateStatus = false;
  vm.canBulkDelete = false;
  vm.limit = 100;

  // functions
  vm.statusChange = statusChange;
  vm.click = click;
  vm.changeAllSelectedCardStatus = changeAllSelectedCardStatus;
  vm.deleteAllSelectedCards = deleteAllSelectedCards;
  vm.getSelectedCards = getSelectedCards;
  vm.exportList = exportList;
  vm.isCardReleasedOrEmpty = isCardReleasedOrEmpty;
  vm.selectAll = selectAll;
  vm.selectionChanged = selectionChanged;
  vm.showCommentDialog = showCommentDialog;
  vm.showImportDialog = showImportDialog;
  vm.showChangeLogDialog = showChangeLogDialog;
  vm.showContactDialog = showContactDialog;
  vm.showFilesDialog = showFilesDialog;
  vm.showPendingImports = showPendingImports;
  vm.hasReleasableCards = hasReleasableCards;
  vm.previousYearParameters = previousYearParameters;
  vm.onPaginate = onPaginate;
  vm.prePaginate = prePaginate;
  vm.onReorder = onReorder;
  vm.showComments = showComments;
  vm.showFiles = showFiles;
  vm.showContacts = showContacts;
  vm.canEditSportYear = canEditSportYear;
  vm.deleteSportYear = deleteSportYear;
  vm.showEditSportYearDialog = showEditSportYearDialog;
  vm.save = save;
  vm.isDisabled = isDisabled;

  // for tests only!
  vm._onFileChange = onFileChange;
  vm._buildFileName = buildFileName;
  vm._canBulkUpdateStatus = canBulkUpdateStatus;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$on('swoa:cardUpdated', onCardUpdated);
    $scope.$on('swoa:cardDeleted', onCardDeleted);
    $scope.$on('swoa:cardCommentAdded', onCommentAdded);
    $scope.$on('swoa:cardCommentDeleted', onCommentDeleted);
    $scope.$on('swoa:sportYearFilesChanged', onFileChange);
    $scope.$on('swoa:sportYearCommentAdded', onSportYearCommentAdded);
    $scope.$on('swoa:sportYearCommentDeleted', onSportYearCommentDeleted);

    $scope.$watch('vm.selectedCards', selectionChanged, true);

    // reset breadcrumb title so there isn't an old one displayed until the new year is loaded
    $rootScope.$broadcast(
      'swoa:updateBreadcrumbPlaceholder',
      'sportYearName',
      null
    );

    loadData();
  }

  function loadData() {
    exportAsyncHelper.showSpinner();
    const yearPromise = sportYearService.loadSportYearDetails(vm.yearId);
    const fileCountPromise = sportYearService.getFileCount(vm.yearId);

    return $q
      .all([yearPromise, fileCountPromise])
      .then(results => {
        vm.sportYear = results[0];
        vm.fileCount = results[1];

        vm.sportYear.cards.forEach(card => {
          // set releasable
          securityService.extendCardModelWithReleasable(vm.sportYear, card);
        });

        vm.cardTypes = lodash.map(
          vm.sportYear.cardTypeSummaries,
          card => card.type
        );

        vm.showCardOrganisation = showCardOrganisation();
        if (
          sportYearService.areCardContingentsExceeded(
            vm.sportYear.cardTypeSummaries
          )
        ) {
          vm.notificationKey = 'sportYearDetail.contingetsExceeded';
        }
        vm.canBulkDelete = $rootScope.uaPermission(
          `card_w_${vm.sportYear.status}_${vm.sportYear.category}`
        );
        vm.canBulkUpdateStatus = canBulkUpdateStatus();

        // update breadcrumb
        const translateValues = {
          year: swoaDateFilter(vm.sportYear.validFrom, 'YYYY')
        };
        const sportYearName = $translate.instant(
          `sportYear.shortTitle.${vm.sportYear.category}`,
          translateValues
        );
        $rootScope.$broadcast(
          'swoa:updateBreadcrumbPlaceholder',
          'sportYearName',
          sportYearName
        );

        $timeout(() => {
          onPaginate(vm.page, vm.limit);
        });
      }, onError)
      .finally(exportAsyncHelper.hideSpinner);
  }

  function onPaginate(page, limit) {
    if (!vm.filteredCards) {
      return;
    }

    const displayedCards = $filter('orderBy')(vm.filteredCards, vm.order);
    const cards = lodash.filter(
      displayedCards,
      (card, idx) =>
        idx >= (page - 1) * limit &&
        // eslint-disable-next-line no-mixed-operators
        idx < (page - 1) * limit + limit &&
        !card.warning
    );

    if (cards.length > 0) {
      const cardIds = lodash.map(cards, card => card.id);

      // load the card warnings for the cards that are currently displayed and don't have the warnings loaded yet
      sportYearService
        .loadSportYearCardWarnings(vm.yearId, { cards: cardIds })
        .then(warnings => {
          lodash.forEach(warnings, warning => {
            const card = lodash.find(vm.sportYear.cards, { id: warning.id });
            if (card) {
              card.warning = warning;
            }
          });
        })
        .finally(exportAsyncHelper.hideSpinner);
    } else {
      exportAsyncHelper.hideSpinner();
    }
  }

  function prePaginate() {
    exportAsyncHelper.showSpinner();
    $window.scrollTo(0, 0);
  }

  function onReorder() {
    // Force load from card warnings
    onPaginate(vm.page, vm.limit);
  }

  function changeAllSelectedCardStatus(cardStatus) {
    const cardsToBeUpdated = getSelectedCards(true);
    if (cardsToBeUpdated.length === 0) {
      return $q.when(onAllCardsUpdated(vm.sportYear.cards));
    }
    notificationService.resetErrors(vm);
    return sportYearService
      .changeAllSelectedCardStatus(
        vm.sportYear.id,
        cardStatus,
        cardsToBeUpdated
      )
      .then(onAllCardsUpdated, onError);
  }

  function deleteAllSelectedCards() {
    const cardsToBeUpdated = getSelectedCards();
    return dialogService
      .showConfirmationDialog('sportYear.bulkDelete.confirmation', {
        numCards: cardsToBeUpdated.length
      })
      .then(() => {
        notificationService.resetErrors(vm);
        exportAsyncHelper.showSpinner();
        return sportYearService
          .deleteAllSelectedCards(vm.sportYear.id, cardsToBeUpdated)
          .then(() => {
            vm.selectAllValue = false;
            loadData();
          });
      });
  }

  function click(card, event) {
    if (card.isReleasable) {
      card.selected = !card.selected;
      selectionChanged();
    }
    event.stopPropagation();
  }

  function hasReleasableCards() {
    return lodash.find(vm.sportYear.cards, { isReleasable: true });
  }

  function selectAll() {
    if (vm.sportYear && vm.sportYear.cards) {
      const notReleasedCards = vm.canBulkDelete
        ? vm.sportYear.cards
        : lodash.filter(vm.sportYear.cards, { isReleasable: true });
      lodash.forEach(notReleasedCards, value => {
        value.selected = vm.selectAllValue;
      });
    }
  }

  function isCardReleasedOrEmpty(card) {
    return (
      card.status === 'RELEASED' ||
      card.status === 'EMPTY' ||
      !card.isReleasable
    );
  }

  function selectionChanged() {
    if (vm.sportYear && vm.sportYear.cards && vm.sportYear.cards.length > 0) {
      vm.selectAllValue = lodash.every(vm.sportYear.cards, {
        selected: true,
        isReleasable: true
      });
    }
  }

  function getSelectedCards(onlyReleasable) {
    let selectedCards = lodash.filter(vm.sportYear.cards, { selected: true });
    if (onlyReleasable) {
      selectedCards = lodash.filter(selectedCards, { isReleasable: true });
    }
    return lodash.map(selectedCards, card => card.id);
  }

  function showCommentDialog(event) {
    $mdDialog.show({
      template: '<swoa-sport-year-comments></swoa-sport-year-comments>',
      autoWrap: true,
      preserveScope: true,
      scope: $scope,
      parent: angular.element($document.body),
      targetEvent: event,
      fullscreen: true
    });
  }

  function showImportDialog(event) {
    $mdDialog.show({
      template: '<swoa-card-import-file-upload></swoa-card-import-file-upload>',
      autoWrap: true,
      preserveScope: true,
      scope: $scope,
      parent: angular.element($document.body),
      targetEvent: event,
      fullscreen: true,
      onRemoving: uploadFinished
    });
  }

  function showFilesDialog(event) {
    $mdDialog.show({
      template: `<swoa-file-storage back-to-dialog="vm.showFilesDialog"
                                    can-upload="$root.uaPermission(
                                        'filestorage_cardlist_create_w_${vm.sportYear.status}_${vm.sportYear.category}'
                                    )"
                                    can-delete="$root.uaPermission(
                                        'filestorage_cardlist_all_w_${vm.sportYear.status}_${vm.sportYear.category}'
                                    )"
                                    can-delete-own="$root.uaPermission(
                                        'filestorage_cardlist_own_w_${vm.sportYear.status}_${vm.sportYear.category}'
                                    )"
                                    max-size="10MB" >
                 </swoa-file-storage>`,
      autoWrap: true,
      preserveScope: true,
      scope: $scope,
      parent: angular.element($document.body),
      targetEvent: event,
      fullscreen: true
    });
  }

  function showContactDialog(event) {
    $mdDialog.show({
      template: `<swoa-sport-year-contact card-year-id="vm.sportYear.id"
                                          sport-year-category="vm.sportYear.category">
                 </swoa-sport-year-contact>`,
      autoWrap: true,
      preserveScope: true,
      scope: $scope,
      parent: angular.element($document.body),
      targetEvent: event,
      fullscreen: true
    });
  }

  function showPendingImports() {
    $state.go('cardImport.overview', $stateParams);
  }

  function exportList() {
    const language = $translate.use();
    const url = `${ConfigConstants.api.url}/sportyears/${vm.yearId}/export/${language}`;
    const config = {
      params: { fileName: buildFileName(language) }
    };
    exportAsyncHelper.showSpinner();
    return $http
      .get(url, config)
      .then(exportAsyncHelper.getAsyncJobStatus)
      .catch(exportAsyncHelper.errorHandler);
  }

  function downloadExcel(resultUrl, result, options) {
    return fileService.download(resultUrl, result.resultHint, options);
  }

  function buildFileName(language) {
    const fileNameElements = [
      'cards',
      $translate.instant(
        `sportYear.category.${vm.sportYear.category.toUpperCase()}`
      ),
      swoaDateFilter(vm.sportYear.validFrom, 'DD.MM.YYYY'),
      $translate.instant('sportYear.exportCards.until'),
      swoaDateFilter(vm.sportYear.validUntil, 'DD.MM.YYYY'),
      language.toUpperCase()
    ];
    return `${fileNameElements.join('_')}.xlsx`;
  }

  function statusChange(status, confirmationMessageKey) {
    let confirmPromise = $q.when();
    if (confirmationMessageKey) {
      confirmPromise = dialogService.showConfirmationDialog(
        confirmationMessageKey
      );
    }

    return confirmPromise.then(() => {
      notificationService.resetErrors(vm);
      return sportYearService
        .goToStatus(vm.yearId, status)
        .then(statusChangeAsyncHelper.getAsyncJobStatus)
        .catch(statusChangeAsyncHelper.errorHandler);
    }, onError);
  }

  function statusSuccessChanged() {
    dialogService.showSuccessToast('sportYear.status.change.success');
    return loadData();
  }

  function showChangeLogDialog() {
    sportYearService
      .getChangeLog(vm.sportYear.id)
      .then(
        sportYearChangeLog =>
          changeLogService.showDialog(sportYearChangeLog, 'sportYear'),
        onError
      );
  }

  function onFileChange(event, sportYearId, fileCount) {
    if (vm.sportYear.id === sportYearId) {
      vm.fileCount = fileCount;
    }
  }

  function showComments() {
    if (!vm.sportYear) {
      return false;
    }
    const hasReadPermission = $rootScope.uaAnyPermission([
      `comment_cardlist_all_r_${vm.sportYear.status}_${vm.sportYear.category}`,
      `comment_cardlist_own_r_${vm.sportYear.status}_${vm.sportYear.category}`
    ]);
    const hasCreatePermission = $rootScope.uaPermission(
      `comment_cardlist_create_w_${vm.sportYear.status}_${vm.sportYear.category}`
    );
    return hasReadPermission || hasCreatePermission;
  }

  function showFiles() {
    if (!vm.sportYear) {
      return false;
    }
    const hasReadPermission = $rootScope.uaAnyPermission([
      `filestorage_cardlist_all_r_${vm.sportYear.status}_${vm.sportYear.category}`,
      `filestorage_cardlist_own_r_${vm.sportYear.status}_${vm.sportYear.category}`
    ]);
    const hasCreatePermission = $rootScope.uaPermission(
      `filestorage_cardlist_create_w_${vm.sportYear.status}_${vm.sportYear.category}`
    );
    return hasReadPermission || hasCreatePermission;
  }

  function canBulkUpdateStatus() {
    return (
      vm.sportYear &&
      vm.canBulkDelete &&
      (vm.sportYear.status === 'FOR_CONSIDERATION' ||
        vm.sportYear.status === 'PROVISIONALLY_RELEASED')
    );
  }

  function showContacts() {
    return vm.sportYear && vm.sportYear.type === 'SPORT';
  }

  function showCardOrganisation() {
    return (
      vm.sportYear &&
      vm.sportYear.category === 'FUNCTIONARY' &&
      vm.sportYear.type === 'FUNCTIONARY'
    );
  }

  // Private helpers

  function onAllCardsUpdated(cards) {
    lodash.forEach(cards, card => {
      onCardUpdated(null, card);
    });
    resetSelectedValue();
  }

  function resetSelectedValue() {
    vm.selectAllValue = false;
    vm.selectedValuesLength = 0;
  }

  function onCardUpdated(event, card) {
    const index = lodash.findIndex(vm.sportYear.cards, { id: card.id });
    if (index > -1) {
      securityService.extendCardModelWithReleasable(vm.sportYear, card);

      // container vs real object, only copy certain attributes
      vm.sportYear.cards[index].type = card.type;
      vm.sportYear.cards[index].temporaryType = card.temporaryType;
      vm.sportYear.cards[index].phase = card.phase;
      vm.sportYear.cards[index].temporaryPhase = card.temporaryPhase;
      vm.sportYear.cards[index].status = card.status;
      vm.sportYear.cards[index].active = card.active;
      vm.sportYear.cards[index].temporaryActive = card.temporaryActive;
      vm.sportYear.cards[index].isReleasable = card.isReleasable;
      vm.sportYear.cards[index].functionaryOrganisation =
        card.functionaryOrganisation;
      vm.sportYear.cards[index].eliteResult = card.eliteResult;
      vm.sportYear.cards[index].technicianResponsibilities =
        card.technicianResponsibilities;
      vm.sportYear.cards[index].comments =
        card.comments && card.comments.length;
      vm.sportYear.cards[index].selected = false;

      updateCardCounts();
    }
  }

  function onCardDeleted(event, card) {
    const index = lodash.findIndex(vm.sportYear.cards, { id: card.id });
    if (index > -1) {
      vm.sportYear.cards.splice(index, 1);
      updateCardCounts();
    }
  }

  function onCommentAdded(event, cardId) {
    const index = lodash.findIndex(vm.sportYear.cards, { id: cardId });
    if (index > -1) {
      vm.sportYear.cards[index].comments += 1;
    }
  }

  function onCommentDeleted(event, cardId) {
    const index = lodash.findIndex(vm.sportYear.cards, { id: cardId });
    if (index > -1) {
      vm.sportYear.cards[index].comments -= 1;
    }
  }

  function onSportYearCommentAdded(event, sportYearId, comment) {
    if (sportYearId === vm.sportYear.id && vm.sportYear.comments) {
      vm.sportYear.comments.push(comment);
    }
  }

  function onSportYearCommentDeleted(event, sportYearId, comment) {
    if (sportYearId === vm.sportYear.id && vm.sportYear.comments) {
      lodash.remove(vm.sportYear.comments, { id: comment.id });
    }
  }

  function updateCardCounts() {
    const summaries = {};
    vm.sportYear.cardTypeSummaries.forEach(summary => {
      summary.count = 0;
      summaries[summary.type] = summary;
    });
    vm.sportYear.cards.forEach(card => {
      if (card.active) {
        summaries[card.type].count += 1;
      }
    });
    if (
      sportYearService.areCardContingentsExceeded(
        vm.sportYear.cardTypeSummaries
      )
    ) {
      vm.notificationKey = 'sportYearDetail.contingetsExceeded';
    }
  }

  function uploadFinished() {
    loadData().then(() => {
      if ($scope.fileUploadSuccessful) {
        $state.go('cardImport.overview', $stateParams);
      }
    });
  }

  function previousYearParameters() {
    return sportYearService.getCardListDetailRouteParameter(
      vm.sportYear.previousYear
    );
  }

  function canEditSportYear() {
    if (!vm.sportYear) {
      return false;
    }
    return $rootScope.uaPermission(
      `cardlist_admin_w_${vm.sportYear.status}_${vm.sportYear.category}`
    );
  }

  function deleteSportYear() {
    dialogService
      .showConfirmationDialog('sportYear.delete.confirmation')
      .then(() => {
        sportYearService.purgeSportYear(vm.sportYear.id).then(() => {
          dialogService.showSuccessToast('sportYear.delete.success');

          if (vm.sportYear.category === 'FUNCTIONARY') {
            $state.go('functionaryCards', $stateParams);
          } else if (vm.sportYear.category === 'TECHNICIAN') {
            $state.go('organisationYearSummary', $stateParams);
          } else {
            $state.go('sportYearSummary', $stateParams);
          }
        }, onError);
      });
  }

  function save($validFrom, $validUntil) {
    sportYearService
      .updateSportYearDates(vm.sportYear.id, $validFrom, $validUntil)
      .then(() => {
        vm.sportYear.validFrom = $validFrom;
        vm.sportYear.validUntil = $validUntil;
        dialogService.showSuccessToast('sportYear.update.success');
      }, onError);

    $mdDialog.hide();
  }

  function showEditSportYearDialog(event) {
    $mdDialog.show({
      template: `<swoa-sport-year-edit valid-from="vm.sportYear.validFrom"
                                       valid-until="vm.sportYear.validUntil"
                                       on-save="vm.save($validFrom, $validUntil)">
                </swoa-sport-year-edit>`,
      autoWrap: true,
      preserveScope: true,
      scope: $scope,
      parent: angular.element($document.body),
      targetEvent: event,
      fullscreen: true
    });
  }

  function isDisabled(card, cardType) {
    if (card.temporaryActive === null || card.temporaryActive === true) {
      return card.temporaryType
        ? card.temporaryType !== cardType
        : card.type !== cardType || !card.active;
    }
    return true;
  }
}
