import angular from 'angular';
import swoaConstants from '../../../app.constants';
import swoaTranslatableElementService from '../../../translation/translatable-element.service';
import swoaNotification from '../../../components/notification/notification.module';
import htmlTemplate from './translations-technical.html';

export default angular
  .module('swoa.admin.translations-technical', [
    swoaConstants,
    swoaTranslatableElementService,
    swoaNotification
  ])
  .component('swoaTranslationsTechnical', {
    template: htmlTemplate,
    bindings: {},
    controller: TranslationsTechnicalController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function TranslationsTechnicalController(
  $rootScope,
  $translate,
  lodash,
  translatableElementService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.translatableElements = [];
  vm.selectedType = undefined;
  vm.types = [];
  vm.order = ['translationType', 'sort'];

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    getTranslatableElements();
  }

  function getTranslatableElements() {
    $rootScope.showSpinner = true;
    translatableElementService
      .loadTranslatableElementsFiltered(
        element => element.technicalName !== null
      )
      .then(elements => {
        vm.translatableElements = lodash.sortBy(
          elements,
          element => element.translationType
        );
        vm.types = lodash.uniq(
          lodash.map(vm.translatableElements, element => element.type)
        );
        $rootScope.showSpinner = false;
      }, onError);
  }
}
