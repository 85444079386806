import angular from 'angular';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaNotification from '../../components/notification/notification.module';
import swoaOrganisationService from '../../organisation/organisation.service';

export default angular
  .module('swoa.nwf-contribution.controlling.edit', [
    swoaNotification,
    swoaTranslatable,
    swoaOrganisationService
  ])
  .component('swoaNwfControllingEdit', {
    template: ` <md-card flex>
                    <md-card-content class="swoa-profile-data">
                        <div class="swoa-profile-data-list">
                            <p translate="nwfContributions.controlling.edit"></p>
                  
                            <div class="margin-top-40">
                              <div ng-repeat="entry in vm.calendarYear.controllingEntries">
                                  <swoa-nwf-controlling-entry ng-if="vm.sports && vm.institutions"
                                                              entry="entry" 
                                                              on-delete="vm.deleteRow(entry)"
                                                              submitted="vm.submitted"
                                                              editing="vm.editing"
                                                              sports="vm.sports"
                                                              institutions="vm.institutions">
                                  </swoa-nwf-controlling-entry>
                              </div>   
                              <md-button md-no-ink class="md-accent md-hue-1 has-icon"
                                         ng-show="vm.editing || vm.calendarYear.controllingEntries.length === 0"
                                         ng-disabled="!vm.editing"
                                         ua-permission="nwf_contribution_controlling_w" 
                                         ng-click="vm.addRow()">
                                  <swoa-icon name="add_circle" class="is-small"></swoa-icon>
                                  <span translate="app.addRow"></span>
                              </md-button>
                            </div>     
                            
                            <div flex layout="row" layout-align="end end">
                              <md-button class="md-raised md-hue-1 md-accent"
                                         ng-hide="vm.editing || vm.calendarYear.controllingEntries.length === 0"
                                         ua-permission="nwf_contribution_controlling_w"
                                         ng-click="vm.onSend()">
                                  <span translate="nwfContributions.controlling.sendList"></span>
                              </md-button>
                            </div>
                        </div>
                    </md-card-content>
                </md-card>`,
    controller: NwfControllingForm,
    bindings: {
      calendarYear: '<',
      submitted: '<',
      editing: '<',
      onSend: '<'
    },
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfControllingForm(
  $q,
  $scope,
  lodash,
  swoaTranslatableFilter,
  notificationService,
  organisationService,
  nwfControllingService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.sports = undefined;
  vm.institutions = undefined;

  vm.addRow = addRow;
  vm.deleteRow = deleteRow;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    const promises = [];
    vm.calendarYear.controllingEntries = nwfControllingService.sortControllingEntries(
      vm.calendarYear.controllingEntries
    );

    promises.push(
      organisationService
        .get(vm.calendarYear.organisation.id)
        .then(organisation => {
          vm.institutions = lodash.map(
            organisation.nwfInstitutions,
            institution => {
              institution.strong = institution.level === 'NATIONAL';
              return institution;
            }
          );

          vm.institutions = lodash.filter(
            vm.institutions,
            institution => institution.active === true
          );
          vm.institutions = lodash.sortBy(
            vm.institutions,
            institution =>
              `${institution.level} ${institution.name.toLowerCase()}`
          );

          vm.sports = lodash.map(organisation.sports, sport => {
            sport.displayName = swoaTranslatableFilter(
              sport.translatedName,
              'de'
            );
            return sport;
          });

          const institutionSports = lodash
            .chain(vm.institutions)
            .flatMap('sports')
            .uniqBy('id')
            .value();
          vm.sports = lodash.filter(
            vm.sports,
            sport => lodash.findIndex(institutionSports, ['id', sport.id]) >= 0
          );
          vm.sports = lodash.sortBy(
            lodash.filter(vm.sports, sport => sport.active),
            'displayName'
          );
        })
    );

    $q.all(promises).catch(onError);
  }

  function deleteRow(entry) {
    lodash.pull(vm.calendarYear.controllingEntries, entry);
  }

  function addRow() {
    vm.calendarYear.controllingEntries.push({});
  }
}
