import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.nwf-parameter.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('nwfParameter', {
      url: '/nwf-parameter',
      template:
        '<swoa-nwf-parameter-page current-tab="selectedTab"></swoa-nwf-parameter-page>',
      controller($scope, $state, $transitions) {
        if ($state.current.name === 'nwfParameter') {
          $state.go('nwfParameter.baseAmount');
          $scope.selectedTab = 'baseAmount';
        } else {
          $scope.selectedTab = $state.current.data.selectedTab;
        }

        $transitions.onSuccess({ to: 'nwfParameter.**' }, transition => {
          if (transition.to().name === 'nwfParameter') {
            $state.go('nwfParameter.baseAmount');
            $scope.selectedTab = 'baseAmount';
          } else {
            $scope.selectedTab = $state.current.data.selectedTab;
          }
        });
      },
      controllerAs: 'vm',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_nwf_contribution_r'
      }
    })
    .state('nwfParameter.baseAmount', {
      url: '/base-amount',
      data: {
        selectedTab: 'baseAmount',
        breadcrumb: {
          parent: 'nwfParameter',
          ignore: true
        },
        hasPermission: 'admin_nwf_contribution_r'
      },
      views: {
        baseAmount: {
          template:
            '<swoa-nwf-parameter-base-amount></swoa-nwf-parameter-base-amount>'
        }
      }
    })
    .state('nwfParameter.variableAmount', {
      url: '/variable-amount',
      data: {
        selectedTab: 'variableAmount',
        breadcrumb: {
          parent: 'nwfParameter',
          ignore: true
        },
        hasPermission: 'admin_nwf_contribution_r'
      },
      views: {
        variableAmount: {
          template:
            '<swoa-nwf-parameter-variable-amount></swoa-nwf-parameter-variable-amount>'
        }
      }
    });
}
