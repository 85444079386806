import angular from 'angular';
import angularTranslate from 'angular-translate';
import htmlTemplate from './tooltip-icon.html';

export default angular
  .module('swoa.tooltip-icon', [
    angularTranslate,
  ])
  .component('swoaTooltipIcon', {
    template: htmlTemplate,
    bindings: {
      icon: '@',
      iconType: '@',
      size: '@',
      tooltipKey: '@',
    },
    controllerAs: 'vm',
  })
  .name;
