import angular from 'angular';

export default angular
  .module('swoa.cockpit.config.service', [
    'LocalStorageModule',
  ])
  .factory('cockpitConfigService', cockpitConfigService)
  .name;

export const COCKPIT_CONFIG_STORAGE_KEY = 'cockpit.filter';

/** @ngInject */
function cockpitConfigService(localStorageService) {
  return {
    getConfig,
    updateConfig,
    clearCache,
  };

  // //////////

  function getConfig() {
    return localStorageService.get(COCKPIT_CONFIG_STORAGE_KEY);
  }

  function updateConfig(config) {
    localStorageService.set(COCKPIT_CONFIG_STORAGE_KEY, config);
  }

  function clearCache() {
    localStorageService.remove(COCKPIT_CONFIG_STORAGE_KEY);
  }
}

