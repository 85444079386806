import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaPersonService from '../person.service';
import swoaPersonSyncService from '../sync/person-sync.service';
import swoaPersonPersonMinimal from '../person-minimal.component';
import swoaObjectProperty from '../../components/object-property/object-property.filter';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './person-mutation-detail.html';

export default angular
  .module('swoa.person.mutation.person-mutation-detail', [
    uiRouter,
    swoaPersonService,
    swoaPersonSyncService,
    swoaPersonPersonMinimal,
    swoaObjectProperty,
    swoaNotification
  ])
  .component('swoaPersonMutationDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: PersonMutationDetailController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonMutationDetailController(
  $state,
  $stateParams,
  lodash,
  personMutationService,
  personSyncService,
  personService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.personMutationId = $stateParams.personMutationId;
  vm.originalPerson = null;
  vm.personMutation = null;
  vm.config = null;

  vm.closeDetail = closeDetail;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    personMutationService
      .loadPendingMutation(vm.personMutationId)
      .then(personMutation => {
        vm.config = lodash.filter(
          personSyncService.SYNC_CONFIGURATION,
          config =>
            lodash.findIndex(
              personMutation.changedFields,
              field => config.personKey.indexOf(field) >= 0
            ) >= 0
        );

        vm.personMutation = personMutation.personMutation;
        vm.personMutation.foreignIds = personService.hashifyForeignIds(
          vm.personMutation.foreignIds
        );
        vm.personMutation.configKey = 'personKey';

        vm.originalPerson = personMutation.personMutation.originalPerson;
        vm.originalPerson.foreignIds = personService.hashifyForeignIds(
          vm.originalPerson.foreignIds
        );
        vm.originalPerson.configKey = 'personKey';
      }, onError);
  }

  function closeDetail() {
    $state.go('personMutations');
  }
}
