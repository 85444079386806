import angular from 'angular';
import ngMaterial from 'angular-material';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import htmlTemplate from './deactivation.html';

export default angular
  .module('swoa.deactivation.deactivation', [
    ngMaterial,
    angularTranslate,
    swoaConstants,
  ])
  .component('swoaDeactivation', {
    template: htmlTemplate,
    bindings: {
      type: '<',
      entry: '<',
      onDeactivate: '&',
      currentEditor: '=',
    },
    controller: DeactivationController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function DeactivationController($mdDialog, moment, lodash) {
  const vm = this;

  vm.deactivationTypes = ['BLOCKED', 'DECEASED'];
  vm.maxDate = new Date();
  vm.activeSports = [];

  vm.closeDialog = closeDialog;
  vm.deactivate = deactivate;
  vm.showDeactivationReason = showDeactivationReason;
  vm.showDeactivationType = showDeactivationType;
  vm.showDeceasedDate = showDeceasedDate;
  vm.showOrganisationDeactivationInfo = showOrganisationDeactivationInfo;

  function showOrganisationDeactivationInfo() {
    vm.activeSports = lodash.filter(vm.entry.sports, sport => sport.active);
    return vm.type === 'organisation' && (vm.entry.users.length > 0 || vm.activeSports.length > 0);
  }

  function showDeactivationReason() {
    if (vm.type === 'sport' || vm.type === 'organisation') {
      return true;
    } else if (vm.type === 'person') {
      return vm.entry.deactivationType === 'BLOCKED';
    }
    return false;
  }

  function showDeceasedDate() {
    if (vm.type === 'person') {
      if (vm.entry.deactivationType === 'DECEASED') {
        if (!vm.entry.deactivationDate) {
          vm.entry.deactivationDate = moment().format('YYYY-MM-DD');
        }
        return true;
      }
    }
    return false;
  }

  function showDeactivationType() {
    return vm.type === 'person';
  }

  function closeDialog() {
    vm.currentEditor = null;
    $mdDialog.hide();
  }

  function deactivate(valid) {
    if (valid) {
      vm.currentEditor = null;
      return vm.onDeactivate()(vm.entry);
    }
    return null;
  }
}
