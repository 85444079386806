import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaDataTable from '../components/data-table/data-table.module';
import htmlTemplate from './cockpit-person-mutation-list.html';

export default angular
  .module('swoa.cockpit.cockpit-person-mutation-list', [
    angularTranslate,
    swoaDataTable
  ])
  .component('swoaCockpitPersonMutationList', {
    template: htmlTemplate,
    bindings: {
      limit: '<'
    },
    controller: CockpitPersonMutationListController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CockpitPersonMutationListController(personMutationService) {
  const vm = this;

  vm.pendingPersonMutationDetails = null;
  vm.running = true;

  vm.$onInit = () => {
    activate();
  };

  // /////////

  function activate() {
    personMutationService
      .loadPersonMutations(0, vm.limit)
      .then(pendingPersonMutationDetails => {
        vm.pendingPersonMutationDetails = pendingPersonMutationDetails;
        vm.running = false;
      });
  }
}
