import angular from 'angular';
import swoaFileStorageService from './file-storage.service';
import swoaFileStorageFileStorage from './file-storage.component';

export default angular
  .module('swoa.file-storage', [
    swoaFileStorageService,
    swoaFileStorageFileStorage,
  ])
  .name;

