import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaCityService from '../../city/city.service';
import { DATE_FORMAT_DATE_ONLY as humanFormat } from '../../components/date/date.filter';
import { DATE_FORMAT_JSON as apiFormat } from '../../components/input-date/input-date.component';
import htmlTemplate from './person-extended-search.html';

export default angular
  .module('swoa.person.person-extended-search', [
    swoaConstants,
    swoaCityService,
    swoaNotification
  ])
  .component('swoaPersonExtendedSearch', {
    template: htmlTemplate,
    bindings: {
      selectButtonTextKey: '@',
      selectAction: '&',
      cancelAction: '&',
      withoutSelection: '<'
    },
    controller: PersonExtendedSearchController,
    controllerAs: 'vm'
  }).name;

export const MAX_RESULTS_ENRICHED = 100;
export const MAX_RESULTS_NORMAL = 99999;

/** @ngInject */
function PersonExtendedSearchController(
  $translate,
  moment,
  Constants,
  ConfigConstants,
  notificationService,
  cityService,
  personService
) {
  const vm = this;

  // loaded data
  vm.foundPersons = null;
  vm.totalPersonsCount = null;
  vm.hintKey = 'personSearch.noSearchStarted';
  vm.maxResults = MAX_RESULTS_ENRICHED;
  vm.availableLanguages = [];
  vm.availableGenders = [];
  vm.availableNationalities = [];
  vm.availableStates = [];

  // selections
  vm.selectedPerson = null;
  vm.enrichResults = false;
  vm.query = null;

  // error handling
  vm.notificationKey = null;

  // functions
  vm.search = search;
  vm.resetQuery = resetQuery;
  vm.isAutocompleteEnabled = isAutocompleteEnabled;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    resetQuery();
    vm.availableLanguages = ConfigConstants.translation.availableLanguagesEnum.map(
      lang => ({ id: lang, label: $translate.instant(`language.${lang}`) })
    );
    vm.availableGenders = Constants.genders.map(gender => ({
      id: gender,
      label: $translate.instant(`person.gender.${gender}`)
    }));
    vm.availableNationalities = Constants.nationalities.map(nat => ({
      id: nat,
      label: $translate.instant(`person.nationality.${nat}`)
    }));
    cityService.getAllStates().then(states => {
      vm.availableStates = states;
    });
  }

  function resetQuery() {
    vm.query = {
      genders: [],
      languages: [],
      nationalities: [],
      states: [],
      countries: []
    };
  }

  function isAutocompleteEnabled() {
    return vm.query.countries.length === 1 && vm.query.countries[0] === 'CH';
  }

  function search() {
    vm.hintKey = 'personSearch.searching';
    vm.foundPersons = null;

    const queryObj = angular.copy(vm.query);

    queryObj.genders = queryObj.genders.map(gender => gender.id);
    queryObj.languages = queryObj.languages.map(lang => lang.id);
    queryObj.nationalities = queryObj.nationalities.map(nat => nat.id);

    const dateOfBirth = queryObj.dateOfBirth;
    if (dateOfBirth) {
      if (/^19\d\d|20\d\d$/.test(dateOfBirth)) {
        queryObj.yearOfBirth = parseInt(dateOfBirth, 10);
        queryObj.dateOfBirth = null;
      } else if (/\d\d\.\d\d\.\d{4}/.test(dateOfBirth)) {
        queryObj.dateOfBirth = moment(dateOfBirth, humanFormat).format(
          apiFormat
        );
        queryObj.yearOfBirth = null;
      } else {
        queryObj.dateOfBirth = null;
        queryObj.yearOfBirth = null;
      }
    } else {
      queryObj.dateOfBirth = null;
      queryObj.yearOfBirth = null;
    }

    vm.maxResults = vm.enrichResults
      ? MAX_RESULTS_ENRICHED
      : MAX_RESULTS_NORMAL;
    personService
      .extendedSearch(queryObj, 0, vm.maxResults, vm.enrichResults)
      .then(personResult => {
        vm.foundPersons = personResult.result;
        vm.totalPersonsCount = personResult.totalCount;
      }, notificationService.errorHandler(vm))
      .finally(() => {
        if (
          (vm.totalPersonsCount > MAX_RESULTS_ENRICHED && vm.enrichResults) ||
          vm.totalPersonsCount > MAX_RESULTS_NORMAL
        ) {
          vm.hintKey = 'personSearch.tooManyResults';
        } else {
          vm.hintKey = 'personSearch.found';
        }
      });
  }
}
