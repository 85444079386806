import angular from 'angular';
import swoaInputTextComponent from './input-text.component';
import swoaPercentageDirective from './percentage.directive';

export default angular
  .module('swoa.input-text', [
    swoaInputTextComponent,
    swoaPercentageDirective,
  ])
  .name;
