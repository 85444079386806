import angular from 'angular';
import swoaCardSportYearService from './sport-year.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './sport-year-add-athlete.html';

export default angular
  .module('swoa.card.sport-year.sport-year-add-athlete', [
    swoaCardSportYearService,
    swoaNotification,
  ])
  .component('swoaSportYearAddAthlete', {
    template: htmlTemplate,
    bindings: {},
    controller: SportYearAddAthleteController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function SportYearAddAthleteController($state, $stateParams, sportYearService, notificationService) {
  const vm = this;

  // state params
  vm.params = $stateParams;
  vm.yearId = $stateParams.yearId;

  // functions
  vm.selectPerson = selectPerson;
  vm.cancelAction = cancelAction;

  // error handling
  vm.notificationKey = null;

  // //////////

  function selectPerson(personId) {
    const person = { id: personId };
    return sportYearService.addPerson(vm.yearId, person).then((card) => {
      const params = $stateParams;
      params.cardId = card.id;
      $state.go('sportYearDetail.cardDetail', params);
    }, notificationService.errorHandler(vm));
  }

  function cancelAction() {
    $state.go('sportYearDetail', $stateParams);
  }
}
