import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaCardService from './card.service';
import swoaDialog from '../components/dialog/dialog.module';
import swoaCardSportYearService from './sport-year/sport-year.service';
import swoaChangeLogService from '../components/change-log/change-log.service';
import swoaConstants from '../app.constants';
import swoaNotification from '../components/notification/notification.module';
import swoaSecurityService from '../security/security.service';
import htmlTemplate from './card-detail.html';
import * as Card from './model/Card';

export default angular
  .module('swoa.card.card-detail', [
    uiRouter,
    swoaCardService,
    swoaDialog,
    swoaCardSportYearService,
    swoaChangeLogService,
    swoaNotification,
    swoaSecurityService,
    swoaConstants
  ])
  .component('swoaCardDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: CardDetailController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardDetailController(
  $rootScope,
  $scope,
  $stateParams,
  $state,
  $timeout,
  $q,
  cardService,
  dialogService,
  sportYearService,
  changeLogService,
  notificationService,
  securityService,
  Constants
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.cardId = $stateParams.cardId;
  vm.card = null;
  vm.cardType = null;
  vm.ftem = null;
  vm.sportYearId = $stateParams.yearId;
  vm.sportYear = null;
  vm.isFunctionaryCard = false;
  vm.isOrganisationCardYear = false;
  vm.cardStatusReleased = 'RELEASED';
  vm.showAdditionalPersonData = false;
  vm.changeFtem = changeFtem;

  vm.closeDetail = closeDetail;
  vm.changeCardStatus = changeCardStatus;
  vm.isCardStatusEmpty = isCardStatusEmpty;
  vm.isCardSummaryEnabled = isCardSummaryEnabled;
  vm.isTalentOrEliteCardYear = isTalentOrEliteCardYear;
  vm.purgeCard = purgeCard;
  vm.showChangeLogDialog = showChangeLogDialog;
  vm.toggleCardType = toggleCardType;

  vm.ftemTypes = Constants.cards.ftemTypes;

  // exposed for tests only!!
  vm._initCard = initCard;
  vm._onCardUpdate = onCardUpdate;
  vm._saveCardType = saveCardType;
  vm._updateCardForStaffCategory = updateCardForStaffCategory;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    const promises = [];
    promises.push(sportYearService.loadSportYearDetails($stateParams.yearId));
    promises.push(cardService.loadCard($stateParams.cardId));

    $q.all(promises).then(result => {
      vm.sportYear = result[0];

      initCard(result[1]);
    }, onError);

    $scope.$on('swoa:cardUpdated', onCardUpdate);
  }

  function closeDetail() {
    $state.go('^', {
      sportId: $stateParams.sportId,
      yearId: $stateParams.yearId
    });
  }

  function isCardStatusEmpty() {
    return vm.card.status === 'EMPTY';
  }

  function isFunctionaryCard() {
    return vm.sportYear.type === 'FUNCTIONARY';
  }

  function isOrganisationCardYear() {
    return vm.sportYear.type === 'ORGANISATION';
  }

  function isTalentOrEliteCardYear() {
    return (
      vm.sportYear &&
      (vm.sportYear.category === 'ELITE' || vm.sportYear.category === 'TALENT')
    );
  }

  function isCardSummaryEnabled(type) {
    return vm.cardType === type;
  }

  function saveCardType() {
    if (vm.card.canChangeType) {
      if (cardService.isStaffCategory(vm.card)) {
        return updateCardForStaffCategory();
      }
      return updateCardForSportCategory(vm.cardType);
    }
    return null;
  }

  function saveCardFtem(ftem) {
    if (vm.card.canChangeType && vm.isTalentOrEliteCardYear()) {
      if (ftem !== null) {
        return cardService
          .changeCardFtem(vm.card.id, ftem)
          .then(triggerCardUpdate, onError);
      }
    }
    return null;
  }

  function toggleCardType(type) {
    if (vm.cardType === type) {
      vm.cardType = null;
    } else {
      vm.cardType = type;
    }
    saveCardType();
  }

  function updateCardForSportCategory(type) {
    if (
      type === null &&
      vm.card.active &&
      (vm.sportYear.status === 'NEW' ||
        vm.sportYear.status === 'REVISION_NEEDED' ||
        vm.sportYear.status === 'FOR_CONSIDERATION' ||
        vm.sportYear.status === 'PROVISIONALLY_RELEASED')
    ) {
      return cardService
        .removeCard(vm.card.id)
        .then(triggerCardUpdate, onError);
    }
    if (
      type !== null &&
      (Card.getCardType(vm.card) !== type || !vm.card.active)
    ) {
      return cardService
        .changeCardType(vm.card.id, type)
        .then(triggerCardUpdate, onError);
    }
    return null;
  }

  function updateCardForStaffCategory() {
    if (vm.card.status === 'NEW') {
      return updateActivePropertyCard();
    } else if (vm.sportYear.status === 'FOR_CONSIDERATION') {
      return updateCardStatusStaff();
    }
    return null;
  }

  function updateActivePropertyCard() {
    if (vm.cardType === null) {
      if (vm.card.active) {
        return cardService
          .removeCard(vm.card.id)
          .then(triggerCardUpdate, onError);
      }
    } else if (!vm.card.active) {
      return cardService
        .activateCard(vm.card.id)
        .then(triggerCardUpdate, onError);
    }
    return null;
  }

  function updateCardStatusStaff() {
    if (vm.cardType === null) {
      if (vm.card.status !== 'EMPTY') {
        return changeCardStatus('EMPTY');
      }
    } else if (vm.card.status !== 'RELEASED') {
      return changeCardStatus('RELEASED');
    }
    return null;
  }

  function changeCardStatus(status) {
    return cardService
      .changeCardStatus(vm.card.id, status)
      .then(triggerCardUpdate, onError);
  }

  function showAdditionalPersonData() {
    return !isFunctionaryCard();
  }

  function triggerCardUpdate(card) {
    $rootScope.$broadcast('swoa:cardUpdated', card);
    return card;
  }

  function postMortem() {
    $rootScope.$broadcast('swoa:cardDeleted', vm.card);
    closeDetail();
  }

  function onCardUpdate(event, card) {
    if (card.id === vm.card.id) {
      initCard(card);
    }
  }

  function initCard(card) {
    vm.card = card;

    securityService.extendCardModelWithEditAllowed(vm.sportYear, vm.card);
    securityService.extendCardModelWithReleasable(vm.sportYear, vm.card);

    initCardType(vm.card);

    vm.showAdditionalPersonData = showAdditionalPersonData();
    vm.isFunctionaryCard = isFunctionaryCard();
    vm.isOrganisationCardYear = isOrganisationCardYear();
    vm.ftem = Card.getFtemType(vm.card);
  }

  function initCardType(card) {
    if (card.temporaryActive === false) {
      // if the card was released - and now is temporary inactive -> do not show the cards color
      vm.cardType = null;
    } else {
      // this is a hacky way to not show the cardtype if the card is removed (active === false) - see issue #634
      vm.cardType = card.active ? Card.getCardType(card) : null;
    }
  }

  function changeFtem() {
    $timeout(() => saveCardFtem(vm.ftem));
  }

  function purgeCard() {
    dialogService
      .showConfirmationDialog('cardDetail.cancel.confirmation')
      .then(() => cardService.purgeCard(vm.card.id).then(postMortem, onError));
  }

  function showChangeLogDialog() {
    cardService
      .getChangeLog(vm.card.id)
      .then(
        cardChangeLog => changeLogService.showDialog(cardChangeLog, 'card'),
        onError
      );
  }
}
