import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaPersonService from '../person.service';
import swoaPersonSyncService from '../sync/person-sync.service';
import swoaDialogService from '../../components/dialog/dialog.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaPersonProfilePersonProfileHelperService from './person-profile-helper.service';
import htmlTemplate from './person-profile-additional.html';

export default angular
  .module('swoa.person.profile.person-profile-additional', [
    swoaConstants,
    swoaPersonService,
    swoaPersonSyncService,
    swoaDialogService,
    swoaNotification,
    swoaPersonProfilePersonProfileHelperService
  ])
  .component('swoaPersonProfileAdditional', {
    template: htmlTemplate,
    bindings: {
      person: '=',
      currentEditor: '=',
      isInNonReleasedCardYear: '<'
    },
    controller: PersonProfileAdditionalController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonProfileAdditionalController(
  $scope,
  $rootScope,
  $element,
  $q,
  $translate,
  lodash,
  moment,
  Constants,
  personService,
  notificationService,
  personProfileHelper,
  dialogService
) {
  const vm = this,
    helper = personProfileHelper.createFormHelper(vm, 'additional'),
    onError = notificationService.errorHandler(vm);

  const CONFIGS = [
    {
      additionalData: 'personData',
      personKey: 'education',
      dataType: 'translatable',
      translatableType: 'education',
      permissionPart: 'data_education'
    },
    {
      additionalData: 'eliteData',
      personKey: 'military',
      dataType: 'translatable',
      translatableType: 'military',
      permissionPart: 'data_military'
    },
    {
      additionalData: 'recognitionData',
      personKey: 'recognition',
      dataType: 'translatable',
      translatableType: 'recognition',
      permissionPart: 'data_recognition',
      readOnly: false
    },
    {
      additionalData: 'recognitionData',
      personKey: 'recognitionYear',
      dataType: 'enum',
      allowedValues: lodash.rangeRight(
        Constants.recognition.startYear,
        moment().year() + 1
      ),
      dependentValueKey: 'recognition',
      dependentValue: 'NO_DTA_BTA',
      readOnly: false,
      permissionPart: 'data_recognition'
    }
  ];

  vm.additionalDataConfig = [];

  // view data
  vm.editEnable = false;
  vm.editing = false;
  vm.editPerson = null;
  vm.submitted = false;
  vm.showAdditionalData = false;

  vm.initialized = false;

  // functions
  vm.save = save;
  vm.cancelEdit = helper.cancelEdit;
  vm.startEdit = helper.startEdit;
  vm.resetAdditionalData = resetAdditionalData;

  // error handling
  vm.errors = [];
  vm.notificationKey = null;
  vm.notificationSuccess = null;
  vm.notificationKeyList = [];

  // only for testing!!
  vm._updateConfigWithRequiredFields = updateConfigWithRequiredFields;
  vm.initViewData = initViewData;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.person', initViewData, true);
    $scope.$watch(
      'vm.editPerson.recognitionData.recognition',
      updateConfigWithRequiredFields
    );
    $scope.$watch('vm.currentEditor', helper.checkCurrentEditorAndCancelEdit);
  }

  function initViewData() {
    if (helper.needsInitialization(vm.editPerson)) {
      vm.initialized = true;
      vm.editPerson = angular.copy(vm.person);
    }

    vm.canEdit = !vm.isInNonReleasedCardYear || $rootScope.isSwoaUser();

    vm.additionalDataConfig = [];
    CONFIGS.forEach(config => {
      if ($rootScope.personPermission(vm.person, config.permissionPart, 'r')) {
        config.isReadOnly = !$rootScope.personPermission(
          vm.person,
          config.permissionPart,
          'w'
        );
        vm.additionalDataConfig.push(config);
      }
    });
    vm.showAdditionalData = vm.additionalDataConfig.length > 0;
  }

  function save(formValid) {
    helper.resetNotifications();

    if (vm.canEdit) {
      vm.notificationKeyList = [];
      vm.submitted = true;

      if (!hasActiveAdditionalData(vm.editPerson)) {
        return null;
      }
      vm.notificationKeyList = null;

      if (formValid) {
        return updateOrResetAdditionalData();
      }
      vm.notificationKey = 'form.invalid';
      vm.notificationSuccess = false;
      $element.find('form')[0].scrollIntoView();
    } else {
      vm.editing = false;
      vm.initialized = false;
      initViewData();
    }
    return null;
  }

  function hasActiveAdditionalData(editPerson) {
    if (
      editPerson.personData &&
      editPerson.personData.education &&
      editPerson.personData.education.active === false
    ) {
      initNotificationValues(editPerson.personData.education.type);
    }
    if (
      editPerson.eliteData &&
      editPerson.eliteData.military &&
      editPerson.eliteData.military.active === false
    ) {
      initNotificationValues(editPerson.eliteData.military.type);
    }
    if (
      vm.editPerson.recognitionData &&
      vm.editPerson.recognitionData.recognition &&
      vm.editPerson.recognitionData.recognition.active === false
    ) {
      initNotificationValues(vm.editPerson.recognitionData.type);
    }
    return vm.notificationKeyList.length === 0;
  }

  function initNotificationValues(type) {
    const translatedKey = $translate.instant(`applicationParameter.${type}`);
    const translatedValue = $translate.instant(
      'applicationParameter.deactivate.reason'
    );
    vm.notificationKeyList.push({
      notificationKey: `${translatedKey} ${translatedValue}`
    });
  }

  function updateConfigWithRequiredFields() {
    if (vm.editPerson) {
      const hasRecognition = !!(
        vm.editPerson.recognitionData &&
        vm.editPerson.recognitionData.recognition
      );
      const recognitionYearConfig = lodash.find(vm.additionalDataConfig, {
        personKey: 'recognitionYear'
      });
      if (recognitionYearConfig) {
        recognitionYearConfig.required = hasRecognition;
      }
      if (
        hasRecognition &&
        $scope.personProfileAdditionalForm &&
        $scope.personProfileAdditionalForm.recognitionYear
      ) {
        $scope.personProfileAdditionalForm.recognitionYear.$setDirty();
      }
    }
  }

  function updateOrResetAdditionalData() {
    const data = {
      personData: angular.copy(vm.editPerson.personData),
      eliteData: angular.copy(vm.editPerson.eliteData),
      recognitionData: angular.copy(vm.editPerson.recognitionData)
    };
    const resets = [];

    // education
    if (data.personData && data.personData.education === Constants.nullValue) {
      resets.push('education');
      data.personData = null;
    } else if (data.personData && !data.personData.education) {
      data.personData = null;
    }

    // military
    if (data.eliteData && data.eliteData.military === Constants.nullValue) {
      resets.push('military');
      data.eliteData = null;
    } else if (data.eliteData && !data.eliteData.military) {
      data.eliteData = null;
    }

    // recognition
    if (
      data.recognitionData &&
      data.recognitionData.recognition === Constants.nullValue
    ) {
      resets.push('recognition');
      data.recognitionData = null;
    } else if (data.recognitionData && !data.recognitionData.recognition) {
      data.recognitionData = null;
    }

    let resetPromise = $q.when();

    if (resets.length > 0) {
      resetPromise = personService.removeAdditionalData(
        vm.editPerson.id,
        resets
      );
    }

    return resetPromise
      .then(() =>
        personService
          .updateAdditionalData(vm.editPerson.id, data)
          .then(() => helper.processSaved())
      )
      .catch(onError);
  }

  function resetAdditionalData() {
    dialogService
      .showConfirmationDialog('personProfileAdditional.reset.confirmation')
      .then(() =>
        personService
          .removeAdditionalData(vm.editPerson.id, [
            'education',
            'military',
            'recognition'
          ])
          .then(() => helper.processSaved(), onError)
      );
  }
}
