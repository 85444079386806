import angular from 'angular';
import 'restangular';
import swoaConstants from '../../app.constants';
import swoaDate from '../../components/date/date.filter';
import swoaPersonService from '../person.service';

export default angular
  .module('swoa.person.sync.service', [
    'restangular',
    swoaConstants,
    swoaDate,
    swoaPersonService
  ])
  .factory('personSyncService', personSyncService).name;

/** @ngInject */
function personSyncService(
  $rootScope,
  lodash,
  personService,
  Constants,
  ConfigConstants
) {
  const SYNC_CONFIGURATION = [
    {
      personKey: 'forename',
      required: true,
      pattern: '[^0-9]*',
      customMessageKeys: { pattern: 'form.input.validation.nonumber' }
    },
    {
      personKey: 'surname',
      required: true,
      pattern: '[^0-9]*',
      customMessageKeys: { pattern: 'form.input.validation.nonumber' }
    },
    {
      personKey: 'title'
    },
    {
      personKey: 'dateOfBirth',
      dataType: 'date',
      maxDate: new Date(),
      required: true
    },
    {
      personKey: 'gender',
      dataType: 'enum',
      translationPrefix: 'person.gender.',
      allowedValues: Constants.genders,
      required: true
    },
    {
      personKey: 'nationality',
      dataType: 'enum',
      translationPrefix: 'person.nationality.',
      allowedValues: Constants.nationalities,
      required: true
    },
    {
      personKey: 'language',
      dataType: 'enum',
      translationPrefix: 'person.language.',
      allowedValues: ConfigConstants.translation.availableLanguagesEnum,
      required: true
    },
    {
      personKey: 'address.streetAndNumber',
      importKey: 'streetAndNumber',
      required: true
    },
    {
      personKey: 'address.addressSuffix',
      importKey: 'addressSuffix'
    },
    {
      personKey: 'address.zip',
      dataType: 'city-autocomplete',
      importKey: 'zip',
      itemText: 'item.zip',
      updatableElement: 'city',
      required: true
    },
    {
      personKey: 'address.city',
      dataType: 'city-autocomplete',
      importKey: 'city',
      itemText: 'item.name',
      updatableElement: 'zip',
      required: true
    },
    {
      personKey: 'address.country',
      importKey: 'country',
      dataType: 'country',
      required: true
    },
    {
      personKey: 'swissOlympicId',
      infoOnly: true
    },
    {
      personKey: 'email',
      dataType: 'email',
      pattern: Constants.validationExpressions.email
    },
    {
      personKey: 'website',
      dataType: 'social-media-link'
    },
    {
      personKey: 'facebook',
      dataType: 'social-media-link'
    },
    {
      personKey: 'instagram',
      dataType: 'social-media-link'
    },
    {
      personKey: 'twitter',
      dataType: 'social-media-link'
    },
    {
      personKey: 'phoneNumber',
      pattern: '[0-9 \\(\\)\\+]{10,}',
      customMessageKeys: {
        pattern: 'form.input.validation.phone',
        required: 'form.input.validation.phone.required'
      }
    },
    {
      personKey: 'mobileNumber',
      pattern: '[0-9 \\(\\)\\+]{10,}',
      customMessageKeys: {
        pattern: 'form.input.validation.phone',
        required: 'form.input.validation.phone.required'
      }
    },
    {
      personKey: 'profession'
    },
    {
      personKey: 'foreignIds.JS_NUMBER',
      dataType: 'foreignId'
    },
    {
      personKey: 'foreignIds.AHV_NUMBER',
      dataType: 'foreignId'
    }
  ];

  const PROFILE_PERSON_CONFIGURATION = [
    {
      personKey: 'surname',
      required: true,
      pattern: '[^0-9]*',
      customMessageKeys: { pattern: 'form.input.validation.nonumber' }
    },
    {
      personKey: 'forename',
      required: true,
      pattern: '[^0-9]*',
      customMessageKeys: { pattern: 'form.input.validation.nonumber' }
    },
    {
      personKey: 'title'
    },
    {
      personKey: 'gender',
      dataType: 'enum',
      translationPrefix: 'person.gender.',
      allowedValues: Constants.genders,
      required: true
    },
    {
      personKey: 'dateOfBirth',
      dataType: 'date',
      maxDate: new Date(),
      required: true
    },
    {
      personKey: 'nationality',
      dataType: 'enum',
      translationPrefix: 'person.nationality.',
      allowedValues: Constants.nationalities,
      required: true
    },
    {
      personKey: 'language',
      dataType: 'enum',
      translationPrefix: 'person.language.',
      allowedValues: ConfigConstants.translation.availableLanguagesEnum,
      required: true
    },
    {
      personKey: 'profession'
    },
    {
      personKey: 'swissOlympicId',
      infoOnly: true
    },
    {
      personKey: 'REGISTER_NUMBER',
      dataType: 'foreignId',
      infoOnly: true,
      permission: 'person_register_number_r'
    },
    {
      personKey: 'JS_NUMBER',
      dataType: 'foreignId'
    },
    {
      personKey: 'AHV_NUMBER',
      dataType: 'foreignId'
    }
  ];

  const PROFILE_CONTACT_CONFIGURATION = [
    {
      personKey: 'phoneNumber',
      pattern: '[0-9 \\(\\)\\+]{10,}',
      required: '!($parent.$parent.$parent.vm.editPerson.mobileNumber.length)',
      overrideAsterisk: false,
      customMessageKeys: {
        pattern: 'form.input.validation.phone',
        required: 'form.input.validation.phone.required'
      }
    },
    {
      personKey: 'mobileNumber',
      pattern: '[0-9 \\(\\)\\+]{10,}',
      required: '!($parent.$parent.$parent.vm.editPerson.phoneNumber.length)',
      overrideAsterisk: true,
      customMessageKeys: {
        pattern: 'form.input.validation.phone',
        required: 'form.input.validation.phone.required'
      }
    },
    {
      personKey: 'email',
      dataType: 'email',
      pattern: Constants.validationExpressions.email,
      required: true
    }
  ];

  const PROFILE_SOCIAL_MEDIA_CONFIGURATION = [
    {
      personKey: 'website',
      dataType: 'social-media-link'
    },
    {
      personKey: 'facebook',
      dataType: 'social-media-link'
    },
    {
      personKey: 'instagram',
      dataType: 'social-media-link'
    },
    {
      personKey: 'twitter',
      dataType: 'social-media-link'
    }
  ];

  const ADDITIONAL_ELITE_REQUIRED = [
    { key: 'email', requiredValue: true },
    {
      key: 'phoneNumber',
      requiredValue:
        '!($parent.$parent.$parent.vm.editPerson.mobileNumber.length)'
    },
    {
      key: 'mobileNumber',
      requiredValue:
        '!($parent.$parent.$parent.vm.editPerson.phoneNumber.length)',
      overrideAsterisk: true
    }
  ];

  const ADDITIONAL_TRAINER_REQUIRED = angular.copy(ADDITIONAL_ELITE_REQUIRED);
  const ADDITIONAL_TECHNICIAN_REQUIRED = angular.copy(
    ADDITIONAL_ELITE_REQUIRED
  );

  const PROPERTY_NOT_SET_VALUE = '___swoa:needs-sync___';

  const service = {
    extendSyncModel,
    hasUnsetProperty,
    SYNC_CONFIGURATION,
    PROFILE_PERSON_CONFIGURATION,
    PROFILE_CONTACT_CONFIGURATION,
    PROFILE_SOCIAL_MEDIA_CONFIGURATION,
    PROPERTY_NOT_SET_VALUE,
    ADDITIONAL_ELITE_REQUIRED,
    ADDITIONAL_TRAINER_REQUIRED,
    ADDITIONAL_TECHNICIAN_REQUIRED
  };

  activate();

  return service;

  // //////////

  function activate() {
    // define implicit config values automatically
    lodash.forEach(SYNC_CONFIGURATION, config => {
      config.importKey = config.importKey || config.personKey;
      config.infoOnly = config.infoOnly || false;
    });
  }

  function extendSyncModel(sync) {
    // do we have data?
    if (sync.cardImport && sync.person) {
      // the sync result will be a person like object with a nested address property
      sync.syncResult = {
        configKey: 'personKey',
        address: null
      };

      // set the key configuration on the objects from the database
      sync.cardImport.configKey = sync.cardImport.configKey || 'importKey';
      sync.person.configKey = sync.person.configKey || 'personKey';

      // the foreignIds are an array but we want a hash for easy access
      sync.cardImport.foreignIds = personService.hashifyForeignIds(
        sync.cardImport.foreignIds
      );
      sync.person.foreignIds = personService.hashifyForeignIds(
        sync.person.foreignIds
      );

      // create a custom configuration where we can pass additional information
      sync.config = angular.copy(SYNC_CONFIGURATION);

      // configure each property of the syncResult object
      lodash.forEach(sync.config, config => {
        const propertySyncResult = getPropertySyncResult(
          config,
          sync.person,
          sync.cardImport
        );
        lodash.set(sync.syncResult, config.personKey, propertySyncResult);
      });
    }
    return sync;
  }

  function getPropertySyncResult(config, person, cardImport) {
    const personValue = lodash.get(person, config[person.configKey]);
    const importValue = lodash.get(cardImport, config[cardImport.configKey]);

    // if the value is different, we want to show it to the user
    if (!bothEmptyOrEqual(personValue, importValue)) {
      config.syncNeeded = true;
    }

    // if it's only an info, we always take the value from the person. or if value is the same, we set the one
    // from the person, otherwise we set it to null for the user to decide
    if (config.infoOnly || bothEmptyOrEqual(personValue, importValue)) {
      return personValue;
    }

    // optimize: if one of the sides is set and the other isn't, auto suggest to take the existing value
    if (!personValue && importValue) {
      config.syncDone = true;
      return importValue;
    } else if (!importValue && personValue) {
      config.syncDone = true;
      return personValue;
    }
    return PROPERTY_NOT_SET_VALUE;
  }

  function hasUnsetProperty(syncResult) {
    return lodash.some(
      SYNC_CONFIGURATION,
      config =>
        lodash.get(syncResult, config.personKey) === PROPERTY_NOT_SET_VALUE
    );
  }

  function bothEmptyOrEqual(a, b) {
    return (!a && !b) || lodash.isEqual(a, b);
  }
}
