import angular from 'angular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.person.profile.person-profile-helper.service', [
    swoaConstants,
  ])
  .factory('personProfileHelper', personProfileHelper)
  .name;

/** @ngInject */
function personProfileHelper($rootScope, lodash) {
  const singletonStore = {
    parentComponentPersonReloadFunction: null,
    instances: [],
  };

  return {
    createFormHelper,
    setParentComponentPersonReloadFunction,
  };

  // //////////

  function setParentComponentPersonReloadFunction(reloadFn) {
    singletonStore.parentComponentPersonReloadFunction = reloadFn;
  }

  function createFormHelper(vm, editorName) {
    const instance = new FormHelper(vm, editorName);
    singletonStore.instances.push(instance);
    return instance;
  }

  function FormHelper(vm, editorName) {
    this.resetNotifications = resetNotifications;

    function resetNotifications() {
      vm.errors = [];
      vm.notificationKey = null;
      vm.notificationSuccess = null;
      vm.notificationKeyList = [];
    }

    this.startEdit = () => {
      if (editorName) {
        vm.currentEditor = editorName;
      }
      vm.editing = true;
      vm.submitted = false;
      resetNotifications(vm);
    };

    this.cancelEdit = (resetEditor) => {
      vm.editing = false;
      vm.submitted = false;
      resetNotifications(vm);
      vm.initialized = false;
      if (resetEditor) {
        vm.currentEditor = null;
      }
      vm.initViewData();
    };

    this.checkCurrentEditorAndCancelEdit = () => {
      if (vm.editing && vm.currentEditor && editorName && vm.currentEditor !== editorName) {
        this.cancelEdit();
      }
    };

    /** checks if the view data is not initialized and the needed data is available. */
    this.needsInitialization = (personCopy) => {
      if (!vm.person) {
        return false;
      }
      if (personCopy && isFinite(personCopy.version) && isFinite(vm.person.version) && personCopy.version < vm.person.version) {
        return true;
      }
      return !vm.initialized && (vm.person !== null);
    };

    this.processSaved = () => {
      vm.editing = false;

      // show save success info
      vm.notificationSuccess = true;
      vm.notificationKey = 'personProfileInfos.save.success.message';

      // update view
      vm.initialized = false;
      if (singletonStore.parentComponentPersonReloadFunction) {
        lodash.forEach(singletonStore.instances, (instance) => {
          if (instance.setUninitialized) {
            instance.setUninitialized();
          }
        });
        singletonStore.parentComponentPersonReloadFunction();
      }
    };

    this.setUninitialized = () => (vm.initialized = false);

    this.checkMutation = (person) => {
      if (person) {
        if (person.mutationState === 'NEW') {
          return 'personProfile.pendingConfirmation';
        }
        if (person.mutationState === 'PENDING') {
          return 'personProfile.pendingMutation';
        }
      }
      return null;
    };

    this.isPersonMutation = person => person.originalPerson;

    this.getPersonId = person => this.getOriginalPerson(person).id;

    this.getOriginalPerson = person => (person.originalPerson ? person.originalPerson : person);
  }
}
