import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './spinner-button.html';

export default angular
  .module('swoa.spinner-button', [
    swoaConstants,
  ])
  .component('swoaSpinnerButton', {
    template: htmlTemplate,
    bindings: {
      actionWithPromise: '&',
      isDisabled: '<',
      text: '@',
      icon: '@',
      buttonClasses: '@',
    },
    controller: SpinnerButtonController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function SpinnerButtonController($q) {
  const vm = this;

  vm.spinning = false;

  vm.action = action;

  // ///////////////

  function action($event) {
    vm.spinning = true;
    $q.when(vm.actionWithPromise($event)).finally(() => {
      vm.spinning = false;
    });
  }
}
