import angular from 'angular';
import swoaPersonService from '../person.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './person-create-page.html';

export default angular
  .module('swoa.person.search.person-create-page', [
    swoaPersonService,
    swoaNotification,
  ])
  .component('swoaPersonCreatePage', {
    template: htmlTemplate,
    bindings: {},
    controller: SwoaPersonCreatePageController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function SwoaPersonCreatePageController($window, $state, personService, notificationService) {
  const vm = this;

  vm.requiredProperties = [
    { key: 'email', requiredValue: true },
    { key: 'phoneNumber', requiredValue: '!($parent.$parent.$parent.vm.editPerson.mobileNumber.length)' },
    {
      key: 'mobileNumber',
      requiredValue: '!($parent.$parent.$parent.vm.editPerson.phoneNumber.length)',
      overrideAsterisk: true,
    },
  ];

  vm.cancelAction = cancelAction;
  vm.createPerson = createPerson;

  // error handling
  vm.notificationKey = null;

  // //////////

  function createPerson(person) {
    const popup = $window.open('', '_blank');
    personService.createPerson(person).then((serverPerson) => {
      popup.location.href = $state.href('personProfile', { soId: serverPerson.swissOlympicId }, { absolute: true });
      $state.go('search.person', { notificationKey: 'personSearch.createPerson.successMessage', isSuccess: true });
    }, (error) => {
      popup.close();
      notificationService.errorHandler(vm)(error);
    });
  }

  function cancelAction() {
    $state.go('search.person');
  }
}

