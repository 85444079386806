import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './input-checkbox-list.html';

export default angular
  .module('swoa.input-checkbox-list', [
    swoaConstants,
  ])
  .component('swoaInputCheckboxList', {
    template: htmlTemplate,
    bindings: {
      checkboxClass: '@',
      isReadOnly: '<',
      labelKey: '@',
      name: '@',
      ngModel: '=',
      enumList: '<',
      valueProperty: '@',
      valueId: '@',
      orderBy: '@',
    },
    controller: InputCheckboxListController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function InputCheckboxListController(lodash) {
  const vm = this;

  vm.toggle = toggle;
  vm.exists = exists;

  // //////////

  function toggle(item, list) {
    if (!vm.isReadOnly) {
      const idx = lodash.findIndex(list, obj => obj[vm.valueId] === item[vm.valueId]);
      if (idx > -1) {
        list.splice(idx, 1);
      } else {
        list.push(item);
      }
    }
  }

  function exists(item, list) {
    return lodash.findIndex(list, obj => obj[vm.valueId] === item[vm.valueId]) > -1;
  }
}
