import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../app.constants';
import swoaNewsService from '../admin/news/news.service';
import htmlTemplate from './cockpit-news-list.html';

export default angular
  .module('swoa.cockpit.cockpit-news-list', [
    angularTranslate,
    swoaConstants,
    swoaNewsService,
  ])
  .component('swoaCockpitNewsList', {
    bindings: {
      limit: '<',
    },
    template: htmlTemplate,
    controller: CockpitNewsListController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function CockpitNewsListController(
  $rootScope,
  $sce,
  $translate,
  lodash,
  newsService
) {
  const vm = this;

  vm.news = [];
  vm.page = 0;

  vm.getTranslatedProperty = getTranslatedProperty;

  vm.$onInit = () => {
    vm.language = $translate.proposedLanguage() || $translate.use();
    newsService
      .loadByRoleTypes($rootScope.getUniqueUserRoleTypes())
      .then((news) => {
        vm.news = news;
        const numPages = Math.ceil(vm.news.length / vm.limit);
        vm.lastPage = numPages - 1;
        vm.pages = lodash.map(
          lodash.fill(new Array(numPages), 0),
          (value, index) => index
        );
      });
  };

  function getTranslatedProperty(newsItem, property) {
    return $sce.trustAsHtml(newsItem[property + capitalize(vm.language)]);
  }

  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
