import angular from 'angular';
import swoaPersonSearchRoutes from './person-search.routes';
import swoaPersonSearchPersonSearchPage from './person-search-page.component';
import swoaPersonSearchPersonCreatePage from './person-create-page.component';
import swoaPersonPersonSearch from './person-search.component';
import swoaPersonPersonExtendedSearch from './person-extended-search.component';
import swoaPersonPersonSearchDetailsTable from './person-search-details-table.component';

export default angular
  .module('swoa.person.search', [
    swoaPersonSearchRoutes,
    swoaPersonSearchPersonSearchPage,
    swoaPersonSearchPersonCreatePage,
    swoaPersonPersonSearch,
    swoaPersonPersonExtendedSearch,
    swoaPersonPersonSearchDetailsTable,
  ])
  .name;
