import angular from 'angular';
import ngMaterial from 'angular-material';
import swoaConstants from '../../../app.constants';
import swoaTemplateService from './templates.service';
import swoaNotification from '../../../components/notification/notification.module';
import htmlTemplate from './templates.html';

export default angular
  .module('swoa.admin.template.templates', [
    ngMaterial,
    swoaConstants,
    swoaTemplateService,
    swoaNotification
  ])
  .component('swoaTemplates', {
    template: htmlTemplate,
    bindings: {},
    controller: TemplatesController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function TemplatesController(
  $rootScope,
  $scope,
  $document,
  $mdDialog,
  lodash,
  notificationService,
  templateService,
  $translate
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.templates = null;
  vm.order = {};
  vm.selectedCategory = undefined;
  vm.categories = [];

  vm.showFilesDialog = showFilesDialog;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    $rootScope.showSpinner = true;
    templateService
      .getAllTemplates()
      .then(templates => {
        vm.templates = templates;
        vm.templates.forEach(category => {
          const templatesByCategory = [];
          lodash.forEach(
            lodash.groupBy(category.templates, 'type'),
            (value, key) => {
              templatesByCategory.push({
                type: key,
                translatedType: $translate.instant(`template.type.${key}`),
                templates: value
              });
            }
          );
          category.groupedTemplates = templatesByCategory;
        });
      }, onError)
      .finally(() => {
        $rootScope.showSpinner = false;
        vm.categories = vm.templates.map(category => category.category);
      });
  }

  function showFilesDialog(event, template) {
    vm.selectedTemplate = template;
    $scope.backToDialog = () => showFilesDialog(event, template);
    $mdDialog.show({
      template: `<swoa-file-storage back-to-dialog="backToDialog"
                                    template="vm.selectedTemplate"
                                    can-upload="$root.uaPermission('admin_translation_w')"
                                    can-delete="$root.uaPermission('admin_translation_w')"
                                    can-delete-own="$root.uaPermission('admin_translation_w')">
                 </swoa-file-storage>`,
      autoWrap: true,
      preserveScope: true,
      scope: $scope,
      parent: angular.element($document.body),
      targetEvent: event,
      fullscreen: true
    });
  }
}
