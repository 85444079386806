import angular from 'angular';
import angularTranslate from 'angular-translate';
import htmlTemplate from './select-revert.html';

export default angular
  .module('swoa.select-revert', [
    angularTranslate,
  ])
  .component('swoaSelectRevert', {
    template: htmlTemplate,
    bindings: {
      valueSelected: '=',
      selectIcon: '@',
      selectAction: '&',
      revertAction: '&',
    },
    controllerAs: 'vm',
  })
  .name;
