import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaAsyncService from '../../components/async/async-helper.service';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaExportService from './export.service';
import swoaReportHelper from './report-helper.service';
import swoaOrganisationService from '../../organisation/organisation.service';
import templateHtml from './exports-users.html';

export default angular
  .module('swoa.admin.report.exports.users', [
    angularTranslate,
    swoaConstants,
    swoaNotification,
    swoaAsyncService,
    swoaTranslatable,
    swoaExportService,
    swoaReportHelper,
    swoaOrganisationService
  ])
  .component('swoaExportsUsers', {
    template: templateHtml,
    bindings: {},
    controller: ExportsUsersController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function ExportsUsersController(
  $scope,
  $q,
  $translate,
  Constants,
  lodash,
  roleService,
  exportService,
  asyncHelperService,
  userService,
  notificationService,
  reportHelperService,
  organisationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    reportHelper = reportHelperService.createReportHelper(
      vm,
      $scope,
      'exportUserFavorites',
      { getInitialExportParamState }
    ),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      reportHelper.downloadReport
    );

  vm.roleTypes = [];
  vm.allRoles = [];
  vm.roles = [];
  vm.organisations = [];
  vm.allOrganisations = [];
  vm.attributes = { selected: [], all: [], saved: [] };
  vm.categories = [];
  vm.allCategories = lodash.flatMap(
    Constants.organisationTypes.map(elem => elem.categories)
  );

  vm.exportParams = null;
  vm.uiConfig = {};

  vm.resetExportParams = reportHelper.resetExportParams;
  vm.generate = generate;

  // for tests only!
  vm._buildFileName = buildFileName;

  vm.$onInit = () => {
    activate();
  };

  function activate() {
    vm.roleTypes = Constants.roleTypes;
    initializeDataFromServices()
      .then(reportHelper.resetExportParams)
      .then(selectRolesAndCategoriesAccordingToSelectedRoleType);

    asyncHelper.getAsyncJobStatus();
    vm.uiConfig = userService.getCurrentUser().uiConfig;

    $scope.$watch(
      'vm.exportParams.roleTypes',
      selectRolesAndCategoriesAccordingToSelectedRoleType
    );
    $scope.$watch(
      'vm.exportParams.categories',
      selectOrganisationsAccordingToSelectedCategories
    );
  }

  function initializeDataFromServices() {
    const promises = [];
    promises.push(
      roleService.getAllRoles().then(roles => (vm.allRoles = roles))
    );
    promises.push(
      organisationService
        .getList()
        .then(organisations => (vm.allOrganisations = organisations))
    );
    promises.push(
      exportService
        .loadExportableColumnsUsers()
        .then(reportHelper.translateAttributes, onError)
    );
    return $q.all(promises);
  }

  function getInitialExportParamState() {
    return {
      roleIds: [],
      roleTypes: [],
      categories: [],
      organisationsIds: []
    };
  }

  function selectRolesAndCategoriesAccordingToSelectedRoleType() {
    if (!vm.exportParams) {
      return;
    }
    const selectedRoleTypes = vm.exportParams.roleTypes;
    vm.roles = vm.allRoles.filter(role =>
      emptyOrHasSome(selectedRoleTypes, type => type === role.roleType)
    );
    vm.exportParams.roleIds = vm.exportParams.roleIds.filter(roleId =>
      lodash.some(vm.roles, ['id', roleId])
    );
    vm.categories = vm.allCategories.filter(category =>
      emptyOrHasSome(selectedRoleTypes, type =>
        lodash.includes(getCategoriesPerRoleType(type), category)
      )
    );
    vm.exportParams.categories = vm.exportParams.categories.filter(category =>
      lodash.includes(vm.categories, category)
    );
  }

  function selectOrganisationsAccordingToSelectedCategories() {
    if (!vm.exportParams) {
      return;
    }
    let selectedCategories = vm.exportParams.categories;
    if (lodash.isEmpty(selectedCategories)) {
      selectedCategories = angular.copy(vm.categories);
    }
    vm.organisations = vm.allOrganisations.filter(organisation =>
      lodash.some(selectedCategories, category =>
        lodash.includes(organisation.categories, category)
      )
    );
    vm.organisations.forEach(
      organisation =>
        (organisation.displayName = organisationService.getOrganisationDisplayName(
          organisation
        ))
    );
    vm.organisations = lodash.sortBy(vm.organisations, org =>
      org.displayName.toLowerCase()
    );
    vm.exportParams.organisationsIds = vm.exportParams.organisationsIds.filter(
      organisationId => lodash.some(vm.organisations, ['id', organisationId])
    );
  }

  function emptyOrHasSome(arr, predicate) {
    return lodash.isEmpty(arr) || lodash.some(arr, predicate);
  }

  function getCategoriesPerRoleType(roleType) {
    return (lodash.find(Constants.organisationTypes, ['type', roleType]) || {})
      .categories;
  }

  function generate() {
    const parameters = reportHelper.prepareToSerializeAsFavorite();
    asyncHelper.showSpinner();
    exportService
      .loadExport(
        `users/${$translate.use()}`,
        buildFileName(parameters),
        parameters
      )
      .then(asyncHelper.getAsyncJobStatus)
      .catch(asyncHelper.errorHandler);
  }

  function buildFileName(parameters) {
    const language = $translate.use();

    const fileNameElements = [
      'users',
      parameters.roleTypes
        ? parameters.roleTypes
            .map(type => $translate.instant(`user.type.${type}`))
            .join('_')
        : '',
      language.toUpperCase()
    ];

    return `${fileNameElements
      .filter(e => !!e)
      .join('_')
      .replace(' / ', '_')}.xlsx`;
  }
}
