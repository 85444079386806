import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaOrganisationService from '../../organisation/organisation.service';
import swoaCardCardYearService from '../../card/card-year/card-year.service';
import swoaDate from '../../components/date/date.filter';
import htmlTemplate from './card-parameter-detail-setting.html';

export default angular
  .module('swoa.admin.card-parameter-detail-setting', [
    angularTranslate,
    swoaConstants,
    swoaDate,
    swoaOrganisationService,
    swoaCardCardYearService
  ])
  .component('swoaCardParameterDetailSetting', {
    template: htmlTemplate,
    bindings: {
      sport: '=',
      categorySetting: '=',
      typeSettings: '=',
      isReadOnly: '<'
    },
    controller: CardParameterDetailSettingController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardParameterDetailSettingController(
  $scope,
  $translate,
  lodash,
  moment,
  Constants,
  organisationService,
  cardYearService,
  swoaDateFilter
) {
  const vm = this;

  vm.cardTypes = [];
  vm.months = [];
  vm.cardYearDuration = [];
  vm.editMode = false;
  vm.cardTypeContingents = [];
  vm.isCardYearActivate = false;
  vm.generationYear = moment().year();
  vm.generationOrganisation = null;
  vm.possibleYears = lodash.rangeRight(
    moment().year() - 1,
    moment().year() + 4
  );
  vm.organisations = null;

  vm.getContingentByCardType = getContingentByCardType;
  vm.enableEditMode = enableEditMode;
  vm.disableEditMode = disableEditMode;
  vm.generateYear = generateYear;
  vm.getDateFromDateTo = getDateFromDateTo;

  vm._getContingentByCardType = getContingentByCardType;
  vm._updateSeasonStartMonth = updateSeasonStartMonth;
  vm._updateSeasonDuration = updateSeasonDuration;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    initSeasonDurationRange();
    initMonthsIdList();
    initContingent();
    initOrganisations();
    $scope.$watch(
      'vm.categorySetting.seasonDuration',
      updateSeasonStartMonth,
      true
    );
    $scope.$watch(
      'vm.categorySetting.seasonStartMonth',
      updateSeasonDuration,
      true
    );
  }

  function updateSeasonStartMonth() {
    if (!vm.categorySetting.seasonDuration) {
      vm.categorySetting.seasonStartMonth = null;
    }
  }

  function updateSeasonDuration() {
    if (!vm.categorySetting.seasonStartMonth) {
      vm.categorySetting.seasonDuration = null;
    }
  }

  function initSeasonDurationRange() {
    const durationRange = lodash.range(0, 24, 1);
    let index = -1;
    durationRange.forEach(() => {
      index += 1;
      if (index === 0) {
        vm.cardYearDuration.push({ id: null, text: '' });
      } else if (index === 1) {
        vm.cardYearDuration.push({
          id: index,
          text: index + $translate.instant('MONTH')
        });
      } else {
        vm.cardYearDuration.push({
          id: index,
          text: index + $translate.instant('MONTHS')
        });
      }
    });
  }

  function initMonthsIdList() {
    vm.months.push({ id: null, month: '' });
    Constants.months.forEach(month => {
      vm.months.push({ id: month.id, month: $translate.instant(month.month) });
    });
  }

  function initContingent() {
    vm.cardTypes =
      Constants.cards.category[vm.categorySetting.sportYearCategory].types;
    vm.cardTypes.forEach(ct => {
      const contingent = getContingentByCardType(ct);
      if (contingent) {
        vm.cardTypeContingents.push(contingent);
      }
    });
  }

  function initOrganisations() {
    if (
      vm.categorySetting &&
      vm.categorySetting.sportYearCategory === 'TECHNICIAN'
    ) {
      organisationService.getList().then(organisations => {
        vm.organisations = lodash.filter(organisations, org =>
          lodash.includes(org.categories, 'NATIONAL_ASSOCIATION')
        );
        vm.organisations = lodash.map(vm.organisations, organisation => ({
          id: organisation.id,
          displayName: organisationService.getOrganisationDisplayName(
            organisation
          )
        }));
        vm.organisations = lodash.sortBy(vm.organisations, 'displayName');
        vm.generationOrganisation = vm.organisations[0].id;
      });
    }
  }

  function enableEditMode() {
    vm.editMode = true;
  }

  function disableEditMode() {
    vm.editMode = false;
  }

  function getContingentByCardType(cardType) {
    return lodash.find(vm.typeSettings, { cardType });
  }

  function generateYear() {
    const cat = vm.categorySetting.sportYearCategory;
    cardYearService.generateCardYear(
      cat,
      vm.generationYear,
      vm.sport ? vm.sport.id : null,
      vm.generationOrganisation
    );
  }

  function getDateFromDateTo() {
    const dateFrom = getDateFrom();
    return `${swoaDateFilter(dateFrom)} - ${swoaDateFilter(
      getDateTo(dateFrom)
    )}`;
  }

  function getDateFrom() {
    return getMomentDate(vm.categorySetting.seasonStartMonth - 1);
  }

  function getDateTo(dateFrom) {
    return dateFrom
      .add(vm.categorySetting.seasonDuration - 1, 'months')
      .endOf('month');
  }

  function getMomentDate(month) {
    const date = moment(vm.generationYear, 'YYYY');
    return date.month(month).startOf('month');
  }
}
