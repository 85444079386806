import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaDate from '../date/date.filter';
import htmlTemplate from './timeline.html';

export default angular
  .module('swoa.timeline', [swoaConstants, swoaDate])
  .component('swoaTimeline', {
    template: htmlTemplate,
    transclude: true,
    bindings: {
      entries: '<',
      entryDateField: '@',
      filterFunction: '<'
    },
    controller: TimelineController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function TimelineController(moment) {
  const vm = this;

  vm.inSameMonth = inSameMonth;
  vm.getDate = getDate;

  vm.$onInit = () => {
    activate();
  };

  // //////

  function activate() {}

  function getDate(entry) {
    return moment(entry[vm.entryDateField]);
  }

  function inSameMonth(entry1, entry2) {
    return !vm.getDate(entry1).diff(vm.getDate(entry2), 'months');
  }
}
