import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.card.sport-year.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('functionaryCards', {
      url: '/cards/functionary',
      template: '<swoa-sport-year-summary></swoa-sport-year-summary>',
      data: {
        breadcrumb: true,
        hasPermission: 'cardlist_r_functionary'
      }
    })

    .state('cardsOrganisationList', {
      url: '/cards/organisations',
      template:
        '<swoa-organisation-list sport-year-mode="true"></swoa-organisation-list>',
      data: {
        breadcrumb: {
          ignore: false,
          hasPermission: 'organisation_r'
        }
      }
    })
    .state('organisationYearSummary', {
      url: '/cards/organisation/:organisationId',
      template: '<swoa-sport-year-summary></swoa-sport-year-summary>',
      data: {
        breadcrumb: {
          parent: 'cardsOrganisationList',
          title: {
            stateParam: 'organisationId',
            service: 'organisationService'
          }
        },
        hasPermission: 'cardlist_r_technician'
      }
    })

    .state('sportYearSummary', {
      url: '/cards/sport/:sportId',
      template: '<swoa-sport-year-summary></swoa-sport-year-summary>',
      data: {
        breadcrumb: {
          parent: 'cardSportList',
          title: { stateParam: 'sportId', service: 'sportService' }
        },
        hasAnyPermission: [
          'cardlist_r_talent',
          'cardlist_r_elite',
          'cardlist_r_trainer'
        ]
      }
    })

    // cardYear states from here
    .state('sportYearDetail', {
      url: '/cards/:type/:typedId/year/:yearId',
      template: '<swoa-sport-year-detail></swoa-sport-year-detail>',
      data: {
        breadcrumb: {
          parents: [
            {
              type: 'ORGANISATION',
              typeIdName: 'organisationId',
              parent: 'organisationYearSummary'
            },
            {
              type: 'SPORT',
              typeIdName: 'sportId',
              parent: 'sportYearSummary'
            },
            {
              type: 'FUNCTIONARY',
              typeIdName: 'functionaryId',
              parent: 'functionaryCards'
            }
          ],
          titlePlaceholder: 'sportYearName'
        },
        hasPermission: 'card_r'
      }
    })
    .state('sportYearDetail.cardDetail', {
      url: '/card/:cardId',
      views: {
        detail: {
          template: '<swoa-card-detail></swoa-card-detail>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'sportYearDetail',
          ignore: true
        },
        hasPermission: 'card_r'
      }
    })
    .state('sportYearAddAthlete', {
      url: '/cards/:type/:typedId/year/:yearId/add-athlete',
      template: '<swoa-sport-year-add-athlete></swoa-sport-year-add-athlete>',
      data: {
        breadcrumb: {
          parent: 'sportYearDetail'
        },
        hasPermission: 'card_w'
      }
    })
    .state('sportYearCreatePerson', {
      url: '/cards/:type/:typedId/year/:yearId/create-person',
      template:
        '<swoa-sport-year-create-person></swoa-sport-year-create-person>',
      data: {
        breadcrumb: {
          parent: 'sportYearDetail'
        },
        hasPermission: 'person_create_w'
      }
    })
    .state('sportYearPrint', {
      url: '/cards/:type/:typedId/year/:yearId/print',
      template: '<swoa-sport-year-print></swoa-sport-year-print>',
      data: {
        breadcrumb: {
          parent: 'sportYearDetail'
        },
        hasAnyPermission: ['card_print_w_released', 'card_print_w_closed']
      }
    });
}
