import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import swoaConstants from '../../app.constants';
import htmlTemplate from './file-upload.html';

export default angular
  .module('swoa.file-upload', [swoaConstants, ngSanitize])
  .component('swoaFileUpload', {
    template: htmlTemplate,
    bindings: {
      allowFileTypes: '@', // can be an array of file types, for example 'EXCEL,TEXT'
      maxFileSize: '@',
      fileValid: '&',
      ngModel: '='
    },
    controller: FileUploadController,
    controllerAs: 'vm'
  }).name;

export const ALLOW_FILE_TYPES = {
  EXCEL: {
    accept:
      'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    pattern: '.xls,.xlsx'
  },
  TEXT: {
    accept: 'text/plain',
    pattern: '.txt'
  },
  CSV: {
    accept: 'text/csv',
    pattern: '.csv'
  },
  PDF: {
    accept: 'application/pdf',
    pattern: '.pdf'
  },
  TPL: {
    accept: '*/*',
    pattern: '.tpl'
  }
};

/** @ngInject */
function FileUploadController(lodash) {
  const vm = this;

  vm.accept = null;
  vm.pattern = null;
  vm.validFile = null;
  vm.invalidFile = null;
  vm.translationParameter = null;

  vm.onChange = onChange;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    // join together all accept and pattern attributes
    const fileTypes = vm.allowFileTypes.split(',');
    const accept = [],
      pattern = [];
    lodash.forEach(fileTypes, type => {
      const template = ALLOW_FILE_TYPES[type];
      if (template) {
        accept.push(template.accept);
        pattern.push(template.pattern);
      }
    });
    vm.accept = accept.join(',');
    vm.pattern = pattern.join(',');

    vm.translationParameter = {
      maxFileSize: vm.maxFileSize,
      pattern: vm.pattern.split(',').join(', ')
    };
  }

  function onChange(files, file, newFiles, duplicateFiles, invalidFiles) {
    vm.validFile = null;
    vm.invalidFile = null;

    if (newFiles) {
      if (file) {
        // valid file
        vm.validFile = file;

        // notify parent controller that the file can be used now
        const validFn = vm.fileValid();
        if (validFn && angular.isFunction(validFn)) {
          validFn();
        }
      } else if (invalidFiles.length > 0) {
        // invalid file
        vm.invalidFile = invalidFiles[0];
      }
    }
  }
}
