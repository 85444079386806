import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import ngMaterial from 'angular-material';
import angularTranslate from 'angular-translate';
import swoaFileService from '../../components/file/file.service';
import swoaConstants from '../../app.constants';
import swoaDate from '../../components/date/date.filter';
import swoaCardSportYearService from '../sport-year/sport-year.service';
import swoaAsyncService from '../../components/async/async-helper.service';
import htmlTemplate from './card-import-file-upload.html';

export default angular
  .module('swoa.card.card-import.card-import-file-upload', [
    uiRouter,
    ngMaterial,
    swoaFileService,
    swoaConstants,
    swoaDate,
    swoaCardSportYearService,
    angularTranslate,
    swoaAsyncService
  ])
  .component('swoaCardImportFileUpload', {
    template: htmlTemplate,
    bindings: {},
    controller: CardImportFileUploadController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardImportFileUploadController(
  $scope,
  $stateParams,
  $mdDialog,
  ConfigConstants,
  sportYearService,
  $translate,
  fileService,
  asyncHelperService
) {
  const vm = this,
    asyncUploadHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      showError,
      uploadComplete,
      {
        fetchResult: true,
        resultHint: 'uploadcards'
      }
    ),
    asyncSaveHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      showError,
      saveComplete,
      {
        fetchResult: true,
        resultHint: 'importcards'
      }
    );

  vm.sportYear = null;
  vm.selectedFile = null;
  vm.tableHeader = null;
  vm.tableData = null;
  vm.loadingMessage = null;
  vm.step = null;
  vm.stepValid = false;
  vm.importType = null;
  vm.importResult = null;
  vm.successInfo = null;

  // functions
  vm.checkStep = checkStep;
  vm.nextStep = nextStep;
  vm.closeDialog = closeDialog;
  vm.exampleFile = exampleFile;

  // for tests only!
  vm._nameFile = nameFile;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    // load sport year details
    sportYearService
      .loadCardYearBasicInfo($stateParams.yearId)
      .then(sportYear => {
        vm.sportYear = sportYear;
      });

    // initial values
    vm.step = 1;
    vm.stepValid = false;
    vm.importResult = null;
    vm.importType = null;

    // see if we still have a job running
    asyncUploadHelper.getAsyncJobStatus().then(() => {
      if (vm.running) {
        vm.loadingMessage = 'cardimport.loading.check';
        vm.step = 2;
      }
    });
    asyncSaveHelper.getAsyncJobStatus().then(() => {
      if (vm.running) {
        vm.loadingMessage = 'cardimport.loading.import';
        vm.step = 3;
      }
    });
  }

  function checkStep() {
    vm.stepValid =
      (vm.importType === 'xls' && vm.selectedFile) || vm.importType === 'copy';
  }

  function nextStep() {
    vm.stepValid = false;

    if (vm.step === 1) {
      if (vm.importType === 'xls') {
        startExcelImport();
      } else {
        startCopy();
      }
    } else if (vm.step === 2) {
      uploadImportedEntities();
    }
  }

  function startExcelImport() {
    vm.loadingMessage = 'cardimport.loading.check';
    fileService
      .upload(getImportUrl(), vm.selectedFile, {
        id: vm.sportYear.id,
        language: $translate.use()
      })
      .then(asyncUploadHelper.getAsyncJobStatus)
      .catch(asyncUploadHelper.errorHandler);
  }

  function uploadComplete(response) {
    vm.importResult = response;
    vm.step = 2;

    vm.stepValid = vm.importResult.allRowsOk;
    stopLoading();
  }

  function getImportUrl() {
    return ConfigConstants.upload.url[vm.sportYear.category];
  }

  function uploadImportedEntities() {
    vm.loadingMessage = 'cardimport.loading.import';
    sportYearService
      .saveImportedCards(vm.importResult.parsedEntities, vm.sportYear.id)
      .then(asyncSaveHelper.getAsyncJobStatus)
      .catch(asyncSaveHelper.errorHandler);
  }

  function saveComplete(result) {
    vm.successInfo = {
      numCards: result.imported
    };
    vm.step = 3;
    vm.done = true;
    $scope.$parent.fileUploadSuccessful = true;
    stopLoading();
  }

  function startCopy() {
    vm.loadingMessage = 'cardimport.loading.copy';
    sportYearService
      .copyCardsFromLastSportYear(vm.sportYear.id)
      .then(result => {
        vm.successInfo = {
          numCards: result.copied
        };
        vm.step = 3;
        vm.done = true;
      }, showCopyError)
      .finally(stopLoading);
  }

  function showError(error) {
    vm.step = 3;
    vm.done = true;
    vm.failureInfo = {
      failureMessage: error.statusText
    };
    stopLoading();
  }

  function showCopyError(error) {
    vm.step = 3;
    vm.done = true;
    vm.failureInfo = {
      failureMessage:
        error && error.data && error.data.errors.length
          ? $translate.instant(error.data.errors[0].messageKey)
          : null
    };
  }

  function stopLoading() {
    vm.loadingMessage = null;
  }

  function closeDialog() {
    asyncUploadHelper.stopRefresh();
    asyncSaveHelper.stopRefresh();
    $mdDialog.hide();
  }

  function exampleFile() {
    const params = `category=${vm.sportYear.category}&lang=${$translate.use()}`;
    const url = `${ConfigConstants.api.url}/import/excel/cards-example?${params}`;
    fileService.download(url, nameFile());
  }

  function nameFile() {
    return `${
      vm.sportYear.category
    }_cards_import_example_${$translate.use()}.xlsx`;
  }
}
