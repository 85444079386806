import angular from 'angular';
import swoaConstants from '../app.constants';
import swoaLoginService from './login.service';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './login-password-forgot.html';

export default angular
  .module('swoa.login.password-forgot', [
    swoaConstants,
    swoaLoginService,
    swoaNotification,
  ])
  .component('swoaLoginPasswordForgot', {
    template: htmlTemplate,
    bindings: {},
    controller: PasswordForgotController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function PasswordForgotController($state, loginService, notificationService) {
  const vm = this;

  const onError = notificationService.errorHandler(vm);

  vm.sendCodeUserName = sendCodeUserName;
  vm.sendCodeEmail = sendCodeEmail;

  // expose for tests only
  vm._goToLogin = goToLogin;

  // /////////

  function sendCodeUserName(userName) {
    return loginService.sendCodeForUserName(userName).then(goToLogin, onError);
  }

  function sendCodeEmail(email) {
    return loginService.sendCodeForEmail(email).then(goToLogin, onError);
  }

  function goToLogin() {
    const successParameters = {
      notificationKey: 'user.login.codeGenerated',
      isSuccess: true,
    };
    $state.go('login', successParameters);
  }
}
