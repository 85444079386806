import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaPersonService from '../person.service';
import swoaCardService from '../../card/card.service';
import swoaSecurityService from '../../security/security.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './person-data.html';

export default angular
  .module('swoa.person.data.person-data', [
    swoaConstants,
    swoaPersonService,
    swoaCardService,
    swoaSecurityService,
    swoaNotification
  ])
  .component('swoaPersonData', {
    template: htmlTemplate,
    bindings: {
      cardId: '='
    },
    controller: PersonDataController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonDataController(
  $scope,
  $translate,
  personService,
  cardService,
  securityService,
  notificationService,
  moment,
  lodash,
  Constants
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  // view data
  vm.history = null;
  vm.selectedHistoryEntry = null;
  vm.personData = null;
  vm.eliteData = null;
  vm.eliteSportData = null;
  vm.recognitionData = null;
  vm.trainerSportData = null;
  vm.category = null;
  vm.status = null;
  vm.sport = null;
  vm.canEdit = false;
  vm.editMode = false;
  vm.submitted = false;
  vm.possibleLevelsOfEmployment = lodash.range(10, 100, 10);
  vm.yearList = lodash.rangeRight(
    Constants.recognition.startYear,
    moment().year() + 1
  );
  vm.preSelectedCardId = null;
  vm.temporaryContract = null;

  // error handling
  vm.notificationKey = null;
  vm.notificationKeyList = [];

  // functions
  vm.disableEditMode = disableEditMode;
  vm.enableEditMode = enableEditMode;
  vm.save = save;

  // exposed for tests only!!
  vm._checkEditable = checkEditable;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    cardService.loadCardHistory(vm.cardId).then(history => {
      vm.history = history;
      vm.selectedHistoryEntry =
        history && cardService.findCard(history, vm.cardId);
      vm.preSelectedCardId = vm.cardId;

      fillData();
      checkEditable();
    }, onError);

    $scope.$on('swoa:historyEntryChanged', onHistoryEntryChange);
  }

  function onHistoryEntryChange(event, historyEntry) {
    vm.selectedHistoryEntry = historyEntry;
    fillData();
    checkEditable();
    disableEditMode();
  }

  function fillData() {
    if (vm.selectedHistoryEntry) {
      vm.cardId = vm.selectedHistoryEntry.card.id;
      vm.category = vm.selectedHistoryEntry.sportYear.category;
      vm.status = vm.selectedHistoryEntry.sportYear.status;
      vm.sport = vm.selectedHistoryEntry.sport;
      vm.personData = angular.copy(vm.selectedHistoryEntry.personData);
      vm.eliteData = angular.copy(vm.selectedHistoryEntry.eliteData);
      vm.eliteSportData = angular.copy(vm.selectedHistoryEntry.eliteSportData);
      vm.recognitionData = angular.copy(
        vm.selectedHistoryEntry.recognitionData
      );
      vm.trainerSportData = angular.copy(
        vm.selectedHistoryEntry.trainerSportData
      );
    }
  }

  function checkEditable() {
    securityService.extendCardModelWithEditAllowed(
      vm.selectedHistoryEntry.sportYear,
      vm.selectedHistoryEntry.card
    );
    const past =
      moment().diff(
        moment(vm.selectedHistoryEntry.sportYear.validUntil, 'YYYY-MM-DD')
      ) > 0;
    vm.canEdit =
      vm.selectedHistoryEntry.card.canChangeType &&
      !past &&
      parseInt(vm.preSelectedCardId, 10) === vm.cardId;
  }

  function enableEditMode() {
    if (vm.canEdit) {
      vm.editMode = true;
    }
  }

  function disableEditMode() {
    vm.editMode = false;
    vm.submitted = false;
    vm.notificationKey = null;
    vm.notificationKeyList = [];
    vm.personDataForm.$setUntouched();
    vm.personDataForm.$setPristine();
    fillData();
  }

  function save(valid) {
    vm.submitted = true;

    if (!valid) {
      return;
    }

    if (vm.canEdit) {
      vm.notificationKeyList = [];
      const additionalDataContainer = {
        personData: vm.personData,
        eliteData: vm.eliteData,
        recognitionData: vm.recognitionData
      };

      if (!hasActiveAdditionalData()) {
        return;
      }
      vm.notificationKeyList = null;

      if (vm.eliteSportData) {
        if (!vm.eliteSportData.sport) {
          vm.eliteSportData.sport = vm.sport;
        }
        const eliteSportData = [];
        eliteSportData.push(vm.eliteSportData);
        additionalDataContainer.eliteSportData = eliteSportData;
      }
      if (vm.trainerSportData) {
        if (!vm.trainerSportData.sport) {
          vm.trainerSportData.sport = vm.sport;
        }
        const trainerSportData = [];
        trainerSportData.push(vm.trainerSportData);
        additionalDataContainer.trainerSportData = trainerSportData;
      }
      personService
        .updateAdditionalData(
          vm.selectedHistoryEntry.card.athlete.person.id,
          additionalDataContainer
        )
        .then(() => {
          vm.selectedHistoryEntry.personData = vm.personData;
          vm.selectedHistoryEntry.eliteData = vm.eliteData;
          vm.selectedHistoryEntry.eliteSportData = vm.eliteSportData;
          vm.selectedHistoryEntry.recognitionData = vm.recognitionData;
          vm.selectedHistoryEntry.trainerSportData = vm.trainerSportData;

          vm.notificationKey = null;
          disableEditMode();
        }, onError);
    }
    vm.submitted = false;
  }

  function hasActiveAdditionalData() {
    if (vm.personData && vm.personData.education.active === false) {
      initNotificationValues(vm.personData.education.type);
    }
    if (vm.eliteData && vm.eliteData.military.active === false) {
      initNotificationValues(vm.eliteData.military.type);
    }
    if (vm.recognitionData && vm.recognitionData.recognition.active === false) {
      initNotificationValues(vm.recognitionData.recognition.type);
    }
    if (
      vm.trainerSportData &&
      vm.trainerSportData.levelOfEmployment &&
      vm.trainerSportData.levelOfEmployment.active === false
    ) {
      initNotificationValues(vm.trainerSportData.levelOfEmployment.type);
    }

    checkActiveEliteSportData();

    return vm.notificationKeyList.length === 0;
  }

  function checkActiveEliteSportData() {
    if (vm.eliteSportData) {
      if (
        vm.eliteSportData.longTermPotential &&
        vm.eliteSportData.longTermPotential.active === false
      ) {
        initNotificationValues(vm.eliteSportData.longTermPotential.type);
      }
      if (
        vm.eliteSportData.shortTermPotential &&
        vm.eliteSportData.shortTermPotential.active === false
      ) {
        initNotificationValues(vm.eliteSportData.shortTermPotential.type);
      }
      if (
        vm.eliteSportData.mediumTermPotential &&
        vm.eliteSportData.mediumTermPotential.active === false
      ) {
        initNotificationValues(vm.eliteSportData.mediumTermPotential.type);
      }
    }
  }

  function initNotificationValues(type) {
    const translatedKey = $translate.instant(`applicationParameter.${type}`);
    const translatedValue = $translate.instant(
      'applicationParameter.deactivate.reason'
    );
    vm.notificationKeyList.push({
      notificationKey: `${translatedKey} ${translatedValue}`
    });
  }
}
