import angular from 'angular';
import htmlTemplate from './import-result.html';

export default angular
  .module('swoa.import-result', [])
  .component('swoaImportResult', {
    template: htmlTemplate,
    bindings: {
      result: '<',
    },
    controllerAs: 'vm',
  })
  .name;
