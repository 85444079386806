import angular from 'angular';
import swoaTranslatable from '../../components/translatable/translatable.filter';

export default angular
  .module(
    'swoa.nwf-contribution.variable-amount.nwf-variable-amount-period-list',
    [swoaTranslatable]
  )
  .component('swoaNwfVariableAmountPeriodList', {
    template: `
    <div class="sport-detail-category">
    <h2 translate="nwfContributions.variableAmount" class="swoa-offset"></h2>
    <swoa-data-table ng-if="vm.periods.length > 0">
      <table md-table class="swoa-periods-table">
        <thead md-head md-order="vm.order">
        <tr md-row>
          <th md-column md-order-by="validFrom">{{'sportYearSummary.table.duration.from' | translate}}</th>
          <th md-column md-order-by="validUntil">{{'sportYearSummary.table.duration.to' | translate}}</th>
          <th md-column md-order-by="status">{{'sportYearSummary.table.state' | translate}}</th>
          <th md-column md-order-by="sport.displayName">{{'organisation.sport' | translate}}</th>
          <th md-column></th>
        </tr>
        </thead>
        <tbody md-body>
        <tr md-row
            ng-repeat="period in vm.periods | orderBy: vm.order"
            ng-class="{'swoa-historic': !period.sport.active || !period.sport.hasNwfConcept}"
            ui-sref="nwfVariableAmountPeriod(::{organisationId: vm.organisationId, periodId: period.id})">
          <td md-cell ng-bind="period.validFrom | swoaDate"></td>
          <td md-cell ng-bind="period.validUntil | swoaDate"></td>
          <td md-cell>
            <swoa-status status="period.status"></swoa-status>
          </td>
          <td md-cell ng-bind="period.sport.displayName"></td>
          <td md-cell>
            <swoa-warning-icon 
                ng-if="!period.sport.active" warning="{warningTextKey: 'sport.inactive'}">
            </swoa-warning-icon>
            <swoa-warning-icon 
                ng-if="period.sport.active && !period.sport.hasNwfConcept" 
                warning="{warningTextKey: 'organisation.concepts.nwf.notAvailable'}">
            </swoa-warning-icon>
          </td>
        </tr>
        </tbody>
      </table>
    </swoa-data-table>
    <p ng-if="vm.periods.length === 0" class="is-caption swoa-offset" flex
       translate="nwfContributions.noPeriods"></p>
  </div>`,
    controller: NwfBaseAmountPeriodList,
    controllerAs: 'vm',
    bindings: {
      periods: '<',
      organisationId: '<'
    }
  }).name;

/** @ngInject */
function NwfBaseAmountPeriodList() {
  const vm = this;
  vm.order = '-validFrom';
}
