import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.admin.report.service', [
    'restangular',
  ])
  .factory('reportService', reportService)
  .name;

/** @ngInject */
function reportService(Restangular) {
  const REPORTS_ROUTE = 'reports';

  const baseReports = Restangular.all(REPORTS_ROUTE);

  // expose public service API
  return {
    loadReport,
    loadCardChanges,
  };

  // //////////

  function loadReport(language, params) {
    return baseReports.customPOST(params, language);
  }

  function loadCardChanges(language, params) {
    return baseReports.one('change').customPOST(params, language);
  }
}
