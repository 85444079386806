import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaPersonSyncService from './sync/person-sync.service';
import swoaObjectProperty from '../components/object-property/object-property.filter';
import htmlTemplate from './person-create.html';

export default angular
  .module('swoa.person.person-create', [
    uiRouter,
    swoaPersonSyncService,
    swoaObjectProperty
  ])
  .component('swoaPersonCreate', {
    template: htmlTemplate,
    bindings: {
      createButtonTextKey: '@',
      personTitleKey: '@',
      inputTitleKey: '@',
      person: '=',
      requiredProperties: '=',
      createPerson: '&',
      cancelAction: '&'
    },
    controller: PersonCreateController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonCreateController($scope, $element, lodash, personSyncService) {
  const vm = this;

  // view data
  vm.config = null;
  vm.cachedPerson = null;
  vm.editPerson = {
    language: 'GERMAN',
    configKey: 'importKey',
    nationality: 'SWISS'
  };
  vm.submitted = false;

  // functions
  vm.create = create;

  // error handling
  vm.notificationKey = null;

  // for tests only!
  vm._initViewData = initViewData;
  vm._needsInitialization = needsInitialization;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.config = angular.copy(personSyncService.SYNC_CONFIGURATION);
    if (!vm.requiredProperties) {
      vm.requiredProperties = [];
    }
    makePropertiesRequired(vm.config, vm.requiredProperties);
    ignoreSwissOlympicId(vm.config);

    $scope.$watch('vm.person', initViewData, true);
    $scope.$watch('vm.requiredProperties', makePropertiesRequired, true);
    $scope.$watch('vm.editPerson.country', resetZipCity);
  }

  function makePropertiesRequired() {
    if (vm.requiredProperties) {
      vm.requiredProperties.forEach(property => {
        const prop = lodash.find(vm.config, { personKey: property.key });
        if (prop) {
          prop.required = property.requiredValue;
          prop.overrideAsterisk = property.overrideAsterisk;
        }
      });
    }
  }

  function ignoreSwissOlympicId(config) {
    lodash.remove(
      config,
      configItem => configItem.personKey === 'swissOlympicId'
    );
  }

  function create(formValid) {
    vm.submitted = true;

    if (formValid) {
      const newPerson = {
        address: {},
        foreignIds: {},
        active: true
      };
      copyAllValues(vm.editPerson, newPerson, 'importKey', 'personKey');
      vm.createPerson({ $person: newPerson });
    } else {
      vm.notificationKey = 'form.invalid';
      $element.find('form')[0].scrollIntoView();
    }
  }

  function initViewData() {
    if (needsInitialization()) {
      vm.cachedPerson = vm.person;
      vm.cachedPerson.configKey = 'importKey';
      copyAllValues(vm.cachedPerson, vm.editPerson, 'importKey', 'importKey');
    }
  }

  /** checks if the view data is not initialized and the needed data is available. */
  function needsInitialization() {
    if (!vm.person) {
      return false;
    }
    return !vm.cachedPerson && vm.person !== null;
  }

  function copyAllValues(sourcePerson, targetPerson, sourceKey, targetKey) {
    lodash.forEach(vm.config, config => {
      lodash.set(
        targetPerson,
        config[targetKey],
        lodash.get(sourcePerson, config[sourceKey])
      );
    });
  }

  function resetZipCity() {
    delete vm.editPerson.zip;
    delete vm.editPerson.city;
    vm.searchText = undefined;
  }
}
