import angular from 'angular';
import swoaNewsRoutes from './news.routes';
import swoaNewsList from './news-list.component';
import swoaNewsService from './news.service';

export default angular.module('swoa.admin.news', [
  swoaNewsRoutes,
  swoaNewsList,
  swoaNewsService,
]).name;
