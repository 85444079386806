import angular from 'angular';
import swoaTranslatable from '../components/translatable/translatable.filter';
import swoaConstants from '../app.constants';
import htmlTemplate from './cockpit-sport-summary-table.html';

export default angular
  .module('swoa.cockpit.cockpit-sport-summary-table', [
    swoaTranslatable,
    swoaConstants
  ])
  .component('swoaCockpitSportSummaryTable', {
    template: htmlTemplate,
    bindings: {
      sportSummaries: '<',
      selectedCategory: '<',
      order: '<',
      filter: '<',
      onRowClick: '<',
      showColumns: '<'
    },
    controller: CockpitSportSummaryTableController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CockpitSportSummaryTableController(
  $scope,
  lodash,
  Constants,
  swoaTranslatableFilter
) {
  const vm = this;

  vm.cardTypes = Constants.cards.category;

  vm.getSummaryTotal = getSummaryTotal;
  vm.showColumn = showColumn;

  vm.$onInit = () => {
    activate();
  };

  // /////////

  function activate() {
    $scope.$watch('vm.sportSummaries', translateSummaries);
  }

  function translateSummaries() {
    lodash.forEach(vm.sportSummaries, summary => {
      if (summary.organisation) {
        summary.organisation.displayName = swoaTranslatableFilter(
          summary.organisation.name
        );
      }
      if (summary.sport) {
        summary.sport.displayName = swoaTranslatableFilter(
          summary.sport.translatedName
        );
      }
    });
  }

  function getSummaryTotal(summary) {
    return summary.cardTypeSummaries.reduce(
      (total, card) => total + card.count,
      0
    );
  }

  function showColumn(column) {
    const columns = vm.showColumns || [];
    return columns.indexOf(column) !== -1;
  }
}
