import angular from 'angular';
import htmlTemplate from './status.html';

export default angular
  .module('swoa.status', [])
  .component('swoaStatus', {
    template: htmlTemplate,
    bindings: {
      status: '<',
    },
    controllerAs: 'vm',
  })
  .name;
