import angular from 'angular';

export default angular
  .module('swoa.disable-animate', [])
  .directive('swoaDisableAnimate', swoaDisableAnnotate)
  .name;

/** @ngInject */
function swoaDisableAnnotate($animate) {
  return (scope, element) => {
    $animate.enabled(false, element);
  };
}
