import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './validator-messages.html';

export default angular
  .module('swoa.validator-messages', [
    swoaConstants,
  ])
  .component('swoaValidatorMessages', {
    template: htmlTemplate,
    bindings: {
      customMessageKeys: '<',
    },
    controller: ValidatorMessagesController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function ValidatorMessagesController() {
  const vm = this;

  vm.messages = [
    { type: 'valid', messageKey: 'form.input.validation.valid' },
    { type: 'pattern', messageKey: 'form.input.validation.valid' },
    { type: 'email', messageKey: 'form.input.validation.valid' },
    { type: 'required', messageKey: 'form.input.validation.required' },
    { type: 'minlength', messageKey: 'form.input.validation.minlength' },
    { type: 'maxlength', messageKey: 'form.input.validation.maxlength' },
    { type: 'maxdate', messageKey: 'form.input.validation.maxdate' },
    { type: 'ahvNumber', messageKey: 'form.input.validation.ahvNumber' },
    { type: 'md-require-match', messageKey: 'form.input.validation.requireMatch' },
    { type: 'expression', messageKey: 'form.input.validation.expression' },
    { type: 'url', messageKey: 'form.input.validation.url' },
    { type: 'percentage', messageKey: 'form.input.validation.percentage' },
  ];

  vm.getMessageKey = getMessageKey;

  // //////////

  function getMessageKey(message) {
    return (vm.customMessageKeys && vm.customMessageKeys[message.type]) || message.messageKey;
  }
}
