import angular from 'angular';
import swoaConstants from '../../../app.constants';
import swoaNotification from '../../../components/notification/notification.module';
import swoaPersonService from '../../person.service';
import swoaDialogService from '../../../components/dialog/dialog.service';
import swoaPersonProfilePersonProfileHelperService from './../person-profile-helper.service';
import htmlTemplate from './person-profile-timeline.html';

export default angular
  .module('swoa.person.profile.person-profile-timeline', [
    swoaConstants,
    swoaNotification,
    swoaPersonService,
    swoaDialogService,
    swoaPersonProfilePersonProfileHelperService
  ])
  .component('swoaPersonProfileTimeline', {
    template: htmlTemplate,
    bindings: {
      person: '<'
    },
    controller: ProfileTimeline,
    controllerAs: 'vm'
  }).name;

const FIELDS_TO_FULL_TEXT_SEARCH = [
  'comment.text',
  'comment.author.forename',
  'comment.author.surname',
  'sportYear.sport.translatedName',
  'card.type'
];

/** @ngInject */
function ProfileTimeline(
  $window,
  $element,
  moment,
  lodash,
  notificationService,
  personService,
  dialogService,
  personProfileHelper
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    helper = personProfileHelper.createFormHelper(vm);

  vm.timeline = [];
  vm.years = [];
  vm.filterType = null;
  vm.filterSearchQuery = null;
  vm.filterTypeComments = ['CARD_COMMENT', 'PERSON_COMMENT'];

  vm.jumpToYear = jumpToYear;
  vm.addComment = addComment;
  vm.commentDeleteAction = commentDeleteAction;
  vm.timelineFilter = timelineFilter;
  vm.countWithFilter = countWithFilter;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    personService.loadTimeline(helper.getPersonId(vm.person)).then(timeline => {
      vm.timeline = timeline;
      vm.years = getYearsFromTimeline(timeline);
    }, onError);
  }

  function getYearsFromTimeline(timeline) {
    return timeline
      .reduce(
        (years, timelineEntry) => {
          const year = parseInt(timelineEntry.date, 10);

          if (years.indexOf(year) === -1) {
            years.push(year);
          }

          return years;
        },
        [moment().year()]
      )
      .sort((a, b) => b - a);
  }

  function addComment(comment) {
    personService
      .addComment(helper.getPersonId(vm.person), comment)
      .then(activate, onError);
  }

  function commentDeleteAction(comment) {
    dialogService
      .showConfirmationDialog('comment.delete.confirmation')
      .then(() => {
        personService
          .deleteComment(helper.getPersonId(vm.person), comment.id)
          .then(activate, onError);
      });
  }

  function jumpToYear(year) {
    const $to = $element
      .find(`[data-timeline-date^="${year}"]`)
      .first()
      // Navigate to timeline separator, this way, the month and year
      // are still visible after jumping.
      .closest('ng-transclude')
      .prev();

    $window.scrollTo(0, $to.offset().top);
  }

  function timelineFilter(entry) {
    if (vm.filterType && vm.filterType.indexOf(entry.type) < 0) {
      return false;
    }

    if (vm.filterSearchQuery) {
      return filterByQuery(vm.filterSearchQuery.toLowerCase(), entry);
    }
    return true;
  }

  function countWithFilter(filter) {
    return lodash.filter(vm.timeline, entry => filter.indexOf(entry.type) >= 0)
      .length;
  }

  function filterByQuery(query, entry) {
    let result = false;
    FIELDS_TO_FULL_TEXT_SEARCH.forEach(field => {
      const fieldContent = JSON.stringify(lodash.get(entry, field));
      if (
        fieldContent &&
        fieldContent.toLowerCase &&
        fieldContent.toLowerCase().indexOf(query) >= 0
      ) {
        result = true;
      }
    });

    return result;
  }
}
