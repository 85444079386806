import angular from 'angular';
import ngSanitize from 'angular-sanitize';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import 'matchmedia-ng';
import swoaConstants from '../../app.constants';
import htmlTemplate from './breadcrumb.html';

export default angular
  .module('swoa.breadcrumb.breadcrumb', [
    ngSanitize,
    uiRouter,
    angularTranslate,
    swoaConstants,
    'material.components.menu',
    'matchmedia-ng'
  ])
  .component('swoaBreadcrumb', {
    template: htmlTemplate,
    bindings: {},
    controller: BreadcrumbController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function BreadcrumbController(
  $scope,
  $injector,
  $transitions,
  $state,
  $stateParams,
  lodash,
  matchmedia
) {
  const vm = this;

  vm.crumbs = [];
  vm.allCrumbs = [];
  vm.params = {};
  vm.titles = {};
  vm.placeholder = {};

  vm.openMenu = openMenu;
  vm.getHref = getHref;
  vm.getStateId = getStateId;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    const unregisterFn = $transitions.onSuccess({}, () => {
      updateBreadcrumb();
    });
    const unregisterFn2 = $scope.$on(
      'swoa:updateBreadcrumbPlaceholder',
      (event, placeholder, content) => {
        vm.placeholder[placeholder] = content;
        updateBreadcrumb();
      }
    );
    $scope.$on('$destroy', unregisterFn);
    $scope.$on('$destroy', unregisterFn2);
    updateBreadcrumb();
  }

  function updateBreadcrumb() {
    vm.params = $stateParams;
    vm.allCrumbs = getAncestorBreadcrumbStates();
    vm.menuCrumbs = vm.allCrumbs.slice(0, -1).reverse();
    vm.crumbs = vm.allCrumbs;

    if (!matchmedia.isGtMd()) {
      vm.crumbs = vm.allCrumbs.slice(-1);
    }
  }

  function getAncestorBreadcrumbStates() {
    const states = [];
    let state = $state.$current;

    while (state && state.data && state.data.breadcrumb) {
      if (!state.data.breadcrumb.ignore) {
        states.unshift(state);
        fetchTitle(state);
      }

      if (state.data.breadcrumb.parent) {
        state = $state.get(state.data.breadcrumb.parent);
      } else if (state.data.breadcrumb.parents && vm.params.type) {
        // case for multiple parents, must be defined in an array with type definition
        const stateDef = lodash.find(state.data.breadcrumb.parents, {
          type: vm.params.type
        });
        if (stateDef) {
          state = $state.get(stateDef.parent);
          vm.params[stateDef.typeIdName] = vm.params.typedId;
        } else {
          state = null;
        }
      } else {
        state = null;
      }
    }

    return states;
  }

  function fetchTitle(state) {
    const id = getStateId(state);
    if (state.data.breadcrumb.title) {
      const service = $injector.get(state.data.breadcrumb.title.service);
      if (!vm.titles[`${state.name}_${id}`]) {
        service.getBreadcrumbTitle(id).then(title => {
          vm.titles[`${state.name}_${id}`] = title;
        });
      }
    } else if (state.data.breadcrumb.titlePlaceholder) {
      vm.titles[`${state.name}_${id}`] =
        vm.placeholder[state.data.breadcrumb.titlePlaceholder];
    }
  }

  function getStateId(state) {
    if (!state || !state.name) {
      return '';
    }

    return state.data.breadcrumb.title
      ? $stateParams[state.data.breadcrumb.title.stateParam]
      : null;
  }

  function getHref(state) {
    if (!state || !state.name) {
      return '';
    }

    return $state.href(state.name, vm.params);
  }

  function openMenu($mdOpenMenu, e) {
    if (matchmedia.isGtMd() || vm.allCrumbs.length <= 1) {
      return $state.go('cockpit');
    }

    return $mdOpenMenu(e);
  }
}
