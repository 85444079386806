import angular from 'angular';
import swoaAdminRoutes from './admin.routes';
import swoaAdminCardParameter from './card-parameter/card-parameter.module';
import swoaAdminReport from './report/report.module';
import swoaAdminRole from './role/role.module';
import swoaAdminTranslationsPage from './translations/translations-page.module';
import swoaAdminUiKitchensink from './ui-kitchensink/ui-kitchensink.module';
import swoaAdminDebug from './debug.component';
import swoaAdminMySports from './my-sports.component';
import swoaAdminNews from './news/news.module';
import swoaAdminNwfParameter from './nwf-parameter/nwf-parameter.module';
import swoaAdminJobs from './jobs/jobs.module';
import swoaAdminPersonImport from './person-import.component';

export default angular
  .module('swoa.admin', [
    swoaAdminRoutes,
    swoaAdminCardParameter,
    swoaAdminReport,
    swoaAdminRole,
    swoaAdminTranslationsPage,
    swoaAdminUiKitchensink,
    swoaAdminDebug,
    swoaAdminMySports,
    swoaAdminNews,
    swoaAdminNwfParameter,
    swoaAdminJobs,
    swoaAdminPersonImport,
  ])
  .name;

