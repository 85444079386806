import angular from 'angular';
import swoaCompile from './compile.directive';
import swoaUiKitchensinkRoutes from './ui-kitchensink.routes';
import swoaUiKitchensink from './ui-kitchensink.component';

export default angular
  .module('swoa.admin.ui-kitchensink', [
    swoaCompile,
    swoaUiKitchensinkRoutes,
    swoaUiKitchensink,
  ])
  .name;
