import angular from 'angular';
import swoaValidatorMessages from '../validator-messages/validator-messages.component';
import swoaEnsureExpression from '../ensure-expression/ensure-expression.directive';
import htmlTemplate from './input-number.html';

export default angular
  .module('swoa.input-number.component', [
    swoaValidatorMessages,
    swoaEnsureExpression
  ])
  .component('swoaInputNumber', {
    template: htmlTemplate,
    bindings: {
      ngModel: '=',
      id: '@',
      name: '@',
      labelKey: '@',
      containerClass: '@',
      placeholder: '@',
      isReadOnly: '<',
      isRequired: '<',
      messages: '<',
      messagesIf: '<',
      style: '@',
      digits: '<'
    },
    controller: InputNumber,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function InputNumber() {
  const vm = this;

  vm.$onInit = () => {
    vm.pattern = vm.digits
      ? new RegExp(`^\\d+(\\.\\d{1,${vm.digits}})?$`)
      : /^\d+$/;
  };
}
