import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.person.creation.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('personCreationAssignSimilar', {
      url: '/persons/creations/:personCreationId/assign-similar',
      template: '<swoa-person-creation-assign></swoa-person-creation-assign>',
      data: {
        breadcrumb: {
          parent: 'personMutations'
        },
        hasPermission: 'admin_personmutation_r'
      },
      params: {
        search: { value: 'similar' }
      }
    })
    .state('personCreationAssignManual', {
      url: '/persons/creations/:personCreationId/assign-manual',
      template: '<swoa-person-creation-assign></swoa-person-creation-assign>',
      data: {
        breadcrumb: {
          parent: 'personMutations'
        },
        hasPermission: 'admin_personmutation_w'
      },
      params: {
        search: { value: 'manual' }
      }
    })
    .state('personCreationSync', {
      url: '/persons/creations/:personCreationId/sync/:personId',
      template: '<swoa-person-creation-sync></swoa-person-creation-sync>',
      data: {
        breadcrumb: {
          parent: 'personMutations'
        },
        hasPermission: 'admin_personmutation_w'
      }
    });
}
