import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './organisation-search-page.html';

export default angular
  .module('swoa.organisation.search.organisation-search-page', [
    swoaConstants,
  ])
  .component('swoaOrganisationSearchPage', {
    template: htmlTemplate,
    bindings: {
      currentTab: '<',
    },
    controller: SwoaOrganisationSearchPageController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function SwoaOrganisationSearchPageController($stateParams) {
  const vm = this;

  vm.notificationKey = $stateParams.notificationKey;
  vm.isSuccess = $stateParams.isSuccess;
}

