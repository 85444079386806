import angular from 'angular';
import htmlTemplate from './favorite-list.html';

export default angular
  .module('swoa.admin.report.favorite-list', [])
  .component('swoaFavoriteList', {
    template: htmlTemplate,
    bindings: {
      selectFavoriteAction: '&',
      deleteFavoriteAction: '&',
      closeDialogAction: '&',
      favorites: '<',
    },
    controller: FavoriteList,
    controllerAs: 'vm',

  }).name;

/** @ngInject() */
function FavoriteList($q) {
  const vm = this;

  vm.deleteFavorite = deleteFavorite;
  vm.selectFavorite = selectFavorite;

  // //////////

  function selectFavorite(selectedFavorite) {
    vm.selectFavoriteAction({ $favorite: selectedFavorite });
    vm.closeDialogAction();
  }

  function deleteFavorite(favoriteToDelete) {
    vm.deleteFavoriteAction({ $favorite: favoriteToDelete }).then(() => {
      vm.notificationKey = 'report.favorites.deleted';
      vm.notificationSuccess = true;
    }, (error) => {
      vm.errors = [{ messageKey: 'server.error.generic' }];
      return $q.reject(error);
    });
  }
}
