import angular from 'angular';
import htmlTemplate from './data-table-pagination.html';

// this file is copied directly from the md-data-table, because we needed to change the HTML template.
// but we don't want to edit it further, in case we need to update it at some time.
// that's why we disable ESLint
/* eslint-disable */

export default angular
  .module('swoa.data-table.data-table-pagination', [])
  .directive('swoaDataTablePagination', mdTablePagination)
  .name;

/** @ngInject */
function mdTablePagination() {
  function compile(tElement) {
    tElement.addClass('md-table-pagination');
  }

  /** @ngInject */
  function Controller($attrs, $scope, $timeout) {
    const self = this;
    const defaultLabel = {
      page: 'Page:',
      rowsPerPage: 'Rows per page:',
      of: 'of',
    };

    self.label = angular.copy(defaultLabel);

    function isPositive(number) {
      return parseInt(number, 10) > 0;
    }

    self.eval = function (expression) {
      return $scope.$eval(expression);
    };

    self.first = function () {
      self.prePaginationChange();
      $timeout(() => {
        self.page = 1;
        self.onPaginationChange();
      });
    };

    self.hasNext = function () {
      return self.page * self.limit < self.total;
    };

    self.hasPrevious = function () {
      return self.page > 1;
    };

    self.last = function () {
      self.prePaginationChange();
      $timeout(() => {
        self.page = self.pages();
        self.onPaginationChange();
      });
    };

    self.max = function () {
      return self.hasNext() ? self.page * self.limit : self.total;
    };

    self.min = function () {
      return isPositive(self.total) ? self.page * self.limit - self.limit + 1 : 0;
    };

    self.next = function () {
      self.prePaginationChange();
      $timeout(() => {
        self.page++;
        self.onPaginationChange();
      });
    };

    self.onPaginationChange = function () {
      if (angular.isFunction(self.onPaginate)) {
        $timeout(() => {
          self.onPaginate(self.page, self.limit);
        });
      }
    };

    self.prePaginationChange = function () {
      if (angular.isFunction(self.prePaginate)) {
        self.prePaginate(self.page, self.limit);
      }
    };

    self.pages = function () {
      return isPositive(self.total) ? Math.ceil(self.total / (isPositive(self.limit) ? self.limit : 1)) : 1;
    };

    self.previous = function () {
      self.prePaginationChange();
      $timeout(() => {
        self.page--;
        self.onPaginationChange();
      });
    };

    self.showBoundaryLinks = function () {
      return $attrs.mdBoundaryLinks === '' || self.boundaryLinks;
    };

    self.showPageSelect = function () {
      return $attrs.mdPageSelect === '' || self.pageSelect;
    };

    $scope.$watch('$pagination.limit', (newValue, oldValue) => {
      if (isNaN(newValue) || isNaN(oldValue) || newValue === oldValue) {
        return;
      }

      // find closest page from previous min
      self.prePaginationChange();
      $timeout(() => {
        self.page = Math.floor(((self.page * oldValue - oldValue) + newValue) / (isPositive(newValue) ? newValue : 1));
        self.onPaginationChange();
      });
    });

    $attrs.$observe('mdLabel', (label) => {
      angular.extend(self.label, defaultLabel, $scope.$eval(label));
    });

    $scope.$watch('$pagination.total', (newValue, oldValue) => {
      if (isNaN(newValue) || newValue === oldValue) {
        return;
      }

      if (self.page > self.pages()) {
        self.last();
      }
    });
  }

  return {
    bindToController: {
      boundaryLinks: '=?mdBoundaryLinks',
      disabled: '=ngDisabled',
      limit: '=mdLimit',
      page: '=mdPage',
      pageSelect: '=?mdPageSelect',
      onPaginate: '=?mdOnPaginate',
      prePaginate: '=?mdPrePaginate',
      limitOptions: '=?mdLimitOptions',
      total: '@mdTotal',
    },
    compile,
    controller: Controller,
    controllerAs: '$pagination',
    restrict: 'E',
    scope: {},
    template: htmlTemplate,
  };
}
