import angular from 'angular';
import swoaOrganisationRoutes from './organisation.routes';
import swoaOrganisationService from './organisation.service';
import swoaOrganisationCompact from './organisation-compact.component';
import swoaOrganisationSearch from './search/organisation-search.module';
import swoaOrganisationCreate from './organisation-create.component';
import swoaOrganisationProfile from './profile/organisation-profile.module';
import swoaOrganisationList from './organisation-list.component';

export default angular
  .module('swoa.organisation', [
    swoaOrganisationRoutes,
    swoaOrganisationService,
    swoaOrganisationCompact,
    swoaOrganisationSearch,
    swoaOrganisationCreate,
    swoaOrganisationProfile,
    swoaOrganisationList,
  ])
  .name;
