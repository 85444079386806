import angular from 'angular';
import swoaNotification from '../components/notification/notification.module';
import swoaTranslatable from '../components/translatable/translatable.filter';
import swoaOrganisationService from '../organisation/organisation.service';
import swoaNwfContributionService from './nwf-contribution.service';

export default angular
  .module('swoa.nwf-contribution.nwf-periods', [
    swoaNotification,
    swoaTranslatable,
    swoaOrganisationService,
    swoaNwfContributionService
  ])
  .component('swoaNwfPeriods', {
    template: `
      <md-content flex>
        <swoa-nwf-base-amount-period-list periods="vm.periods.baseAmountPeriods" 
                                          organisation-id="vm.organisationId"></swoa-nwf-base-amount-period-list>
        <swoa-nwf-variable-amount-period-list periods="vm.periods.variableAmountPeriods" 
                                              organisation-id="vm.organisationId"></swoa-nwf-variable-amount-period-list>
      </md-content>`,
    bindings: {},
    controller: NwfPeriods,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfPeriods(
  $rootScope,
  $stateParams,
  $q,
  lodash,
  $translate,
  organisationService,
  notificationService,
  swoaTranslatableFilter,
  nwfContributionService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.periods = {};

  vm.organisationId = $stateParams.organisationId;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    nwfContributionService.periods(vm.organisationId).then(periods => {
      vm.periods = periods;
      lodash.forEach(vm.periods.variableAmountPeriods, period => {
        period.sport.displayName = swoaTranslatableFilter(
          period.sport.translatedName
        );
      });
    }, onError);
  }
}
