import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaFileService from '../../components/file/file.service';
import swoaNwfContributionService from '../../nwf-contribution/nwf-contribution.service';
import htmlTemplate from './nwf-abacus-export.html';

export default angular
  .module('swoa.nwf-contribution.abacus.export', [
    angularTranslate,
    swoaFileService,
    swoaConstants,
    swoaNwfContributionService
  ])
  .component('swoaNwfAbacusExport', {
    bindings: {
      year: '<'
    },
    controller: NwfAbacusExport,
    template: htmlTemplate,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfAbacusExport(
  $scope,
  $translate,
  $http,
  fileService,
  nwfContributionService,
  notificationService,
  asyncHelperService,
  ConfigConstants
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      downloadExport
    );

  vm.model = {
    year: vm.year || 2018,
    payment: undefined
  };

  vm.availablePayments = [
    {
      id: 'payment_1',
      text: $translate.instant('nwfContributions.column.payment1')
    },
    {
      id: 'payment_2',
      text: $translate.instant('nwfContributions.column.payment2')
    }
  ];

  vm.createExport = createExport;

  vm.$onChanges = changesObj => {
    vm.model = angular.extend({}, vm.model, {
      year: changesObj.year.currentValue
    });
  };

  function createExport(model) {
    const url = `${ConfigConstants.api.url}/nwf-contribution/create-abacus-export`;
    asyncHelper.showSpinner();
    const config = {
      params: { fileName: `abacus-export-${model.year}-${model.payment}.xml` }
    };

    $http
      .post(url, model, config)
      .then(asyncHelper.getAsyncJobStatus)
      .catch(asyncHelper.errorHandler);
  }

  function downloadExport(resultUrl, result, options) {
    return fileService.download(resultUrl, result.resultHint, options);
  }
}
