import angular from 'angular';
import ngMaterial from 'angular-material';
import swoaConstants from '../app.constants';
import swoaUserService from './user.service';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './change-password.html';

export default angular
  .module('swoa.user.change-password', [
    ngMaterial,
    swoaConstants,
    swoaUserService,
    swoaNotification
  ])
  .component('swoaChangePassword', {
    template: htmlTemplate,
    bindings: {},
    controller: ChangePasswordController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function ChangePasswordController(
  $scope,
  $mdDialog,
  userService,
  notificationService
) {
  const vm = this;

  vm.credentials = null;
  vm.passwordConfirm = null;
  vm.submitted = false;
  vm.notificationKey = null;
  vm.isSuccess = false;
  vm.passwordStrength = null;

  vm.save = save;
  vm.closeDialog = closeDialog;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.credentials = {
      oldPassword: null,
      password: null
    };
    vm.passwordConfirm = null;
    vm.submitted = false;
  }

  function save(formValid) {
    vm.submitted = true;

    if (!formValid) {
      return;
    }

    // save password
    vm.notificationKey = null;
    userService.changePassword(vm.credentials).then(() => {
      // success
      vm.isSuccess = true;
      vm.notificationKey = 'user.password.changed';
      activate();
      reset();
    }, notificationService.errorHandler(vm));
  }

  function reset() {
    $scope.changePassword.$setPristine();
    $scope.changePassword.$setUntouched();
  }

  function closeDialog() {
    $mdDialog.hide();
  }
}
