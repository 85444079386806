import angular from 'angular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.admin.translations.service', [
    swoaConstants,
  ])
  .factory('translationsService', translationsService)
  .name;

/** @ngInject */
function translationsService($q, $http, lodash, ConfigConstants) {
  const languages = ConfigConstants.translation.availableLanguages;
  const prefix = ConfigConstants.translation.prefix;
  const suffix = ConfigConstants.translation.suffix;

  return {
    loadAllTranslations,
    saveTranslations,
  };

  // ////////

  function loadAllTranslations() {
    return $q.all(translationsPromises()).then((translations) => {
      // re-map array to object keyed by the locale
      const translationsMap = {};
      lodash.forEach(lodash.keyBy(translations, 'locale'), (value, key) => {
        translationsMap[key] = value.translations;
      });
      return translationsMap;
    });
  }

  function translationsPromises() {
    return languages.map((locale) => {
      const thenBody = result => ({ locale, translations: (result && result.data) || {} });
      return $http.get(prefix + locale + suffix).then(thenBody);
    });
  }

  function saveTranslations(translations) {
    return $http.put(prefix, translations);
  }
}
