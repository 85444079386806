import angular from 'angular';
import ngMaterial from 'angular-material';
import angularTranslate from 'angular-translate';
import swoaDialog from '../dialog/dialog.module';

export default angular
  .module('swoa.change-log.service', [ngMaterial, angularTranslate, swoaDialog])
  .factory('changeLogService', changeLogService).name;

export const PERMISSION_MAPPING = {
  'person.note': 'note',
  'person.deactivationReason': 'deactivation',
  'person.deactivationType': 'deactivation',
  'person.*': 'data_base',
  'address.*': 'data_base',
  'foreignId.*': 'data_base',
  'personData.*': 'data_education',
  'eliteData.*': 'data_military',
  'recognitionData.*': 'data_recognition',
  'eliteSportData.cadre': 'data_cadre',
  'eliteSportData.*': 'data_potential',
  'trainerSportData.*': 'data_trainer',
};

// is used to group changeLog entries.
export const ATTRIBUTE_GROUPINGS = {
  person: {
    // logType
    basicData: [
      // groupingKey
      { entityName: 'person', attributeSelector: '*' },
      { entityName: 'address', attributeSelector: '*' },
      { entityName: 'foreignId', attributeSelector: '*' },
    ],
    additionalData: [
      { entityName: 'personData', attributeSelector: '*' },
      { entityName: 'eliteData', attributeSelector: '*' },
      { entityName: 'recognitionData', attributeSelector: '*' },
    ],
    sportSpecificAdditionalData: [
      { entityName: 'eliteSportData', attributeSelector: '*' },
      { entityName: 'trainerSportData', attributeSelector: '*' },
    ],
    filePhoto: [{ entityName: 'fileStorage', attributeSelector: '*' }],
    stateChange: [
      { entityName: 'person', isCreation: true },
      { entityName: 'person', attributeSelector: 'active' },
    ],
  },
  sportYear: {
    basicData: [
      { entityName: 'sportYear', attributeSelector: 'validFrom' },
      { entityName: 'sportYear', attributeSelector: 'validUntil' },
    ],
    status: [{ entityName: 'sportYear', attributeSelector: 'status' }],
    notification: [{ entityName: 'mailProtocol', attributeSelector: '*' }],
    filePhoto: [{ entityName: 'fileStorage', attributeSelector: '*' }],
  },
  organisation: {
    basicData: [
      { entityName: 'organisation', attributeSelector: '*' },
      { entityName: 'address', attributeSelector: '*' },
      { entityName: 'elementTranslation', attributeSelector: '*' },
    ],
    stateChange: [
      { entityName: 'organisation', isCreation: true },
      { entityName: 'organisation', attributeSelector: 'active' },
    ],
    nwf: [{ entityName: 'organisation', attributeSelector: 'nwfInstitutions' }],
    sportSpecificAdditionalData: [
      { entityName: 'sport', attributeSelector: 'hasNwfConcept' },
      { entityName: 'sport', attributeSelector: 'hasFoerderConcept' },
      { entityName: 'sport', attributeSelector: 'costcenter' },
    ],
  },
  user: {
    basicData: [{ entityName: 'user', attributeSelector: '*' }],
    notification: [{ entityName: 'mailProtocol', attributeSelector: '*' }],
    password: [{ entityName: 'user', attributeSelector: 'password' }],
  },
  card: {
    basicData: [{ entityName: 'card', attributeSelector: '*' }],
    cardTypeEntity: [
      { entityName: 'card', attributeSelector: 'cardTypeEntity' },
      { entityName: 'card', attributeSelector: 'ftemPhase' },
    ],
    status: [
      { entityName: 'card', attributeSelector: 'status' },
      { entityName: 'card', attributeSelector: 'printed' },
      { entityName: 'card', attributeSelector: 'digitalCard' },
    ],
  },
  sportYearSetting: {
    basicData: [
      { entityName: 'sportYearSetting', attributeSelector: '*' },
      { entityName: 'cardYearCategorySetting', attributeSelector: '*' },
      { entityName: 'cardTypeSetting', attributeSelector: '*' },
    ],
  },
};

/** @ngInject */
function changeLogService(
  $rootScope,
  $mdDialog,
  $translate,
  lodash,
  dialogService
) {
  return {
    showDialog,
    buildGroupingKey,
    canSeeAttribute,
  };

  // /////////////////

  function showDialog(changeLog, type, auditEntityId) {
    const uncloseable = false;
    const template =
      '<swoa-change-log change-logs="changeLog" log-type="type" audit-entity-id="auditEntityId"></swoa-change-log>';
    const title = $translate.instant('changeLog.title');
    const scope = {
      changeLog,
      type,
      auditEntityId,
      closeAndResolve() {
        $mdDialog.cancel();
      },
    };
    return dialogService.showDialog(template, uncloseable, title, scope);
  }

  function buildGroupingKey(logType, changeLog) {
    const groupings = ATTRIBUTE_GROUPINGS[logType];
    const lastMatch = {
      groupingKey: null,
      wasWildcard: true,
    };
    // we go through
    lodash.forEach(groupings, (group, groupingKey) => {
      lodash.forEach(group, (path) => {
        // match the entity
        if (changeLog.entityName === path.entityName) {
          // is it a creation activity?
          if (changeLog.activity === 'CREATION' && path.isCreation === true) {
            lastMatch.groupingKey = groupingKey;
            lastMatch.wasWildcard = false;
          } else if (lastMatch.groupingKey === null) {
            // there was no previous match
            if (path.attributeSelector === '*') {
              // this match is a wild card
              lastMatch.groupingKey = groupingKey;
              lastMatch.wasWildcard = true;
            } else if (
              lodash.some(changeLog.attributes, [
                'name',
                path.attributeSelector,
              ])
            ) {
              // this match is a specific attribute
              lastMatch.groupingKey = groupingKey;
              lastMatch.wasWildcard = false;
            }
          } else if (lastMatch.wasWildcard) {
            // we have a previous match but it was a wildcard. maybe we can be more specific?
            if (
              lodash.some(changeLog.attributes, [
                'name',
                path.attributeSelector,
              ])
            ) {
              lastMatch.groupingKey = groupingKey;
              lastMatch.wasWildcard = false;
            }
          }
        }
      });
    });
    return lastMatch.groupingKey;
  }

  function canSeeAttribute(personId, logType, entity, attribute) {
    if (PERMISSION_MAPPING[`${entity}.${attribute}`]) {
      return personReadPermissionOk(
        personId,
        PERMISSION_MAPPING[`${entity}.${attribute}`]
      );
    } else if (PERMISSION_MAPPING[`${entity}.*`]) {
      return personReadPermissionOk(
        personId,
        PERMISSION_MAPPING[`${entity}.*`]
      );
    }
    return true;
  }

  function personReadPermissionOk(personId, permissionPart) {
    if (permissionPart === 'note') {
      return $rootScope.uaPermission('person_note_r');
    }
    if (permissionPart === 'deactivation') {
      return $rootScope.isSwoaUser() || $rootScope.isAssociationUser();
    }
    return $rootScope.personPermission({ id: personId }, permissionPart, 'r');
  }
}
