import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaAsyncService from '../../components/async/async-helper.service';
import swoaDate from '../../components/date/date.filter';
import swoaExportService from './export.service';
import swoaReportHelper from './report-helper.service';
import htmlTemplate from './exports-sports.html';

export default angular
  .module('swoa.admin.report.exports.sports', [
    angularTranslate,
    swoaConstants,
    swoaNotification,
    swoaAsyncService,
    swoaDate,
    swoaExportService,
    swoaReportHelper
  ])
  .component('swoaExportsSports', {
    template: htmlTemplate,
    bindings: {},
    controller: ExportsSportController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function ExportsSportController(
  $scope,
  $q,
  exportService,
  notificationService,
  asyncHelperService,
  Constants,
  $translate,
  userService,
  reportHelperService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    reportHelper = reportHelperService.createReportHelper(
      vm,
      $scope,
      'exportSportFavorites',
      { getInitialExportParamState }
    ),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      reportHelper.downloadReport
    );

  vm.organisations = [];
  vm.olympic = Constants.sport.olympic;
  vm.attributes = { selected: [], all: [], saved: [] };

  vm.exportParams = null;
  vm.uiConfig = {};

  vm.resetExportParams = reportHelper.resetExportParams;
  vm.generate = generate;

  vm.$onInit = () => {
    activate();
  };

  // //////

  function activate() {
    initializeDataFromServices().then(reportHelper.resetExportParams);

    asyncHelper.getAsyncJobStatus();
    vm.uiConfig = userService.getCurrentUser().uiConfig;
  }

  function initializeDataFromServices() {
    const promises = [];
    promises.push(
      exportService
        .loadOrganisations()
        .then(organisations => (vm.organisations = organisations)),
      onError
    );
    promises.push(
      exportService
        .loadExportableColumnsSports()
        .then(reportHelper.translateAttributes, onError)
    );
    return $q.all(promises);
  }

  function getInitialExportParamState() {
    return {
      classification: [],
      olympic: [],
      organisationsIds: [],
      season: []
    };
  }

  function generate() {
    const parameters = reportHelper.prepareToSerializeAsFavorite();
    asyncHelper.showSpinner();
    exportService
      .loadExport(`sports/${$translate.use()}`, buildFileName(), parameters)
      .then(asyncHelper.getAsyncJobStatus)
      .catch(asyncHelper.errorHandler);
  }

  function buildFileName() {
    const language = $translate.use();

    const fileNameElements = ['sports', language.toUpperCase()];

    return `${fileNameElements.filter(e => !!e).join('_')}.xlsx`;
  }
}
