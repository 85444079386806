import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.card.card-import.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('cardImport', {
      abstract: true,
      url: '/cards/:type/:typedId/year/:yearId/import',
      template: '<swoa-card-import></swoa-card-import>'
    })
    .state('cardImport.overview', {
      url: '/overview',
      views: {
        detail: {
          template: '<swoa-card-import-overview></swoa-card-import-overview>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'sportYearDetail.cardDetail'
        },
        hasAnyPermission: [
          'cardimport_w_new',
          'cardimport_w_for_consideration',
          'cardimport_w_revision_needed',
          'cardimport_w_provisionally_released',
          'cardimport_w_released',
          'cardimport_w_closed'
        ]
      }
    })
    .state('cardImport.sync', {
      url: '/:cardImportId/sync/{personId}',
      views: {
        detail: {
          template: '<swoa-card-import-sync></swoa-card-import-sync>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'cardImport.overview',
          ignore: true
        },
        hasAnyPermission: [
          'cardimport_w_new',
          'cardimport_w_for_consideration',
          'cardimport_w_revision_needed',
          'cardimport_w_provisionally_released',
          'cardimport_w_released',
          'cardimport_w_closed'
        ]
      },
      params: {
        personId: null
      }
    })
    .state('cardImport.assignSimilar', {
      url: '/:cardImportId/assign-similar',
      views: {
        detail: {
          template: '<swoa-card-import-assign></swoa-card-import-assign>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'cardImport.overview',
          ignore: true
        },
        hasAnyPermission: [
          'cardimport_w_new',
          'cardimport_w_for_consideration',
          'cardimport_w_revision_needed',
          'cardimport_w_provisionally_released',
          'cardimport_w_released',
          'cardimport_w_closed'
        ]
      },
      params: {
        search: { value: 'similar' }
      }
    })
    .state('cardImport.assignManual', {
      url: '/:cardImportId/assign-manual',
      views: {
        detail: {
          template: '<swoa-card-import-assign></swoa-card-import-assign>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'cardImport.overview',
          ignore: true
        },
        hasAnyPermission: [
          'cardimport_w_new',
          'cardimport_w_for_consideration',
          'cardimport_w_revision_needed',
          'cardimport_w_provisionally_released',
          'cardimport_w_released',
          'cardimport_w_closed'
        ]
      },
      params: {
        search: { value: 'manual' }
      }
    })
    .state('cardImport.createPerson', {
      url: '/:cardImportId/create-person',
      views: {
        detail: {
          template:
            '<swoa-card-import-create-person></swoa-card-import-create-person>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'cardImport.overview',
          ignore: true
        },
        hasAnyPermission: [
          'cardimport_w_new',
          'cardimport_w_for_consideration',
          'cardimport_w_revision_needed',
          'cardimport_w_provisionally_released',
          'cardimport_w_released',
          'cardimport_w_closed'
        ]
      }
    })
    .state('cardImport.cardConfirm', {
      url: '/:cardImportId/card-confirm',
      views: {
        detail: {
          template:
            '<swoa-card-import-card-confirm></swoa-card-import-card-confirm>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'cardImport.overview',
          ignore: true
        },
        hasAnyPermission: [
          'cardimport_w_new',
          'cardimport_w_for_consideration',
          'cardimport_w_revision_needed',
          'cardimport_w_provisionally_released',
          'cardimport_w_released',
          'cardimport_w_closed'
        ]
      }
    });
}
