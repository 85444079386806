import angular from 'angular';
import angularTranslate from 'angular-translate';
import { JSON_DATE_FORMAT } from './report.constants';
import { NWF_START_YEAR } from './exports-nwf-base-amount.component';

export default angular
  .module('swoa.admin.report.exports.nwfBaseAmount.filter', [
    angularTranslate,
  ])
  .component('swoaNwfExportBaseAmountFilter', {
    template: `
    <fieldset class="margin-bottom-20 input-row">
      <legend translate="report.filter"></legend>
      <div layout="row" class="input-row jail-selects-70-25">
        <div flex="25">
          <swoa-input-select ng-model="vm.organisationIds"
                             name="organisation"
                             label-key="user.type.ASSOCIATION"
                             id-value-list="vm.organisations"
                             value-property="displayName"
                             multiple="true"
                             allow-empty="vm.organisations.length > 1"
                             empty-label="all">
          </swoa-input-select>
        </div>
        <div flex="50" layout="row">
          <md-input-container flex>
            <label translate="date.from"></label>
            <md-select ng-model="vm.exportFrom">
              <md-option ng-value="date" ng-repeat="date in ::vm.startDates">{{::date | swoaDate: 'YYYY'}}</md-option>
            </md-select>
          </md-input-container>

          <md-input-container flex>
            <label translate="date.until"></label>
            <md-select ng-model="vm.exportUntil">
              <md-option ng-value="date" ng-repeat="date in ::vm.endDates">{{::date | swoaDate: 'YYYY'}}</md-option>
            </md-select>
          </md-input-container>
        </div>
      </div>
      </fieldset>`,
    bindings: {
      organisations: '<',
      organisationIds: '=',
      exportFrom: '=',
      exportUntil: '=',
      maxYear: '<',
    },
    controllerAs: 'vm',
    controller: ExportNwfBaseAmountFilter,
  }).name;

/** @ngInject */
function ExportNwfBaseAmountFilter(moment, lodash) {
  const vm = this;

  vm.startDates = [];
  vm.endDates = [];

  vm.$onInit = onInit;

  function onInit() {
    for (let year = vm.maxYear; year >= NWF_START_YEAR; year--) {
      const startOfMonth = moment([year, 0, 1]);
      vm.startDates = lodash.concat(vm.startDates, startOfMonth.format(JSON_DATE_FORMAT));
      vm.endDates = lodash.concat(vm.endDates, startOfMonth.clone().endOf('year').startOf('day').format(JSON_DATE_FORMAT));
    }
  }
}
