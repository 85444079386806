import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.admin.template.service', [
    'restangular',
  ])
  .factory('templateService', templateService)
  .name;

/** @ngInject */
function templateService(Restangular) {
  return {
    getAllTemplates,
    getTemplatesWithTypeAndLanguage,
    deleteTemplate,
  };

  // //////////

  function getAllTemplates() {
    return Restangular.one('templates').get();
  }

  function getTemplatesWithTypeAndLanguage(template) {
    let url = `templates/${template.type}`;
    if (template.language) {
      url += `/${template.language.toLowerCase()}`;
    }
    return Restangular.one(url).get();
  }

  function deleteTemplate(template) {
    return Restangular.one(`templates/${template.category}`).remove({ fileName: template.fileName });
  }
}
