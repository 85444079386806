import angular from 'angular';
import 'restangular';
import swoaConstants from '../app.constants';

export default angular
  .module('swoa.nwf-contribution.service', [
    'restangular',
    swoaConstants,
  ])
  .factory('nwfContributionService', nwfContributionService)
  .name;

export const CALENDAR_YEAR_ROUTE = 'nwf-calendaryear';
export const CONTRIBUTION_ROUTE = 'nwf-contribution';
export const VARIABLE_AMOUNT_PERIOD_ROUTE = 'nwf-variable-amount-period';
export const BASE_AMOUNT_PERIOD_ROUTE = 'nwf-base-amount-period';
export const TRAINER_ROUTE = 'nwf-trainer';
export const INSTITUTION_ROUTE = 'nwf-institution';

/** @ngInject */
function nwfContributionService($q, lodash, Restangular) {
  return {
    getCalendarYears,
    periods,
    currentBaseAmountCategories,
    currentVariableAmountCategories,
    countByBaseAmountCategories,
    currentFactor,
    currentSums,
    updateVariableAmountCategories,
    updateBaseAmountCategories,
    getMaxYear,
    getAllInstitutions,
    getSportsWithContainers,
    createAbacusExport,
  };

  // //////////

  function getCalendarYears(calendarYear) {
    return Restangular.one(CALENDAR_YEAR_ROUTE, 'all-by-year').customGET(calendarYear);
  }

  function periods(organisationId) {
    return Restangular.one(CONTRIBUTION_ROUTE, organisationId).customGET('periods');
  }

  function currentBaseAmountCategories() {
    return Restangular.one(CONTRIBUTION_ROUTE, 'base-amount-categories').customGETLIST('current');
  }

  function currentVariableAmountCategories() {
    return Restangular.one(CONTRIBUTION_ROUTE, 'variable-amount-categories').customGETLIST('current');
  }

  function countByBaseAmountCategories() {
    return Restangular.one(CONTRIBUTION_ROUTE, 'base-amount-categories').customGET('count-by-category');
  }

  function currentFactor() {
    return Restangular.one(CONTRIBUTION_ROUTE, 'variable-amount-categories').customGET('factor');
  }

  function currentSums() {
    return Restangular.one(CONTRIBUTION_ROUTE, 'variable-amount-categories').customGET('sums');
  }

  function updateVariableAmountCategories(factor, categories) {
    return Restangular.one(CONTRIBUTION_ROUTE, 'variable-amount-categories').customPUT(categories, 'current', { factor });
  }

  function updateBaseAmountCategories(categories) {
    return Restangular.one(CONTRIBUTION_ROUTE, 'base-amount-categories').customPUT(categories, 'current');
  }

  function getMaxYear() {
    return Restangular.one(CALENDAR_YEAR_ROUTE, 'max-year').get();
  }

  function getAllInstitutions() {
    return Restangular.all(INSTITUTION_ROUTE).getList();
  }

  function getSportsWithContainers() {
    return Restangular.all(VARIABLE_AMOUNT_PERIOD_ROUTE).customGET('sports-with-container');
  }

  function createAbacusExport(parameters) {
    return Restangular.one(CONTRIBUTION_ROUTE, 'create-abacus-export').customPOST(parameters);
  }
}
