import angular from 'angular';
import swoaConstants from '../app.constants';
import swoaTranslatable from '../components/translatable/translatable.filter';
import htmlTemplate from './card-technician-responsibilities.html';

export default angular
  .module('swoa.card.card-technician-responsibilities', [
    swoaConstants,
    swoaTranslatable
  ])
  .component('swoaCardTechnicianResponsibilities', {
    template: htmlTemplate,
    bindings: {
      technicianResponsibilities: '<'
    },
    controller: CardTechnicianResponsibilities,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardTechnicianResponsibilities(
  $scope,
  Constants,
  swoaTranslatableFilter
) {
  const vm = this;

  // view data
  vm.availableResponsibilities =
    Constants.cards.category.TECHNICIAN.responsibilities;
  vm.groupedResponsibilities = {};

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch(
      'vm.technicianResponsibilities',
      updateGroupedResponsibilities
    );
  }

  function updateGroupedResponsibilities() {
    vm.groupedResponsibilities = {};

    if (!vm.technicianResponsibilities) {
      return;
    }

    vm.technicianResponsibilities.forEach(r => {
      if (angular.isUndefined(vm.groupedResponsibilities[r.responsibility])) {
        vm.groupedResponsibilities[r.responsibility] = [];
      }
      if (r.sport) {
        vm.groupedResponsibilities[r.responsibility].push(
          swoaTranslatableFilter(r.sport.translatedName)
        );
      }
    });
  }
}
