import angular from 'angular';
import swoaValidatorMessages from '../validator-messages/validator-messages.component';
import swoaEnsureExpression from '../ensure-expression/ensure-expression.directive';
import htmlTemplate from './input-textarea.html';

export default angular
  .module('swoa.input-textarea', [swoaValidatorMessages, swoaEnsureExpression])
  .component('swoaInputTextarea', {
    template: htmlTemplate,
    bindings: {
      containerClass: '@',
      isReadOnly: '=',
      labelKey: '@',
      labelSuffix: '@',
      name: '@',
      ngModel: '=',
      maxlength: '@',
      isRequired: '=',
      messages: '=',
      messagesIf: '=',
      customMessageKeys: '=',
      placeholder: '@',
      overrideAsterisk: '=',
      id: '@',
      style: '@'
    },
    transclude: true,
    controller: InputTextareaController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function InputTextareaController($scope) {
  const vm = this;

  vm.expressionWatcher = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////////////

  function activate() {
    // sometimes a change on the required attribute gets lost, no idea why... so we do this hack to capture changes to
    // the required attribute. and because it can be an expression, we need to watch the expression itself too.
    $scope.$watch('vm.isRequired', updateRequired);
    if (angular.isString(vm.isRequired) && vm.isRequired.indexOf('.') >= 0) {
      vm.expressionWatcher = $scope.$watch(vm.isRequired, updateRequired);
    }
  }

  function updateRequired(newValue) {
    vm.isRequiredExpr = newValue;

    if (
      !vm.expressionWatcher &&
      angular.isString(vm.isRequired) &&
      vm.isRequired.indexOf('.') >= 0
    ) {
      vm.expressionWatcher = $scope.$watch(vm.isRequired, updateRequired);
    } else if (vm.expressionWatcher && !angular.isString(vm.isRequired)) {
      // unregister watcher
      vm.expressionWatcher();
      vm.expressionWatcher = null;
    }
  }
}
