import angular from 'angular';
import swoaGetService from './get/get.service';
import swoaAhv from './ahv/ahv.module';
import swoaAsyncService from './async/async-helper.service';
import swoaBreadcrumb from './breadcrumb/breadcrumb.module';
import swoaComment from './comment/comment.module';
import swoaChangeLog from './change-log/change-log.module';
import swoaDataTable from './data-table/data-table.module';
import swoaDateFilter from './date/date.filter';
import swoaDateService from './date/date.service';
import swoaDeactivation from './deactivation/deactivation.module';
import swoaDialog from './dialog/dialog.module';
import swoaEscape from './escape/escape.filter';
import swoaFileService from './file/file.service';
import swoaFileUpload from './file-upload/file-upload.component';
import swoaFormHelper from './form-helper/form-helper.service';
import swoaIcon from './icon/icon.component';
import swoaImportResult from './import-result/import-result.component';
import swoaInputCityAutocomplete from './input-city-autocomplete/input-city-autocomplete.component';
import swoaInputCheckboxList from './input-checkbox-list/input-checkbox-list.component';
import swoaInputConditionalDate from './input-conditional-date/input-conditional-date.component';
import swoaInputConfigured from './input-configured/input-configured.component';
import swoaInputDate from './input-date/input-date.component';
import swoaInputItemSelector from './input-item-selector/input-item-selector.component';
import swoaInputSelect from './input-select/input-select.component';
import swoaInputSelectCountry from './input-select-country/input-select-country.component';
import swoaInputSocialMediaLink from './input-social-media-link/input-social-media-link.component';
import swoaInputText from './input-text/input-text.module';
import swoaInputTextarea from './input-textarea/input-textarea.component';
import swoaNotification from './notification/notification.module';
import swoaObjectProperty from './object-property/object-property.filter';
import swoaTimeline from './timeline/timeline.component';
import swoaPageToolbar from './page-toolbar/page-toolbar.component';
import swoaToolbar from './toolbar/toolbar.component';
import swoaTooltipIcon from './tooltip-icon/tooltip-icon.component';
import swoaTranslatable from './translatable/translatable.filter';
import swoaPositiveNegative from './positive-negative/positive-negative.filter';
import swoaSelectRevert from './select-revert/select-revert.component';
import swoaSidenav from './sidenav/sidenav.component';
import swoaSpinnerButton from './spinner-button/spinner-button.component';
import swoaPageSpinner from './spinner/page-spinner.component';
import swoaDisableAnimate from './disable-animate/disable-animate.directive';
import swoaStringUtil from './string-util/string-util.service';
import swoaTeaser from './teaser/teaser.component';
import swoaValidatorMessages from './validator-messages/validator-messages.component';
import swoaPasswordStrength from './password-strength/password-strength.module';
import swoaEnsureExpression from './ensure-expression/ensure-expression.directive';
import swoaTranslatableInputSelect from './translatable/translatable-input-select.component';
import swoaTranslatableInputText from './translatable/translatable-input-text.component';
import swoaAddress from './address/address.component';
import swoaStatus from './status/status.component';
import swoaWarningIcon from './warning-icon/warning-icon.component';
import swoaFormatNumber from './format-number/format-number.filter';
import swoaInputNumber from './input-number/input-number.component';
import swoaBannerComponent from './banner/banner.component';

export default angular.module('swoa.components', [
  swoaGetService,
  swoaAhv,
  swoaAsyncService,
  swoaBreadcrumb,
  swoaComment,
  swoaChangeLog,
  swoaDataTable,
  swoaDateFilter,
  swoaDateService,
  swoaDeactivation,
  swoaDialog,
  swoaEscape,
  swoaFileService,
  swoaFileUpload,
  swoaFormHelper,
  swoaIcon,
  swoaImportResult,
  swoaInputCityAutocomplete,
  swoaInputCheckboxList,
  swoaInputConditionalDate,
  swoaInputConfigured,
  swoaInputDate,
  swoaInputItemSelector,
  swoaInputSelect,
  swoaInputSelectCountry,
  swoaInputSocialMediaLink,
  swoaInputText,
  swoaInputTextarea,
  swoaNotification,
  swoaObjectProperty,
  swoaTimeline,
  swoaPageToolbar,
  swoaToolbar,
  swoaTooltipIcon,
  swoaTranslatable,
  swoaPositiveNegative,
  swoaSelectRevert,
  swoaSidenav,
  swoaSpinnerButton,
  swoaPageSpinner,
  swoaDisableAnimate,
  swoaStringUtil,
  swoaTeaser,
  swoaValidatorMessages,
  swoaPasswordStrength,
  swoaEnsureExpression,
  swoaTranslatableInputSelect,
  swoaTranslatableInputText,
  swoaAddress,
  swoaStatus,
  swoaWarningIcon,
  swoaFormatNumber,
  swoaInputNumber,
  swoaBannerComponent
]).name;
