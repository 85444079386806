import angular from 'angular';

export default angular
  .module('swoa.password-strength.filter', [])
  .filter('swoaPasswordStrength', swoaPasswordStrength)
  .name;

/** @ngInject */
function swoaPasswordStrength() {
  return (password) => {
    if (password === null || password === undefined || password.trim().length === 0) {
      return 0;
    }

    return strength(password.trim());
  };

  function strength(pw) {
    const lowerLetters = /[a-z]+/.test(pw);
    const upperLetters = /[A-Z]+/.test(pw);
    const numbers = /[0-9]+/.test(pw);
    const symbols = /[!"#$%&'()*+,-./:;?@\][\\^_`{|}~]/.test(pw);

    let points = 0;

    if (lowerLetters) {
      // any number of lower case characters give one point
      points += 1;
    }

    if (upperLetters) {
      // upper case letters give another point
      points += 1;
    }

    if (numbers) {
      // numbers give another point
      points += 1;
    }

    if (pw.length >= 8) {
      // 8 characters award another point
      points += 1;
    }

    // all of the above rules need to match for a password to be valid.
    // so the 5. point can only be awarded if these rules match and there are already 4 points available
    if (points === 4 && (symbols || pw.length >= 10)) {
      points = 5;
    }
    return points;
  }
}

