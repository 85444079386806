import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.user.service', ['restangular'])
  .factory('userService', userService).name;

/** @ngInject */
function userService($q, Restangular, ngUserAuthInfoService) {
  const USERS_ROUTE = 'users';

  const service = Restangular.all(USERS_ROUTE);

  service.allUsers = allUsers;
  service.changePassword = changePassword;
  service.resetPassword = resetPassword;
  service.persist = persist;
  service.getCurrentUser = getCurrentUser;
  service.saveUiConfig = saveUiConfig;
  service.informUser = informUser;
  service.hasLoggedUserSwissOlympicId = hasLoggedUserSwissOlympicId;
  service.getChangeLog = getChangeLog;
  service.getUiConfig = getUiConfig;

  return service;

  // //////////

  function allUsers() {
    return Restangular.one(USERS_ROUTE, 'list').getList();
  }

  function changePassword(newCredentials) {
    const user = ngUserAuthInfoService.getUser();

    if (!user) {
      return $q.reject('not authenticated');
    }

    return Restangular.one(USERS_ROUTE, getCurrentUser().id).customPUT(
      newCredentials,
      'password'
    );
  }

  function resetPassword(user, credentials) {
    return Restangular.one(USERS_ROUTE, user.id).customPUT(
      credentials,
      'reset-password'
    );
  }

  function persist(user, credentials) {
    if (user.id) {
      return user.save();
    }
    return service
      .post(user)
      .then((newuser) => resetPassword(newuser, credentials));
  }

  function informUser(user) {
    return Restangular.one(USERS_ROUTE, user.id).customPUT(
      '',
      'send-activation-email'
    );
  }

  function getCurrentUser() {
    const user = ngUserAuthInfoService.getUser();

    if (!user) {
      return null;
    }

    // three possibilities: ui config is there but not yet parsed, it's already parsed and ready or not available at all
    if (user.uiConfig && user.uiConfig.jsonString) {
      user.uiConfig = JSON.parse(user.uiConfig.jsonString);
    } else if (!user.uiConfig) {
      user.uiConfig = {};
    }

    return user;
  }

  function getChangeLog(userId) {
    return Restangular.one(USERS_ROUTE, userId).customGET('changelog');
  }

  function saveUiConfig(uiConfig) {
    const user = ngUserAuthInfoService.getUser();

    if (!user) {
      return $q.reject('not authenticated');
    }

    return Restangular.one(USERS_ROUTE, user.id)
      .customPUT(uiConfig, 'ui-config')
      .then(() => {
        user.uiConfig = uiConfig;
      });
  }

  function getUiConfig() {
    const user = ngUserAuthInfoService.getUser();

    if (!user) {
      return $q.reject('not authenticated');
    }

    return Restangular.one(USERS_ROUTE, user.id).customGET('ui-config');
  }

  function hasLoggedUserSwissOlympicId(soId) {
    return ngUserAuthInfoService.getUser().person.swissOlympicId === soId;
  }
}
