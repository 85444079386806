import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.sport.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('sportManager', {
    url: '/sports',
    template: '<swoa-sport-manager></swoa-sport-manager>',
    data: {
      breadcrumb: true,
      hasPermission: 'admin_sport_r',
    },
  });
}
