import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaGetService from '../components/get/get.service';
import swoaPersonService from './person.service';
import swoaPersonPersonMinimal from './person-minimal.component';
import htmlTemplate from './person-compact.html';

export default angular
  .module('swoa.person.person-compact', [
    uiRouter,
    swoaGetService,
    swoaPersonService,
    swoaPersonPersonMinimal
  ])
  .component('swoaPersonCompact', {
    template: htmlTemplate,
    bindings: {
      entry: '<',
      showEmail: '<',
      noLink: '<',
      hasAvatar: '<'
    },
    controller: PersonCompactController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonCompactController($scope, get, personService) {
  const vm = this;

  vm.defaultImageUrl = '/assets/images/user_avatar_default.svg';
  vm.avatar = get.withDefault(vm, 'entry.imageUrl', vm.defaultImageUrl);
  vm.avatarLoaded = false;

  vm.imageStyle = imageStyle;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.entry', loadAvatar);
  }

  function loadAvatar() {
    if (vm.entry && !vm.avatarLoaded) {
      if (
        !vm.entry.imageUrl &&
        vm.hasAvatar !== false &&
        vm.entry.id &&
        (vm.entry.hasAvatar === true || vm.entry.hasAvatar === undefined)
      ) {
        personService
          .loadAvatar(vm.entry.id)
          .then(image => {
            vm.avatar = URL.createObjectURL(image.data);
            vm.avatarCrop = image.metadata;
            vm.avatarLoaded = true;
          })
          .catch(angular.noop);
      }
    }
  }

  function imageStyle(imgUrl, cropObject) {
    const style = {
      'background-image': `url(${imgUrl})`
    };

    if (cropObject) {
      style[
        'background-position'
      ] = `${cropObject.positionX}% ${cropObject.positionY}%`;
      style['background-size'] = `${cropObject.sizeX}% ${cropObject.sizeY}%`;
    }

    return style;
  }
}
