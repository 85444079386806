import angular from 'angular';
import swoaConstants from '../app.constants';
import swoaTranslatableElementService from '../translation/translatable-element.service';
import swoaCardService from './card.service';
import swoaSecurityService from '../security/security.service';
import swoaCardSportYearService from './sport-year/sport-year.service';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './card-detail-functionary-form.html';

export default angular
  .module('swoa.card.card-detail-functionary', [
    swoaConstants,
    swoaTranslatableElementService,
    swoaCardService,
    swoaSecurityService,
    swoaCardSportYearService,
    swoaNotification
  ])
  .component('swoaCardDetailFunctionary', {
    template: htmlTemplate,
    bindings: {
      cardId: '=',
      cardYearId: '<'
    },
    controller: CardDetailFunctionaryController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardDetailFunctionaryController(
  $rootScope,
  $scope,
  translatableElementService,
  cardService,
  securityService,
  sportYearService,
  notificationService,
  lodash
) {
  const vm = this;

  // view data
  vm.organisations = [];
  vm.canEdit = false;
  vm.editMode = false;
  vm.selectedOrganisation = null;

  // error handling
  vm.notificationKey = null;

  // functions
  vm.disableEditMode = disableEditMode;
  vm.enableEditMode = enableEditMode;
  vm.save = save;

  // Only for testing!!
  vm._onHistoryEntryChange = onHistoryEntryChange;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    sportYearService.loadSportYearDetails(vm.cardYearId).then(cardYear => {
      vm.cardYear = cardYear;
      if (isFunctionaryYear()) {
        loadCard();
        loadOrganisations();
      }
    }, notificationService.errorHandler(vm));

    $scope.$on('swoa:historyEntryChanged', onHistoryEntryChange);
  }

  function loadCard() {
    cardService.loadCard(vm.cardId).then(card => {
      vm.card = card;
      initCard();
    });
  }

  function loadOrganisations() {
    translatableElementService
      .loadTranslatableElementsFromType('functionary_organisation')
      .then(translatableElements => {
        lodash.forEach(translatableElements, elem => {
          vm.organisations.push(elem);
        });
      });
  }

  function onHistoryEntryChange(event, historyEntry) {
    vm.cardYear = historyEntry.sportYear;
    vm.cardId = historyEntry.card.id;
    cardService.loadCard(historyEntry.card.id).then(card => {
      vm.card = card;
      initCard();
    }, notificationService.errorHandler(vm));
  }

  function isFunctionaryCard() {
    if (vm.card) {
      return vm.card.type === 'FUNCTIONARY' && vm.card.functionaryOrganisation;
    }
    return false;
  }

  function isFunctionaryYear() {
    if (vm.cardYear) {
      return vm.cardYear.type === 'FUNCTIONARY';
    }
    return false;
  }

  function save() {
    if (vm.canEdit && vm.editMode) {
      const organisation = lodash.filter(vm.organisations, {
        id: vm.selectedOrganisation
      })[0];
      cardService.updateOrganisation(vm.card.id, organisation).then(result => {
        vm.card = result;
        disableEditMode();
        $rootScope.$broadcast('swoa:cardUpdated', vm.card);
      }, notificationService.errorHandler(vm));
    }
  }

  function enableEditMode() {
    if (vm.canEdit) {
      vm.editMode = true;
    }
  }

  function disableEditMode() {
    vm.editMode = false;
    initCardOrganisation();
  }

  function initCard() {
    securityService.extendCardModelWithEditAllowed(vm.cardYear, vm.card);
    initCardOrganisation();
    vm.canEdit = vm.card.canChangeType;
  }

  function initCardOrganisation() {
    if (isFunctionaryCard(vm.card)) {
      vm.selectedOrganisation = vm.card.functionaryOrganisation.id;
    }
  }
}
