import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.nwf-contribution.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('nwfContribution', {
      url: '/nwf-contribution',
      template: '<swoa-nwf-contribution></swoa-nwf-contribution>',
      data: {
        breadcrumb: true,
        hasPermission: 'nwf_contribution_r'
      }
    })
    .state('nwfPeriods', {
      url: '/nwf-periods/:organisationId',
      template: '<swoa-nwf-periods></swoa-nwf-periods>',
      data: {
        breadcrumb: {
          parent: 'nwfContribution',
          title: {
            stateParam: 'organisationId',
            service: 'organisationService'
          }
        },
        hasPermission: 'nwf_contribution_r'
      }
    });
}
