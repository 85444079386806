import angular from 'angular';
import swoaTranslatableElementService from '../../../translation/translatable-element.service';
import swoaNotification from '../../../components/notification/notification.module';
import swoaDialog from '../../../components/dialog/dialog.module';
import htmlTemplate from './translations-detail.html';

export default angular
  .module('swoa.admin.settings-detail', [
    swoaTranslatableElementService,
    swoaNotification,
    swoaDialog
  ])
  .component('swoaTranslationDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: SettingsDetailController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function SettingsDetailController(
  $rootScope,
  $state,
  $stateParams,
  lodash,
  translatableElementService,
  notificationService,
  dialogService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.settingId = $stateParams.settingId;
  vm.isNew = checkNew($stateParams.settingId);
  vm.translatableElement = {};
  vm.translatableElementsTypes = [];
  vm.sortList = [];
  vm.originalTranslatableElement = null;
  vm.editing = false;
  vm.submitted = false;
  vm.canEditSetting = false;
  vm.isInfo = false;

  // functions
  vm.languageOrder = languageOrder;
  vm.startEdit = startEdit;
  vm.cancelEdit = cancelEdit;
  vm.closeDetail = closeDetail;
  vm.save = save;
  vm.deactivate = deactivate;

  vm.$onInit = () => {
    activate();
  };

  // ///////////

  function activate() {
    if (vm.settingId === 'new') {
      vm.editing = true;
      vm.translatableElementsTypes = getTranslatableTypes();
      initNewTranslatableElement();
    } else {
      initTranslatableElement();
    }
  }

  function initSortList(type) {
    vm.sortList = [];
    translatableElementService.loadMaxSortByType(type).then(maxSort => {
      let index = 1;
      lodash.times(maxSort, () => {
        vm.sortList.push({ id: index });
        index += 1;
      });
    });
  }

  function languageOrder(elementTranslation) {
    switch (elementTranslation.language) {
      case 'GERMAN':
        return 0;
      case 'FRENCH':
        return 1;
      case 'ITALIAN':
        return 2;
      default:
        return null;
    }
  }

  function initNewTranslatableElement() {
    vm.translatableElement = {
      sort: null,
      active: true,
      elementTranslations: [
        {
          text: null,
          language: 'GERMAN'
        },
        {
          text: null,
          language: 'FRENCH'
        },
        {
          text: null,
          language: 'ITALIAN'
        }
      ]
    };
  }

  function checkNew(id) {
    return id === 'new';
  }

  function save(valid) {
    vm.submitted = true;
    if (!valid) {
      return null;
    }
    if (vm.translatableElement.id) {
      return translatableElementService
        .updateTranslatableElement(vm.translatableElement)
        .then(onSaveSuccess, onError);
    }
    return translatableElementService
      .addTranslatableElement(vm.translatableElement)
      .then(onSaveSuccess, onError);
  }

  function onDeactivateSuccess(result) {
    triggerTranslatableElementUpdate([result]);
    closeDetail();
  }

  function onSaveSuccess(result) {
    vm.editing = false;
    vm.canEditSetting = canEditSetting();
    if (vm.isNew) {
      triggerTranslatableElementAdd(result);
      initSortList(vm.translatableElement.type);
    } else {
      triggerTranslatableElementUpdate(result);
    }
  }

  function triggerTranslatableElementUpdate(translatableElements) {
    $rootScope.$broadcast(
      'swoa:settingsTranslatableUpdate',
      translatableElements
    );
    return translatableElements;
  }

  function triggerTranslatableElementAdd(translatableElement) {
    $rootScope.$broadcast('swoa:settingsTranslatableAdd', translatableElement);
    return translatableElement;
  }

  function cancelEdit() {
    vm.editing = false;
    vm.translatableElement = vm.originalTranslatableElement;
    vm.canEditSetting = canEditSetting();
  }

  function startEdit() {
    vm.editing = true;
    vm.canEditSetting = canEditSetting();
  }

  function initTranslatableElement() {
    return translatableElementService
      .loadById($stateParams.settingId)
      .then(translatable => {
        vm.originalTranslatableElement = translatable;
        vm.translatableElement = translatable;
        vm.canEditSetting = canEditSetting();

        if (vm.translatableElement.active === false) {
          initInactiveInfo();
        }
        initSortList(translatable.type);
      }, onError);
  }

  function initInactiveInfo() {
    vm.isInfo = true;
    vm.notificationKey = 'applicationParameter.inactive.warning';
  }

  function initTranslatableTypesIdList(types) {
    vm.translatableElementsTypes = types;
  }

  function getTranslatableTypes() {
    return translatableElementService.loadTranslatableTypes().then(types => {
      initTranslatableTypesIdList(types);
    }, onError);
  }

  function deactivate() {
    dialogService
      .showConfirmationDialog('applicationParameter.deactivate.confirmation')
      .then(() => {
        translatableElementService
          .deactivate(vm.translatableElement)
          .then(onDeactivateSuccess, onError);
      });
  }

  function closeDetail() {
    vm.editing = false;
    $state.go('^'); // go to parent state
  }

  function canEditSetting() {
    return !vm.editing && vm.translatableElement.active !== false;
  }
}
