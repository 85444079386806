import angular from 'angular';
import 'restangular';
import swoaFileService from '../components/file/file.service';
import swoaConstants from '../app.constants';
import swoaStringUtil from '../components/string-util/string-util.service';

export default angular
  .module('swoa.person.service', [
    'restangular',
    swoaFileService,
    swoaConstants,
    swoaStringUtil,
  ])
  .factory('personService', personService)
  .name;

/** @ngInject */
function personService($q, Restangular, lodash, fileService, ConfigConstants, stringUtilService) {
  const basePersons = Restangular.all('persons');

  let abortPromise = null;

  return {
    confirm,
    count,
    findBySwissOlympicId,
    getChangeLog,
    hashifyForeignIds,
    loadNewPersons,
    loadNewPerson,
    loadPerson,
    loadPersons,
    loadSimilarPersons,
    loadCountries,
    updateAdditionalData,
    removeAdditionalData,
    removeAdditionalSportData,
    persist,
    mergeOrCreatePersonMutation,
    queryPersons,
    extendedSearch,
    saveSync,
    typeaheadMatch,
    unhashifyForeignIds,
    loadAvatar,
    createPerson,
    removeAvatar,
    saveAvatar,
    loadHeaderImage,
    countProfileFiles,
    getProfileFiles,
    saveHeaderImage,
    removeHeaderImage,
    loadTimeline,
    extendPersonModel,
    addComment,
    deleteComment,
    loadReleasedCards,
    loadCards,
    isPersonInCardYearWithStatus,
    sortedAdditionalInfoSportList,
    deactivate,
    activate,

    // for tests only!
    _getImageUrl: getImageUrl,
  };

  // //////////

  /** Load all persons and return as a list */
  function loadPersons() {
    return basePersons.getList();
  }

  function loadPerson(personId) {
    return basePersons.get(personId);
  }

  function loadTimeline(personId) {
    return Restangular.one('persons', personId).customGET('timeline');
  }

  function loadReleasedCards(personId) {
    return Restangular.one('persons', personId).customGET('released-cards');
  }

  function isPersonInCardYearWithStatus(personId, statuses) {
    return Restangular.one('persons', personId).customGET('check-card-year-status', { status: statuses });
  }

  function loadCards(personId) {
    return Restangular.one('persons', personId).customGET('cards');
  }

  function getChangeLog(personId) {
    return Restangular.one('persons', personId).customGET('changelog');
  }

  function count(state) {
    return basePersons.customGET('count', { state });
  }

  function confirm(personId) {
    return Restangular.one('persons', personId).customPOST({}, 'confirm');
  }

  function findBySwissOlympicId(soId) {
    return basePersons.customGET('swiss-olympic-id', { soId });
  }

  /** Full-text person search. */
  function queryPersons(query, offset, limit, enrichResults) {
    const options = {
      query,
      offset,
      limit,
      enrichResults,
    };
    if (offset === undefined) {
      options.offset = 0;
    }
    if (limit === undefined) {
      options.limit = 10;
    }

    if (abortPromise !== null) {
      abortPromise.resolve();
    }
    abortPromise = $q.defer();

    return Restangular.all('persons').withHttpConfig({ timeout: abortPromise.promise }).customGET('search', options);
  }

  function extendedSearch(queryObject, offset, limit, enrichResults) {
    const options = {
      offset,
      limit,
      enrichResults,
    };
    if (offset === undefined) {
      options.offset = 0;
    }
    if (limit === undefined) {
      options.limit = 10;
    }

    if (abortPromise !== null) {
      abortPromise.resolve();
    }
    abortPromise = $q.defer();

    return Restangular.all('persons').withHttpConfig({ timeout: abortPromise.promise }).customPOST(queryObject, 'extended-search', options);
  }

  /** Find similar persons */
  function loadSimilarPersons(personId, offset, limit) {
    const options = {
      offset,
      limit,
    };
    if (offset === undefined) {
      options.offset = 0;
    }
    if (limit === undefined) {
      options.limit = 10;
    }
    return Restangular.one('persons', personId).customGET('similar-persons', options);
  }

  function loadCountries() {
    return Restangular.all('countries').getList();
  }

  /** Test if an input string of a typeahead field matches a person on any field */
  function typeaheadMatch(person, inputString) {
    return stringUtilService.typeaheadMatch([person.forename, person.surname, person.email], inputString);
  }

  function unhashifyForeignIds(foreignIds) {
    // remove unset foreignId's
    lodash.forEach(foreignIds, (value, key) => {
      if (!value || !value.idValue) {
        delete foreignIds[key];
      }
    });

    // bring in right format -> array
    return lodash.map(foreignIds, (value, key) => ({
      idType: key,
      idValue: value.idValue,
    }));
  }

  function hashifyForeignIds(foreignIds) {
    lodash.forEach(foreignIds, (elem) => {
      delete elem.id;
      delete elem.version;
    });
    return lodash.keyBy(foreignIds, 'idType');
  }

  function loadNewPersons(offset, max) {
    return basePersons.customGET('new', { offset, max });
  }

  function loadNewPerson(personId) {
    return basePersons.all('new').customGET(personId);
  }

  function saveSync(replaceCreationId, syncResult) {
    syncResult.foreignIds = unhashifyForeignIds(syncResult.foreignIds);

    delete syncResult.configKey;
    return Restangular.one('persons', replaceCreationId).customPOST(syncResult, 'replace');
  }

  function persist(person) {
    if (person.id) {
      return Restangular.one('persons', person.id).customPUT(person, null);
    }
    return basePersons.post(person);
  }

  function deactivate(person) {
    const container = getDeactivateContainer(person);
    return Restangular.one('persons', person.id).customPUT(container, 'deactivate');
  }

  function getDeactivateContainer(person) {
    const container = {};
    container.deactivationType = person.deactivationType;
    if (person.deactivationType === 'DECEASED') {
      container.deactivationDate = person.deactivationDate;
    } else {
      container.deactivationReason = person.deactivationReason;
    }
    return container;
  }

  function activate(person) {
    return Restangular.one('persons', person.id).customPUT('', 'activate');
  }

  function mergeOrCreatePersonMutation(person) {
    if (person.id) {
      return Restangular.one('persons', person.id).customPUT(person, 'merge-person-mutation');
    }
    return null;
  }

  function updateAdditionalData(personId, additionalDataContainer) {
    if (personId) {
      return Restangular.one('persons', personId).customPOST({
        personData: additionalDataContainer.personData,
        eliteData: additionalDataContainer.eliteData,
        recognitionData: additionalDataContainer.recognitionData,
        eliteSportData: additionalDataContainer.eliteSportData,
        trainerSportData: additionalDataContainer.trainerSportData,
      }, 'additional-data');
    }

    return $q.reject(new Error('Invalid personId'));
  }

  function removeAdditionalData(personId, fields) {
    return Restangular.one('persons', personId).customDELETE('additional-data', { fields });
  }

  function removeAdditionalSportData(personId, sportId, fields) {
    return Restangular.one('persons', personId).one('additional-sport-data', sportId).customDELETE(null, { fields });
  }

  function createPerson(person) {
    person.foreignIds = unhashifyForeignIds(person.foreignIds);
    return persist(person);
  }

  function loadAvatar(personId) {
    return fileService.load(getImageUrl(personId, 'person_profile_avatar'));
  }

  function saveAvatar(personId, file, metadata) {
    return fileService.upload(getImageUrl(personId, 'person_profile_avatar', file.name), file, { metadata });
  }

  function removeAvatar(personId) {
    return fileService.deleteFile(getImageUrl(personId, 'person_profile_avatar'));
  }

  function loadHeaderImage(personId) {
    return fileService.load(getImageUrl(personId, 'person_profile_header'));
  }

  function countProfileFiles(personId) {
    return Restangular.one('persons', personId).one('profile-files-count').get();
  }

  function getProfileFiles(personId) {
    return Restangular.one('persons', personId).getList('profile-files');
  }

  function saveHeaderImage(personId, file, metadata) {
    return fileService.upload(getImageUrl(personId, 'person_profile_header', file.name), file, { metadata });
  }

  function removeHeaderImage(personId) {
    return fileService.deleteFile(getImageUrl(personId, 'person_profile_header'));
  }

  function getImageUrl(personId, kind, filename) {
    let url = `${ConfigConstants.api.url}/persons/${personId}/profile-images?kind=${kind}`;
    if (filename) {
      url += `&fileName=${filename}`;
    }
    return url;
  }

  function addComment(personId, comment) {
    return Restangular.one('persons', personId).customPOST(comment, 'comments');
  }

  function deleteComment(personId, commentId) {
    return Restangular.one('persons', personId).one('comments', commentId).remove();
  }

  function sortedAdditionalInfoSportList(person) {
    const sports = lodash.map(person.eliteSportData, eliteSportData => eliteSportData.sport);
    return lodash.sortBy(sports, 'name');
  }

  function extendPersonModel(person, cards) {
    const releasedCards = getReleasedOrClosedCards(cards);
    if (releasedCards && releasedCards.length > 0) {
      person.isTalentAthlete = hasCard(releasedCards, ['NATIONAL', 'REGIONAL', 'LOCAL']);
      person.isEliteAthlete = hasCard(releasedCards, ['GOLD', 'SILVER', 'BRONZE', 'ELITE']);
      person.isTrainer = hasCard(releasedCards, ['TRAINER']);
      person.isTechnician = hasCard(releasedCards, ['TECHNICIAN']);
    } else {
      person.isTalentAthlete = false;
      person.isEliteAthlete = false;
      person.isTrainer = false;
      person.isTechnician = false;
    }
    person.hasOnlyTalentCards = cards && cards.length > 0 && hasOnlyTalentCards(cards);
  }

  function hasOnlyTalentCards(cards) {
    return lodash.every(cards, card => lodash.includes(['NATIONAL', 'REGIONAL', 'LOCAL'], card.type));
  }

  function getReleasedOrClosedCards(cards) {
    return lodash.filter(cards, card => card.status === 'RELEASED' || card.status === 'CLOSED');
  }

  function hasCard(releasedCards, cardTypeList) {
    const cardWithType = lodash.find(releasedCards, elem => cardTypeList.indexOf(elem.type) !== -1);
    return Boolean(cardWithType);
  }
}
