import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.person.profile.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('personProfile', {
    url: '/profile/:soId?tabId',
    template: '<swoa-person-profile></swoa-person-profile>',
    reloadOnSearch: false,
    data: {
      breadcrumb: true,
      minimalToolbar: true,
      hasPermission: 'person_r'
    },
    params: {
      tabId: {
        value: null,
        squash: true
      }
    }
  });
}
