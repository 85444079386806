import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.card-parameter.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('cardParameter', {
      url: '/admin/card-parameter',
      template: '<swoa-card-parameter></swoa-card-parameter>',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_cardparameter_r'
      }
    })
    .state('cardParameter.cardParameterDetail', {
      url: '/:sportId',
      views: {
        detail: {
          template: '<swoa-card-parameter-detail></swoa-card-parameter-detail>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'cardParameter',
          ignore: true
        },
        hasPermission: 'admin_cardparameter_r'
      }
    });
}
