import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('mySports', {
      url: '/admin/sports',

      template: '<swoa-my-sports></swoa-my-sports>',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_mysports_r',
      },
    })
    .state('personImport', {
      url: '/admin/person-import',
      template: '<swoa-person-import></swoa-person-import>',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_ssam_import_r',
      },
    })
    .state('debug', {
      url: '/admin/debug',
      template: '<swoa-debug></swoa-debug>',
      data: {
        breadcrumb: true,
        hasPermission: 'admin_ssam_import_r',
      },
    });
}
