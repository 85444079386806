import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './person-search.html';

export default angular
  .module('swoa.person.person-search', [
    swoaConstants,
    swoaNotification,
  ])
  .component('swoaPersonSearch', {
    template: htmlTemplate,
    bindings: {
      selectButtonTextKey: '@',
      selectAction: '&',
      cancelAction: '&',
      withoutSelection: '<',
    },
    controller: PersonSearchController,
    controllerAs: 'vm',
  })
  .name;

export const MAX_RESULTS_ENRICHED = 100;
export const MAX_RESULTS_NORMAL = 99999;

/** @ngInject */
function PersonSearchController($location, $stateParams, personService, notificationService) {
  const vm = this;

  // loaded data
  vm.foundPersons = null;
  vm.totalPersonsCount = null;
  vm.hintKey = 'personSearch.enterQuery';
  vm.maxResults = MAX_RESULTS_ENRICHED;

  // selections
  vm.selectedPerson = null;
  vm.searchText = $stateParams.query;
  vm.enrichResults = false;

  // error handling
  vm.notificationKey = null;

  // functions
  vm.search = search;
  vm.keyPress = keyPress;

  search();

  // //////////

  function search() {
    if (vm.searchText && vm.searchText.length > 2) {
      $location.search('query', vm.searchText);

      vm.hintKey = 'personSearch.searching';
      vm.foundPersons = null;

      vm.maxResults = vm.enrichResults ? MAX_RESULTS_ENRICHED : MAX_RESULTS_NORMAL;
      personService.queryPersons(vm.searchText, 0, vm.maxResults, vm.enrichResults).then((personResult) => {
        vm.foundPersons = personResult.result;
        vm.totalPersonsCount = personResult.totalCount;
      }, notificationService.errorHandler(vm)).finally(() => {
        if ((vm.totalPersonsCount > MAX_RESULTS_ENRICHED && vm.enrichResults) ||
          vm.totalPersonsCount > MAX_RESULTS_NORMAL) {
          vm.hintKey = 'personSearch.tooManyResults';
        } else {
          vm.hintKey = 'personSearch.found';
        }
      });
    } else {
      vm.hintKey = 'personSearch.enterQuery';
      vm.foundPersons = null;
      vm.totalPersonsCount = null;
    }
  }

  function keyPress(event) {
    if (event.keyCode === 13 /* ENTER */) {
      // circumvent the debounce when pressing enter
      if (event.target && event.target.value) {
        vm.searchText = event.target.value;
      }
      // search again on enter
      search();
    } else if (event.charCode > 0 || event.keyCode === 8 /* BACKSPACE */ || event.keyCode === 27 /* ESC */) {
      // only clear when typing or deleting characters or pressing esc
      vm.hintKey = 'personSearch.searching';
      vm.foundPersons = null;
      vm.totalPersonsCount = null;
    }
  }
}
