import angular from 'angular';
import swoaTranslationsPageRoute from './translations-page.routes';
import swoaLabelsModule from './labels/labels.module';
import swoaTranslationsModule from './translations/translations.module';
import swoaAdminTemplate from './templates/templates.module';
import swoaAdminTranslationsService from './translations.service';
import swoaTranslationsPage from './translations-page.component';

export default angular
  .module('swoa.admin.translations.page', [
    swoaTranslationsPageRoute,
    swoaTranslationsPage,
    swoaLabelsModule,
    swoaTranslationsModule,
    swoaAdminTranslationsService,
    swoaAdminTemplate,
  ])
  .name;

