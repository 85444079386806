import angular from 'angular';
import swoaNotification from '../../components/notification/notification.module';
import swoaPersonSyncService from '../../person/sync/person-sync.service';
import swoaNwfVariableAmountPeriodService from './nwf-variable-amount-period.service';
import htmlTemplate from './nwf-variable-amount-period-create-person.html';

export default angular
  .module(
    'swoa.nwf-contribution.variable-amount.nwf-variable-amount-period-create-person',
    [
      swoaNwfVariableAmountPeriodService,
      swoaNotification,
      swoaPersonSyncService
    ]
  )
  .component('swoaNwfVariableAmountPeriodCreatePerson', {
    template: htmlTemplate,
    bindings: {},
    controller: NwfVariableAmountCreatePersonController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfVariableAmountCreatePersonController(
  $state,
  $stateParams,
  notificationService,
  personSyncService,
  nwfVariableAmountPeriodService
) {
  const vm = this;

  vm.params = $stateParams;
  vm.requiredProperties = personSyncService.ADDITIONAL_TRAINER_REQUIRED;

  vm.createPerson = createPerson;
  vm.cancelAction = cancelAction;

  // //////////

  function createPerson(person) {
    nwfVariableAmountPeriodService
      .addPerson($stateParams.periodId, person)
      .then(trainer => {
        const params = $stateParams;
        params.trainerId = trainer.id;
        params.state = 'new';
        $state.go('nwfVariableAmountPeriod.foundation.detail', params);
      }, notificationService.errorHandler(vm));
  }

  function cancelAction() {
    $state.go('nwfVariableAmountPeriod', $stateParams);
  }
}
