import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaEscape from '../escape/escape.filter';
import swoaTranslatableDropdownValue from '../translatable/translatable-dropdown-value.filter';
import htmlTemplate from './input-select.html';

export default angular
  .module('swoa.input-select', [
    swoaConstants,
    swoaEscape,
    swoaTranslatableDropdownValue
  ])
  .component('swoaInputSelect', {
    template: htmlTemplate,
    bindings: {
      containerClass: '@',
      isReadOnly: '<',
      labelKey: '@',
      isRequired: '<',
      messages: '<',
      messagesIf: '<',
      name: '@',
      ngModel: '=',
      enumList: '<',
      placeholder: '@',
      enumTranslatableType: '@',
      enumTranslatePrefix: '@',
      enumTranslateSuffix: '@',
      idValueList: '<',
      valueProperty: '@',
      optionAsValue: '<',
      ngChange: '&',
      multiple: '<',
      allowEmpty: '<',
      emptyLabel: '@',
      allowExplicitNull: '<',
      explicitNullLabel: '@'
    },
    controller: InputSelectController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function InputSelectController($scope, lodash) {
  const vm = this;

  vm.selectAll = selectAll;
  vm.allSelected = allSelected;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.ngModel', removeUndefined, true);
  }

  function removeUndefined() {
    if (vm.multiple) {
      vm.ngModel = lodash.flatten(
        lodash.filter(vm.ngModel, elem => !lodash.isNil(elem))
      );
    }
  }

  function selectAll() {
    if (vm.multiple) {
      if (vm.enumList) {
        toggleAll(vm.enumList);
      } else if (vm.idValueList) {
        toggleAll(vm.idValueList, vm.optionAsValue ? null : 'id');
      }
    }
  }

  function toggleAll(arr, prop) {
    if (vm.ngModel.length === arr.length + (vm.allowExplicitNull ? 1 : 0)) {
      vm.ngModel = [];
    } else {
      arr.forEach(elem => vm.ngModel.push(prop ? elem[prop] : elem));
      if (vm.allowExplicitNull) {
        vm.ngModel.push('__EXPLICIT_NULL_VALUE__');
      }
    }
  }

  function allSelected() {
    let compareLength = vm.allowExplicitNull ? 1 : 0;
    if (vm.enumList) {
      compareLength += vm.enumList.length;
    } else if (vm.idValueList) {
      compareLength += vm.idValueList.length;
    }
    return vm.ngModel && vm.ngModel.length === compareLength;
  }
}
