import angular from 'angular';
import htmlTemplate from './person-minimal.html';

export default angular
  .module('swoa.person.person-minimal', [])
  .component('swoaPersonMinimal', {
    template: htmlTemplate,
    bindings: {
      entry: '<',
      withSwissOlympicId: '<',
    },
    controllerAs: 'vm',
  })
  .name;
