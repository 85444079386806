import { FTEMTYPE } from '../../app.constants';

/**
 * Get the ftem phase of the given card - if the temporary type is set, the temporary type is returned
 * Otherwise the ftem type of the card is returned. Defaults to FTEMTYPE.NOT_AVAILABLE
 * @param {*} card
 * @returns boolean
 */
export function getFtemType({ temporaryPhase, phase }) {
  const ftemType = temporaryPhase ?? phase;
  return ftemType ?? FTEMTYPE.NOT_AVAILABLE;
}

export function getCardType({ temporaryType, type }) {
  return temporaryType ?? type;
}
