import angular from 'angular';
import swoaCockpitRoutes from './cockpit.routes';
import swoaCockpitCockpitDashboard from './cockpit-dashboard.component';
import swoaCockpitCockpitConfigService from './cockpit-config.service';
import swoaCockpitCockpitPersonMutationList from './cockpit-person-mutation-list.component';
import swoaCockpitCockpitNewPersonList from './cockpit-new-person-list.component';
import swoaCockpitCockpitSportSummary from './cockpit-sport-summary.component';
import swoaCockpitCockpitSportSummaryTable from './cockpit-sport-summary-table.component';
import swoaCockpitCockpitNewsList from './cockpit-news-list.component';

export default angular
  .module('swoa.cockpit', [
    swoaCockpitRoutes,
    swoaCockpitCockpitDashboard,
    swoaCockpitCockpitConfigService,
    swoaCockpitCockpitPersonMutationList,
    swoaCockpitCockpitNewPersonList,
    swoaCockpitCockpitSportSummary,
    swoaCockpitCockpitSportSummaryTable,
    swoaCockpitCockpitNewsList,
  ])
  .name;
