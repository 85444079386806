import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import ngMaterial from 'angular-material';
import swoaConstants from '../../app.constants';
import htmlTemplate from './page-toolbar.html';

export default angular
  .module('swoa.page-toolbar', [
    uiRouter,
    angularTranslate,
    ngMaterial,
    ngMaterial,
    swoaConstants
  ])
  .component('swoaPageToolbar', {
    template: htmlTemplate,
    transclude: true,
    bindings: {},
    controller: PageToolbarController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PageToolbarController(
  $rootScope,
  $scope,
  $state,
  $transitions,
  $mdSidenav,
  $translate,
  ConfigConstants
) {
  const vm = this;

  vm.availableLanguages = ConfigConstants.translation.availableLanguages;
  vm.currentLanguage = $translate.use();

  vm.openLeftNav = openLeftNav;
  vm.changeLanguage = changeLanguage;

  // for tests only!
  vm._onStateChangeSuccess = onStateChangeSuccess;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    onStateChangeSuccess();
    let unregisterFn = $transitions.onSuccess({}, onStateChangeSuccess);
    $scope.$on('$destroy', unregisterFn);
    unregisterFn = $rootScope.$on('$translateChangeSuccess', () => {
      vm.currentLanguage = $translate.use();
    });
    $scope.$on('$destroy', unregisterFn);
  }

  function onStateChangeSuccess() {
    vm.minimal = $state.current.data && $state.current.data.minimalToolbar;
  }

  function openLeftNav() {
    $mdSidenav('left').open();
  }

  function changeLanguage(lang) {
    vm.currentLanguage = lang;
    $translate.use(lang);
  }
}
