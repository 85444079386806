import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import 'restangular';
import swoaConstants from '../app.constants';
import htmlTemplate from './organisation-create.html';
import swoaOrganisationService from './organisation.service';
import swoaNotification from '../components/notification/notification.module';

export default angular
  .module('swoa.organisation.create', [
    swoaConstants,
    uiRouter,
    angularTranslate,
    'restangular',
    swoaOrganisationService,
    swoaNotification
  ])
  .component('swoaOrganisationCreate', {
    template: htmlTemplate,
    bindings: {},
    controller: OrganisationCreateController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function OrganisationCreateController(
  $window,
  $scope,
  $state,
  lodash,
  Constants,
  organisationService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.submitted = false;
  vm.availableOrganisationCategories = [];

  vm.config = [
    {
      organisationKey: 'name',
      key: 'name',
      columnName: 'organisation.name',
      dataType: 'translatable-name',
      requiredLanguages: ['GERMAN', 'FRENCH', 'ITALIAN']
    },
    {
      organisationKey: 'translatedAcronym',
      key: 'translatedAcronym',
      columnName: 'organisation.translatedAcronym',
      dataType: 'translatable-name',
      requiredLanguages: []
    },
    {
      organisationKey: 'email',
      key: 'email',
      dataType: 'email',
      pattern: Constants.validationExpressions.email,
      required: true
    },
    {
      organisationKey: 'address.streetAndNumber',
      key: 'streetAndNumber',
      required: true
    },
    {
      organisationKey: 'address.addressSuffix',
      key: 'addressSuffix'
    },
    {
      organisationKey: 'address.zip',
      dataType: 'city-autocomplete',
      key: 'zip',
      itemText: 'item.zip',
      updatableElement: 'city',
      required: true
    },
    {
      organisationKey: 'address.city',
      dataType: 'city-autocomplete',
      key: 'city',
      itemText: 'item.name',
      updatableElement: 'zip',
      required: true
    },
    {
      organisationKey: 'address.country',
      key: 'country',
      dataType: 'country',
      required: true
    }
  ];

  vm.popup = undefined;

  vm.create = create;
  vm.cancelAction = cancelAction;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.organisation.type', updateOrganisationCategory);
    $scope.$watch('vm.organisation.country', resetZipCity);

    vm.organisation = {
      name: { elementTranslations: [], type: 'ORGANISATION_NAME' },
      translatedAcronym: {
        elementTranslations: [],
        type: 'ORGANISATION_ACRONYM'
      }
    };
    vm.availableOrganisationTypes = Constants.organisationTypes.map(
      elem => elem.type
    );
  }

  function updateOrganisationCategory() {
    vm.availableOrganisationCategories = [];
    Constants.organisationTypes
      .filter(elem => elem.type === vm.organisation.type)
      .forEach(orgType =>
        vm.availableOrganisationCategories.push(...orgType.categories)
      );
    organisationService.updateIsEmailRequiredForOrganisation(
      vm.organisation,
      vm.config
    );
  }

  function create(formValid) {
    vm.submitted = true;
    if (formValid) {
      vm.notificationKey = undefined;
      vm.popup = $window.open('', '_blank');
      const newOrganisation = {
        sports: [],
        categories: [vm.organisation.category],
        active: true
      };
      copyAllValues(vm.organisation, newOrganisation, 'key', 'organisationKey');
      return organisationService
        .persist(newOrganisation)
        .then(onSaveSuccess, onError);
    }
    vm.notificationKey = 'form.invalid';
    return null;
  }

  function onSaveSuccess(organisation) {
    vm.popup.location.href = $state.href('organisationProfile', {
      id: organisation.id
    });
    $state.go('organisationSearch.query', {
      notificationKey: 'organisationSearch.createOrganisation.successMessage',
      isSuccess: true
    });
  }

  function cancelAction() {
    $state.go('organisationSearch');
  }

  function copyAllValues(
    sourceOrganisation,
    targetOrganisation,
    sourceKey,
    targetKey
  ) {
    lodash.forEach(vm.config, config => {
      lodash.set(
        targetOrganisation,
        config[targetKey],
        lodash.get(sourceOrganisation, config[sourceKey])
      );
    });
  }

  function resetZipCity() {
    delete vm.organisation.zip;
    delete vm.organisation.city;
    vm.searchText = undefined;
  }
}
