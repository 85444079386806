import angular from 'angular';
import swoaLabelRoutes from './labels.routes';
import swoaTranslationLabels from './labels.component';
import swoaTranslationLabelsDetail from './labels-detail.component';


export default angular
  .module('swoa.admin.translations.label', [
    swoaLabelRoutes,
    swoaTranslationLabels,
    swoaTranslationLabelsDetail,
  ])
  .name;

