import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.report.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('report', {
      url: '/report',
      template:
        '<swoa-report-page current-tab="selectedTab"></swoa-report-page>',
      controller($scope, $state, $transitions) {
        if ($state.current.name === 'report') {
          $state.go('report.exports');
          $scope.selectedTab = 'exports';
        } else {
          $scope.selectedTab = $state.current.data.selectedTab;
        }

        $transitions.onSuccess({ to: 'report.**' }, transition => {
          if (transition.to().name === 'report') {
            if (
              $scope.$root &&
              $scope.$root.uaAnyPermission([
                'admin_exports_cards_r',
                'admin_exports_sports_r',
                'admin_exports_users_r',
                'admin_exports_nwf_base_amount_r',
                'admin_exports_nwf_variable_amount_r',
                'admin_exports_nwf_trainer_r',
                'admin_exports_nwf_trainer_current_r'
              ])
            ) {
              $state.go('report.exports');
              $scope.selectedTab = 'exports';
            } else {
              $state.go('report.reports');
              $scope.selectedTab = 'reports';
            }
          } else {
            $scope.selectedTab = $state.current.data.selectedTab;
          }
        });
      },
      controllerAs: 'vm',
      data: {
        breadcrumb: true,
        hasAnyPermission: [
          'admin_reports_r',
          'admin_exports_cards_r',
          'admin_exports_sports_r',
          'admin_exports_users_r',
          'admin_exports_nwf_base_amount_r',
          'admin_exports_nwf_variable_amount_r',
          'admin_exports_nwf_trainer_r',
          'admin_exports_nwf_trainer_current_r'
        ]
      }
    })
    .state('report.exports', {
      url: '/exports',
      data: {
        selectedTab: 0,
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasAnyPermission: [
          'admin_exports_cards_r',
          'admin_exports_sports_r',
          'admin_exports_users_r',
          'admin_exports_nwf_base_amount_r',
          'admin_exports_nwf_variable_amount_r',
          'admin_exports_nwf_trainer_r',
          'admin_exports_nwf_trainer_current_r'
        ]
      },
      views: {
        exports: {
          template: '<swoa-exports-page></swoa-exports-page>'
        }
      }
    })
    .state('report.exports.cards', {
      url: '/cards',
      data: {
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasPermission: ['admin_exports_cards_r'],
        exportType: 'cards'
      },
      views: {
        cards: {
          template: '<swoa-exports-cards></swoa-exports-cards>'
        }
      }
    })
    .state('report.exports.sports', {
      url: '/sports',
      data: {
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasPermission: ['admin_exports_sports_r'],
        exportType: 'sports'
      },
      views: {
        sports: {
          template: '<swoa-exports-sports></swoa-exports-sports>'
        }
      }
    })
    .state('report.exports.users', {
      url: '/users',
      data: {
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasPermission: ['admin_exports_users_r'],
        exportType: 'users'
      },
      views: {
        users: {
          template: '<swoa-exports-users></swoa-exports-users>'
        }
      }
    })
    .state('report.exports.nwfBaseAmount', {
      url: '/nwf-base-amount',
      data: {
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasPermission: ['admin_exports_nwf_base_amount_r'],
        exportType: 'nwfBaseAmount'
      },
      views: {
        nwfBaseAmount: {
          template:
            '<swoa-exports-nwf-base-amount></swoa-exports-nwf-base-amount>'
        }
      }
    })
    .state('report.exports.nwfVariableAmount', {
      url: '/nwf-variable-amount',
      data: {
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasPermission: ['admin_exports_nwf_variable_amount_r'],
        exportType: 'nwfVariableAmount'
      },
      views: {
        nwfVariableAmount: {
          template:
            '<swoa-exports-nwf-variable-amount></swoa-exports-nwf-variable-amount>'
        }
      }
    })
    .state('report.exports.nwfTrainerCurrent', {
      url: '/nwf-trainer-current',
      data: {
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasPermission: ['admin_exports_nwf_trainer_current_r'],
        exportType: 'nwfTrainerCurrent'
      },
      views: {
        nwfVariableAmount: {
          template:
            '<swoa-exports-nwf-trainer-current></swoa-exports-nwf-trainer-current>'
        }
      }
    })
    .state('report.exports.nwfTrainerPercentage', {
      url: '/nwf-trainer-percentages',
      data: {
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasPermission: ['admin_exports_nwf_trainer_r'],
        exportType: 'nwfTrainerPercentages'
      },
      views: {
        nwfVariableAmount: {
          template:
            '<swoa-exports-nwf-trainer-percentage></swoa-exports-nwf-trainer-percentage>'
        }
      }
    })
    .state('report.reports', {
      url: '/reports',
      data: {
        selectedTab: 1,
        breadcrumb: {
          parent: 'report',
          ignore: true
        },
        hasPermission: ['admin_reports_r']
      },
      views: {
        reports: {
          template: '<swoa-reports></swoa-reports>'
        }
      }
    });
}
