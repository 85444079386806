export default class urlHelper {
  // Cleans up dangling url param keys, e.g. ../users/?id= and sets new ones
  static sanitize(url, data) {
    const params = new URLSearchParams(data.payload.search);
    params.forEach((value, key) => {
      if (key === 'lang') return;
      url.searchParams.delete(key);
      url.searchParams.append(key, value);
    });
    return url;
  }
}
