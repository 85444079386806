import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaPersonService from '../person.service';
import swoaPersonMutationService from '../mutation/person-mutation.service';
import swoaPersonSyncService from '../sync/person-sync.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaPersonProfilePersonProfileHelperService from './person-profile-helper.service';
import htmlTemplate from './person-profile-infos.html';

export default angular
  .module('swoa.person.profile.person-profile-infos', [
    swoaConstants,
    swoaPersonService,
    swoaPersonMutationService,
    swoaPersonSyncService,
    swoaNotification,
    swoaPersonProfilePersonProfileHelperService
  ])
  .component('swoaPersonProfileInfos', {
    template: htmlTemplate,
    bindings: {
      person: '=',
      currentEditor: '='
    },
    controller: PersonProfileInfosController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonProfileInfosController(
  $scope,
  $rootScope,
  $element,
  $timeout,
  lodash,
  personService,
  personMutationService,
  personSyncService,
  notificationService,
  personProfileHelper
) {
  const vm = this,
    helper = personProfileHelper.createFormHelper(vm, 'infos');

  // view data
  vm.canEdit = false;
  vm.editing = false;
  vm.personConfig = null;
  vm.contactConfig = null;
  vm.socialMediaConfig = null;
  vm.editPerson = null;
  vm.submitted = false;
  vm.mutationInfo = null;
  vm.initialized = false;
  vm.hasRegisterNumber = false;

  // functions
  vm.initViewData = initViewData;
  vm.save = save;
  vm.cancelEdit = helper.cancelEdit;
  vm.startEdit = helper.startEdit;

  // error handling
  vm.errors = [];
  vm.notificationKey = null;
  vm.notificationSuccess = null;

  // for tests only!
  vm._canEdit = canEdit;
  vm._onError = onError;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.person', initViewData, true);
    $scope.$watch('vm.person.isEliteAthlete', updatePersonContactInfoConfig);
    $scope.$watch('vm.person.isTrainer', updatePersonContactInfoConfig);
    $scope.$watch('vm.person.isTechnician', updatePersonContactInfoConfig);
    $scope.$watch('vm.currentEditor', helper.checkCurrentEditorAndCancelEdit);
  }

  function initViewData() {
    if (helper.needsInitialization(vm.editPerson)) {
      vm.mutationInfo = helper.checkMutation(
        helper.getOriginalPerson(vm.person)
      );
      vm.canEdit = canEdit(vm.person);

      vm.personConfig = angular.copy(
        personSyncService.PROFILE_PERSON_CONFIGURATION
      );
      vm.contactConfig = angular.copy(
        personSyncService.PROFILE_CONTACT_CONFIGURATION
      );
      vm.socialMediaConfig = angular.copy(
        personSyncService.PROFILE_SOCIAL_MEDIA_CONFIGURATION
      );

      // prepare person info section
      vm.editPerson = angular.copy(vm.person);
      if (!vm.editPerson.foreignIds || vm.editPerson.foreignIds.length === 0) {
        vm.editPerson.foreignIds = {};
      } else {
        vm.editPerson.foreignIds = personService.hashifyForeignIds(
          vm.editPerson.foreignIds
        );
      }
      vm.hasRegisterNumber = vm.editPerson.foreignIds.REGISTER_NUMBER;
      vm.editPerson.address = vm.editPerson.address || {};

      vm.initialized = true;
    }
    updatePersonContactInfoConfig();
  }

  function save(formValid) {
    helper.resetNotifications();
    if (vm.canEdit) {
      vm.submitted = true;
      if (formValid) {
        vm.editPerson.foreignIds = personService.unhashifyForeignIds(
          vm.editPerson.foreignIds
        );
        getUpdatePromise(vm.editPerson).then(() => {
          helper.processSaved();
          vm.submitted = false;
        }, onError);
      } else {
        vm.notificationKey = 'form.invalid';
        vm.notificationSuccess = false;
        $element.find('form')[0].scrollIntoView();
      }
    } else {
      vm.editing = false;
      vm.initialized = false;
      initViewData();
    }
  }

  function onError(error) {
    notificationService.errorHandler(vm)(error);
    vm.editPerson.foreignIds = personService.hashifyForeignIds(
      vm.editPerson.foreignIds
    );
  }

  function getUpdatePromise(person) {
    if (helper.isPersonMutation(person)) {
      return personMutationService.updatePersonMutation(person);
    }
    return personService.mergeOrCreatePersonMutation(person);
  }

  function canEdit(person) {
    return !vm.mutationInfo || canEditPersonWithMutationInfo(person);
  }

  function canEditPersonWithMutationInfo(person) {
    return (
      vm.mutationInfo &&
      (helper.isPersonMutation(person) || canEditNewPerson(person))
    );
  }

  function canEditNewPerson(person) {
    return (
      person.mutationState === 'NEW' && $rootScope.uaPermission('person_new_w')
    );
  }

  function updatePersonContactInfoConfig() {
    if (vm.person) {
      if (vm.person.hasOnlyTalentCards === true) {
        $timeout(() => {
          updatePropertyToNotRequired(vm.contactConfig, 'phoneNumber');
          updatePropertyToNotRequired(vm.contactConfig, 'mobileNumber');
          updatePropertyToNotRequired(vm.contactConfig, 'email');
        });
      }
    }
  }

  function updatePropertyToNotRequired(config, key) {
    if (config) {
      const prop = lodash.find(config, { personKey: key });
      if (prop) {
        prop.required = false;
        delete prop.overrideAsterisk;
      }
    }
  }
}
