import angular from 'angular';
import swoaAhvAhvNumber from './ahv-number.directive';
import swoaAhvAhvNumberValidator from './ahv-number-validator.service';

export default angular
  .module('swoa.ahv', [
    swoaAhvAhvNumber,
    swoaAhvAhvNumberValidator,
  ])
  .name;

