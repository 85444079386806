import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.admin.jobs.service', [
    'restangular',
  ])
  .factory('jobsService', jobsService)
  .name;

/** @ngInject */
function jobsService(Restangular) {
  const JOBS_ROUTE = 'jobs';

  const service = Restangular.all(JOBS_ROUTE);

  service.getList = getList;
  service.startJob = startJob;
  service.rescheduleTimer = rescheduleTimer;

  return service;

  // //////////

  function getList() {
    return Restangular.all(JOBS_ROUTE).getList();
  }

  function startJob(id, info) {
    return Restangular.one(JOBS_ROUTE, id).customPUT({ startDate: info }, 'start');
  }

  function rescheduleTimer(job) {
    return Restangular.one(JOBS_ROUTE, job.id).customPUT('', 'reschedule');
  }
}
