import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaDate from '../../components/date/date.filter';
import swoaPersonService from '../person.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaPersonProfileHelperService from './person-profile-helper.service';
import swoaUserService from '../../user/user.service';
import swoaChangeLogService from '../../components/change-log/change-log.service';
import swoaDialog from '../../components/dialog/dialog.module';
import swoaFileService from '../../components/file/file.service';
import swoaConstants from '../../app.constants';
import htmlTemplate from './person-profile.html';

export default angular
  .module('swoa.person.profile.person-profile', [
    swoaDate,
    swoaPersonService,
    swoaNotification,
    swoaPersonProfileHelperService,
    swoaUserService,
    swoaChangeLogService,
    angularTranslate,
    swoaDialog,
    swoaFileService,
    swoaConstants
  ])
  .component('swoaPersonProfile', {
    template: htmlTemplate,
    bindings: {},
    controller: PersonProfileController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonProfileController(
  $rootScope,
  $scope,
  $document,
  $stateParams,
  $mdDialog,
  personService,
  notificationService,
  personProfileHelper,
  userService,
  changeLogService,
  $translate,
  dialogService,
  fileService,
  ConfigConstants
) {
  const vm = this,
    helper = personProfileHelper.createFormHelper(vm);

  // state params
  vm.swissOlympicId = parseInt($stateParams.soId, 10);
  vm.availableLanguages = ConfigConstants.translation.availableLanguages;
  vm.currentLanguage = $translate.use();

  // view data
  vm.headerImage = null;
  vm.headerTempImage = null;
  vm.headerImageCrop = null;
  vm.headerImageTempCrop = null;
  vm.avatar = null;
  vm.avatarTemp = null;
  vm.avatarCrop = null;
  vm.avatarTempCrop = null;
  vm.fileCount = null;
  vm.selectedTab = $stateParams.tabId || 1;
  vm.currentEditor = null;
  vm.personIsInNonReleasedCardYear = false;
  vm.isCurrentUserProfile = false;

  // error handling
  vm.notificationKey = null;
  vm.notificationKeyList = [];
  vm.isInfo = false;

  // functions
  vm.showChangeLogDialog = showChangeLogDialog;
  vm.showPersonDeactivationDialog = showPersonDeactivationDialog;
  vm.uploadHeaderImage = uploadHeaderImage;
  vm.removeHeaderImage = removeHeaderImage;
  vm.uploadAvatar = uploadAvatar;
  vm.removeAvatar = removeAvatar;
  vm.headerStyle = headerStyle;
  vm.avatarStyle = avatarStyle;
  vm.showFilesDialog = showFilesDialog;
  vm.activatePerson = activatePerson;
  vm.showPrivacyPolicy = showPrivacyPolicy;
  vm.downloadPrivacyPolicy = downloadPrivacyPolicy;
  vm.changeLanguage = changeLanguage;
  vm.downloadFile = downloadFile;

  // permission based
  vm.isDownloadable = isDownloadable;
  vm.isUploadable = isUploadable;
  vm.isRemoveable = isRemoveable;
  vm.isAnyActionDoable = isAnyActionDoable;

  // for tests only!
  vm._onFileChange = onFileChange;
  vm._deactivate = deactivate;
  vm._initInactiveInfo = initInactiveInfo;
  vm._downloadPrivacyPolicy = downloadPrivacyPolicy;
  vm._isPersonModelExtended = isPersonModelExtended;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.person', initPersonInfo, true);
    $scope.$on('swoa:personProfileFilesChanged', onFileChange);
    personProfileHelper.setParentComponentPersonReloadFunction(loadPerson);

    if (
      $rootScope.userHasOnlyRole('PERSON') &&
      !userService.hasLoggedUserSwissOlympicId(vm.swissOlympicId)
    ) {
      showPersonNotFoundAlert();
    } else {
      loadPerson();
    }
  }

  function loadPerson() {
    personService.findBySwissOlympicId(vm.swissOlympicId).then(person => {
      vm.person = person;
      vm.originalPerson = helper.getOriginalPerson(vm.person);
      loadProfileImages(vm.originalPerson.id);
      countProfileFiles(vm.originalPerson.id);
      loadCardsAndExtendPersonModel(vm.originalPerson);
      const personSoId = vm.person.swissOlympicId;
      const currentUser = userService.getCurrentUser();
      if (currentUser && currentUser.person) {
        vm.isCurrentUserProfile =
          personSoId === currentUser.person.swissOlympicId;
      }
      personService
        .isPersonInCardYearWithStatus(vm.originalPerson.id, [
          'for_consideration',
          'provisionally_released'
        ])
        .then(
          isInYearWithStatus =>
            (vm.personIsInNonReleasedCardYear = isInYearWithStatus)
        );
    }, onError);
  }

  function showChangeLogDialog() {
    personService
      .getChangeLog(vm.originalPerson.id)
      .then(
        personChangeLog =>
          changeLogService.showDialog(
            personChangeLog,
            'person',
            vm.originalPerson.id
          ),
        onError
      );
  }

  function initPersonInfo() {
    if (vm.person) {
      vm.originalPerson = helper.getOriginalPerson(vm.person);
      vm.notificationKeyList = [];
      initMutationInfo();
      initInactiveInfo();
      if (!isPersonModelExtended(vm.originalPerson)) {
        loadCardsAndExtendPersonModel(vm.originalPerson);
      }
    }
  }

  function initMutationInfo() {
    vm.isInfo = true;
    const mutationInfo = helper.checkMutation(vm.originalPerson);
    if (mutationInfo) {
      vm.notificationKeyList.push({ notificationKey: mutationInfo });
    }
  }

  function initInactiveInfo() {
    if (!vm.originalPerson.active) {
      vm.isInfo = true;
      const values = {
        deactivationType: $translate.instant(
          `deactivate.type.${vm.originalPerson.deactivationType}`
        )
      };
      if (vm.originalPerson.deactivationReason) {
        values.deactivationContent = vm.originalPerson.deactivationReason;
      } else if (vm.originalPerson.deactivationType) {
        values.deactivationContent = vm.originalPerson.deactivationDate;
      }
      initShowNotification(values);
    }
  }

  function initShowNotification(values) {
    const translatedKey = $translate.instant('personProfile.personInactive');
    if ($rootScope.isSwoaUser() || $rootScope.isAssociationUser()) {
      vm.notificationKeyList.push({
        notificationKey: `${translatedKey}: ${values.deactivationType}, ${values.deactivationContent}`
      });
    } else {
      vm.notificationKeyList.push({ notificationKey: translatedKey });
    }
  }

  function uploadHeaderImage(file, metadata) {
    return personService
      .saveHeaderImage(vm.originalPerson.id, file, metadata)
      .then(() => {
        vm.headerImage = URL.createObjectURL(file);
        vm.headerImageCrop = JSON.parse(metadata);
        vm.headerTempImage = null;
        vm.headerImageTempCrop = null;
      });
  }

  function removeHeaderImage() {
    return personService.removeHeaderImage(vm.originalPerson.id).then(() => {
      vm.headerImage = null;
      vm.headerImageCrop = null;
      vm.headerTempImage = null;
      vm.headerImageTempCrop = null;
    });
  }

  function uploadAvatar(file, metadata) {
    return personService
      .saveAvatar(vm.originalPerson.id, file, metadata)
      .then(() => {
        vm.avatar = URL.createObjectURL(file);
        vm.avatarCrop = JSON.parse(metadata);
        vm.avatarTemp = null;
        vm.avatarTempCrop = null;
      });
  }

  function removeAvatar() {
    return personService.removeAvatar(vm.originalPerson.id).then(() => {
      vm.avatar = null;
      vm.avatarCrop = null;
      vm.avatarTemp = null;
      vm.avatarTempCrop = null;
    });
  }

  function loadProfileImages(personId) {
    personService
      .loadAvatar(personId)
      .then(image => {
        vm.avatar = URL.createObjectURL(image.data);
        vm.avatarCrop = image.metadata;
      })
      .catch(angular.noop);

    personService
      .loadHeaderImage(personId)
      .then(image => {
        vm.headerImage = URL.createObjectURL(image.data);
        vm.headerImageCrop = image.metadata;
      })
      .catch(angular.noop);
  }

  function countProfileFiles(personId) {
    personService
      .countProfileFiles(personId)
      .then(fileCount => {
        vm.fileCount = fileCount;
      })
      .catch(angular.noop);
  }

  function onFileChange(event, personId, fileCount) {
    if (vm.originalPerson.id === personId) {
      vm.fileCount = fileCount;
    }
  }

  function downloadFile(file, fileType) {
    const typeTranslated = $translate.instant(
      `personProfile.fileName.${fileType}`
    );
    const fileName = `SO-ID_${vm.person.swissOlympicId}_${typeTranslated}`;
    fileService.download(file, fileName);
  }

  function loadCardsAndExtendPersonModel(person) {
    personService
      .loadCards(person.id)
      .then(cards => personService.extendPersonModel(person, cards))
      // not the normal error handler otherwise an error is shown if the person doesn't have access to any cards
      .catch(() => personService.extendPersonModel(person, []));
  }

  function onError(error) {
    const ENTITY_NOT_FOUND_STATUS = 404;
    const ENTITY_NOT_FOR_YOU = 400;

    notificationService.errorHandler(vm)(error);

    if (error.status === ENTITY_NOT_FOUND_STATUS) {
      showPersonNotFoundAlert();
    } else if (error.status === ENTITY_NOT_FOR_YOU) {
      showPersonNoneOfYourBusinessAlert();
    }
  }

  function headerStyle() {
    const imgUrl =
      vm.headerTempImage ||
      vm.headerImage ||
      vm.originalPerson.imageHeaderUrl ||
      vm.defaultImageHeaderUrl;
    return imageStyle(imgUrl, vm.headerImageTempCrop || vm.headerImageCrop);
  }

  function avatarStyle() {
    const imgUrl =
      vm.avatarTemp ||
      vm.avatar ||
      vm.originalPerson.imageUrl ||
      '/assets/images/user_avatar_default.svg';
    return imageStyle(imgUrl, vm.avatarTempCrop || vm.avatarCrop);
  }

  function imageStyle(imgUrl, cropObject) {
    const style = {
      'background-image': imgUrl ? `url(${imgUrl})` : 'none'
    };

    if (cropObject) {
      style[
        'background-position'
      ] = `${cropObject.positionX}% ${cropObject.positionY}%`;
      style['background-size'] = `${cropObject.sizeX}% ${cropObject.sizeY}%`;
    }

    return style;
  }

  function showPersonNotFoundAlert() {
    vm.notificationKey = 'personProfile.error.personNotFound.text';
  }

  function showPersonNoneOfYourBusinessAlert() {
    vm.notificationKey = 'personProfile.error.personNotForYou.text';
  }

  function showFilesDialog(event) {
    $mdDialog.show({
      template: `<swoa-file-storage back-to-dialog="vm.showFilesDialog"
                                    can-upload="$root.uaPermission('filestorage_person_create_w')"
                                    can-upload-own="$root.uaPermission('filestorage_person_own_w')"
                                    can-delete="$root.uaPermission('filestorage_person_all_w')"
                                    can-delete-own="$root.uaPermission('filestorage_person_own_w')"
                                    person-id="vm.originalPerson.id"
                                    max-size="10MB">
                 </swoa-file-storage>`,
      autoWrap: true,
      preserveScope: true,
      scope: $scope,
      parent: angular.element($document.body),
      targetEvent: event,
      fullscreen: true
    });
  }

  function showPersonDeactivationDialog() {
    const uncloseable = true;
    const template = `<swoa-deactivation type="type" entry="entry" on-deactivate="onDeactivate" current-editor="currentEditor">
                      </swoa-deactivation>`;

    const title = $translate.instant('person.deactivate.title');
    vm.currentEditor = 'deactivation';
    const scope = {
      type: 'person',
      entry: vm.originalPerson,
      onDeactivate: deactivate,
      currentEditor: vm.currentEditor
    };
    return dialogService.showDialog(template, uncloseable, title, scope);
  }

  function deactivate(entry, type, reason) {
    return personService
      .deactivate(entry, type, reason)
      .then(onDeactivationSuccess, onError);
  }

  function activatePerson() {
    return personService
      .activate(vm.originalPerson)
      .then(onActivationSuccess, onError);
  }

  function onDeactivationSuccess() {
    $mdDialog.hide();
    dialogService.showSuccessToast('person.deactivate.done');
    vm.originalPerson.active = false;
    helper.processSaved();
  }

  function onActivationSuccess() {
    $mdDialog.hide();
    dialogService.showSuccessToast('person.activate.done');
    vm.originalPerson.active = true;
    vm.originalPerson.deactivationReason = null;
    vm.originalPerson.deactivationType = null;
    vm.originalPerson.deactivationDate = null;
    helper.processSaved();
  }

  function showPrivacyPolicy() {
    const isOwnProfile =
      vm.originalPerson &&
      ($rootScope.authInfo.getUser() || { person: {} }).person.id ===
        vm.originalPerson.id;
    return (
      isOwnProfile &&
      (vm.originalPerson.isEliteAthlete ||
        vm.originalPerson.isTechnician ||
        vm.originalPerson.isTrainer)
    );
  }

  function downloadPrivacyPolicy(language) {
    const privacyPolicyFile = {
      category: 'PRIVACY',
      fileName: `privacy_policy_${language}.pdf`
    };
    return fileService.downloadPersistentStorage(privacyPolicyFile);
  }

  function changeLanguage(lang) {
    vm.currentLanguage = lang;
    $translate.use(lang);
  }

  function isPersonModelExtended(person) {
    return (
      isFlagDefined(person.isEliteAthlete) &&
      isFlagDefined(person.isTrainer) &&
      isFlagDefined(person.isTechnician)
    );
  }

  function isFlagDefined(flag) {
    return typeof flag !== typeof undefined;
  }

  function isDownloadable() {
    const downloadOwnImage =
      vm.isCurrentUserProfile &&
      $rootScope.uaPermission('filestorage_person_fotos_own_r');
    const downloadAnyImage = $rootScope.uaPermission(
      'filestorage_person_fotos_r'
    );
    return downloadAnyImage || downloadOwnImage;
  }

  function isUploadable() {
    const uploadOwnProfileImage =
      vm.isCurrentUserProfile &&
      $rootScope.uaPermission('filestorage_person_fotos_own_w');
    const uploadAnyProfileImage = $rootScope.uaPermission(
      'filestorage_person_fotos_w'
    );
    return uploadOwnProfileImage || uploadAnyProfileImage;
  }

  function isRemoveable() {
    const removeOwnImage =
      vm.isCurrentUserProfile &&
      $rootScope.uaPermission('filestorage_person_fotos_remove_own_w');
    const removeAnyImage = $rootScope.uaPermission(
      'filestorage_person_fotos_remove_w'
    );
    return removeAnyImage || removeOwnImage;
  }

  function isAnyActionDoable() {
    return isRemoveable() || isDownloadable() || isUploadable();
  }
}
