import angular from 'angular';
import htmlTemplate from './card-import-status.html';

export default angular
  .module('swoa.card.card-import.card-import-status', [])
  .component('swoaCardImportStatus', {
    template: htmlTemplate,
    bindings: {
      status: '<',
    },
    controller: CardImportStatusController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function CardImportStatusController() {
  const vm = this;

  vm.statusClass = vm.status === 'NEEDS_MATCHING' ? 'info' : 'needs-action';
  vm.statusTextKey = `card.importStatus.${vm.status || 'NEEDS_MATCHING'}`;
}
