import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaAsyncService from '../../components/async/async-helper.service';
import swoaDate from '../../components/date/date.filter';
import swoaExportService from './export.service';
import swoaReportHelper from './report-helper.service';
import swoaNwfContributionService from '../../nwf-contribution/nwf-contribution.service';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import { JSON_DATE_FORMAT } from './report.constants';
import { NWF_START_YEAR } from './exports-nwf-base-amount.component';
import htmlTemplate from './exports-nwf-common-content.html';

export default angular
  .module('swoa.admin.report.exports.nwfInstitutionsContent', [
    angularTranslate,
    swoaConstants,
    swoaNotification,
    swoaAsyncService,
    swoaDate,
    swoaExportService,
    swoaReportHelper,
    swoaNwfContributionService,
    swoaTranslatable
  ])
  .component('swoaExportsNwfCommonContent', {
    template: htmlTemplate,
    bindings: {
      favoriteName: '@',
      exportUrl: '@',
      exportName: '@',
      endpointForAttributes: '@',
      showYears: '<'
    },
    controller: ExportsNwfCommonContentComponent,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function ExportsNwfCommonContentComponent(
  $scope,
  $rootScope,
  $q,
  moment,
  lodash,
  $translate,
  notificationService,
  asyncHelperService,
  Constants,
  exportService,
  userService,
  reportHelperService,
  nwfContributionService,
  cityService,
  swoaTranslatableFilter
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    reportHelper = reportHelperService.createReportHelper(
      vm,
      $scope,
      vm.favoriteName,
      { getInitialExportParamState }
    ),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      reportHelper.downloadReport
    );

  vm.organisations = [];
  vm.sports = [];
  vm.states = undefined;
  vm.attributes = { selected: [], all: [], saved: [] };

  vm.exportParams = null;
  vm.uiConfig = {};

  vm.resetExportParams = reportHelper.resetExportParams;
  vm.generate = generate;

  vm.$onInit = () => {
    activate();
  };

  // //////

  function activate() {
    $rootScope.showSpinner = true;
    initializeDataFromServices()
      .then(selectSportsAndInstitutionsAndStatesAccordingToOrganisations)
      .then(reportHelper.resetExportParams)
      .finally(() => ($rootScope.showSpinner = false));

    $scope.$watch(
      'vm.exportParams.organisationsIds',
      selectSportsAndInstitutionsAndStatesAccordingToOrganisations,
      true
    );
    $scope.$watch(
      'vm.exportParams.sportIds',
      selectInstitutionsAndStatesAccordingToSports,
      true
    );
    $scope.$watch(
      'vm.exportParams.institutionIds',
      selectStatesAccordingToInstitutions,
      true
    );
    asyncHelper.getAsyncJobStatus();
    vm.uiConfig = userService.getCurrentUser().uiConfig;
  }

  function initializeDataFromServices() {
    const promises = [];
    promises.push(
      exportService
        .loadOrganisations()
        .then(organisations => (vm.organisations = organisations))
    );
    promises.push(
      exportService
        .loadExportableColumnsFor(vm.endpointForAttributes)
        .then(reportHelper.translateAttributes)
    );
    promises.push(
      nwfContributionService.getMaxYear().then(maxYear => {
        vm.startDates = [];
        vm.endDates = [];
        for (let year = maxYear; year >= NWF_START_YEAR; year--) {
          const startOfMonth = moment([year, 0, 1]);
          vm.startDates.push(startOfMonth.format(JSON_DATE_FORMAT));
          vm.endDates.push(
            startOfMonth
              .clone()
              .endOf('year')
              .startOf('day')
              .format(JSON_DATE_FORMAT)
          );
        }
      })
    );

    promises.push(
      nwfContributionService.getSportsWithContainers().then(sports => {
        vm.allSports = lodash.forEach(
          sports,
          s => (s.displayName = swoaTranslatableFilter(s.name))
        );
        vm.allSports = lodash.sortBy(sports, s => s.displayName);
        vm.sports = vm.allSports;
      })
    );

    promises.push(
      nwfContributionService
        .getAllInstitutions()
        .then(institutionSportMappings => {
          let institutions = lodash.groupBy(
            institutionSportMappings,
            'institutionId'
          );
          institutions = lodash.map(institutions, (value, key) => ({
            id: key,
            name: value[0].institutionName,
            level: value[0].institutionLevel,
            strong: value[0].institutionLevel === 'NATIONAL',
            organisationId: value[0].organisationId,
            sportIds: lodash.map(value, 'sportId'),
            state: value[0].state
          }));
          vm.allInstitutions = lodash.sortBy(
            institutions,
            i => `${i.level} ${i.name.toLowerCase()}`
          );
        })
    );

    promises.push(
      cityService.getAllStates().then(states => {
        vm.states = states;
        vm.allStates = states;
      }, onError)
    );

    return $q.all(promises);
  }

  function selectSportsAndInstitutionsAndStatesAccordingToOrganisations() {
    if (!vm.exportParams) {
      return;
    }
    let selectedIds = vm.exportParams.organisationsIds;
    if (!selectedIds || selectedIds.length === 0) {
      selectedIds = lodash.map(vm.organisations, 'id');
    }

    vm.institutions = lodash.filter(vm.allInstitutions, i =>
      lodash.includes(selectedIds, i.organisationId)
    );

    const institutionSports = vm.institutions.flatMap(
      institution => institution.sportIds
    );

    vm.sports = vm.allSports.filter(sport =>
      institutionSports.includes(sport.id)
    );

    vm.states = filterStates(vm.institutions);
    recalculateSelections();
  }

  function recalculateSelections() {
    vm.exportParams.organisationIds = vm.exportParams.organisationsIds.filter(
      organisationId => lodash.some(vm.organisations, ['id', organisationId])
    );
    vm.exportParams.sportIds = vm.exportParams.sportIds.filter(sportId =>
      lodash.some(vm.sports, ['id', sportId])
    );
    vm.exportParams.institutionIds = vm.exportParams.institutionIds.filter(
      institutionId => lodash.some(vm.institutions, ['id', institutionId])
    );
    vm.exportParams.states = vm.exportParams.states.filter(state =>
      lodash.includes(vm.states, state)
    );
  }

  function selectInstitutionsAndStatesAccordingToSports() {
    if (!vm.exportParams) {
      return;
    }
    let selectedIds = vm.exportParams.sportIds;
    if (!selectedIds || selectedIds.length === 0) {
      selectedIds = lodash.map(vm.sports, 'id');
    }
    vm.institutions = lodash.filter(
      vm.allInstitutions,
      i => lodash.intersection(selectedIds, i.sportIds).length > 0
    );
    vm.states = filterStates(vm.institutions);
    recalculateSelections();
  }

  function selectStatesAccordingToInstitutions() {
    if (!vm.exportParams) {
      return;
    }

    let selectedIds = vm.exportParams.institutionIds;
    if (!selectedIds || selectedIds.length === 0) {
      selectedIds = lodash.map(vm.institutions, 'id');
    }

    const selectedInstitutions = lodash.map(selectedIds, id =>
      lodash.find(vm.institutions, ['id', id])
    );
    vm.states = filterStates(selectedInstitutions);
    recalculateSelections();
  }

  function filterStates(selectedInstitutions) {
    const selectedInstituionStates = lodash.map(
      selectedInstitutions,
      institution => institution.state
    );
    return lodash.intersection(vm.allStates, selectedInstituionStates);
  }

  function getInitialExportParamState() {
    const exportParams = {
      organisationsIds: [],
      sportIds: [],
      institutionIds: [],
      states: []
    };
    if (vm.organisations && vm.organisations.length === 1) {
      exportParams.organisationsIds.push(vm.organisations[0].id);
    }
    reportHelper.setExportDateRange(exportParams);
    return exportParams;
  }

  function generate() {
    const parameters = reportHelper.prepareToSerializeAsFavorite();
    asyncHelper.showSpinner();
    exportService
      .loadExport(
        `${vm.exportUrl}/${$translate.use()}`,
        buildFileName(),
        parameters
      )
      .then(asyncHelper.getAsyncJobStatus)
      .catch(asyncHelper.errorHandler);
  }

  function buildFileName() {
    const language = $translate.use();

    const fileNameElements = [vm.exportName, language.toUpperCase()];

    return `${fileNameElements.filter(e => !!e).join('_')}.xlsx`;
  }
}
