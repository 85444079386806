import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaTranslatable from '../../components/translatable/translatable.filter';
import swoaFileService from '../../components/file/file.service';
import {
  DATE_SELECT_YEARS_AHEAD,
  EVENT_NAME_SHOW_LOAD_FAVORITE_DIALOG,
  EVENT_NAME_SHOW_SAVE_FAVORITE_DIALOG,
  JSON_DATE_FORMAT,
} from './report.constants';

export default angular
  .module('swoa.admin.report.helper.service', [
    swoaConstants,
    swoaTranslatable,
    swoaFileService,
  ])
  .factory('reportHelperService', reportHelper)
  .name;

/** @ngInject */
function reportHelper($rootScope, $document, $q, $translate, moment, lodash, $mdDialog, fileService, userService, swoaTranslatableFilter) {
  return {
    createReportHelper,
  };

  // //////////

  function createReportHelper(vm, $scope, favoriteProperty, callbacks) {
    const helper = {

      generateMonthsEntries: (date) => {
        vm.startDates = [];
        vm.endDates = [];
        vm.years = [];
        const earliestYear = moment(date, JSON_DATE_FORMAT).year();
        const currentYear = moment().year();
        for (let year = currentYear + DATE_SELECT_YEARS_AHEAD; year >= earliestYear; year--) {
          vm.years.push(year);

          for (let month = 11; month >= 0; month--) {
            const startOfMonth = moment([year, month, 1]);
            vm.startDates.push(startOfMonth.format(JSON_DATE_FORMAT));
            vm.endDates.push(startOfMonth.clone().endOf('month').startOf('day').format(JSON_DATE_FORMAT));
          }
        }
      },

      setExportDateRange: (params) => {
        const currentYear = moment().year();
        params.exportFrom = moment([currentYear, 0, 1]).format(JSON_DATE_FORMAT);
        params.exportUntil = moment([currentYear, 11, 31]).format(JSON_DATE_FORMAT);
        params.exportYear = currentYear;
      },

      translateAttributes: (attributes) => {
        lodash.forEach(attributes, attribute => (attribute.displayName = swoaTranslatableFilter(attribute, $translate.use())));
        vm.attributes.saved = lodash.sortBy(attributes, 'displayName');
        vm.attributes.all = angular.copy(vm.attributes.saved);
      },

      downloadReport: (resultUrl, result, options) => fileService.download(resultUrl, result.resultHint, options),

      resetExportParams: () => {
        $rootScope.showSaveFavoriteButton = false;
        vm.exportParams = callbacks.getInitialExportParamState();
        vm.attributes.all = angular.copy(vm.attributes.saved);
        vm.attributes.selected = [];
      },

      prepareToSerializeAsFavorite: () => {
        const parameters = angular.copy(vm.exportParams);
        parameters.columns = vm.attributes.selected.map(s => s.technicalName);
        return parameters;
      },

      filterSelectionTypesByPermission: (selectionTypes) => {
        if (!$rootScope.uaPermission('admin_exports_cardchange_r')) {
          return lodash.filter(selectionTypes, t => t !== 'CARD_CHANGES');
        }
        return selectionTypes;
      },

      unserializeAndLoadFavorite: ($favorite) => {
        const selected = angular.copy($favorite);
        helper.resetExportParams();
        const technicalNames = angular.copy(selected.columns);
        delete selected.name;
        delete selected.columns;
        vm.attributes.selected = lodash.map(technicalNames, name => lodash.find(vm.attributes.all, attr => attr.technicalName === name));
        vm.attributes.all = lodash.filter(angular.copy(vm.attributes.saved), attr => !lodash.find(vm.attributes.selected, attr));
        vm.exportParams = selected;
      },

      favoriteDialogHandler: (event) => {
        $scope.deleteFavorite = ($favorite) => {
          vm.uiConfig[favoriteProperty] = lodash.filter(vm.uiConfig[favoriteProperty], fav => fav.name !== $favorite.name);
          $scope.favorites = vm.uiConfig[favoriteProperty];
          return userService.saveUiConfig(vm.uiConfig);
        };
        $scope.addFavorite = ($name) => {
          const prepareFn = callbacks.prepareToSerializeAsFavorite || helper.prepareToSerializeAsFavorite;
          const parameters = prepareFn();
          parameters.name = $name;

          if (!vm.uiConfig[favoriteProperty]) {
            vm.uiConfig[favoriteProperty] = [];
          }
          vm.uiConfig[favoriteProperty].push(parameters);
          userService.saveUiConfig(vm.uiConfig).then(() => {
            vm.notificationKey = 'report.favorites.new.save.successful';
            vm.notificationSuccess = true;
          }, (error) => {
            vm.errors = [{ messageKey: 'server.error.generic' }];
            return $q.reject(error);
          });
        };
        $scope.selectFavorite = callbacks.unserializeAndLoadFavorite || helper.unserializeAndLoadFavorite;
        $scope.closeDialog = $mdDialog.hide;
        $scope.favorites = vm.uiConfig[favoriteProperty];

        if (event.name === EVENT_NAME_SHOW_SAVE_FAVORITE_DIALOG) {
          $mdDialog.show({
            template: `<swoa-create-favorite-dialog add-favorite-action="addFavorite($name)"
                                                    close-dialog-action="closeDialog()">
                       </swoa-create-favorite-dialog>`,
            autoWrap: true,
            preserveScope: true,
            scope: $scope,
            parent: angular.element($document.body),
            targetEvent: event,
            fullscreen: true,
          });
        } else if (event.name === EVENT_NAME_SHOW_LOAD_FAVORITE_DIALOG) {
          $mdDialog.show({
            template: `<swoa-favorite-list favorites="favorites"
                                           select-favorite-action="selectFavorite($favorite)"
                                           delete-favorite-action="deleteFavorite($favorite)"
                                           close-dialog-action="closeDialog()">
                       </swoa-favorite-list>`,
            autoWrap: true,
            preserveScope: true,
            scope: $scope,
            parent: angular.element($document.body),
            targetEvent: event,
            fullscreen: true,
          });
        }
      },
    };

    const checkParamState = () => {
      const empty = lodash.isEmpty(vm.exportParams);
      const allEmpty = lodash.every(vm.exportParams, param => lodash.isEmpty(param));
      const isInitialState = angular.equals(vm.exportParams, callbacks.getInitialExportParamState());
      const attributesSelected = vm.attributes.selected && vm.attributes.selected.length > 0;

      $rootScope.showSaveFavoriteButton = (!empty && !allEmpty && !isInitialState) || attributesSelected;
    };

    const unregisterList = [];
    if (callbacks.getInitialExportParamState) {
      unregisterList.push($scope.$watch('vm.exportParams', checkParamState, true));
      unregisterList.push($scope.$watch('vm.attributes.selected', checkParamState, true));
    }
    unregisterList.push($scope.$on(EVENT_NAME_SHOW_SAVE_FAVORITE_DIALOG, helper.favoriteDialogHandler));
    unregisterList.push($scope.$on(EVENT_NAME_SHOW_LOAD_FAVORITE_DIALOG, helper.favoriteDialogHandler));

    $scope.$on('$destroy', () => {
      unregisterList.forEach(fn => fn());
    });

    return helper;
  }
}
