import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './user-select-person.html';

export default angular
  .module('swoa.user.select-person', [uiRouter, swoaNotification])
  .component('swoaUserSelectPerson', {
    template: htmlTemplate,
    bindings: {},
    controller: UserSelectPersonController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function UserSelectPersonController($state) {
  const vm = this;

  // functions
  vm.createUser = createUser;
  vm.cancelAction = cancelAction;

  // error handling
  vm.notificationKey = null;

  function createUser(personId) {
    $state.go('createUserForPerson', { personId });
  }

  function cancelAction() {
    $state.go('userList');
  }
}
