import angular from 'angular';
import swoaTranslationRoutes from './translations.route';
import swoaTranslationDropdwon from './translations-dropdown.component';
import swoaTranslationTechnical from './translations-technical.component';
import swoaSettingsTable from './translations-table.component';
import swoaAdminSettingsDetail from './translations-detail.component';

export default angular
  .module('swoa.admin.translations', [
    swoaTranslationRoutes,
    swoaTranslationDropdwon,
    swoaTranslationTechnical,
    swoaSettingsTable,
    swoaAdminSettingsDetail,
  ])
  .name;

