import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaDialogService from '../../components/dialog/dialog.service';
import swoaSportService from '../../sport/sport.service';
import swoaOrganisationService from './../organisation.service';
import swoaOrganisationProfileHelper from './organisation-profile-helper.service';
import htmlTemplate from './organisation-profile-sport-entry.html';

export default angular
  .module('swoa.organisation.profile.organisation-profile-sport.entry', [
    swoaConstants,
    swoaNotification,
    swoaDialogService,
    swoaSportService,
    swoaOrganisationService,
    swoaOrganisationProfileHelper,
  ])
  .component('swoaOrganisationProfileSportEntry', {
    template: htmlTemplate,
    bindings: {
      organisation: '<',
      sport: '<',
      organisationHeadChiefs: '=',
      editing: '<',
    },
    controller: OrganisationProfileSportEntry,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function OrganisationProfileSportEntry(
  lodash,
  notificationService,
  organisationService,
  organisationProfileHelper,
  dialogService,
  sportService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.editing = false;

  vm.isNationalAssociation = isNationalAssociation;

  // Only for tests
  vm._initSportContacts = initSportContacts;

  vm.$onInit = () => {
    activate();
  };

  // //////

  function activate() {
    sportService
      .loadSportContacts(vm.sport.id)
      .then(initSportContacts, onError);
  }

  function initSportContacts(sportContacts) {
    if (sportContacts.headChiefs && sportContacts.headChiefs.length > 0) {
      sportContacts.headChiefs.forEach((headChief) => {
        // Push Head Chiefs to parent component
        vm.organisationHeadChiefs.push(headChief);
      });
      vm.organisationHeadChiefs = removeDuplicatePersons(
        vm.organisationHeadChiefs
      );
    }
    if (
      sportContacts.chiefsNachwuchs &&
      sportContacts.chiefsNachwuchs.length > 0
    ) {
      vm.chiefsNachwuchs = sportContacts.chiefsNachwuchs;
    }
    if (
      sportContacts.chiefsLeistungssport &&
      sportContacts.chiefsLeistungssport.length > 0
    ) {
      vm.chiefsLeistungssport = sportContacts.chiefsLeistungssport;
    }
    if (
      sportContacts.chiefsAusbildung &&
      sportContacts.chiefsAusbildung.length > 0
    ) {
      vm.chiefsAusbildung = sportContacts.chiefsAusbildung;
    }
  }

  function removeDuplicatePersons(personList) {
    return lodash.uniqBy(personList, 'id');
  }

  function isNationalAssociation() {
    return (
      vm.organisation &&
      lodash.includes(vm.organisation.categories, 'NATIONAL_ASSOCIATION')
    );
  }
}
