import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaNotification from '../../components/notification/notification.module';
import swoaCardCardImportService from './card-import.service';
import swoaCardCardImportCardImportState from './card-import-state.service';
import htmlTemplate from './card-import-assign.html';

export default angular
  .module('swoa.card.card-import.card-import-assign', [
    uiRouter,
    swoaNotification,
    swoaCardCardImportService,
    swoaCardCardImportCardImportState
  ])
  .component('swoaCardImportAssign', {
    template: htmlTemplate,
    bindings: {},
    controller: CardImportAssignController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardImportAssignController(
  $rootScope,
  $state,
  $stateParams,
  notificationService,
  cardImportService,
  cardImportStateService
) {
  const vm = this;

  // functions
  vm.assignAction = assignAction;
  vm.selectPerson = selectPerson;
  vm.cancelAction = cancelAction;

  // state params
  vm.sportId = $stateParams.sportId;
  vm.yearId = $stateParams.yearId;
  vm.cardImportId = $stateParams.cardImportId;
  vm.searchMode = $stateParams.search;

  // loaded data
  vm.cardImport = null;
  vm.foundPersons = null;
  vm.canCreateNewPersons = $rootScope.uaPermission('person_create_w');

  // selections
  vm.selectedPerson = null;
  vm.searchText = null;

  // error handling
  vm.notificationKey = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function selectPerson(personId) {
    $stateParams.personId = personId;
    $state.go('cardImport.sync', $stateParams);
  }

  function assignAction() {
    selectPerson(vm.selectedPerson);
  }

  function cancelAction() {
    $state.go('cardImport.overview', $stateParams);
  }

  function activate() {
    cardImportService.loadCardImport(vm.cardImportId).then(cardImport => {
      vm.cardImport = cardImport;
      cardImportStateService.setName(cardImport.forename, cardImport.surname);
    }, notificationService.errorHandler(vm));

    if (vm.searchMode === 'similar') {
      cardImportService.loadSimilarPersons(vm.cardImportId).then(cardImport => {
        vm.foundPersons = cardImport.result;
      }, notificationService.errorHandler(vm));
    }
  }
}
