import angular from 'angular';
import htmlTemplate from './translations-page.html';

export default angular
  .module('swoa.admin.translations-page', [])
  .component('swoaTranslationsPage', {
    template: htmlTemplate,
    bindings: {
      currentTab: '<',
    },
    controllerAs: 'vm',
  }).name;
