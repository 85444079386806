import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.translations.labels.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('translations.labels', {
      url: '/labels',
      data: {
        selectedTab: 1,
        breadcrumb: {
          parent: 'translations',
          ignore: true
        },
        hasPermission: 'admin_translation_r'
      },
      views: {
        labels: {
          template: '<swoa-translations-label></swoa-translations-label>'
        }
      }
    })
    .state('translations.labels.translationsDetail', {
      url: '/labels/:translationsKey',
      views: {
        'detail@translations': {
          template:
            '<swoa-translations-label-detail></swoa-translations-label-detail>'
        }
      },
      data: {
        hasPermission: 'admin_translation_r'
      }
    });
}
