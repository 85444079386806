import angular from 'angular';
import swoaCardService from '../card/card.service';

export default angular
  .module('swoa.security.service', [
    swoaCardService,
  ])
  .factory('securityService', securityService)
  .name;

/** @ngInject */
function securityService(cardService, ngUserAuthInfoService) {
  return {
    extendCardModelWithEditAllowed,
    extendCardModelWithReleasable,
  };

  // //////////

  function extendCardModelWithEditAllowed(sportYear, card) {
    const isEditAllowed = ngUserAuthInfoService.userHasPermission(`card_w_${sportYear.status}_${sportYear.category}`);
    if (sportYear.status === 'REVISION_NEEDED') {
      card.canChangeType = isEditAllowed && (card.status === 'NEW' || card.status === 'UPDATED');
    } else if (sportYear.status === 'PROVISIONALLY_RELEASED') {
      card.canChangeType = isEditAllowed && card.status !== 'NEW';
    } else {
      card.canChangeType = (sportYear.status === 'NEW' || sportYear.status === 'FOR_CONSIDERATION') && isEditAllowed;
    }
  }

  function extendCardModelWithReleasable(sportYear, card) {
    if (cardService.isStaffCategory(card) && card.status === 'NEW' && card.active === false) {
      card.isReleasable = true;
    } else if (sportYear.status === 'FOR_CONSIDERATION' && card.status === 'NEW' && card.active === true) {
      card.isReleasable = true;
    } else if (sportYear.status === 'PROVISIONALLY_RELEASED' && card.status === 'UPDATED') {
      card.isReleasable = true;
    }
  }
}
