import angular from 'angular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.form-helper.service', [
    swoaConstants,
  ])
  .factory('formHelper', formHelper)
  .name;

/** @ngInject */
function formHelper() {
  return {
    createFormHelper,
  };

  // //////////

  function createFormHelper(vm) {
    return new FormHelper(vm);
  }

  function FormHelper(vm) {
    this.resetNotifications = resetNotifications;

    function resetNotifications() {
      vm.errors = [];
      vm.notificationKey = null;
      vm.notificationSuccess = null;
      vm.notificationKeyList = [];
    }

    this.startEdit = () => {
      vm.editing = true;
      vm.submitted = false;
      resetNotifications(vm);
    };

    this.cancelEdit = () => {
      vm.editing = false;
      vm.submitted = false;
      resetNotifications(vm);
      if (vm.initViewData) {
        vm.initViewData();
      }
    };

    this.showSuccess = (notificationKey) => {
      vm.editing = false;

      // show save success info
      vm.notificationSuccess = true;
      vm.notificationKey = notificationKey;
    };
  }
}
