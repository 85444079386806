import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaDialog from '../../components/dialog/dialog.module';
import htmlTemplate from './organisation-profile.html';
import swoaOrganisationService from '../organisation.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaTranslatable from '../../components/translatable/translatable.filter';

export default angular
  .module('swoa.organisation.profile.component', [
    swoaConstants,
    swoaDialog,
    swoaOrganisationService,
    swoaNotification,
    swoaTranslatable
  ])
  .component('swoaOrganisationProfile', {
    template: htmlTemplate,
    bindings: {},
    controller: OrganisationProfileController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function OrganisationProfileController(
  $scope,
  $stateParams,
  $translate,
  $mdDialog,
  lodash,
  dialogService,
  organisationService,
  notificationService,
  changeLogService,
  swoaTranslatableFilter
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.organisationHeadChiefs = [];
  vm.selectedTab = $stateParams.tabId || 0;

  // error handling
  vm.notificationKey = null;
  vm.notificationKeyList = [];
  vm.isInfo = false;

  vm.showOrganisationSports = showOrganisationSports;
  vm.showDeactivationDialog = showDeactivationDialog;
  vm.activateOrganisation = activateOrganisation;
  vm.showChangeLogDialog = showChangeLogDialog;
  vm.selectTab = selectTab;

  // only for testing
  vm._deactivate = deactivate;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.organisation', initOrganisationInfo, true);
    loadOrganisationInfo();
  }

  function initOrganisationInfo() {
    vm.notificationKeyList = [];
    initInactiveInfo();
    updateOrganisationDisplayInfo(vm.organisation);
  }

  function loadOrganisationInfo() {
    organisationService
      .get($stateParams.id)
      .then(organisation => {
        vm.organisation = organisation;
        updateOrganisationDisplayInfo(organisation);
      }, onError)
      .finally(() => (vm.loaded = true));
  }

  function updateOrganisationDisplayInfo(organisation) {
    if (organisation && vm.organisation) {
      vm.organisation.displayName = swoaTranslatableFilter(
        organisation.name,
        'de'
      );
      vm.organisation.categoryList = organisation.categories
        .map(category =>
          $translate.instant(`organisation.category.${category}`)
        )
        .join(', ');
      const hasSports = organisation.sports && organisation.sports.length > 0;
      vm.isNationalAssociationWithSports =
        lodash.includes(organisation.categories, 'NATIONAL_ASSOCIATION') &&
        hasSports;
      vm.organisation.typeList = buildOrganisationTypeList(
        organisation.categories
      );
    }
  }

  function initInactiveInfo() {
    if (vm.organisation && !vm.organisation.active) {
      vm.isInfo = true;
      const translatedKey = $translate.instant(
        'organisationProfile.organisationInactive'
      );
      vm.notificationKeyList.push({
        notificationKey: `${translatedKey}: ${vm.organisation.deactivationReason}`
      });
    }
  }

  function showOrganisationSports() {
    if (vm.organisation) {
      return lodash.includes(
        vm.organisation.categories,
        'NATIONAL_ASSOCIATION'
      );
    }
    return false;
  }

  function buildOrganisationTypeList(categories) {
    return organisationService
      .getOrganisationTypes(categories)
      .map(t => $translate.instant(`organisation.type.${t}`))
      .join(', ');
  }

  function showDeactivationDialog() {
    return organisationService.getUsers(vm.organisation).then(users => {
      vm.organisation.users = users;

      const uncloseable = true;
      const template = `<swoa-deactivation type="type" entry="entry" on-deactivate="onDeactivate" current-editor="currentEditor">
                        </swoa-deactivation>`;
      const title = $translate.instant('organisation.deactivate.title');
      const scope = {
        type: 'organisation',
        entry: vm.organisation,
        onDeactivate: deactivate
      };
      return dialogService.showDialog(template, uncloseable, title, scope);
    });
  }

  function activateOrganisation() {
    return organisationService
      .activate(vm.organisation)
      .then(onActivationSuccess, onError);
  }

  function deactivate(organisation) {
    return organisationService
      .deactivate(organisation)
      .then(onDeactivationSuccess, onError);
  }

  function onDeactivationSuccess() {
    $mdDialog.hide();
    dialogService.showSuccessToast('organisation.deactivate.done', {
      organisationName: vm.organisation.displayName
    });
    vm.organisation.active = false;
    loadOrganisationInfo();
  }

  function onActivationSuccess() {
    dialogService.showSuccessToast('organisation.activate.done', {
      organisationName: vm.organisation.displayName
    });
    vm.organisation.active = true;
    loadOrganisationInfo();
  }

  function showChangeLogDialog() {
    organisationService
      .getChangeLog(vm.organisation.id)
      .then(
        organisationChangeLog =>
          changeLogService.showDialog(organisationChangeLog, 'organisation'),
        onError
      );
  }

  function selectTab(tabIndex) {
    vm.selectedTab = tabIndex;
  }
}
