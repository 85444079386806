import angular from 'angular';
import 'restangular';
import swoaConstants from '../../app.constants';
import { BASE_AMOUNT_PERIOD_ROUTE } from '../nwf-contribution.service';

export default angular
  .module('swoa.nwf-contribution.base-amount.nwf-base-amount-period.service', [
    'restangular',
    swoaConstants,
  ])
  .factory('nwfBaseAmountPeriodService', nwfBaseAmountPeriodService)
  .name;

/** @ngInject */
function nwfBaseAmountPeriodService(moment, Restangular, $translate) {
  const service = Restangular.all(BASE_AMOUNT_PERIOD_ROUTE);

  return {
    getBreadcrumbTitle,
    loadPeriod,
    goToStatus,
    save,
    getCalendarYearPayments,
  };

  // //////////

  function loadPeriod(id) {
    return service.get(id);
  }

  function save(period) {
    return period.put();
  }

  function getBreadcrumbTitle(periodId) {
    return service.get(periodId).then((period) => {
      const translatedName = $translate.instant('nwfContributions.baseAmount');
      const fromYear = moment(period.validFrom).year();
      const untilYear = moment(period.validUntil).year();
      return `${translatedName} ${fromYear} - ${untilYear}`;
    });
  }

  function goToStatus(periodId, status) {
    return Restangular.one(BASE_AMOUNT_PERIOD_ROUTE, periodId).customPUT(status, 'status');
  }

  function getCalendarYearPayments(periodId) {
    return Restangular.one(BASE_AMOUNT_PERIOD_ROUTE, periodId).customGET('calendar-year-payments');
  }
}
