import angular from 'angular';
import swoaDialogService from './dialog.service';
import swoaDialogDialog from './dialog.component';

export default angular
  .module('swoa.dialog', [
    swoaDialogService,
    swoaDialogDialog,
  ])
  .name;

