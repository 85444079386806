import angular from 'angular';
import htmlTemplate from './person-timeline-entry-result.html';

export default angular
  .module('swoa.person.timeline.person-timeline-entry-result', [])
  .component('swoaPersonTimelineEntryResult', {
    template: htmlTemplate,
    bindings: {
      entry: '<',
      type: '<',
      card: '<',
    },
    controllerAs: 'vm',
  })
  .name;
