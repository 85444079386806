import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.cockpit.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('cockpit', {
    url: '/cockpit',
    template: '<swoa-cockpit-dashboard></swoa-cockpit-dashboard>',
    data: {
      breadcrumb: {
        ignore: true
      }
    }
  });
}
