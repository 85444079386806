import angular, { identity } from 'angular';
import swoaTranslatable from '../components/translatable/translatable.filter';
import swoaFormatNumber from '../components/format-number/format-number.filter';
import htmlTemplate from './nwf-contribution-associations.html';
import { APP_CONSTANTS } from '../app.constants';

export default angular
  .module('swoa.nwf-contribution-associations.component', [
    swoaTranslatable,
    swoaFormatNumber
  ])
  .component('swoaNwfContributionAssociations', {
    template: htmlTemplate,
    controller: NwfContributionAssociations,
    controllerAs: 'vm',
    bindings: {
      containers: '<',
      calendarYear: '<'
    }
  }).name;

/** @ngInject */
function NwfContributionAssociations(lodash) {
  const vm = this;

  vm.order = 'organisation.displayName';
  vm.calendarYear = vm.calendarYear || 2018;

  vm.getPayment1ForBaseAmount = getPayment1ForBaseAmount;
  vm.getPayment1ForVariableAmount = getPayment1ForVariableAmount;
  vm.getPayment2ForVariableAmount = getPayment2ForVariableAmount;
  vm.getVariableAmountTotal = getVariableAmountTotal;
  vm.getTotal = getTotal;

  const curriedGetPayment = lodash.curry(getPayment);
  const getBaseAmount1 = curriedGetPayment('payment1BaseAmount')('-');
  const getVariableAmount1 = curriedGetPayment('payment1VariableAmount');
  const getVariableAmount2 = curriedGetPayment('payment2VariableAmount');

  const formatPayment = periods => payment => {
    if (payment !== null && payment !== undefined) {
      return payment;
    } else if (hasFailedPayment(periods)) {
      return 'X';
    }
    return '-';
  };

  function getPayment1ForBaseAmount({ calendarYears }) {
    const formatter = formatPayment([calendarYears[0].baseAmountPeriod]);
    return getBaseAmount1(formatter)(calendarYears);
  }

  function getPayment1ForVariableAmount({ periods, calendarYears }) {
    const formatter = formatPayment(periods);
    return getVariableAmount1('')(formatter)(calendarYears);
  }

  function getPayment2ForVariableAmount({ periods, calendarYears }) {
    const formatter = formatPayment(periods);
    return getVariableAmount2('')(formatter)(calendarYears);
  }

  const getIntVariableAmount1 = getVariableAmount1(0)(identity);
  const getIntVariableAmount2 = getVariableAmount2(0)(identity);
  function getVariableAmountTotal({ calendarYears }) {
    return (
      getIntVariableAmount1(calendarYears) +
      getIntVariableAmount2(calendarYears)
    );
  }

  function getPayment(payment, defaultValue, formatter, calendarYears) {
    if (calendarYears && calendarYears[0]) {
      const paymentAmount = calendarYears[0][payment];
      return formatter(paymentAmount);
    }
    return defaultValue;
  }

  function hasFailedPayment(periods) {
    return (
      periods !== null &&
      lodash.some(periods, ['status', APP_CONSTANTS.nwf.PAYMENT_FAILED])
    );
  }

  function getTotal(container) {
    const payment1BaseAmount = getPayment1ForBaseAmount(container);
    const payment1VariableAmount = getPayment1ForVariableAmount(container);
    const payment2VariableAmount = getPayment2ForVariableAmount(container);

    if (
      payment1BaseAmount === '-' ||
      payment1VariableAmount === '-' ||
      payment2VariableAmount === '-'
    ) {
      return '-';
    }

    return lodash.sum(
      lodash.filter(
        [0, payment1BaseAmount, payment1VariableAmount, payment2VariableAmount],
        p => lodash.isNumber(p)
      )
    );
  }
}
