import angular from 'angular';
import htmlTemplate from './card-status.html';

export default angular
  .module('swoa.card.card-status', [])
  .component('swoaCardStatus', {
    template: htmlTemplate,
    bindings: {
      status: '<',
    },
    controllerAs: 'vm',
  })
  .name;
