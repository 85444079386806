import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

/**
 * Routes for translations of type dropdown and technical
 */
export default angular
  .module('swoa.admin.translations.translations.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('translations.dropdowns', {
      url: '/dropdowns',
      data: {
        selectedTab: 0,
        breadcrumb: {
          parent: 'translations',
          ignore: true
        },
        hasPermission: 'admin_translation_r'
      },
      views: {
        dropdowns: {
          template: '<swoa-translations-dropdown></swoa-translations-dropdown>'
        }
      }
    })
    .state('translations.dropdowns.dropdownsDetail', {
      url: '/:settingId',
      views: {
        'detail@translations': {
          template: '<swoa-translation-detail></swoa-translation-detail>'
        }
      },
      data: {
        hasPermission: 'admin_translation_r'
      }
    })
    .state('translations.technical', {
      url: '/technical',
      data: {
        selectedTab: 2,
        breadcrumb: {
          parent: 'translations',
          ignore: true
        },
        hasPermission: 'admin_translation_r'
      },
      views: {
        technical: {
          template:
            '<swoa-translations-technical></swoa-translations-technical>'
        }
      }
    })
    .state('translations.technical.detail', {
      url: '/:settingId',
      views: {
        'detail@translations': {
          template: '<swoa-translation-detail></swoa-translation-detail>'
        }
      },
      data: {
        hasPermission: 'admin_translation_r'
      }
    });
}
