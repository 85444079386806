import angular from 'angular';
import swoaConstants from '../app.constants';
import swoaLoginService from './login.service';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './login-password-reset.html';

export default angular
  .module('swoa.login.password-reset', [
    swoaConstants,
    swoaLoginService,
    swoaNotification
  ])
  .component('swoaLoginPasswordReset', {
    template: htmlTemplate,
    bindings: {},
    controller: PasswordResetController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PasswordResetController(
  $state,
  $stateParams,
  $translate,
  loginService,
  notificationService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.loginCode = $stateParams.loginCode;
  vm.resetPasswordContainer = null;
  vm.foundCode = null;
  vm.passwordStrength = null;
  vm.notificationKey = null;
  vm.userNameInputVisible = false;
  vm.userName = null;
  vm.user = {};

  vm.save = save;

  // expose for tests only
  vm._goToLogin = goToLogin;

  vm.$onInit = () => {
    activate();
  };

  // /////////

  function activate() {
    if ($stateParams.lang) {
      $translate.use($stateParams.lang);
    }
    vm.resetPasswordContainer = {
      code: vm.loginCode
    };
    loginService.findLoginCode(vm.loginCode).then(code => {
      vm.foundCode = code;
      if (isProfileActivation(vm.foundCode)) {
        vm.userNameInputVisible = true;
        vm.userName = `SODB-${code.person.swissOlympicId}`;
      }
    }, onError);
  }

  function fillUser() {
    vm.user.login = vm.userName;
    vm.user.person = vm.foundCode.person;
    vm.user.roles = [];
    vm.user.sports = [];
    vm.user.type = 'PERSON';
    vm.user.password = vm.resetPasswordContainer.password;
  }

  function save(valid) {
    if (!valid) {
      return;
    }
    if (isProfileActivation(vm.foundCode)) {
      fillUser();
      loginService.createUser(vm.user, vm.resetPasswordContainer).then(() => {
        goToLogin('user.account.activated');
      }, onError);
    }
    loginService.resetPassword(vm.resetPasswordContainer).then(() => {
      goToLogin('user.password.changed');
    }, onError);
  }

  function isProfileActivation(code) {
    return code.loginCodeType === 'PROFILE_ACTIVATION';
  }

  function goToLogin(key) {
    const successParameters = {
      notificationKey: key,
      isSuccess: true
    };
    $state.go('login', successParameters);
  }
}
