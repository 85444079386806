import angular from 'angular';
import swoaCardService from './card.service';
import htmlTemplate from './card-results.html';

export default angular
  .module('swoa.card.card-results', [swoaCardService])
  .component('swoaCardResults', {
    template: htmlTemplate,
    bindings: {
      cardId: '<',
      canEditResults: '<'
    },
    controller: CardResultsController,
    controllerAs: 'vm',
    bindToController: true
  }).name;

/** @ngInject */
function CardResultsController($rootScope, $scope, $q, cardService) {
  const vm = this;

  vm.selectedHistoryEntry = null;
  vm.selectedCardResult = null;
  vm.isElite = null;
  vm.gotResult = null;

  vm.updateCardResult = updateCardResult;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function updateCardResult() {
    return cardService
      .updateCardResult(vm.cardId, vm.selectedCardResult)
      .then(cardResult => {
        vm.selectedCardResult = cardResult;
        vm.gotResult = true;
        $rootScope.$broadcast('swoa:cardResultUpdated', vm.cardId, cardResult);
        return cardResult;
      }, onError);
  }

  function onError(reason) {
    vm.errors = reason.data && reason.data.errors;
    return $q.reject(reason);
  }

  function onHistoryEntryChange(event, historyEntry) {
    vm.selectedHistoryEntry = historyEntry;
    checkElite();
    vm.sportYearId = vm.selectedHistoryEntry.sportYear.id;
    vm.cardId = vm.selectedHistoryEntry.card.id;
  }

  function checkElite() {
    if (vm.selectedHistoryEntry.sportYear.category === 'ELITE') {
      vm.selectedCardResult =
        vm.selectedHistoryEntry.card &&
        vm.selectedHistoryEntry.card.eliteResult;
      if (vm.selectedHistoryEntry.card.eliteResult) {
        vm.gotResult = true;
      }
      vm.isElite = true;
    } else {
      vm.selectedCardResult = null;
      vm.isElite = false;
    }
  }

  function activate() {
    cardService.loadCardHistory(vm.cardId).then(history => {
      vm.selectedHistoryEntry =
        history && cardService.findCard(history, vm.cardId);
      checkElite();
    });

    $scope.$on('swoa:historyEntryChanged', onHistoryEntryChange);
  }
}
