import angular from 'angular';
import swoaPersonService from './person.service';
import swoaPersonPersonCompact from './person-compact.component';
import swoaPersonPersonCreate from './person-create.component';
import swoaPersonPersonMinimal from './person-minimal.component';
import swoaPersonPersonSettings from './person-settings.component';
import swoaPersonForeignid from './person-foreignid.component';
import swoaPersonCreation from './creation/person-creation.module';
import swoaPersonData from './data/person-data.module';
import swoaPersonMutation from './mutation/person-mutation.module';
import swoaPersonProfile from './profile/person-profile.module';
import swoaPersonSearch from './search/person-search.module';
import swoaPersonSync from './sync/person-sync.module';

export default angular
  .module('swoa.person', [
    swoaPersonService,
    swoaPersonPersonCompact,
    swoaPersonPersonCreate,
    swoaPersonPersonMinimal,
    swoaPersonPersonSettings,
    swoaPersonForeignid,
    swoaPersonCreation,
    swoaPersonData,
    swoaPersonMutation,
    swoaPersonProfile,
    swoaPersonSearch,
    swoaPersonSync,
  ])
  .name;

