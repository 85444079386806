import angular from 'angular';
import 'restangular';
import { CALENDAR_YEAR_ROUTE } from '../nwf-contribution.service';

export default angular
  .module('swoa.nwf-contributions.controlling.service', ['restangular'])
  .factory('nwfControllingService', nwfControllingService).name;

/** @ngInject */
function nwfControllingService(Restangular, lodash) {
  const calendarYearService = Restangular.all(CALENDAR_YEAR_ROUTE);

  return {
    loadCalendarYear,
    updateControllingEntries,
    sendControllingEntries,
    editControllingEntries,
    sortControllingEntries
  };

  // //////////

  function loadCalendarYear(id) {
    return calendarYearService.get(id);
  }

  function updateControllingEntries(id, entries) {
    return Restangular.one(CALENDAR_YEAR_ROUTE, id).customPOST(
      entries,
      'controlling-entries'
    );
  }

  function sendControllingEntries(id) {
    return Restangular.one(CALENDAR_YEAR_ROUTE, id).customPOST(
      {},
      'controlling-send'
    );
  }

  function editControllingEntries(id) {
    return Restangular.one(CALENDAR_YEAR_ROUTE, id).customPOST(
      {},
      'controlling-edit'
    );
  }

  function sortControllingEntries(entries) {
    return lodash.sortBy(entries, 'sport.name', 'institution.name');
  }
}
