import angular from 'angular';
import swoaReportService from './report.service';
import htmlTemplate from './report-page.html';

export default angular
  .module('swoa.admin.report.report-page', [
    swoaReportService,
  ])
  .component('swoaReportPage', {
    template: htmlTemplate,
    bindings: {
      currentTab: '<',
    },
    controllerAs: 'vm',
  })
  .name;
