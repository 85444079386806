import angular from 'angular';

export default angular
  .module('swoa.format-number', [])
  .filter('swoaFormatNumber', swoaFormatNumber)
  .name;

function formatNumber(str, numDecimalPlaces = 0) {
  if (str === 0) {
    return '0';
  }
  if (!str) {
    return undefined;
  }

  const number = parseFloat(str);
  if (isNaN(number)) {
    return str;
  }
  return formatThousandsNoRounding(number, numDecimalPlaces);
}

/* eslint-disable */
function formatThousandsNoRounding(n, dp) {
  var e = '', s = e + n, l = s.length, b = n < 0 ? 1 : 0,
    i = s.lastIndexOf('.'), j = i == -1 ? l : i,
    r = e, d = s.substr(j + 1, dp);
  while ((j -= 3) > b) {
    r = '\'' + s.substr(j, 3) + r;
  }
  return s.substr(0, j + 3) + r +
    (dp ? '.' + d + (d.length < dp ?
      ('00000').substr(0, dp - d.length) : e) : e);
}

/* eslint-enable */

/** @ngInject */
function swoaFormatNumber() {
  return formatNumber;
}
