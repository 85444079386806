import angular from 'angular';
import swoaConstants from '../../app.constants';
import htmlTemplate from './exports-page.html';

export default angular
  .module('swoa.admin.report.exports-page', [swoaConstants])
  .component('swoaExportsPage', {
    template: htmlTemplate,
    controllerAs: 'vm',
    controller: ExportPageController,
    bindings: {}
  }).name;

/** @ngInject */
function ExportPageController($rootScope, $scope, $state, lodash) {
  const vm = this;
  vm.selected = undefined;
  const options = [
    { type: 'cards', requiredPermission: 'admin_exports_cards_r' },
    { type: 'sports', requiredPermission: 'admin_exports_sports_r' },
    { type: 'users', requiredPermission: 'admin_exports_users_r' },
    {
      type: 'nwfBaseAmount',
      requiredPermission: 'admin_exports_nwf_base_amount_r',
      hint: 'export.nwfContribution.info'
    },
    {
      type: 'nwfVariableAmount',
      requiredPermission: 'admin_exports_nwf_variable_amount_r',
      hint: 'export.nwfContribution.info'
    },
    {
      type: 'nwfTrainerCurrent',
      requiredPermission: 'admin_exports_nwf_trainer_current_r',
      hint: 'export.nwfContribution.info'
    },
    {
      type: 'nwfTrainerPercentage',
      requiredPermission: 'admin_exports_nwf_trainer_r',
      hint: 'export.nwfContribution.info'
    }
  ];

  vm.$onInit = () => {
    activate();
  };

  // //////

  function activate() {
    if ($state.$current.data.exportType) {
      vm.exportType = $state.$current.data.exportType;
    } else {
      // find first export type that the user has permissions for
      for (let i = 0; i < options.length; i++) {
        if ($rootScope.uaPermission(options[i].requiredPermission)) {
          vm.exportType = options[i].type;
          break;
        }
      }
    }
    vm.options = lodash.map(
      lodash.filter(options, option =>
        $rootScope.uaPermission(option.requiredPermission)
      ),
      option => option.type
    );
    vm.showSaveFavoriteButton = false;
    $scope.$watch('vm.exportType', changeExportType);
  }

  function changeExportType() {
    vm.selected = lodash.find(options, ['type', vm.exportType]);
    $state.go(`report.exports.${vm.exportType}`);
  }
}
