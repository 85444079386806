import angular from 'angular';
import swoaGetService from '../components/get/get.service';
import swoaDialog from '../components/dialog/dialog.module';
import swoaPersonService from './person.service';
import swoaPersonPersonMinimal from './person-minimal.component';
import htmlTemplate from './person-settings.html';

export default angular
  .module('swoa.person.person-settings', [
    swoaGetService,
    swoaDialog,
    swoaPersonService,
    swoaPersonPersonMinimal
  ])
  .component('swoaPersonSettings', {
    template: htmlTemplate,
    bindings: {
      entry: '<',
      isProfile: '<'
    },
    controller: PersonSettingsController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonSettingsController(
  $transitions,
  $scope,
  get,
  personService,
  dialogService
) {
  const vm = this;
  const defaultImageUrl = '/assets/images/user_avatar_default.svg';

  vm.avatar = get.withDefault(vm, 'entry.imageUrl', defaultImageUrl);

  vm.showChangePasswordDialog = showChangePasswordDialog;
  vm.imageStyle = imageStyle;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    // hide dialog when user is navigating
    const unregisterFn = $transitions.onSuccess({}, dialogService.hideDialog);
    $scope.$on('$destroy', unregisterFn);

    if (!get(vm, 'entry.imageUrl') && get(vm, 'entry.id')) {
      personService
        .loadAvatar(vm.entry.id)
        .then(image => {
          vm.avatar = URL.createObjectURL(image.data);
          vm.avatarCrop = image.metadata;
        })
        .catch(angular.noop);
    }
  }

  function showChangePasswordDialog() {
    dialogService.showDialog(
      '<swoa-change-password></swoa-change-password>',
      true
    );
  }

  function imageStyle(imgUrl, cropObject) {
    const style = {
      'background-image': `url(${imgUrl})`
    };

    if (cropObject) {
      style[
        'background-position'
      ] = `${cropObject.positionX}% ${cropObject.positionY}%`;
      style['background-size'] = `${cropObject.sizeX}% ${cropObject.sizeY}%`;
    }

    return style;
  }
}
