import angular from 'angular';
import swoaCardRoutes from './card.routes';
import swoaCardCardImport from './card-import/card-import.module';
import swoaCardCardYear from './card-year/card-year.module';
import swoaCardSportYear from './sport-year/sport-year.module';
import swoaCardService from './card.service';
import swoaCardCardDetail from './card-detail.component';
import swoaCardCardDetailTechnician from './card-detail-technician.component';
import swoaCardCardDetailFunctionary from './card-detail-functionary.component';
import swoaCardCardComments from './card-comments.component';
import swoaCardCardResults from './card-results.component';
import swoaCardCard from './card.component';
import swoaCardCardStatus from './card-status.component';
import swoaCardCardsSummary from './cards-summary.component';
import swoaCardCardTechnicianResponsibilities from './card-technician-responsibilities.component';
import swoaSportList from './sport-list.component';

export default angular.module('swoa.card', [
  swoaCardRoutes,
  swoaCardCardImport,
  swoaCardCardYear,
  swoaCardSportYear,
  swoaCardService,
  swoaCardCardDetail,
  swoaCardCardDetailTechnician,
  swoaCardCardDetailFunctionary,
  swoaCardCardComments,
  swoaCardCardResults,
  swoaCardCard,
  swoaCardCardStatus,
  swoaCardCardsSummary,
  swoaCardCardTechnicianResponsibilities,
  swoaSportList,
]).name;
