import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaNotification from '../../components/notification/notification.module';
import swoaCardCardImportService from './card-import.service';
import swoaCardSportYearService from '../sport-year/sport-year.service';
import swoaCardCardImportCardImportState from './card-import-state.service';
import htmlTemplate from './card-import-create-person.html';

export default angular
  .module('swoa.card.card-import.card-import-create-person', [
    uiRouter,
    swoaNotification,
    swoaCardCardImportService,
    swoaCardSportYearService,
    swoaCardCardImportCardImportState
  ])
  .component('swoaCardImportCreatePerson', {
    template: htmlTemplate,
    bindings: {},
    controller: CardImportCreatePersonController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardImportCreatePersonController(
  $stateParams,
  $state,
  notificationService,
  cardImportService,
  sportYearService,
  cardImportStateService
) {
  const vm = this;

  // state params
  vm.yearId = $stateParams.yearId;
  vm.cardImportId = $stateParams.cardImportId;

  // loaded data
  vm.cardImport = null;

  // required by swoa-person-create
  vm.requiredProperties = null;

  // functions
  vm.createPerson = createPerson;
  vm.cancelAction = cancelAction;

  // error handling
  vm.notificationKey = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    cardImportStateService.setName();
    cardImportService.loadCardImport(vm.cardImportId).then(cardImport => {
      vm.cardImport = cardImport;
    }, notificationService.errorHandler(vm));

    sportYearService
      .getAdditionalRequiredProperties(vm.yearId)
      .then(requiredProperties => {
        vm.requiredProperties = requiredProperties;
      }, notificationService.errorHandler(vm));
  }

  function createPerson(person) {
    cardImportService.createPerson(vm.cardImportId, person).then(() => {
      $state.go('cardImport.overview', $stateParams);
    }, notificationService.errorHandler(vm));
  }

  function cancelAction() {
    $state.go('cardImport.overview', $stateParams);
  }
}
