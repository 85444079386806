import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import appForbiddenHtml from './forbidden.html';
import appErrorHtml from './error.html';

export default angular.module('swoa.routes', [uiRouter]).config(routeConfig)
  .name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    // Sometimes, for yet unknown reasons, we end up on /.
    .state('index', {
      url: '/',
      controller($state) {
        $state.go('cockpit');
      },
      controllerAs: 'vm'
    })
    .state('forbidden', {
      url: '/forbidden',
      template: appForbiddenHtml,
      data: {
        anonymousAccessAllowed: true,
        breadcrumb: true
      }
    })
    .state('error', {
      url: '/error',
      template: appErrorHtml,
      data: {
        anonymousAccessAllowed: true,
        breadcrumb: true
      }
    });
}
