import angular from 'angular';
import swoaDate from '../date/date.filter';
import swoaCommentCommentForm from './comment-form.component';
import htmlTemplate from './comments-timeline.html';

export default angular
  .module('swoa.comment.comments-timeline', [
    swoaDate,
    swoaCommentCommentForm,
  ])
  .component('swoaCommentsTimeline', {
    template: htmlTemplate,
    bindings: {
      comments: '=',
      canAddComments: '<',
      canEditComments: '<',
      canEditOwnComments: '<',
      addCommentAction: '&',
      deleteCommentAction: '&',
    },
    controllerAs: 'vm',
  })
  .name;
