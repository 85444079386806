import angular from 'angular';

export default angular
  .module('swoa.card.sport-year-digital-card-state-indicator', [])
  .component('swoaDigitalCardStateIndicator', {
    template: `
      <span translate="digitalCard.state.{{vm.getDigitalCardState(vm.digitalCard)}}"></span>
    `,
    bindings: {
      digitalCard: '<'
    },
    controller: DigitalCardStateController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function DigitalCardStateController() {
  const vm = this;

  vm.getDigitalCardState = digitalCardState =>
    digitalCardState?.state ?? 'NONE';
}
