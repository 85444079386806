import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.logout.component', [uiRouter])
  .component('swoaLogout', {
    bindings: {},
    controller: LogoutController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function LogoutController($state, ngUserAuthService) {
  activate();

  // //////////

  function activate() {
    ngUserAuthService.logout().finally(() => {
      $state.go('login');
    });
  }
}
