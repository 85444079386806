import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './organisation-extended-search.html';

export default angular
  .module('swoa.organisation.organisation-extended-search', [
    swoaConstants,
    swoaNotification
  ])
  .component('swoaOrganisationExtendedSearch', {
    template: htmlTemplate,
    bindings: {
      selectButtonTextKey: '@',
      selectAction: '&',
      cancelAction: '&',
      withoutSelection: '<'
    },
    controller: OrganisationExtendedSearchController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function OrganisationExtendedSearchController(
  $scope,
  lodash,
  Constants,
  notificationService,
  organisationService
) {
  const vm = this;

  // loaded data
  vm.filteredOrganisations = null;
  vm.availableOrganisationTypes = Constants.organisationTypes.map(
    elem => elem.type
  );
  vm.availableOrganisationCategories = [];
  vm.hintKey = '';

  // selections
  vm.selectedOrganisation = null;
  vm.query = null;

  // error handling
  vm.notificationKey = null;

  // functions
  vm.resetQuery = resetQuery;
  vm.search = search;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    resetQuery();

    $scope.$watch('vm.query.type', updateOrganisationCategory);
  }

  function resetQuery() {
    vm.query = {
      type: null,
      categories: []
    };
  }

  function updateOrganisationCategory() {
    vm.availableOrganisationCategories = [];
    vm.query.categories = [];
    Constants.organisationTypes
      .filter(
        elem => lodash.isNil(vm.query.type) || elem.type === vm.query.type
      )
      .forEach(type =>
        vm.availableOrganisationCategories.push(...type.categories)
      );
  }

  function search() {
    const filterBy = [];
    vm.hintKey = 'organisationSearch.searching';
    organisationService.getList().then(organisations => {
      if (lodash.isNil(vm.query.type) && vm.query.categories.length === 0) {
        Constants.organisationTypes.forEach(type =>
          filterBy.push(...type.categories)
        );
      } else if (vm.query.categories.length === 0) {
        Constants.organisationTypes
          .filter(elem => elem.type === vm.query.type)
          .forEach(type => filterBy.push(...type.categories));
      } else {
        filterBy.push(...vm.query.categories);
      }

      vm.filteredOrganisations = lodash.filter(organisations, org =>
        lodash.some(org.categories, cat => lodash.includes(filterBy, cat))
      );
      vm.hintKey = 'organisationSearch.found';
    }, notificationService.errorHandler(vm));
  }
}
