import angular from 'angular';
import swoaGetService from '../../components/get/get.service';
import swoaPersonService from '../person.service';
import swoaPersonProfilePersonProfileHelperService from './person-profile-helper.service';
import swoaDialogService from '../../components/dialog/dialog.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './person-profile-sport-entry.html';

export default angular
  .module('swoa.person.profile.person-profile-sport.entry', [
    swoaGetService,
    swoaPersonService,
    swoaPersonProfilePersonProfileHelperService,
    swoaDialogService,
    swoaNotification
  ])
  .component('swoaPersonProfileSportEntry', {
    template: htmlTemplate,
    bindings: {
      person: '<',
      personSportData: '<',
      isEditPossible: '<',
      currentEditor: '=',
      onCancel: '&'
    },
    controller: PersonProfileSportEntry,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonProfileSportEntry(
  $rootScope,
  $scope,
  $translate,
  get,
  personService,
  personProfileHelper,
  notificationService,
  dialogService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm, true),
    helper = personProfileHelper.createFormHelper(vm);

  vm.submitted = false;
  vm.notificationKey = null;
  vm.notificationKeyList = [];

  vm.eliteSportDataConfig = [];
  vm.showEliteSportData = false;

  const CONFIGS = [
    {
      personKey: 'shortTermPotential',
      dataType: 'translatable',
      translatableType: 'short_term_potential',
      permissionPart: 'data_potential'
    },
    {
      personKey: 'mediumTermPotential',
      dataType: 'translatable',
      translatableType: 'medium_term_potential',
      permissionPart: 'data_potential'
    },
    {
      personKey: 'longTermPotential',
      dataType: 'translatable',
      translatableType: 'long_term_potential',
      permissionPart: 'data_potential'
    },
    {
      personKey: 'cadre',
      permissionPart: 'data_cadre'
    }
  ];

  vm.onSubmit = save;
  vm.initViewData = initViewData;
  vm.cancelEdit = cancelEdit;
  vm.startEdit = startEdit;
  vm.resetAdditionalData = resetAdditionalData;

  vm.$onInit = () => {
    activate();
  };

  // //////

  function activate() {
    $scope.$watch('vm.currentEditor', updateEditingStatus);
    initViewData();
  }

  function updateEditingStatus() {
    if (
      vm.personSportData.editing &&
      vm.currentEditor &&
      vm.currentEditor !== 'sportdata'
    ) {
      cancelEdit();
    }
  }

  function initViewData() {
    prepareEliteSportData();

    CONFIGS.forEach(config => {
      if ($rootScope.personPermission(vm.person, config.permissionPart, 'r')) {
        config.isReadOnly = !$rootScope.personPermission(
          vm.person,
          config.permissionPart,
          'w'
        );
        vm.eliteSportDataConfig.push(config);
      }
    });
    vm.showEliteSportData = vm.eliteSportDataConfig.length > 0;
  }

  function save(isValid) {
    vm.submitted = true;
    if (!isValid) {
      return null;
    }
    vm.notificationKeyList = [];
    const data = {
      eliteSportData: [vm.eliteSportData]
    };
    if (!hasAllActiveAdditionalData()) {
      return null;
    }
    vm.notificationKeyList = null;

    return personService.updateAdditionalData(vm.person.id, data).then(() => {
      helper.processSaved();
    }, onError);
  }

  function hasAllActiveAdditionalData() {
    if (vm.eliteSportData) {
      if (
        vm.eliteSportData.longTermPotential &&
        vm.eliteSportData.longTermPotential.active === false
      ) {
        initNotificationValues(vm.eliteSportData.longTermPotential.type);
      }
      if (
        vm.eliteSportData.shortTermPotential &&
        vm.eliteSportData.shortTermPotential.active === false
      ) {
        initNotificationValues(vm.eliteSportData.shortTermPotential.type);
      }
      if (
        vm.eliteSportData.mediumTermPotential &&
        vm.eliteSportData.mediumTermPotential.active === false
      ) {
        initNotificationValues(vm.eliteSportData.mediumTermPotential.type);
      }
    }
    return vm.notificationKeyList.length === 0;
  }

  function initNotificationValues(type) {
    const translatedKey = $translate.instant(`applicationParameter.${type}`);
    const translatedValue = $translate.instant(
      'applicationParameter.deactivate.reason'
    );
    vm.notificationKeyList.push({
      notificationKey: `${translatedKey} ${translatedValue}`
    });
  }

  function resetNotifications() {
    vm.notificationKey = null;
    vm.notificationKeyList = [];
  }

  function startEdit() {
    vm.currentEditor = 'sportdata';
    vm.personSportData.editing = true;
    resetNotifications();
  }

  function prepareEliteSportData() {
    vm.eliteSportData = angular.copy(
      get(vm, 'personSportData.eliteSportData') || {
        sport: vm.personSportData.sport
      }
    );

    // don't send to server
    delete vm.eliteSportData.editing;
  }

  function cancelEdit(resetCurrentEditor) {
    if (resetCurrentEditor) {
      vm.currentEditor = null;
    }
    prepareEliteSportData();

    vm.showEliteSportData = vm.eliteSportDataConfig.length > 0;
    vm.personSportData.editing = false;
    vm.onCancel();
    resetNotifications();
  }

  function resetAdditionalData() {
    dialogService
      .showConfirmationDialog(
        'personProfileAdditional.sport.delete.confirmation'
      )
      .then(() =>
        personService
          .removeAdditionalSportData(vm.person.id, vm.eliteSportData.sport.id)
          .then(() => helper.processSaved(), onError)
      );
  }
}
