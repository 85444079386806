import angular from 'angular';
import { saveAs as saveAsModule } from 'file-saver';
import ngFileUpload from 'ng-file-upload';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.file.service', [
    swoaConstants,
    ngFileUpload,
  ])
  .constant('saveAs', saveAsModule)
  .factory('fileService', fileService)
  .name;

/** @ngInject */
function fileService($http, Upload, ConfigConstants, saveAs) {
  return {
    deleteFile,
    getDownloadUrl,
    download,
    downloadPost,
    downloadFileStorage,
    downloadPersistentStorage,
    load,
    loadFileStorage,
    upload,
  };

  // //////////

  function upload(url, file, params) {
    return Upload.http({
      url,
      headers: file.type ? { 'Content-Type': file.type } : {},
      params,
      data: file,
    });
  }

  function load(fileUrl) {
    return $http.get(fileUrl, { responseType: 'arraybuffer' }).then((response) => {
      const blob = getDataAsBlob(response);
      let metadata = null;
      const metadataStr = response.headers('x-json-metadata');
      if (metadataStr && metadataStr.length > 0) {
        metadata = JSON.parse(metadataStr);
      }
      return {
        data: blob,
        contentType: response.headers('content-type'),
        metadata,
      };
    });
  }

  function download(fileUrl, fileName, params) {
    return doDownload({
      method: 'GET',
      url: fileUrl,
      responseType: 'arraybuffer',
      params,
    }, fileName);
  }

  function downloadPost(fileUrl, payload, fileName) {
    return doDownload({
      method: 'POST',
      url: fileUrl,
      data: payload,
      responseType: 'arraybuffer',
    }, fileName);
  }

  function doDownload(options, fileName) {
    return $http(options).then((response) => {
      const blob = getDataAsBlob(response);
      saveAs(blob, fileName);
    });
  }

  function deleteFile(fileUrl) {
    return $http.delete(fileUrl);
  }

  function getDataAsBlob(response) {
    const data = new Uint8Array(response.data);
    return new Blob([data], { type: response.headers('content-type') });
  }

  function getDownloadUrl(fileStorage) {
    return `${ConfigConstants.api.url}/file-storages/${
      fileStorage.id}/file?downloadId=${fileStorage.downloadId}`;
  }

  function getPersistentStorageDownloadUrl(template) {
    return `${ConfigConstants.api.url}/templates/${
      template.category}/download?fileName=${template.fileName}`;
  }

  function downloadFileStorage(fileStorage) {
    return download(getDownloadUrl(fileStorage), fileStorage.fileName);
  }

  function downloadPersistentStorage(template) {
    return download(getPersistentStorageDownloadUrl(template), template.fileName);
  }

  function loadFileStorage(fileStorage) {
    return load(
      getDownloadUrl(fileStorage),
      fileStorage.fileName,
    );
  }
}
