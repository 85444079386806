import angular from 'angular';
import swoaLoginRoutes from './login.routes';
import swoaLogin from './login.component';
import swoaLoginPasswordForgot from './login-password-forgot.component';
import swoaLoginPasswordReset from './login-password-reset.component';
import swoaLoginService from './login.service';

export default angular
  .module('swoa.login', [
    swoaLoginRoutes,
    swoaLogin,
    swoaLoginPasswordForgot,
    swoaLoginPasswordReset,
    swoaLoginService,
  ])
  .name;

