import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import 'restangular';
import swoaConstants from '../app.constants';
import swoaNotification from '../components/notification/notification.module';
import swoaUserService from './user.service';
import swoaTranslatable from '../components/translatable/translatable.filter';
import htmlTemplate from './user-list.html';
import urlHelper from '../components/url-helper/url-helper';

export default angular
  .module('swoa.user.user-list', [
    uiRouter,
    'restangular',
    swoaConstants,
    swoaNotification,
    swoaUserService,
    swoaTranslatable,
  ])
  .component('swoaUserList', {
    template: htmlTemplate,
    bindings: {},
    controller: UserListController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function UserListController(
  $scope,
  lodash,
  $translate,
  $stateParams,
  $state,
  Restangular,
  Constants,
  notificationService,
  userService,
  changeLogService,
  swoaTranslatableFilter,
  $window
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  let userAdminUrl = new URL($window.location);
  userAdminUrl.pathname = '/admin/user-administration';
  vm.$onInit = () => {
    vm.language = $translate.use() || $translate.proposedLanguage();
    if ($stateParams && $stateParams.personId) {
      userAdminUrl.pathname = userAdminUrl.pathname + '/users/new';
      userAdminUrl.searchParams.append('personId', $stateParams.personId);
    }
    if ($stateParams && $stateParams.userId) {
      userAdminUrl.pathname += `/users/${$stateParams.userId}`;
    }
    userAdminUrl.searchParams.append('lang', vm.language);

    vm.userAdminPath = userAdminUrl.pathname + userAdminUrl.search;
  };

  vm.$onDestroy = () => {
    $window.removeEventListener('message', handleIncomingMessage);
  };

  $window.addEventListener('message', handleIncomingMessage);

  function handleIncomingMessage({ data }) {
    switch (data.type) {
      case 'open-changelog': {
        userService
          .getChangeLog(data.payload)
          .then(
            (userChangeLog) =>
              changeLogService.showDialog(userChangeLog, 'user'),
            onError
          );
        break;
      }

      case 'url-changed': {
        if (!data.payload) return;
        if (data.payload.pathname.startsWith('/token-signin')) return;

        let url = new URL($window.location);
        url.pathname = data.payload.pathname.startsWith('/users')
          ? data.payload.pathname
          : '/users' + data.payload.pathname;
        url = urlHelper.sanitize(url, data);

        $state.go('userDetail');
        window.history.pushState({}, '', url.toString());
        break;
      }
    }
  }
}
