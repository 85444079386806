import angular from 'angular';
import swoaConstants from '../app.constants';
import swoaAhvAhvNumberValidator from '../components/ahv/ahv-number-validator.service';

export default angular
  .module('swoa.person.foreignid', [swoaConstants, swoaAhvAhvNumberValidator])
  .component('swoaPersonForeignid', {
    template: '{{vm.displayValue}}',
    bindings: {
      entry: '<',
      type: '@'
    },
    controller: PersonForeignidController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function PersonForeignidController($scope, lodash, ahvNumberValidator) {
  const vm = this;

  vm.displayValue = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.entry.foreignIds', updateValue, true);
  }

  function updateValue() {
    let foreignId = null;
    if (vm.entry !== null && vm.entry.foreignIds !== null) {
      foreignId = lodash.find(vm.entry.foreignIds, { idType: vm.type });
    }
    vm.displayValue = foreignId === null ? null : foreignId.idValue;
    if (vm.displayValue && vm.type === 'AHV_NUMBER') {
      vm.displayValue = ahvNumberValidator.getFormattedAHVNumber(
        ahvNumberValidator.getUnformattedAHVNumber(vm.displayValue)
      );
    }
  }
}
