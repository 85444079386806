import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.admin.sport-year-setting.service', [
    'restangular',
  ])
  .factory('sportYearSettingService', sportYearSettingService)
  .name;

/** @ngInject */
function sportYearSettingService(Restangular) {
  const SETTINGS_ROUTE = Restangular.all('sportyearsettings');

  return {
    loadSportYearSettings,
    loadSportYearSetting,
    loadSportYearSettingBySportId,
    loadSportYearSettingByCategory,
    saveSportYearSetting,
    getChangeLog,
  };

  // ////////

  function loadSportYearSettings() {
    return SETTINGS_ROUTE.getList();
  }

  function loadSportYearSetting(settingId) {
    return SETTINGS_ROUTE.get(settingId);
  }

  function saveSportYearSetting(sportYearSetting) {
    return Restangular.one('sportyearsettings').customPOST(sportYearSetting, 'save-sportyear-settings');
  }

  function loadSportYearSettingBySportId(sportId) {
    return SETTINGS_ROUTE.customGET('by-sport', { sportId });
  }

  function loadSportYearSettingByCategory(category) {
    return SETTINGS_ROUTE.customGET('by-category', { category });
  }

  function getChangeLog(settingId) {
    return Restangular.one('sportyearsettings', settingId).getList('changelog');
  }
}
