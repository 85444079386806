import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaPersonService from '../person/person.service';
import swoaNotification from '../components/notification/notification.module';
import htmlTemplate from './user-create-person.html';

export default angular
  .module('swoa.user.user-create-person', [
    uiRouter,
    swoaPersonService,
    swoaNotification,
  ])
  .component('swoaUserCreatePerson', {
    template: htmlTemplate,
    bindings: {},
    controller: UserCreatePersonController,
    controllerAs: 'vm',
  }).name;

/** @ngInject */
function UserCreatePersonController(
  $stateParams,
  $state,
  personService,
  notificationService
) {
  const vm = this;
  const onError = notificationService.errorHandler(vm);

  // functions
  vm.createPerson = createPerson;
  vm.cancelAction = cancelAction;

  // state params
  vm.requiredProperties = [
    { key: 'email', requiredValue: true },
    {
      key: 'phoneNumber',
      requiredValue:
        '!($parent.$parent.$parent.vm.editPerson.mobileNumber.length)',
    },
    {
      key: 'mobileNumber',
      requiredValue:
        '!($parent.$parent.$parent.vm.editPerson.phoneNumber.length)',
      overrideAsterisk: true,
    },
  ];

  // error handling
  vm.notificationKey = null;

  // //////////

  function createPerson(person) {
    personService.createPerson(person).then((createdPerson) => {
      $state.go('createUserForPerson', { personId: createdPerson.id });
    }, onError);
  }

  function cancelAction() {
    $state.go('userSelectPerson', $stateParams);
  }
}
