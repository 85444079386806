import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.organisation.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('organisationCreation', {
      url: '/organisations/create',
      template: '<swoa-organisation-create></swoa-organisation-create>',
      data: {
        breadcrumb: {
          parent: 'organisationSearch.query'
        },
        hasPermission: 'admin_organisation_w'
      }
    })
    .state('organisationProfile', {
      url: '/organisations/profile/:id?tabId',
      template: '<swoa-organisation-profile></swoa-organisation-profile>',
      reloadOnSearch: false,
      data: {
        hasPermission: 'admin_organisation_r',
        minimalToolbar: true
      },
      params: {
        tabId: {
          value: null,
          squash: true
        }
      }
    })
    .state('organisationProfile.nwfInstitutionDetail', {
      url: '/:institutionId',
      views: {
        'detail@organisationProfile': {
          template:
            '<swoa-nwf-institution-detail></swoa-nwf-institution-detail>'
        }
      },
      data: {
        breadcrumb: {
          parent: 'organisationProfile',
          ignore: true
        },
        hasPermission: 'admin_organisation_r'
      }
    });
}
