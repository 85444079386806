import angular from 'angular';
import swoaCardRoutes from './card-import.routes';
import swoaCardCardImportService from './card-import.service';
import swoaCardCardImportCardImportState from './card-import-state.service';
import swoaCardCardImportCardImport from './card-import.component';
import swoaCardCardImportCardImportFileUpload from './card-import-file-upload.component';
import swoaCardCardImportCardImportOverview from './card-import-overview.component';
import swoaCardCardImportCardImportSync from './card-import-sync.component';
import swoaCardCardImportCardImportAssign from './card-import-assign.component';
import swoaCardCardImportCardImportCardConfirm from './card-import-card-confirm.component';
import swoaCardCardImportCardImportCreatePerson from './card-import-create-person.component';
import swoaCardCardImportCardImportStatus from './card-import-status.component';

export default angular
  .module('swoa.card.card-import', [
    swoaCardRoutes,
    swoaCardCardImportService,
    swoaCardCardImportCardImportState,
    swoaCardCardImportCardImport,
    swoaCardCardImportCardImportFileUpload,
    swoaCardCardImportCardImportOverview,
    swoaCardCardImportCardImportSync,
    swoaCardCardImportCardImportAssign,
    swoaCardCardImportCardImportCardConfirm,
    swoaCardCardImportCardImportCreatePerson,
    swoaCardCardImportCardImportStatus,
  ])
  .name;

