import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.nwf-contribution.base-amount.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('nwfBaseAmountPeriod', {
      url: '/nwf-periods/:organisationId/base/:periodId',
      template:
        '<swoa-nwf-base-amount-period current-tab="selectedTab"></swoa-nwf-base-amount-period>',
      controller($scope, $state, $transitions) {
        if ($state.current.name === 'nwfBaseAmountPeriod') {
          $state.go('nwfBaseAmountPeriod.foundation');
          $scope.selectedTab = 'foundation';
        } else {
          $scope.selectedTab = $state.current.data.selectedTab;
        }

        $transitions.onSuccess({ to: 'nwfBaseAmountPeriod.**' }, transition => {
          if (transition.to().name === 'nwfBaseAmountPeriod') {
            $state.go('nwfBaseAmountPeriod.foundation');
            $scope.selectedTab = 'foundation';
          } else {
            $scope.selectedTab = $state.current.data.selectedTab;
          }
        });
      },
      controllerAs: 'vm',
      data: {
        breadcrumb: {
          parent: 'nwfPeriods',
          title: {
            stateParam: 'periodId',
            service: 'nwfBaseAmountPeriodService'
          }
        },
        hasPermission: 'nwf_contribution_r'
      }
    })
    .state('nwfBaseAmountPeriod.foundation', {
      url: '/foundation',
      views: {
        foundation: {
          template:
            '<swoa-nwf-base-amount-period-foundation period="vm.period"></swoa-nwf-base-amount-period-foundation>'
        }
      },
      data: {
        selectedTab: 'foundation',
        breadcrumb: {
          parent: 'nwfBaseAmountPeriod',
          ignore: true
        },
        hasPermission: 'nwf_contribution_r'
      }
    })
    .state('nwfBaseAmountPeriod.contribution', {
      url: '/contribution',
      views: {
        contribution: {
          template:
            '<swoa-nwf-base-amount-period-contribution period="vm.period"></swoa-nwf-base-amount-period-contribution>'
        }
      },
      data: {
        selectedTab: 'contribution',
        breadcrumb: {
          parent: 'nwfBaseAmountPeriod',
          ignore: true
        },
        hasPermission: 'nwf_contribution_r'
      }
    });
}
