import angular from 'angular';
import swoaIcon from '../icon/icon.component';
import htmlTemplate from './teaser.html';

export default angular
  .module('swoa.teaser', [
    swoaIcon,
  ])
  .component('swoaTeaser', {
    template: htmlTemplate,
    bindings: {
      icon: '@',
      size: '@',
      count: '<',
    },
    controllerAs: 'vm',
  })
  .name;
