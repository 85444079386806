import angular from 'angular';
import swoaCardSportYearService from './sport-year.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './sport-year-create-person.html';

export default angular
  .module('swoa.card.sport-year.sport-year-create-person', [
    swoaCardSportYearService,
    swoaNotification
  ])
  .component('swoaSportYearCreatePerson', {
    template: htmlTemplate,
    bindings: {},
    controller: SportYearCreatePersonController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function SportYearCreatePersonController(
  $state,
  $stateParams,
  sportYearService,
  notificationService
) {
  const vm = this;

  // functions
  vm.createPerson = createPerson;
  vm.cancelAction = cancelAction;

  // state params
  vm.sportId = $stateParams.sportId;
  vm.yearId = $stateParams.yearId;

  // required by swoa-person-create
  vm.requiredProperties = null;

  // error handling
  vm.notificationKey = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    sportYearService
      .getAdditionalRequiredProperties(vm.yearId)
      .then(requiredProperties => {
        vm.requiredProperties = requiredProperties;
      }, notificationService.errorHandler(vm));
  }

  function createPerson(person) {
    return sportYearService.addPerson(vm.yearId, person).then(card => {
      const params = $stateParams;
      params.cardId = card.id;
      $state.go('sportYearDetail.cardDetail', params);
    }, notificationService.errorHandler(vm));
  }

  function cancelAction() {
    $state.go('sportYearDetail', $stateParams);
  }
}
