import angular from 'angular';
import htmlTemplate from './organisation-compact.html';

export default angular
  .module('swoa.organisation.organisation-compact', [])
  .component('swoaOrganisationCompact', {
    template: htmlTemplate,
    bindings: {
      entry: '<',
      showEmail: '<',
      noLink: '<',
    },
    controller: OrganisationCompactController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function OrganisationCompactController() {
  const vm = this;

  vm.defaultImageUrl = '/assets/images/organisation_avatar_default.png';

  vm.imageStyle = imageStyle;

  // //////////

  function imageStyle(imgUrl) {
    return {
      'background-image': `url(${imgUrl})`,
    };
  }
}
