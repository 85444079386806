import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.login.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('login', {
      url: '/login?requestedPath&notificationKey&isSuccess',
      template: '<swoa-login></swoa-login>',
      data: {
        anonymousAccessAllowed: true
      },
      params: {
        requestedPath: {
          value: null,
          squash: true
        },
        notificationKey: {
          value: null,
          squash: true
        },
        isSuccess: {
          value: null,
          squash: true
        }
      }
    })
    .state('loginPasswordForgot', {
      url: '/login-password-forgot',
      template: '<swoa-login-password-forgot></swoa-login-password-forgot>',
      data: {
        anonymousAccessAllowed: true
      }
    })
    .state('loginPasswordReset', {
      url: '/login-password-reset/:loginCode/:lang',
      template: '<swoa-login-password-reset></swoa-login-password-reset>',
      data: {
        anonymousAccessAllowed: true
      }
    })
    .state('loginActivation', {
      url: '/login-activation/:loginCode/:lang',
      template: '<swoa-login-password-reset></swoa-login-password-reset>',
      data: {
        anonymousAccessAllowed: true
      }
    });
}
