import angular from 'angular';
import swoaNwfBaseAmountPeriod from './nwf-base-amount-period.component';
import swoaNwfBaseAmountPeriodRoutes from './nwf-base-amount-period.routes';
import swoaNwfBaseAmountPeriodService from './nwf-base-amount-period.service';
import swoaNwfBaseAmountPeriodFoundation from './nwf-base-amount-period-foundation.component';
import swoaNwfBaseAmountPeriodContribution from './nwf-base-amount-period-contribution.component';
import swoaNwfBaseAmountPeriodList from './nwf-base-amount-period-list.component';

export default angular
  .module('swoa.nwf-contribution.base-amount', [
    swoaNwfBaseAmountPeriod,
    swoaNwfBaseAmountPeriodRoutes,
    swoaNwfBaseAmountPeriodService,
    swoaNwfBaseAmountPeriodFoundation,
    swoaNwfBaseAmountPeriodContribution,
    swoaNwfBaseAmountPeriodList,
  ])
  .name;

