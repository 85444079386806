import angular from 'angular';
import htmlTemplate from './data-table-search.html';

export default angular
  .module('swoa.data-table.data-table-search', [])
  .component('swoaDataTableSearch', {
    replace: true,
    template: htmlTemplate,
    bindings: {
      filter: '=swoaFilter',
      onSearch: '&',
    },
    controller: DataTableSearchController,
    controllerAs: 'vm',
  })
  .name;

/** @ngInject */
function DataTableSearchController($timeout) {
  const vm = this;

  vm.searchFieldVisible = false;

  vm.toggleSearchField = toggleSearchField;
  vm.hideOnEscape = hideOnEscape;

  // ////////////////////

  function toggleSearchField(event) {
    vm.searchFieldVisible = !vm.searchFieldVisible;
    vm.filter = '';

    if (vm.searchFieldVisible) {
      $timeout(() => {
        const input = angular.element(event.target).closest('.data-table-search').find('.data-table-search-input');
        input.focus();
      }, 200);
    }
  }

  function hideOnEscape(event) {
    if (event.keyCode === 27) {
      vm.searchFieldVisible = false;
      vm.filter = '';
    }
  }
}
