import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaEscape from '../../../components/escape/escape.filter';
import swoaConstants from '../../../app.constants';
import swoaNotification from '../../../components/notification/notification.module';
import swoaAdminTranslationsService from './../translations.service';
import swoaDialog from '../../../components/dialog/dialog.module';
import htmlTemplate from './labels-detail.html';

export default angular
  .module('swoa.admin.translations.label-detail', [
    angularTranslate,
    swoaEscape,
    swoaConstants,
    swoaNotification,
    swoaAdminTranslationsService,
    swoaDialog
  ])
  .component('swoaTranslationsLabelDetail', {
    template: htmlTemplate,
    bindings: {},
    controller: LabelsDetailController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function LabelsDetailController(
  $rootScope,
  $state,
  $translate,
  $stateParams,
  ConfigConstants,
  notificationService,
  translationsService,
  dialogService
) {
  const vm = this;

  const onError = notificationService.errorHandler(vm);

  vm.translationsKey = $stateParams.translationsKey;

  vm.languages = ConfigConstants.translation.availableLanguages;
  vm.editing = false;

  vm.submit = submit;
  vm.closeDetail = closeDetail;
  vm.startEdit = startEdit;
  vm.cancelEdit = cancelEdit;

  vm.$onInit = () => {
    activate();
  };

  vm._loadTranslations = loadTranslations;
  vm._triggerLoadTranslations = triggerLoadTranslations;

  // //////////////

  function activate() {
    loadTranslations();
  }

  function loadTranslations() {
    return translationsService.loadAllTranslations().then(translations => {
      vm.translations = translations;
    }, onError);
  }

  function submit() {
    vm.saving = true;
    translationsService
      .saveTranslations(vm.translations)
      .then(() => {
        triggerLoadTranslations(vm.translations);
        dialogService.showSuccessToast('translations.save.done', {
          translationKey: vm.translationsKey
        });
        return $translate.refresh();
      }, onError)
      .then(activate)
      .finally(() => {
        vm.saving = false;
        vm.editing = false;
      });
  }

  function closeDetail() {
    vm.editing = false;
    $state.go('^');
  }

  function startEdit() {
    vm.editing = true;
  }

  function cancelEdit() {
    vm.editing = false;
  }

  function triggerLoadTranslations(translations) {
    $rootScope.$broadcast('swoa:onLoadTranslations', translations);
    return translations;
  }
}
