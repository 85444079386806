import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import swoaDialog from '../../components/dialog/dialog.module';
import swoaConstants from '../../app.constants';
import swoaCardSportYearService from './sport-year.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './sport-year-comments.html';

export default angular
  .module('swoa.card.sport-year.sport-year-comments', [
    uiRouter,
    angularTranslate,
    swoaDialog,
    swoaConstants,
    swoaCardSportYearService,
    swoaNotification
  ])
  .component('swoaSportYearComments', {
    template: htmlTemplate,
    bindings: {},
    controller: SportYearCommentsController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function SportYearCommentsController(
  $rootScope,
  $scope,
  $stateParams,
  lodash,
  $mdDialog,
  sportYearService,
  notificationService
) {
  const vm = this;
  const onError = notificationService.errorHandler(vm);

  vm.sportYear = null;

  vm.addComment = addComment;
  vm.deleteComment = deleteComment;
  vm.closeDialog = closeDialog;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$on('swoa:sportYearCommentAdded', onCommentAdded);
    $scope.$on('swoa:sportYearCommentDeleted', onCommentDeleted);

    sportYearService
      .loadSportYearDetails($stateParams.yearId)
      .then(sportYear => {
        vm.sportYear = sportYear;
      }, onError);
  }

  function addComment(newComment) {
    return sportYearService
      .addComment(vm.sportYear.id, newComment)
      .then(comment => {
        $rootScope.$broadcast(
          'swoa:sportYearCommentAdded',
          vm.sportYear.id,
          comment
        );
        return comment;
      }, onError);
  }

  function deleteComment(comment) {
    return sportYearService
      .deleteComment(vm.sportYear.id, comment.id)
      .then(() => {
        $rootScope.$broadcast(
          'swoa:sportYearCommentDeleted',
          vm.sportYear.id,
          comment
        );
        return comment;
      }, onError);
  }

  function onCommentAdded(event, sportYearId, comment) {
    if (sportYearId === vm.sportYear.id && vm.sportYear.comments) {
      vm.sportYear.comments.push(comment);
      vm.sportYear.comments.sort(compareComments).reverse();
    }
  }

  function onCommentDeleted(event, sportYearId, comment) {
    if (sportYearId === vm.sportYear.id && vm.sportYear.comments) {
      lodash.remove(vm.sportYear.comments, { id: comment.id });
    }
  }

  function compareComments(a, b) {
    if (a.creationTime > b.creationTime) {
      return 1;
    }
    if (a.creationTime < b.creationTime) {
      return -1;
    }
    return 0;
  }

  function closeDialog() {
    $mdDialog.hide();
  }
}
