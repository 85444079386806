import angular from 'angular';
import 'restangular';
import swoaConstants from '../../app.constants';

export default angular
  .module('swoa.data-table.data-table-helper', [
    'restangular',
    swoaConstants,
  ])
  .factory('dataTableHelper', dataTableHelper)
  .name;

/** @ngInject */
function dataTableHelper($rootScope, $window, lodash, Restangular) {
  return {
    scrollTop,
    subscribeToUpdateEvents,
  };

  // //////////

  function scrollTop() {
    $window.scrollTo(0, 0);
  }

  function subscribeToUpdateEvents($scope, typeName, vm, collectionName, callback) {
    $scope.$on(`swoa:${typeName}Added`, onAdded);
    $scope.$on(`swoa:${typeName}Updated`, onUpdated);
    $scope.$on(`swoa:${typeName}Deleted`, onDeleted);

    function onAdded(event, item) {
      if (!vm[collectionName]) {
        vm[collectionName] = [];
      }

      if (callback && lodash.isFunction(callback)) {
        vm[collectionName].push(callback(Restangular.copy(item)));
      } else {
        vm[collectionName].push(Restangular.copy(item));
      }
    }

    function onUpdated(event, item) {
      const index = lodash.findIndex(vm[collectionName], { id: item.id });
      if (index > -1) {
        if (callback && lodash.isFunction(callback)) {
          vm[collectionName][index] = Restangular.copy(callback(Restangular.copy(item)));
        } else {
          vm[collectionName][index] = Restangular.copy(Restangular.copy(item));
        }
      }
    }

    function onDeleted(event, item) {
      const index = lodash.findIndex(vm[collectionName], { id: item.id });
      if (index > -1) {
        lodash.pullAt(vm[collectionName], index);
      }
    }
  }
}
