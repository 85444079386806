import angular from 'angular';
import uiRouter from '@uirouter/angularjs';

export default angular
  .module('swoa.admin.translations.page.routes', [uiRouter])
  .config(routeConfig).name;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider.state('translations', {
    url: '/admin/translations',
    template:
      '<swoa-translations-page current-tab="selectedTab"></swoa-translations-page>',
    controller($scope, $state, $transitions) {
      $state.go('translations.dropdowns');
      $scope.selectedTab = 0;

      $transitions.onSuccess({ to: 'translations.**' }, transition => {
        if (transition.to().name === 'translations') {
          $state.go('translations.dropdowns');
          $scope.selectedTab = 0;
        } else {
          $scope.selectedTab = $state.current.data.selectedTab;
        }
      });
    },
    controllerAs: 'vm',
    data: {
      breadcrumb: true,
      hasPermission: ['admin_translation_r']
    }
  });
}
