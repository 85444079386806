import angular from 'angular';
import swoaNwfControlling from './nwf-controlling.component';
import swoaNwfControllingEdit from './nwf-controlling.edit.component';
import swoaNwfControllingShow from './nwf-controlling.show.component';
import swoaNwfControllingEntry from './nwf-controlling.entry.component';
import swoaNwfControllingLink from './nwf-controlling.link.component';
import swoaNwfControllingRoutes from './nwf-controlling.routes';
import swoaNwfControllingService from './nwf-controlling.service';

export default angular
  .module('swoa.nwf-contribution.controlling', [
    swoaNwfControlling,
    swoaNwfControllingEdit,
    swoaNwfControllingShow,
    swoaNwfControllingEntry,
    swoaNwfControllingLink,
    swoaNwfControllingRoutes,
    swoaNwfControllingService,
  ])
  .name;
