import angular from 'angular';
import swoaInputSelect from '../input-select/input-select.component';
import swoaInputDate from '../input-date/input-date.component';
import htmlTemplate from './input-conditional-date.html';

export default angular
  .module('swoa.input-conditional-date', [swoaInputSelect, swoaInputDate])
  .component('swoaInputConditionalDate', {
    template: htmlTemplate,
    bindings: {
      name: '@',
      condition: '=',
      labelKeyCondition: '@',
      labelKeyDate: '@',
      isReadOnly: '<',
      ngModel: '=',
      enumList: '<',
      enumTranslatePrefix: '@',
      isRequired: '<'
    },
    controller: InputConditionalDateController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function InputConditionalDateController($scope) {
  const vm = this;

  vm.condition = vm.ngModel !== null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    $scope.$watch('vm.ngModel', () => {
      vm.condition = vm.ngModel !== null;
    });

    $scope.$watch('vm.condition', () => {
      if (vm.condition === false) {
        vm.ngModel = null;
      }
    });
  }
}
