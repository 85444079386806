import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import swoaConstants from '../../app.constants';
import swoaDialog from '../../components/dialog/dialog.module';
import swoaDate from '../../components/date/date.filter';
import swoaCardSportYearService from '../sport-year/sport-year.service';
import swoaCardCardImportCardImportState from './card-import-state.service';
import swoaPersonPersonMinimal from '../../person/person-minimal.component';
import swoaAsyncService from '../../components/async/async-helper.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './card-import-overview.html';

export default angular
  .module('swoa.card.card-import.card-import-overview', [
    uiRouter,
    swoaConstants,
    swoaDialog,
    swoaDate,
    swoaCardSportYearService,
    swoaCardCardImportCardImportState,
    swoaPersonPersonMinimal,
    swoaAsyncService,
    swoaNotification
  ])
  .component('swoaCardImportOverview', {
    template: htmlTemplate,
    bindings: {},
    controller: CardImportOverviewController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardImportOverviewController(
  $scope,
  $window,
  $http,
  $state,
  $stateParams,
  lodash,
  dialogService,
  sportYearService,
  cardImportStateService,
  notificationService,
  asyncHelperService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      matchingFinished
    );

  // state params
  vm.sportId = $stateParams.sportId;
  vm.yearId = $stateParams.yearId;

  // table data
  vm.order = '[surname,forename]';
  vm.importedCards = null;
  vm.automatic = false;
  vm.cancelImportEnabled = false;
  vm.manually = false;
  vm.loadingPromise = null;

  // functions
  vm.startMatch = startMatch;
  vm.cancelImport = cancelImport;

  // notification and error handling
  vm.successInfo = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    asyncHelper.showSpinner();
    cardImportStateService.setName();
    vm.loadingPromise = sportYearService
      .loadImportedCards(vm.yearId)
      .then(importedCards => {
        vm.importedCards = importedCards;
        lodash.forEach(vm.importedCards, card => {
          card.hasAvatar = false;
        });
        if (vm.importedCards.length < 1) {
          $state.go('sportYearDetail', $stateParams);
        }
        cardImportStateService.setImportedCards(importedCards);
        updateButtons();
      }, onError)
      .finally(asyncHelper.hideSpinner);

    const unregisterAutoMatch = $scope.$on(
      'swoa:cardImportAutoMatch',
      startMatch
    );
    const unregisterCancel = $scope.$on('swoa:cardImportCancel', cancelImport);
    const unregisterMatchAgain = $scope.$on(
      'swoa:cardImportMatchAgain',
      matchAgain
    );

    $scope.$on('$destroy', () => {
      unregisterAutoMatch();
      unregisterCancel();
      unregisterMatchAgain();
    });
  }

  function startMatch() {
    asyncHelper.showSpinner();
    sportYearService
      .startMatch(vm.yearId)
      .then(asyncHelper.getAsyncJobStatus)
      .catch(asyncHelper.errorHandler);
  }

  function matchAgain() {
    if (vm.importedCards && vm.importedCards.length > 0) {
      vm.loadingPromise.then(() => {
        asyncHelper.showSpinner();
        sportYearService
          .matchAgain(vm.yearId)
          .then(asyncHelper.getAsyncJobStatus)
          .catch(asyncHelper.errorHandler);
      });
    }
  }

  function matchingFinished(resultUrl) {
    return $http.get(resultUrl).then(answer => {
      const result = answer.data;
      vm.importedCards = result.openCardImportEntries;
      vm.successInfo = {
        numberOfIgnoredEntries: result.numberOfIgnoredEntries,
        numberOfImportedEntries: result.numberOfImportedEntries,
        numberOfOpenEntries: result.openCardImportEntries.length
      };
      $window.scrollTo(0, 0);
      updateButtons();
      $scope.$emit('swoa:cardImportAutoMatchFinally');
    });
  }

  function cancelImport() {
    dialogService
      .showConfirmationDialog('cardimport.cancel.confirmation')
      .then(() => {
        sportYearService
          .cancelImport(vm.yearId)
          .then(onDeleteSuccess, onError)
          .catch(angular.noop);
      });
  }

  function onDeleteSuccess() {
    dialogService.showSuccessToast('cardimport.cancel.done');
    $state.go('sportYearDetail', $stateParams);
  }

  function updateButtons() {
    const autoMatchAllowed = lodash.some(
      vm.importedCards,
      card => card.status === 'NEEDS_MATCHING'
    );
    $scope.$emit('swoa:cardImportAutoMatchAllowed', autoMatchAllowed);

    const cancelAllowed = vm.importedCards && vm.importedCards.length > 0;
    $scope.$emit('swoa:cardImportCancelAllowed', cancelAllowed);
  }
}
