import angular from 'angular';
import swoaDialogService from '../../components/dialog/dialog.service';
import swoaNotification from '../../components/notification/notification.module';
import swoaChangeLogService from '../../components/change-log/change-log.service';
import swoaNwfBaseAmountPeriodService from './nwf-base-amount-period.service';
import htmlTemplate from './nwf-base-amount-period.html';

export default angular
  .module('swoa.nwf-contribution.base-amount.nwf-base-amount-period', [
    swoaDialogService,
    swoaNotification,
    swoaChangeLogService,
    swoaNwfBaseAmountPeriodService
  ])
  .component('swoaNwfBaseAmountPeriod', {
    template: htmlTemplate,
    bindings: {
      currentTab: '<'
    },
    controller: NwfBaseAmountPeriod,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfBaseAmountPeriod(
  $scope,
  $stateParams,
  $q,
  dialogService,
  notificationService,
  changeLogService,
  nwfBaseAmountPeriodService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.periodId = $stateParams.periodId;

  vm.statusChange = statusChange;
  vm.showChangeLogDialog = showChangeLogDialog;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    nwfBaseAmountPeriodService.loadPeriod(vm.periodId).then(period => {
      vm.period = period;
    }, onError);

    $scope.$on('swoa:nwfCategoryUpdated', onCategoryUpdated);
  }

  function onCategoryUpdated(event, period) {
    vm.period = period;
  }

  function statusChange(status, confirmationMessageKey) {
    let confirmPromise = $q.when();
    if (confirmationMessageKey) {
      confirmPromise = dialogService.showConfirmationDialog(
        confirmationMessageKey
      );
    }

    return confirmPromise.then(() => {
      notificationService.resetErrors(vm);
      return nwfBaseAmountPeriodService
        .goToStatus(vm.periodId, status)
        .then(activate);
    }, onError);
  }

  function showChangeLogDialog() {
    // TODO: implement function getChangeLog and backend and configuration in change-log component
    nwfBaseAmountPeriodService
      .getChangeLog(vm.sportYear.id)
      .then(
        sportYearChangeLog =>
          changeLogService.showDialog(sportYearChangeLog, 'baseAmount'),
        onError
      );
  }
}
