import angular from 'angular';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaDialogService from '../../components/dialog/dialog.service';
import swoaFormatNumber from '../../components/format-number/format-number.filter';
import swoaNwfContributionService from '../../nwf-contribution/nwf-contribution.service';
import htmlTemplate from './nwf-parameter-variable-amount.html';

export default angular
  .module('swoa.admin.nwf-parameter.variable-amount', [
    swoaConstants,
    swoaNotification,
    swoaDialogService,
    swoaFormatNumber,
    swoaNwfContributionService,
  ])
  .component('swoaNwfParameterVariableAmount', {
    template: htmlTemplate,
    bindings: {},
    controller: NwfParameterVariableAmountController,
    controllerAs: 'vm',
  }).name;

const NUM_CATEGORIES = 9;

/** @ngInject */
function NwfParameterVariableAmountController(
  $rootScope,
  $scope,
  $q,
  $sce,
  lodash,
  $translate,
  Constants,
  notificationService,
  dialogService,
  nwfContributionService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.categories = [];
  vm.factor = null;
  vm.forecastFactor = null;
  vm.forecastValues = {};
  vm.forecast = '-';
  vm.current = '-';
  vm.$sce = $sce;

  vm.calculateForecast = calculateForecast;
  vm.saveForecast = saveForecast;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    $rootScope.showSpinner = true;
    const categoriesPromise =
      nwfContributionService.currentVariableAmountCategories();
    const factorPromise = nwfContributionService.currentFactor();
    const sumsPromise = nwfContributionService.currentSums();

    $q.all([categoriesPromise, factorPromise, sumsPromise])
      .then((results) => {
        vm.forecastValues = {};
        vm.categories = results[0];
        vm.factor = parseFloat(results[1]);
        vm.forecastFactor = undefined;
        vm.sums = results[2];
        calculateForecast();
        calculateCurrent();
      })
      .catch(onError)
      .finally(() => ($rootScope.showSpinner = false));
    $scope.$watch('vm.forecastFactor', calculateForecast);
  }

  function calculateForecast() {
    vm.forecast = '-';
    vm.forecastValid = true;
    for (let i = 1; i <= NUM_CATEGORIES; i++) {
      const parsedFloat = parseFloat(vm.forecastValues[i]);
      if (isNaN(parsedFloat) || !parsedFloat || parsedFloat < 0) {
        vm.forecastValid = false;
      }
    }
    if (
      isNaN(parseFloat(vm.forecastFactor)) ||
      !parseFloat(vm.forecastFactor) ||
      parseFloat(vm.forecastFactor) < 0
    ) {
      vm.forecastValid = false;
    }
    if (vm.forecastValid) {
      vm.forecast = 0;
      for (let i = 1; i <= NUM_CATEGORIES; i++) {
        const sumPercentageOfCategory = lodash.sum(
          lodash.map(
            vm.sums,
            (sum) => lodash.find(sum, ['category.id', i]).percentage
          )
        );
        vm.forecast +=
          sumPercentageOfCategory *
          parseFloat(vm.forecastValues[i]) *
          parseFloat(vm.forecastFactor);
      }
      vm.forecast = Math.round(vm.forecast);
    }
  }

  function calculateCurrent() {
    vm.current = 0;
    for (let i = 1; i <= NUM_CATEGORIES; i++) {
      const categoryFactor = lodash.find(vm.categories, ['id', i]).ratingFactor;
      const sumPercentageOfCategory = lodash.sum(
        lodash.map(
          vm.sums,
          (sum) => lodash.find(sum, ['category.id', i]).percentage
        )
      );
      vm.current +=
        sumPercentageOfCategory * categoryFactor * parseFloat(vm.factor);
    }
    vm.current = Math.round(vm.current);
  }

  function saveForecast() {
    dialogService
      .showConfirmationDialog(
        'nwfVariableAmountPeriod.useForecastParameter.confirmation'
      )
      .then(() => {
        for (let i = 1; i <= NUM_CATEGORIES; i++) {
          lodash.find(vm.categories, ['id', i]).ratingFactor = parseFloat(
            vm.forecastValues[i]
          );
        }
        nwfContributionService
          .updateVariableAmountCategories(
            parseFloat(vm.forecastFactor),
            vm.categories
          )
          .then(activate, onError);
      });
  }
}
