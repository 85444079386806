import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import swoaDialog from '../../components/dialog/dialog.module';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './create-favorite.html';

export default angular
  .module('swoa.admin.report.create-favorite', [
    uiRouter,
    angularTranslate,
    swoaDialog,
    swoaConstants,
    swoaNotification
  ])
  .component('swoaCreateFavoriteDialog', {
    template: htmlTemplate,
    bindings: {
      closeDialogAction: '&',
      addFavoriteAction: '&'
    },
    controller: CreateFavoriteController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CreateFavoriteController(lodash, userService) {
  const vm = this;

  vm.favorite = {};
  vm.notificationAddFavoriteKey = undefined;
  vm.notificationAddFavoriteSuccess = undefined;
  vm.uiConfig = userService.getCurrentUser().uiConfig;

  vm.submit = submit;

  // //////////

  function submit(event) {
    event.preventDefault();

    if (
      lodash.filter(
        vm.uiConfig.exportFavorites,
        favorite => favorite.name === vm.favorite.name
      ).length > 0
    ) {
      vm.notificationAddFavoriteKey = 'report.favorites.new.save.duplicated';
      vm.notificationAddFavoriteSuccess = false;
    } else {
      vm.addFavoriteAction({ $name: vm.favorite.name });
      vm.closeDialogAction();
    }
  }
}
