// libraries
import angular from 'angular';
import ngLocale from 'angular-i18n/de-ch';
import ngAnimate from 'angular-animate';
import ngSanitize from 'angular-sanitize';
import ngMaterial from 'angular-material';
import ngMessages from 'angular-messages';
import uiRouter from '@uirouter/angularjs';
import angularTranslate from 'angular-translate';
import angularTranslateLoaderStaticFiles from 'angular-translate-loader-static-files';
import LocalStorageModule from 'angular-local-storage';
import mdDataTable from 'angular-material-data-table';
import highlightJs from 'angular-highlightjs';
import ngUserAuth from 'ng-user-auth';
import ngSortable from 'ng-sortable';
import 'restangular';
import 'angulartics';
import 'angulartics-piwik';
import 'matchmedia-ng';
import ngTimePicker from 'angular-material-time-picker';
// modules
import swoaRoutes from './app.routes';
import swoaComponents from './components/components.module';
import swoaAdmin from './admin/admin.module';
import swoaCard from './card/card.module';
import swoaCockpit from './cockpit/cockpit.module';
import swoaConfig from './app.config';
import swoaConstants from './app.constants';
import swoaFileStorage from './file-storage/file-storage.module';
import swoaLogin from './login/login.module';
import swoaLogout from './logout/logout.module';
import swoaOrganisation from './organisation/organisation.module';
import swoaPerson from './person/person.module';
import swoaRun from './app.run';
import swoaSecurity from './security/security.module';
import swoaSport from './sport/sport.module';
import swoaUser from './user/user.module';
import swoaNwfContribution from './nwf-contribution/nwf-contribution.module';
import swoaProperties from './properties/properties.module';

import 'ui-cropper/compile/unminified/ui-cropper';

export default angular.module('swoa', [
  'restangular',
  'angulartics',
  'angulartics.piwik',
  'matchmedia-ng',
  'uiCropper',
  ngLocale,
  ngSortable,
  ngUserAuth,
  ngAnimate,
  ngSanitize,
  ngMaterial,
  ngMessages,
  uiRouter,
  angularTranslate,
  angularTranslateLoaderStaticFiles,
  LocalStorageModule,
  mdDataTable,
  highlightJs,
  ngTimePicker,
  swoaRoutes,
  swoaComponents,
  swoaAdmin,
  swoaCard,
  swoaCockpit,
  swoaConfig,
  swoaConstants,
  swoaFileStorage,
  swoaLogin,
  swoaLogout,
  swoaOrganisation,
  swoaPerson,
  swoaRun,
  swoaSecurity,
  swoaSport,
  swoaUser,
  swoaNwfContribution,
  swoaProperties,
]).name;
