import angular from 'angular';
import swoaConstants, { APP_CONSTANTS } from '../../app.constants';
import swoaDateService from '../../components/date/date.service';
import swoaNwfVariableAmountPeriodService from './nwf-variable-amount-period.service';
import swoaNotification from '../../components/notification/notification.module';
import htmlTemplate from './nwf-variable-amount-period-contribution.html';

export default angular
  .module(
    'swoa.nwf-contribution.variable-amount.nwf-variable-amount-period-contribution',
    [
      swoaConstants,
      swoaDateService,
      swoaNotification,
      swoaNwfVariableAmountPeriodService
    ]
  )
  .component('swoaNwfVariableAmountPeriodContribution', {
    template: htmlTemplate,
    bindings: {
      period: '<',
      sumPercent: '<'
    },
    controller: NwfVariableAmountPeriodContribution,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function NwfVariableAmountPeriodContribution(
  $q,
  $scope,
  $stateParams,
  $sce,
  $translate,
  numberFilter,
  lodash,
  moment,
  dateService,
  notificationService,
  nwfVariableAmountPeriodService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm);

  vm.years = [];
  vm.calculationYear = null;
  vm.paymentYear = null;
  vm.lastYearFullVariableAmount = null;
  vm.categories = [];
  vm.categoriesTotalAmount = 0;
  vm.calendarYears = [];
  vm.calendarYearsTotal = null;
  vm.institutionPayments = [];

  vm.getCalendarYear = getCalendarYear;
  vm.getPayment1 = getPayment1;
  vm.getPayment2 = getPayment2;
  vm.getTotal = getTotal;
  vm.getTotals = getTotals;
  vm.getTranslatedText = getTranslatedText;
  vm.italics = italics;

  // for tests only!
  vm._initInstitutionPaymentsAndTotals = initInstitutionPaymentsAndTotals;

  vm.$onInit = () => {
    activate();
  };

  // ////////////

  function activate() {
    $scope.$watch('vm.period', onPeriodChanged);
    $scope.$watch('vm.calculationYear', onCalculationYearChanged);
    $scope.$watch('vm.paymentYear', onPaymentYearChanged);
  }

  function onPeriodChanged() {
    if (vm.period) {
      initYearDropDowns();

      const promises = [];
      promises.push(nwfVariableAmountPeriodService.getCategories(vm.period.id));
      promises.push(
        nwfVariableAmountPeriodService.getCalendarYearPayments(vm.period.id)
      );

      $q.all(promises).then(results => {
        vm.categories = results[0];
        vm.categoriesTotalAmount = lodash.sumBy(vm.categories, 'amount');
        vm.calendarYears = results[1];
      }, onError);
    }
  }

  function initYearDropDowns() {
    for (
      let i = 0;
      i <
      dateService.periodLengthInYears(
        vm.period.validFrom,
        vm.period.validUntil
      );
      i++
    ) {
      vm.years.push(
        moment(vm.period.validFrom)
          .add(i, 'years')
          .year()
      );
    }
    vm.calculationYear = vm.years[0];
    vm.paymentYear = vm.years[0];
  }

  function onCalculationYearChanged(calculationYear) {
    if (vm.period) {
      nwfVariableAmountPeriodService
        .getLastYearFullVariableAmount(calculationYear, vm.period.sport.id)
        .then(result => {
          vm.lastYearFullVariableAmount = result.amount;
        });
    }
  }

  function onPaymentYearChanged(paymentYear) {
    if (vm.period) {
      nwfVariableAmountPeriodService
        .getInstitutionPayments(paymentYear, vm.period.id)
        .then(result => {
          vm.institutionPayments = result;
          initInstitutionPaymentsAndTotals();
        });
    }
  }

  function initInstitutionPaymentsAndTotals() {
    lodash.forEach(vm.institutionPayments, payment => {
      payment.payment1 = parsePayment(payment.payment1);
      payment.payment2 = parsePayment(payment.payment2);
      payment.total = calculateTotal(payment.payment1, payment.payment2);
    });

    if (vm.institutionPayments.length > 0) {
      vm.institutionPayment1Total = calculateInstitutionTotals(
        vm.institutionPayments,
        'payment1'
      );
      vm.institutionPayment2Total = calculateInstitutionTotals(
        vm.institutionPayments,
        'payment2'
      );
      vm.institutionPaymentTotal = calculateTotal(
        vm.institutionPayment1Total,
        vm.institutionPayment2Total
      );
    }
  }

  function calculateInstitutionTotals(institutionPayments, payment) {
    if (lodash.some(institutionPayments, [payment, '-'])) {
      return '-';
    }

    if (lodash.every(institutionPayments, [payment, 'X'])) {
      return 'X';
    }

    return lodash
      .chain(institutionPayments)
      .map(payment)
      .filter(lodash.isNumber)
      .sum()
      .value();
  }

  function getCalendarYear(year) {
    return lodash.find(vm.calendarYears, ['year.year', year]);
  }

  function getPayment1(year) {
    const calendarYear = getCalendarYear(year);
    if (!calendarYear) {
      return '-';
    }

    return parsePayment(calendarYear.payment1VariableAmount);
  }

  function getPayment2(year) {
    const calendarYear = getCalendarYear(year);
    if (!calendarYear) {
      return '-';
    }

    return parsePayment(calendarYear.payment2VariableAmount);
  }

  function parsePayment(payment) {
    if (payment !== null && payment !== undefined) {
      return payment;
    } else if (vm.period.status === APP_CONSTANTS.nwf.PAYMENT_FAILED) {
      return 'X';
    }
    return '-';
  }

  function getTotal(year) {
    const payment1 = getPayment1(year);
    const payment2 = getPayment2(year);

    return calculateTotal(payment1, payment2);
  }

  function calculateTotal(payment1, payment2) {
    if (payment1 === '-' || payment2 === '-') {
      return '-';
    }

    if (payment1 === 'X' && payment2 === 'X') {
      return 'X';
    }

    return lodash.sum(
      lodash.filter([payment1, payment2], p => lodash.isNumber(p))
    );
  }

  function getTotals() {
    if (vm.calendarYears.length === vm.years.length) {
      const totals = lodash.map(vm.years, year => getTotal(year));

      if (lodash.some(totals, total => total === '-')) {
        return '-';
      }

      if (lodash.every(totals, total => total === 'X')) {
        return 'X';
      }

      return lodash.sum(lodash.filter(totals, lodash.isNumber));
    }
    return '-';
  }

  function getTranslatedText(label, params) {
    return $sce.trustAsHtml($translate.instant(label, params));
  }

  function italics(institutionPayment) {
    const inactive = !institutionPayment.institution.active;
    const sportDeletedOnInsitution =
      institutionPayment.institution.sports.filter(
        sport => sport.id === vm.period.sport.id
      ).length === 0;

    return inactive || sportDeletedOnInsitution;
  }
}
