import angular from 'angular';
import swoaAdminCardParameterRoutes from './card-parameter.routes';
import swoaAdminCardParameter from './card-parameter.component';
import swoaAdminCardParameterDetail from './card-parameter-detail.component';
import swoaAdminCardParameterDetailSetting from './card-parameter-detail-setting.component';
import swoaAdminSportYearSettingService from './sport-year-setting.service';

export default angular
  .module('swoa.admin.card-parameter', [
    swoaAdminCardParameterRoutes,
    swoaAdminCardParameter,
    swoaAdminCardParameterDetail,
    swoaAdminCardParameterDetailSetting,
    swoaAdminSportYearSettingService,
  ])
  .name;

