import angular from 'angular';
import angularTranslate from 'angular-translate';
import swoaConstants from '../app.constants';
import swoaDate from '../components/date/date.filter';
import swoaTranslatable from '../components/translatable/translatable.filter';

export default angular
  .module('swoa.card.cards-summary', [
    angularTranslate,
    swoaConstants,
    swoaDate,
    swoaTranslatable
  ])
  .component('swoaCardsSummary', {
    template:
      '<span title="{{vm.longText}}">{{vm.longTextOnly ? vm.longText : vm.shortText}}</span>',
    bindings: {
      entry: '<',
      longTextOnly: '<',
      showFullCardValidity: '<'
    },
    controller: CardsSummaryController,
    controllerAs: 'vm'
  }).name;

/** @ngInject */
function CardsSummaryController(
  lodash,
  $translate,
  swoaTranslatableFilter,
  swoaDateFilter
) {
  const vm = this;

  // configuration
  const TEXT_FIELD_LENGTH = 25;
  const TEXT_REPLACEMENT = '…';

  // ui data
  vm.shortText = null;
  vm.longText = null;

  vm.$onInit = () => {
    activate();
  };

  // //////////

  function activate() {
    vm.longText = lodash
      .map(vm.entry, cardSummary => {
        const cardSummaryName = swoaTranslatableFilter(cardSummary.name, 'de');
        const year =
          vm.showFullCardValidity === true
            ? `${swoaDateFilter(cardSummary.validFrom)}-${swoaDateFilter(
                cardSummary.validUntil
              )}`
            : cardSummary.year;
        return [
          cardSummaryName,
          $translate.instant(`card.type.short.${cardSummary.cardType}`),
          year
        ].join(' ');
      })
      .join(', ');

    if (vm.longText.length > TEXT_FIELD_LENGTH) {
      vm.shortText = vm.longText
        .substr(0, TEXT_FIELD_LENGTH - TEXT_REPLACEMENT.length)
        .concat(TEXT_REPLACEMENT);
    } else {
      vm.shortText = vm.longText;
    }
  }
}
