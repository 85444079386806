import angular from 'angular';
import * as lodash from 'lodash';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import appModule from './app/app.module';

Sentry.init({
  dsn: 'https://26ad96c3ffce4634aa92ac194062f680@sentry.puzzle.ch/7',
  integrations: [new Integrations.Angular()],
  environment: location.hostname,
});

// provide lodash for dependencies that need the module ngLodash
angular.module('ngLodash', ['ngSentry']).constant('lodash', lodash);

export default appModule;
