import angular from 'angular';
import 'restangular';

export default angular
  .module('swoa.login.service', [
    'restangular',
  ])
  .factory('loginService', loginService)
  .name;

/** @ngInject */
function loginService(Restangular) {
  return {
    sendCodeForUserName,
    sendCodeForEmail,
    resetPassword,
    findLoginCode,
    createUser,
  };

  // /////////////////

  function sendCodeForUserName(userName) {
    return Restangular.one('logincodes').customPOST(userName, 'reset-code-username');
  }

  function sendCodeForEmail(email) {
    return Restangular.one('logincodes').customPOST(email, 'reset-code-email');
  }

  function resetPassword(resetPasswordContainer) {
    return Restangular.one('logincodes').customPUT(resetPasswordContainer, 'reset-password');
  }

  function findLoginCode(code) {
    return Restangular.one('logincodes').withHttpConfig({ noCancelOnRouteChange: true }).customGET('find-code', { code });
  }

  function createUser(user, resetPasswordContainer) {
    return Restangular.one('logincodes').customPOST(user, 'create-user', {
      code: resetPasswordContainer.code,
      password: resetPasswordContainer.password,
    });
  }
}
