import angular from 'angular';
import swoaPersonSyncService from './person-sync.service';
import swoaPersonSyncPersonSync from './person-sync.component';

export default angular
  .module('swoa.person.sync', [
    swoaPersonSyncService,
    swoaPersonSyncPersonSync,
  ])
  .name;

