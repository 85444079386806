import angular from 'angular';
import angularTranslate from 'angular-translate';
import Chart from 'chart.js';
import jQuery from 'jquery';
import swoaConstants from '../../app.constants';
import swoaNotification from '../../components/notification/notification.module';
import swoaAsyncService from '../../components/async/async-helper.service';
import swoaDate from '../../components/date/date.filter';
import swoaCitySerive from '../../city/city.service';
import swoaReportService from './report.service';
import swoaUserService from '../../user/user.service';
import swoaReportHelper from './report-helper.service';
import htmlTemplate from './reports.html';
import {
  AXIS_CONFIGS,
  CHART_COLORS,
  CHART_LEGEND,
  CHART_PLUGIN_DATA_LABEL,
  CHART_TICK_EMPTY,
  CHART_TICKS_ABS,
  CHART_TOOLTIP_HORIZONTAL_ABS,
  CHART_TOOLTIP_VERTICAL_ABS,
  TOTAL_COLOR,
  TOTAL_LABEL,
  X_AXIS_SORT_OPTIONS
} from './report.constants';

export default angular
  .module('swoa.admin.report.reports', [
    angularTranslate,
    swoaConstants,
    swoaNotification,
    swoaAsyncService,
    swoaCitySerive,
    swoaReportService,
    swoaUserService,
    swoaDate,
    swoaReportHelper
  ])
  .component('swoaReports', {
    template: htmlTemplate,
    bindings: {},
    controller: ReportsController,
    controllerAs: 'vm'
  }).name;

export const NON_SERIALIZABLE_PROPERTIES = [
  'xAxisProperty',
  'dataSetProperty',
  'thirdAxisProperty'
];
export const NON_SERIALIZABLE_SORTS = ['xAxisSort'];

/** @ngInject */
function ReportsController(
  $rootScope,
  $scope,
  $timeout,
  moment,
  lodash,
  $translate,
  Constants,
  notificationService,
  reportHelperService,
  exportService,
  reportService,
  asyncHelperService,
  userService
) {
  const vm = this,
    onError = notificationService.errorHandler(vm),
    asyncHelper = asyncHelperService.createAsyncHelper(
      vm,
      $scope,
      onError,
      prepareData,
      { fetchResult: true }
    ),
    reportHelper = reportHelperService.createReportHelper(
      vm,
      $scope,
      'reportFavorites',
      {
        prepareToSerializeAsFavorite,
        unserializeAndLoadFavorite
      }
    );

  vm.notificationKey = null;
  vm.reportData = null;
  vm.currentChart = null;
  vm.startDates = undefined;
  vm.endDates = undefined;
  vm.axisConfigs = undefined;
  vm.xAxisSorts = X_AXIS_SORT_OPTIONS;
  vm.exportParams = getDefaultParams();
  vm.uiConfig = null;
  vm.selectionTypes = reportHelper.filterSelectionTypesByPermission(
    Constants.reports.selectionTypes
  );

  vm.resetDiagramParamsAndSearch = resetDiagramParamsAndSearch;
  vm.propertyChanged = propertyChanged;
  vm.renderChart = renderChart;
  vm.resetParams = resetParams;

  vm.$onInit = () => {
    activate();
  };

  // ////////

  function activate() {
    vm.cardTypes = Constants.cards.cardTypes;

    $rootScope.showSpinner = true;
    exportService
      .loadEarliestYear()
      .then(reportHelper.generateMonthsEntries, onError)
      .finally(() => ($rootScope.showSpinner = false));
    asyncHelper.getAsyncJobStatus();
    Chart.plugins.register(CHART_PLUGIN_DATA_LABEL(Chart));

    vm.uiConfig = userService.getCurrentUser().uiConfig;

    $scope.$watch('vm.exportParams.xAxisSort', renderChart);
    $scope.$watch('vm.exportParams.xAxisLabels', renderChart);
    $scope.$watch('vm.exportParams.dataSetLabels', renderChart);
    $scope.$watch('vm.exportParams.thirdAxisLabels', renderChart);

    AXIS_CONFIGS.forEach(config => (config.label = translate(config.label)));
    vm.xAxisSorts.forEach(config => (config.label = translate(config.label)));
    prepareLabels(true);
  }

  function propertyChanged() {
    $timeout(() => prepareLabels(true));
  }

  function getDefaultParams() {
    const exportParams = {
      selectionType: 'CURRENTLY_RELEASED_CARDS',
      withTotalX: true,
      withTotalSet: false,
      chartType: 'bar',
      stacked: false,
      percental: false,
      mirrorStacks: true,
      legendShort: false,
      xAxisProperty: lodash.find(vm.axisConfigs, { id: 0 }),
      dataSetProperty: lodash.find(vm.axisConfigs, { id: 1 }),
      thirdAxisProperty: null,
      xAxisSort: vm.xAxisSorts[0],
      xAxisLabels: null,
      dataSetLabels: null,
      thirdAxisLabels: null,
      showAxisLabels: true,
      showAxisTitles: true,
      showDataLabels: true,
      showLegend: true,
      showGrid: true,
      showSectorLabels: true
    };
    reportHelper.setExportDateRange(exportParams);
    return exportParams;
  }

  function resetParams() {
    vm.reportData = null;

    if (vm.currentChart) {
      vm.currentChart.destroy();
    }
    vm.currentChart = null;
    vm.exportParams = getDefaultParams();
  }

  function resetDiagramParamsAndSearch() {
    vm.exportParams.withTotalX = true;
    vm.exportParams.withTotalSet = false;
    vm.exportParams.chartType = 'bar';
    vm.exportParams.stacked = false;
    vm.exportParams.percental = false;
    vm.exportParams.mirrorStacks = true;
    vm.exportParams.legendShort = false;
    vm.exportParams.xAxisProperty = lodash.find(vm.axisConfigs, { id: 0 });
    vm.exportParams.dataSetProperty = lodash.find(vm.axisConfigs, { id: 1 });
    vm.exportParams.thirdAxisProperty = null;
    vm.exportParams.xAxisSort = vm.xAxisSorts[0];
    vm.exportParams.xAxisLabels = null;
    vm.exportParams.dataSetLabels = null;
    vm.exportParams.thirdAxisLabels = null;
    vm.exportParams.showAxisLabels = true;
    vm.exportParams.showAxisTitles = true;
    vm.exportParams.showDataLabels = true;
    vm.exportParams.showLegend = true;
    vm.exportParams.showGrid = true;
    vm.exportParams.showSectorLabels = true;
    vm.reportData = null;
    if (vm.currentChart) {
      vm.currentChart.destroy();
    }
    vm.currentChart = null;
    prepareLabels(true);
    search();
  }

  function search() {
    const params = {
      exportFrom: vm.exportParams.exportFrom,
      exportUntil: vm.exportParams.exportUntil,
      exportYear: vm.exportParams.exportYear,
      cardTypes: vm.exportParams.cardTypes,
      selectionType: vm.exportParams.selectionType
    };
    asyncHelper.showSpinner();
    if (vm.exportParams.selectionType === 'CARD_CHANGES') {
      reportService
        .loadCardChanges($translate.use(), params)
        .then(asyncHelper.getAsyncJobStatus)
        .catch(asyncHelper.errorHandler);
    } else {
      reportService
        .loadReport($translate.use(), params)
        .then(asyncHelper.getAsyncJobStatus)
        .catch(asyncHelper.errorHandler);
    }
  }

  function prepareData(data) {
    vm.reportData = data;

    vm.reportData.forEach(elem => {
      elem.age = elem.dateOfBirth
        ? moment().diff(elem.dateOfBirth, 'years')
        : null;
      elem.year = moment(elem.validFrom).get('year');
      if (vm.exportParams.selectionType === 'CARD_CHANGES') {
        if (elem.cardType === undefined) {
          elem.cardType = 'EMPTY';
        }
        if (elem.cardTypeNew === undefined) {
          elem.cardTypeNew = 'EMPTY';
        }
      }
      if (elem.validFromNew) {
        elem.yearNew = moment(elem.validFromNew).get('year');
      }
    });

    prepareLabels(false);
  }

  function prepareLabels(overwriteLabels) {
    if (vm.exportParams.selectionType !== 'CARD_CHANGES') {
      vm.axisConfigs = lodash.orderBy(
        lodash.filter(AXIS_CONFIGS, config => !config.isCardChange),
        'label'
      );
    } else {
      vm.axisConfigs = lodash.orderBy(AXIS_CONFIGS, 'label');
    }

    // all available values
    vm.allXAxisLabels = extractAxisLabels(
      vm.reportData,
      vm.exportParams.xAxisProperty
    );
    vm.allDataSetLabels = extractAxisLabels(
      vm.reportData,
      vm.exportParams.dataSetProperty
    );
    vm.allThirdAxisLabels = extractAxisLabels(
      vm.reportData,
      vm.exportParams.thirdAxisProperty
    );

    // currently selected values
    if (overwriteLabels) {
      vm.exportParams.xAxisLabels = angular.copy(vm.allXAxisLabels);
      vm.exportParams.dataSetLabels = angular.copy(vm.allDataSetLabels);
      vm.exportParams.thirdAxisLabels = angular.copy(vm.allThirdAxisLabels);
    }

    // if there should be a third axis, some restrictions apply
    if (vm.exportParams.thirdAxisProperty) {
      vm.exportParams.percental = false;
      vm.exportParams.stacked = true;
      if (vm.exportParams.thirdAxisLabels.length === 2) {
        vm.exportParams.mirrorStacks = true;
        vm.exportParams.legendShort = true;
      }
    }

    renderChart();
  }

  function sortXAxis(dataSets, xAxisLabels) {
    const sortConfig = vm.exportParams.xAxisSort;
    const prefixedTranslateFn = suffix =>
      translate(vm.exportParams.xAxisProperty.translatePrefix, suffix);
    let sortableXAxisElements = lodash.map(xAxisLabels, (label, index) => ({
      label,
      data: lodash.map(dataSets, set => set.data[index])
    }));
    sortableXAxisElements = lodash.orderBy(
      sortableXAxisElements,
      [sortConfig.sortFn(prefixedTranslateFn, lodash)],
      [sortConfig.order]
    );
    lodash.forEach(sortableXAxisElements, (elem, index) => {
      xAxisLabels[index] = elem.label;
      lodash.forEach(
        dataSets,
        (set, setIndex) => (set.data[index] = elem.data[setIndex])
      );
    });
  }

  function calculateTotal(dataSets, xAxisLabels, lines) {
    if (vm.exportParams.withTotalX) {
      xAxisLabels.unshift(TOTAL_LABEL);
      dataSets.forEach(set => {
        const total = vm.exportParams.percental
          ? round100(lodash.sum(set.data) / set.data.length)
          : lodash.sum(set.data);
        set.data.unshift(total);
        set.backgroundColor.unshift(set.backgroundColor[0]);
      });
    }
    if (vm.exportParams.withTotalSet) {
      dataSets.push({
        label: translate(TOTAL_LABEL),
        data: lodash.map(xAxisLabels, (l, idx) =>
          lodash.sum(lodash.map(dataSets, set => set.data[idx]))
        ),
        backgroundColor: lodash.map(xAxisLabels, () => TOTAL_COLOR),
        borderColor: lines ? TOTAL_COLOR : undefined,
        borderWidth: lines ? 2 : 1,
        lineTension: 0,
        fill: false
      });
    }
  }

  function countByProperties(dataSetValue) {
    const thirdProperty = vm.exportParams.thirdAxisProperty;
    if (thirdProperty) {
      const result = {};
      const grouped = lodash.groupBy(vm.reportData, prop =>
        mapValues(prop, thirdProperty)
      );
      lodash.forEach(vm.exportParams.thirdAxisLabels, lbl => {
        result[lbl] = lodash.countBy(grouped[lbl], prop =>
          mapValues(prop, vm.exportParams.xAxisProperty)
        );
      });
      return result;
    }
    return lodash.countBy(dataSetValue, prop =>
      mapValues(prop, vm.exportParams.xAxisProperty)
    );
  }

  function makeDataSet(
    dataSetLabel,
    thirdAxisLabel,
    data,
    xAxisLabels,
    noStacks
  ) {
    const lines = vm.exportParams.chartType === 'line';
    const dataSetLabelIndex = vm.exportParams.dataSetLabels.indexOf(
      dataSetLabel
    );
    const thirdAxisLabelTranslated = thirdAxisLabel
      ? translate(
          vm.exportParams.thirdAxisProperty.translatePrefix,
          thirdAxisLabel
        )
      : null;
    const dataSetLabelTranslated = translate(
      vm.exportParams.dataSetProperty.translatePrefix,
      dataSetLabel
    );
    const dataSet = {
      legendShort: dataSetLabelTranslated,
      label: thirdAxisLabel
        ? `${thirdAxisLabelTranslated} - ${dataSetLabelTranslated}`
        : dataSetLabelTranslated,
      data,
      backgroundColor: lodash.map(
        xAxisLabels,
        () => CHART_COLORS[dataSetLabelIndex]
      ),
      borderColor: lines ? CHART_COLORS[dataSetLabelIndex] : undefined,
      borderWidth: lines ? 2 : 1,
      lineTension: 0,
      fill: false
    };
    if (vm.exportParams.stacked) {
      dataSet.stack = !noStacks ? thirdAxisLabel || null : null;
    }
    return dataSet;
  }

  function createDataSets(dataSetValues, dataSetLabel, xAxisLabels) {
    const dataSets = [];
    const dataSetValue = dataSetValues[dataSetLabel];
    let byAxisProperties = countByProperties(dataSetValue);

    if (vm.exportParams.percental) {
      byAxisProperties = lodash.mapValues(byAxisProperties, (val, property) =>
        getPercentage(
          val,
          property,
          dataSetValues,
          vm.exportParams.xAxisProperty
        )
      );
    }

    if (vm.exportParams.thirdAxisProperty) {
      const labels = vm.exportParams.thirdAxisLabels;
      // special case for two sets, put them in the same stack and negate
      if (showStacksMirrored()) {
        const firstSet = lodash.map(
          xAxisLabels,
          l => byAxisProperties[labels[0]][l]
        );
        const secondSet = lodash.map(
          xAxisLabels,
          l => byAxisProperties[labels[1]][l]
        );
        dataSets.push(
          makeDataSet(dataSetLabel, labels[0], firstSet, xAxisLabels, true)
        );
        dataSets.push(
          makeDataSet(
            dataSetLabel,
            labels[1],
            lodash.map(secondSet, val => val * -1),
            xAxisLabels,
            true
          )
        );
      } else {
        lodash.forEach(byAxisProperties, (byXAxisProperty, thirdAxisLabel) => {
          dataSets.push(
            makeDataSet(
              dataSetLabel,
              thirdAxisLabel,
              lodash.map(xAxisLabels, l => byXAxisProperty[l]),
              xAxisLabels
            )
          );
        });
      }
    } else {
      dataSets.push(
        makeDataSet(
          dataSetLabel,
          null,
          lodash.map(xAxisLabels, l => byAxisProperties[l] || 0),
          xAxisLabels
        )
      );
    }

    return dataSets;
  }

  function renderChart() {
    if (
      !vm.reportData ||
      !vm.reportData.length ||
      !vm.exportParams.xAxisLabels
    ) {
      return;
    }

    if (
      vm.exportParams.thirdAxisLabels &&
      vm.exportParams.thirdAxisLabels.length !== 2
    ) {
      vm.exportParams.mirrorStacks = false;
    }

    const dataSetTitle = `${translate('app.state.cards')} ${
      vm.exportParams.percental ? ' (%)' : ''
    }`;
    const lines = vm.exportParams.chartType === 'line';
    const horizontal = vm.exportParams.chartType === 'horizontalBar';
    const xAxisLabels = angular.copy(vm.exportParams.xAxisLabels);
    const dataSetValues = lodash.groupBy(vm.reportData, prop =>
      mapValues(prop, vm.exportParams.dataSetProperty)
    );
    let sectorLabels = null;
    let dataSets = [];

    lodash.forEach(vm.exportParams.dataSetLabels, dataSetLabel => {
      dataSets.push(
        ...createDataSets(dataSetValues, dataSetLabel, xAxisLabels, lines)
      );
    });

    dataSets = lodash.orderBy(dataSets, ['stack', 'label']);

    if (showStacksMirrored() && vm.exportParams.showSectorLabels) {
      sectorLabels = lodash.map(vm.exportParams.thirdAxisLabels, l =>
        translate(vm.exportParams.thirdAxisProperty.translatePrefix, l)
      );
    }

    sortXAxis(dataSets, xAxisLabels);
    calculateTotal(dataSets, xAxisLabels, lines);
    const hasValues =
      dataSets.length > (vm.exportParams.withTotalSet ? 1 : 0) &&
      xAxisLabels.length > (vm.exportParams.withTotalX ? 1 : 0);

    replaceChart({
      type: vm.exportParams.chartType,
      data: {
        labels: lodash.map(xAxisLabels, l =>
          translate(vm.exportParams.xAxisProperty.translatePrefix, l)
        ),
        datasets: dataSets
      },
      hover: {
        mode: 'label'
      },
      options: {
        backgroundColor: 'rgb(250, 250, 250)',
        responsive: true,
        maintainAspectRatio: true,
        showDataLabels: hasValues && vm.exportParams.showDataLabels,
        sectorLabels,
        legend: {
          display: vm.exportParams.showLegend,
          position: 'right',
          labels: {
            padding: lines ? 20 : 10,
            generateLabels: CHART_LEGEND(lodash)
          },
          short: vm.exportParams.legendShort,
          paddingLeft: lines ? 20 : 0
        },
        layout: {
          padding: {
            top: 20
          }
        },
        title: {
          display: false
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: horizontal
              ? CHART_TOOLTIP_HORIZONTAL_ABS
              : CHART_TOOLTIP_VERTICAL_ABS
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
                display: vm.exportParams.showAxisLabels,
                autoSkip: false,
                callback: hasValues ? CHART_TICKS_ABS : CHART_TICK_EMPTY
              },
              stacked: vm.exportParams.stacked,
              gridLines: {
                display: vm.exportParams.showGrid
              },
              scaleLabel: {
                display: vm.exportParams.showAxisTitles,
                labelString: horizontal
                  ? dataSetTitle
                  : vm.exportParams.xAxisProperty.label
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                padding: lines ? 20 : 10,
                beginAtZero: true,
                display: vm.exportParams.showAxisLabels,
                callback: hasValues ? CHART_TICKS_ABS : CHART_TICK_EMPTY
              },
              stacked: vm.exportParams.stacked,
              gridLines: {
                display: vm.exportParams.showGrid
              },
              scaleLabel: {
                display: vm.exportParams.showAxisTitles,
                labelString: horizontal
                  ? vm.exportParams.xAxisProperty.label
                  : dataSetTitle
              }
            }
          ]
        }
      }
    });
  }

  function showStacksMirrored() {
    return (
      vm.exportParams.thirdAxisLabels &&
      vm.exportParams.thirdAxisLabels.length === 2 &&
      vm.exportParams.mirrorStacks
    );
  }

  function replaceChart(chartParams) {
    const ctx = jQuery('#chart');

    if (vm.currentChart) {
      vm.currentChart.destroy();
    }

    vm.currentChart = new Chart(ctx, chartParams);
  }

  function prepareToSerializeAsFavorite() {
    const reportConfig = angular.copy(vm.exportParams);
    if (reportConfig) {
      NON_SERIALIZABLE_PROPERTIES.forEach(prop => {
        if (reportConfig[prop]) {
          reportConfig[prop] = reportConfig[prop].id;
        }
      });
      NON_SERIALIZABLE_SORTS.forEach(prop => {
        if (reportConfig[prop]) {
          reportConfig[prop] = reportConfig[prop].id;
        }
      });
    }
    return reportConfig;
  }

  function unserializeParams(storedConfig) {
    const reportConfig = angular.copy(storedConfig);
    if (!reportConfig) {
      return;
    }
    NON_SERIALIZABLE_PROPERTIES.forEach(prop => {
      if (!lodash.isNil(reportConfig[prop])) {
        reportConfig[prop] = lodash.find(vm.axisConfigs, [
          'id',
          reportConfig[prop]
        ]);
      }
    });
    NON_SERIALIZABLE_SORTS.forEach(prop => {
      if (!lodash.isNil(reportConfig[prop])) {
        reportConfig[prop] = lodash.find(vm.xAxisSorts, [
          'id',
          reportConfig[prop]
        ]);
      }
    });
    vm.exportParams = reportConfig;
  }

  function unserializeAndLoadFavorite($favorite) {
    const selected = angular.copy($favorite);
    delete selected.name;
    unserializeParams(selected);
    vm.reportData = null;
    search();
  }

  function translate(prefix, key) {
    return $translate.instant(`${prefix}${key || ''}`);
  }

  function round100(val) {
    return Math.round(val * 100) / 100;
  }

  function mapValues(valueObj, config) {
    const value = valueObj[config.dataProperty];
    if (config.valueFn === null || lodash.isNil(value)) {
      return value || null;
    }
    return config.valueFn(value);
  }

  function extractAxisLabels(allData, propertyConfig) {
    if (!propertyConfig) {
      return [];
    }
    let arr = lodash.map(allData, data => mapValues(data, propertyConfig));
    arr = lodash.filter(lodash.uniq(arr), item => !lodash.isNil(item));
    if (lodash.isNumber(arr[0])) {
      return arr.sort((a, b) => {
        if (!lodash.isNumber(a)) {
          return -1;
        } else if (!lodash.isNumber(b)) {
          return 1;
        }
        return a - b;
      });
    }
    return arr.sort((a, b) =>
      translate(propertyConfig.translatePrefix, a).localeCompare(
        translate(propertyConfig.translatePrefix, b)
      )
    );
  }

  function getPercentage(val, property, dataSetValues, xAxisProperty) {
    let total = 0;
    lodash.map(dataSetValues, dataSetValue => {
      total += lodash.countBy(dataSetValue, prop =>
        mapValues(prop, xAxisProperty)
      )[property];
    });
    return round100((val / total) * 100);
  }
}
