import angular from 'angular';
import swoaPasswordStrengthComponent from './password-strength.component';
import swoaPasswordStrengthFilter from './password-strength.filter';

export default angular
  .module('swoa.password-strength', [
    swoaPasswordStrengthComponent,
    swoaPasswordStrengthFilter,
  ])
  .name;

