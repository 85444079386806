import angular from 'angular';
import mdDataTable from 'angular-material-data-table';
import swoaConstants from '../../app.constants';
import arrowUp from './arrow-up.svg';
import swoaDataTableDataTable from './data-table.component';
import swoaDataTableDataTableSearch from './data-table-search.component';
import swoaDataTableDataTablePagination from './data-table-pagination.directive';
import swoaDataTableFilter from './data-table.filter';
import swoaDataTableHelper from './data-table-helper.service';

export default angular
  .module('swoa.data-table', [
    mdDataTable,
    swoaConstants,
    arrowUp,
    swoaDataTableDataTable,
    swoaDataTableDataTableSearch,
    swoaDataTableDataTablePagination,
    swoaDataTableFilter,
    swoaDataTableHelper,
  ])
  .name;
